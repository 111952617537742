import { createSlice } from "@reduxjs/toolkit"
import en from "../../../utils/i18n/en.json"
import es from "../../../utils/i18n/es.json"

// Leer el idioma desde localStorage si existe, de lo contrario, usar el del navegador
const storedLanguage ="es"; //localStorage.getItem('language');
const userLocale = storedLanguage || (navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language);

const getDictionary = (idLenguage: string) => {
    if (idLenguage === 'es') return es;
    else if (idLenguage === 'en') return en;
    return en;
}

const idLenguage = userLocale.split("-")[0];

const getIconString = (idLenguage: string) => {
    if (idLenguage === 'es') return "es";
    else if (idLenguage === 'en') return "us";
    return "us";
}
const initialState = {
    language: idLenguage,
    dictionary: getDictionary(idLenguage),
    iconLanguage: getIconString(idLenguage)
}

const languageSlice = createSlice({
    name: 'language',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            const { language } = action.payload;
            state.language = language;
            localStorage.setItem('language', language); // Guardar el idioma en localStorage
        },
        setDictIonary: (state, action) => {
            const { dictionary } = action.payload;
            state.dictionary = getDictionary(dictionary);
            localStorage.setItem('language', dictionary); // Guardar el idioma en localStorage
        },
        setIcon: (state, action) => {
            const { language } = action.payload;
            state.iconLanguage = getIconString(language);
        },
    },
})

export const { setLanguage, setDictIonary, setIcon } = languageSlice.actions;

export default languageSlice.reducer;

export const selectCurrentLanguage = (state: any) => state.language.language;
export const selectCurrentDictonary = (state: any) => state.language.dictionary;
export const selectCurrentIconLanguage = (state: any) => state.language.iconLanguage;