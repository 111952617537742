import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetIndustriesMutation, useGetJobTitlesMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectIndustries, selectJobTitles, setIndustries, setJobTitles } from '../../../redux/features/dimensions/dimensionsSlice';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


export default function GetIndustries() {

    const dispatch = useDispatch()
    const dictionary = useSelector(selectCurrentDictonary);
    const value = useSelector(selectIndustries)
    const [get, { isLoadingGet }]: any = useGetIndustriesMutation()


    const fetch = async () => {
        const res: any = await get();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setIndustries({ industries: resData }))
    }

    useEffect(() => {
        if (!value || value.length == 0) {
            fetch()
        }
        /*  */
    }, [value]);

    return value;
}