import './UserProfileExternal.scss'
import { useState, useEffect } from 'react';

import Card from '../../components/Card/Card'
import { useDispatch, useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import "./FormularioSolicitarReunion.scss";
import Label from '../../components/Form/Label';
import Select from '../../components/Form/Select';
import { Form, Formik } from 'formik';
import TextAreaInput from '../../components/Form/TextAreaInput';
import { usePostUserCrearSolicitudMutation,  usePostUserRespuestaSolicitudRecibidaMutation } from '../../redux/features/meetings/meetingsApiSlice';
import moment from 'moment';
import { changeDonatedTimeZone } from '../../utils/utilsForms';
import GetActualTimeZone from '../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

const FormularioSolicitarReunion = ({ setHorasDonadas, user_id_profile, user_donated_times, handleOnClose, QR = false }: any) => {


    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    const deleteDonateTime = (id: any) => {
        const newUserDonateTimes = user_donated_times.filter((bloque: any) => bloque.Id !== id)
        setHorasDonadas(newUserDonateTimes)
    }
    const actual_time_zone = GetActualTimeZone()

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id; //esto antes era una const

    //considerar algun usseffect y poner otra variable  pero de tipo no const, quizas al moento de montar el renderizado no se guarda bien, ponerlo dps.
    const dispatch = useDispatch()
    const [postUserCrearSolicitud, { isLoadingPostUserCrearSolicitud }]: any = usePostUserCrearSolicitudMutation()

    let inicialValues = {
        meetingTimeId: 0,
        meetingTypeId: 1,
        meetingReason: "",
    }

    const [actual_values, set_actual_values] = useState(inicialValues)
    const [validated, set_validated] = useState(false)
    const [selectedTimes, setSelectedTimes] = useState([]);

    useEffect(() => {
        if (QR) {
            set_validated(true);
        }
    }, [QR]);

    const [postUserResponderSolicitud, { isLoadingPostUserResponderSolicitud }]: any = usePostUserRespuestaSolicitudRecibidaMutation()
    let datos_aux: any = []
    user_donated_times?.map((item: any) => {
        let dict = changeDonatedTimeZone(actual_time_zone, item.Date.split("T")[0], item.StartTime.split("T")[1], item.FinishTime.split("T")[1])
        datos_aux.push({
            id: item.Id,
            Date: dict.date,
            DateTime: item.Date.split("T")[0],
            StartTime: dict.startTime,
            EndTime: dict.endTime,
            TimeZoneOffset: item.TimeZoneOffset
        })
    })
    datos_aux.sort(function (left: any, right: any) {
        return moment.utc(left.DateTime).diff(moment.utc(right.DateTime))
    });


    let lista_bloques_tiempo_html = <></>
    let prev_date: any = "" // inicia vacio ya que vacio es distinto de actual_date y asi muestra la primera fecha
    if (datos_aux) {
        lista_bloques_tiempo_html = <div className='row no-gutters'>
            {datos_aux?.map((item: any) => {
                let claseCard = "card-bloque mb-2"

                if (actual_values.meetingTimeId == item.id) {
                    claseCard = claseCard + " card-bloque-seleccionado"
                }
                let actual_date = item.Date
                let date_html = <></>
                if (actual_date != prev_date) {
                    if (prev_date == "") {
                        date_html = <div className='d-flex justify-content-center w-100 actual_date_donar_tiempo mb-2'><h5><b>{actual_date}</b></h5></div>
                    } else {
                        date_html = <div className='mt-4 d-flex justify-content-center w-100 actual_date_donar_tiempo mb-2'><h5><b>{actual_date}</b></h5></div>
                    }
                }
                prev_date = actual_date
                return (
                    <>
                        <div className='col-12'>
                            {date_html}
                        </div>
                        <div className='col-12'>
                            <Card className={claseCard} handleOnClick={() => set_actual_values({ ...actual_values, meetingTimeId: item.id })}>
                                {/* {item.id}
                                {item.Date} */}
                                <div className='d-flex justify-content-start justify-content-lg-center'>
                                    <div className='mr-4'>
                                        {item.StartTime.split(":")[0] + ":" + item.StartTime.split(":")[1]}
                                    </div>
                                    <div className='mr-4'>
                                        {"-"}
                                    </div>
                                    <div>
                                        {item.EndTime.split(":")[0] + ":" + item.EndTime.split(":")[1]}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </>
                )
            })}
        </div>
    }

    function validate(values: any) {
        let errors: any = {}
        set_actual_values({ ...actual_values, ...values, meetingTimeId: actual_values.meetingTimeId })

        if (Object.keys(errors).length === 0) {
            set_validated(true)
        } else {
            set_validated(false)
        }
        return errors
    }

    // MOSTRAR SELECTOR DE TIPO Y MENSAJE LUEGO DE SELECCIONAR UN BLOQUE DE HORA DONADA
    let formulario_request_html = <></>
    if (actual_values.meetingTimeId) {
        // Mostrar formulario de solicitud o solo botón dependiendo de si QR está activo
        if (actual_values.meetingTimeId) { // Se verifica si se ha seleccionado algún bloque de tiempo
            if (!QR) { // Modo normal, se muestra todo el formulario
                formulario_request_html = <>
                    <Formik
                        initialValues={inicialValues}
                        validate={validate}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={() => { }}
                    >
                        <Form className='m-0'>
                            <div className='row'>
                                <div className='col-12 mb-4 '>
                                    <Label classNameLabel='label-primary label' label={textPublicProfile.param_4} ></Label>
                                    <Select className=" w-100" name="meetingTypeId" label="">
                                        <option value="1">{textPublicProfile.option_1}</option>
                                        <option value="2">{textPublicProfile.option_2}</option>
                                        <option value="3">{textPublicProfile.option_3}</option>
                                        <option value="4">{textPublicProfile.option_4}</option>
                                    </Select>
                                </div>
                                <div className='col-12 mb-4 '>
                                    <Label classNameLabel='label-primary label' label={textPublicProfile.label_7} ></Label>
                                    <TextAreaInput className="w-100" name="meetingReason" type="text" label="" showErrorText={true} defaultValue={""} />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-rounded btn-white mr-2' onClick={handleOnClose}>{textPublicProfile.btn_cancel}</button>
                        <button disabled={!validated} onClick={handleSubmit} className='btn btn-rounded btn-primary'>{textPublicProfile.btn_request}</button>
                    </div>
                </>;
            } else { // Modo QR, solo muestra el botón "Solicitar"
                formulario_request_html = <>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-rounded btn-white mr-2' onClick={handleOnClose}>{textPublicProfile.btn_cancel}</button>
                        <button className={`btn btn-rounded btn-primary`} onClick={handleSubmit}>
                            {textPublicProfile.btn_request} </button>
                    </div>
                </>;
            }
        } else {
            formulario_request_html = <>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-rounded btn-white mr-2' onClick={handleOnClose}>{textPublicProfile.btn_cancel}</button>
                </div>
            </>;
        }


    }
    let user_id_confirm = user_personal_data.Id

    async function handleSubmit() {
        handleOnClose()
        let new_alert = { id: "enviando_solicitud", type: "loading", title: textPublicProfile.param_1, desc: "", close: false } // esto iba despues de delete onatetime
        dispatch(addAlert({ alert: new_alert })) // esto iba despues de deletedonatetime y despues de lo de arriba




        try {
            if (user_id == 0) {
                if (user_id_confirm == 0) {
                    throw new Error("Error de ids");
                } else {
                    user_id = user_id_confirm
                }
            }
            const meetingReason = QR ? "Solicitud de reunión mediante QR" : actual_values.meetingReason;
            const meetingTypeId = QR ? 6 : actual_values.meetingTypeId;
            let item = {
                "userRequestedId": user_id_profile,
                "userRequestId": user_id,
                "meetingTypeId": meetingTypeId,
                "meetingTimeId": actual_values.meetingTimeId,
                "meetingReason": meetingReason,
            }
            deleteDonateTime(actual_values.meetingTimeId) // esto estaba afuera del try
            const responseCrearSolicitud = await postUserCrearSolicitud({ body: item });


            // console.log(responseCrearSolicitud)
            if (responseCrearSolicitud.data.responseMessage === 'Error de UserRequestId') {
                console.log("Error desde el backend")
                throw new Error("Error de ids");
            }
            // nuevo código para obtener el meetingid
          
            if (item.meetingTypeId == 6){
             
            const responseData = responseCrearSolicitud.data;
            // console.log("responseData",responseData)

            // Acceder al ID de la reunión
            let meetingId;
            if (responseData && responseData.responseData) {
                // console.log("entrando al if")
                const meetingResponse = JSON.parse(responseData.responseData);
                meetingId = meetingResponse.MeetingRequestId;
            }

            // console.log("Meeting ID:", meetingId);
            
            let itemConfirm = {
                "meetingRequestId": meetingId,
                "meetingRequestStatusId": 2,
                "reason": "Solicitud generada automáticamente por qr",
                "IsDirect": 1 // Si es igual a 1 se toma como donación directa,y no se añaden modificaciones y se busca actualizar los correos enviados (esto falta...)
            }
            await postUserResponderSolicitud({ body: itemConfirm });

        }

            // fin codigo obtener meetingid
            dispatch(deleteAlert({ alert_id: "enviando_solicitud" }));
            let new_alert2 = { type: "success", timeout: 2000, title: textPublicProfile.param_2, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "enviando_solicitud" }));
            let new_alert2 = { type: "error", title: textPublicProfile.param_3, desc: textPublicProfile.error0, close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        }
    }

    return (
        <>
            <div className={`row ${QR ? 'qr-mode' : ''}`}> {/* Agrega la clase 'qr-mode' si QR es true */}
                <div className='col-12 mb-4'>
                    <p>{textPublicProfile.desc_1}</p>
                </div>
                <div className='col-12 col-lg-6'>
                    <Label classNameLabel='label-primary label' label={textPublicProfile.label_8} ></Label>
                    <div className='lista_bloques_tiempo_container'>
                        {lista_bloques_tiempo_html}

                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    {formulario_request_html}
                </div>
            </div>
            {/* <div className='d-flex justify-content-end'>
                <button className='btn btn-rounded btn-white mr-2' onClick={handleOnClose}>{textPublicProfile.btn_cancel}</button>
                <button disabled={!validated} onClick={handleSubmit} className='btn btn-rounded btn-primary'>{textPublicProfile.btn_request}</button>
            </div> */}
        </>
    )
}

export default FormularioSolicitarReunion;