

import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import AlertTitle from '@mui/material/AlertTitle';

import Alert from '@mui/material/Alert';
import { selectAlertsList, setAlertsList } from '../../redux/features/alerts/alertsSlice';

import './Alerts.scss'

function Alerts(){

    const alerts_list = useSelector(selectAlertsList)
  
  
    const dispatch = useDispatch()
  
    function handleCloseAlert(id: any) {
      let alerts_list_aux = alerts_list.filter((obj: any) => id !== obj.id);
      dispatch(setAlertsList({ alerts_list: alerts_list_aux }))
    }
    return (
        <div className='alerts-container'>
        {
          (alerts_list?.map((item: any) => {
            let content = <>
              <AlertTitle className='alerts-title'>{item.title}</AlertTitle>
              <p className='alerts-desc'>
                {(item.desc ? item.desc : "")}
              </p>
            </>
            
            if (item.type == "success" || item.type == "error" || item.type == "info" || item.type == "warning") {
              if (item.close) {
                return (<Alert key={"alert-" + item.id} className="mb-2" variant="filled" severity={item.type} onClose={() => handleCloseAlert(item.id)} >{content}</Alert>)
              } else {
                return (<Alert key={"alert-" + item.id} className="mb-2" variant="filled" severity={item.type}  >{content}</Alert>)
              }
            } else if (item.type == "loading") {
              if (item.close) {
                return (<Alert key={"alert-" + item.id} className="mb-2 loading-alert" icon={<CircularProgress className="loading-alert-icon" size="1.5rem" />} onClose={() => handleCloseAlert(item.id)}>{content}</Alert>)
              } else {
                return (<Alert key={"alert-" + item.id} className="mb-2 loading-alert" icon={<CircularProgress className="loading-alert-icon" size="1.5rem" />} >{content}</Alert>)
              }
            }
          }))
        }
      </div>
    )
}

export default Alerts;