import { apiSlice } from "../../api/apiSlice";



export const startupsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        /* Startups */
        getUserStartups: builder.mutation({ 
            query: values => ({
                url: '/api/Startups/' + values.user_id,
                method: 'GET',
            })
        }),
        putUserStartups: builder.mutation({ 
            query: values => ({
                url: '/api/Startups/' + values.user_id,
                method: 'PUT',
                body: values.body
            })
        }),
        postUserStartups: builder.mutation({ 
            query: values => ({
                url: '/api/Startups/' + values.user_id,
                method: 'POST',
                body: values.body
            })
        }),
        deleteUserStartups: builder.mutation({ 
            query: values => ({
                url: '/api/Startups/RemoveUserStartup/' + values.startup_id,
                method: 'DELETE'
            })
        }),

    })
})


export const { 
    useGetUserStartupsMutation, 
    usePutUserStartupsMutation, 
    usePostUserStartupsMutation,
    useDeleteUserStartupsMutation,
} = startupsApiSlice


