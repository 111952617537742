import { createSlice } from "@reduxjs/toolkit";
import { store } from '../../store';

interface alerts {
    id: string;
    type: string;
    title: string;
    desc?: string;
    timeout?: number;
    close?: boolean;
}

export interface initialStateAlerts {
    alerts_list: alerts[];
}

// Estado inicial de alertas
const initialState: initialStateAlerts = {
    alerts_list: []
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState: initialState,
    reducers: {
        setAlertsList: (state, action) => {
            const { alerts_list } = action.payload;
            state.alerts_list = alerts_list;
        },
        addAlert: (state, action) => {
            const { alert } = action.payload;

            // Validar que `alert` esté correctamente definido
            if (!alert) {
                console.error("El objeto alert no está definido:", alert);
                return;
            }

            // Asegurar que `alert` tenga un `id`
            let new_id: string = alert.id || "";
            if (!alert.id) {
                // Si no hay `id`, genera uno basado en el máximo existente
                let maxId = 0;

                state.alerts_list.forEach(item => {
                    const numericId = parseInt(item.id, 10);
                    if (!isNaN(numericId) && numericId > maxId) {
                        maxId = numericId;
                    }
                });

                new_id = (maxId + 1).toString();
            }

            // Crear la nueva alerta con el `id` asegurado
            const aux_alert: alerts = { ...alert, id: new_id };
            state.alerts_list = [...state.alerts_list, aux_alert];

            // Verificar `timeout` para eliminar la alerta después de un tiempo
            if (aux_alert.timeout) {
                setTimeout(() => {
                    store.dispatch(deleteAlert({ alert_id: aux_alert.id })); // Utilizar `aux_alert.id` en lugar de `new_id`
                }, aux_alert.timeout);
            }
        },
        deleteAlert: (state, action) => {
            const { alert_id } = action.payload;

            // Validar que `alert_id` esté correctamente definido
            if (!alert_id) {
                console.error("No se puede eliminar una alerta sin un `alert_id` válido:", alert_id);
                return;
            }

            // Filtrar y eliminar la alerta correspondiente
            state.alerts_list = state.alerts_list.filter(obj => alert_id !== obj.id);
        },
    },
});

export const { setAlertsList, addAlert, deleteAlert } = alertsSlice.actions;

export default alertsSlice.reducer;

// Selector para obtener la lista de alertas del estado global
export const selectAlertsList = (state: any) => state.alerts.alerts_list;
