import { useSelector } from 'react-redux';
import './UserProfileExternal.scss'
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';


const UserProfileExternalIdiomasTags = ({ user_languages, user_tags }: any) => {


    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    let user_languages_html = <></>
    if (user_languages && user_languages.length > 0) {
        user_languages_html = <div className='row no-gutters'>
            {user_languages.map((item: any) => {
                return (
                    <div className='col-auto'>
                        <div className='user-profile-idiomas-tags-item d-block mr-2 mb-2'>
                            <p>{item.LanguageName}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    } else {
        user_languages_html = <div className=''>
            <p className='text-white'>{textPublicProfile.label_3}</p>
        </div>
    }

    /* Tags */
    let user_tags_html = <></>
    if (user_tags && user_tags.length > 0) {
        user_tags_html = <div className='row no-gutters'>
            {user_tags.map((item: any) => {
                return (
                    <div className='col-auto'>
                        <div className='user-profile-idiomas-tags-item d-block mr-2 mb-2'>
                            <p>{item.TagName}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    } else {
        user_tags_html = <div className=''>
            <p className='text-white'>{textPublicProfile.label_4}</p>
        </div>
    }

    return (
        <div className="user-profile-idiomas-tags padding-layout-x">
            <div className='row'>
                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <h4 className='text-white mb-2 font-italic fs-titulo-profile'><b>{textPublicProfile.titulo_1}</b></h4>
                    <div className='user-profile-idiomas-tags-item-container '>
                        {user_languages_html}
                    </div>
                </div>
                <div className="col-12 col-lg-8 ">
                    <h4 className='text-white mb-2 font-italic fs-titulo-profile'><b>{textPublicProfile.titulo_2}</b></h4>
                    <div className='user-profile-idiomas-tags-item-container '>
                        {user_tags_html}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfileExternalIdiomasTags;