

import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import './EvaluateCounterpartsDialog.scss';

interface EvaluateCounterpartsDialogProps {
    open: boolean;
    onClose: () => void;
    counterparts: {
        name: string;
        hasFeedback: boolean;
    }[];
    onEvaluate: (name: string) => void;
}

const EvaluateCounterpartsDialog: React.FC<EvaluateCounterpartsDialogProps> = ({
    open,
    onClose,
    counterparts,
    onEvaluate,
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Selecciona una contraparte para evaluar</DialogTitle>
            <DialogContent>
                {counterparts.length === 0 ? (
                    <Typography>No hay contrapartes disponibles para evaluar.</Typography>
                ) : (
                    <List>
                        {counterparts.map((cp, index) => (
                            <ListItem key={index} divider>
                                <ListItemText primary={cp.name} />
                                <Box display="flex" alignItems="center">
                                    {cp.hasFeedback ? (
                                        <Box display="flex" alignItems="center">
                                            <CheckCircleIcon color="success" />
                                            <Typography variant="button" ml={1} color="success.main">
                                                Evaluado
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<EditIcon />}
                                            onClick={() => onEvaluate(cp.name)}
                                        >
                                            Evaluar
                                        </Button>
                                    )}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    CERRAR
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EvaluateCounterpartsDialog;
