import React, { useState } from 'react';
import { Box, FormControl, Select, MenuItem, SelectChangeEvent, CircularProgress } from '@mui/material';
import GenericCard from './GenericCard';
import './GenericCardList.scss';
import '../../styles/common-select.scss';

import { ActionButtonProps } from './GenericCard';

interface GenericCardListProps<T> {
  columns: string[];
  data: (T & { actionButtons?: ActionButtonProps<T>[] })[];
  isLoading?: boolean;
  renderers?: {
    [key: string]: (value: any) => React.ReactNode;
  };
}

const GenericCardList = <T,>({
  columns,
  data,
  isLoading,
  renderers = {},
}: GenericCardListProps<T>) => {
  const [filters, setFilters] = useState<Record<string, string>>({});

  const handleFilterChange = (column: string) => (event: SelectChangeEvent<string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: event.target.value,
    }));
  };

  const filterData = () => {
    return data.filter((item) =>
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true;

        const fieldValue = item[key as keyof T];
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((subItem) =>
            typeof subItem === 'object' && subItem !== null
              ? (
                (typeof subItem.name === 'string' && subItem.name.toLowerCase().includes(value.toLowerCase())) ||
                (typeof subItem.lastName === 'string' && subItem.lastName.toLowerCase().includes(value.toLowerCase()))
              )
              : String(subItem).toLowerCase().includes(value.toLowerCase())
          );
        }

        return String(fieldValue).toLowerCase().includes(value.toLowerCase());
      })
    );
  };

  const filteredData = filterData();

  return (
    <Box className="generic-card-list-container">
      {isLoading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box className="filter-container">
            {columns.map((column) => (
              <FormControl
                variant="outlined"
                size="small"
                key={column}
                className="common-form-control"
              >
                <Select
                  value={filters[column] || ''}
                  onChange={handleFilterChange(column)}
                  displayEmpty
                  renderValue={(value) =>
                    value === '' ? `${column}` : value
                  }
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {Array.from(
                    new Set(
                      data.flatMap((item) => {
                        const field = item[column as keyof T];
                        if (Array.isArray(field)) {
                          // Descomponer los arrays en elementos individuales
                          return field.map((subItem) => {
                            if (typeof subItem === 'object' && subItem !== null) {
                              if ('name' in subItem && typeof subItem.name === 'string') {
                                return subItem.name;
                              } else {
                                return String(subItem);
                              }
                            } else {
                              return String(subItem);
                            }
                          });
                        } else if (typeof field === 'object' && field !== null) {
                          if ('name' in field && typeof field.name === 'string') {
                            return field.name;
                          } else {
                            return String(field);
                          }
                        }
                        return String(field);
                      })
                    )
                  )
                    .filter((option): option is string => typeof option === 'string' && option !== '')
                    .sort((a: string, b: string) => a.localeCompare(b))
                    .map((option: string, index: number) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ))}
          </Box>

          <Box className="generic-card-list">
            {filteredData.map((item, index) => (
              <GenericCard
                key={index}
                columns={columns}
                data={item}
                actionButtons={item.actionButtons || []}
                isLoading={isLoading}
                renderers={renderers}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default GenericCardList;
export type { ActionButtonProps };
