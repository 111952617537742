import "./Home.scss"
import FiltrosHome from './FiltrosHome';
import FiltrosHomeSeleccionados from './FiltrosHomeSeleccionados';
import FiltrosHomeAcciones from './FiltrosHomeAcciones';
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../redux/features/language/LanguageSlice";

const HomeColumnaFiltros = (
    {
        lightBg,
        mostrarSponsors,

        busqueda_texto,
        selector_sponsors,

        isLoadingAcciones,
        isLoadingUserInterests,
        handleClickFiltrar,
        handleClickGuardar,
        handleClickLimpiar,
        handleClickRestablecer,

        selectedSponsors,
        handleSponsorClick,

        ChangeFormState,
        actual_values,
        lista_resultados,
        set_lista_resultados,
        handleClickFiltrarMeetings,
        esPrimerClick,  
        setEsPrimerClick,
        mobileversion  
    }: any) => {

    const textHome = useSelector(selectCurrentDictonary).home


    let selector_sponsors_html = <></>
    if (false) {
        selector_sponsors_html = <>
            {selector_sponsors()}
            <br />
        </>
    }
    /* RETURN ================================================================================================================== */
    return (
        <>
            {/* ===================  FILTROS ===================  */}
            <h5 className='mb-3 '><b>{textHome.titulo_2}</b></h5>
            {/* ===================  BUSQUEDA POR TEXTO ===================  */}
            {busqueda_texto()}
            <br />
            {/* ===================  FILTROS SELECCIONADOS ===================  */}
            {selector_sponsors_html}
            {/* ===================  FILTROS ACCIONES ===================  */}
            <FiltrosHomeAcciones
                lightBg={lightBg}
                handleClickFiltrar={() => handleClickFiltrar(actual_values)}
                handleClickGuardar={handleClickGuardar}
                handleClickLimpiar={handleClickLimpiar}
                handleClickRestablecer={handleClickRestablecer}
                isLoadingAcciones={isLoadingUserInterests || isLoadingAcciones}
                lista_resultados={lista_resultados}
                set_lista_resultados = {set_lista_resultados}
                handleClickFiltrarMeetings={() => handleClickFiltrarMeetings(actual_values)}
                esPrimerClick = {esPrimerClick}  
                setEsPrimerClick = {setEsPrimerClick}
                mobileversion = {mobileversion}
            />

            {/*  */}
            <FiltrosHomeSeleccionados
                selectedSponsors={selectedSponsors}
                handleSponsorClick={handleSponsorClick}
            />
            <FiltrosHome
                ChangeFormState={ChangeFormState}
                actual_values={actual_values}
                isLoading={isLoadingUserInterests || isLoadingAcciones}
            />
        </>
    )
}

export default HomeColumnaFiltros;


