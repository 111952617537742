//import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import "./UserPublicData.scss"
import UserPublicDataImage from './UserPublicDataImage'
import UserPublicDataDesc from './UserPublicDataDesc'
import UserPublicDataSocials from './UserPublicDataSocials'
import UserPublicDataTags from './UserPublicDataTags'
import UserPublicDataLanguages from './UserPublicDataLanguages'

import { selectLanguages, selectTags, setLanguages, setTags } from '../../../redux/features/dimensions/dimensionsSlice'
import { selectPersonalData, selectPublicData, selectUserLanguages, selectUserTags, setUserPublicData, setUserLanguages, setUserTags } from '../../../redux/features/user/userSlice'
import { useGetLanguagesListMutation, useGetTagsListMutation } from '../../../redux/features/dimensions/dimensionsApiSlice'
import { useGetUserPublicDataMutation, useGetUserLanguagesMutation, useGetUserTagsMutation } from '../../../redux/features/user/userApiSlice'
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice'
const UserPublicData = () => {
    //const params = useParams()
    const dispatch = useDispatch()
    const textPublicData = useSelector(selectCurrentDictonary).public_data

    // personal data vars
    const user_personal_data = useSelector(selectPersonalData)
    const user_id = user_personal_data.Id

    // tags vars
    const [getTags, { isLoadingTags }]: any = useGetTagsListMutation()
    const [getUserTags, { isLoadingUserTags }]: any = useGetUserTagsMutation()
    const tags = useSelector(selectTags)
    const userTags = useSelector(selectUserTags)

    // languages vars
    const [getLanguages, { isLoadingLanguages }]: any = useGetLanguagesListMutation()
    const [getUserLanguages, { isLoadingUserLanguages }]: any = useGetUserLanguagesMutation()
    const languages = useSelector(selectLanguages)
    const userLanguages = useSelector(selectUserLanguages)

    const [getUserPublicData, { isLoadingGetUserPublicDataAux }]: any = useGetUserPublicDataMutation()
    const [isLoadingGetUserPublicData, setIsLoadingGetUserPublicData] = useState(false)
    const user_public_data = useSelector(selectPublicData)
    const fetchUserPublicData = async (user_id: number) => {
        setIsLoadingGetUserPublicData(true)
        try {
            const res: any = await getUserPublicData({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData)
            dispatch(setUserPublicData({ user_public_data: resdata }))
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserPublicData(false)
    }

    const fetchTags = async () => {
        try {
            const res: any = await getTags();
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setTags({ tags: resdata }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchUserTags = async () => {
        try {
            const res: any = await getUserTags({ user_id: user_id });
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setUserTags({ user_tags: resdata }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchLanguages = async () => {
        try {
            const res: any = await getLanguages();
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setLanguages({ languages: resdata }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchUserLanguages = async () => {
        try {
            const res: any = await getUserLanguages({ user_id: user_id });
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setUserLanguages({ user_languages: resdata }))
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        /* Public data */
        if (!user_public_data) {
            fetchUserPublicData(user_id)
        }
        /*  */
        /* Tags */
        if (!tags || tags.length == 0) {
            fetchTags().catch(console.error);
        }
        if (!userTags || userTags.length == 0) {
            fetchUserTags().catch(console.error);
        }
        /* Languages */
        if (!languages || languages.length == 0) {
            fetchLanguages().catch(console.error);
        }
        if (!userLanguages || userLanguages.length == 0) {
            fetchUserLanguages().catch(console.error);
        }
    }, [])



    return (
        <div className='px-lg-4 w-100'>
            <div className='mb-3'>
                <h3 className='font-italic'><b>{textPublicData.titulo_1}</b></h3>
                <p className='parrafo1'>{textPublicData.desc_1}</p>
            </div>
            <div className='pb-3'>
                <hr />
            </div>
            <UserPublicDataImage
                public_data={user_public_data}
                fetchUserPublicData={fetchUserPublicData}
                isLoadingGetUserPublicData={isLoadingGetUserPublicData}
                userId={user_id}
            />
            <div className='py-3'>
                <hr />
            </div>
            <UserPublicDataDesc
                public_data={user_public_data}
                isLoadingGetUserPublicData={isLoadingGetUserPublicData}
                userId={user_id}
            />
            <div className='py-3'>
                <hr />
            </div>
            <UserPublicDataTags tags={tags} initialUserTags={userTags} userId={user_id}></UserPublicDataTags>
            <div className='py-3'>
                <hr />
            </div>
            <UserPublicDataLanguages languages={languages} initialUserLanguages={userLanguages} userId={user_id}></UserPublicDataLanguages>
            <div className='py-3'>
                <hr />
            </div>
            <UserPublicDataSocials public_data={user_public_data} isLoadingGetUserPublicData={isLoadingGetUserPublicData} userId={user_id}></UserPublicDataSocials>

        </div>
    )
}

export default UserPublicData;