import { apiSlice } from "../../api/apiSlice";

export const recomApiSlice = apiSlice.injectEndpoints({
    //siempre agregar los endpoint con camelcase y la inicial en minuscula
    endpoints: builder => ({
        postRecomUserInterest: builder.mutation({
            query: values => ({
                url: '/api/Recom/RecomUserInterest',
                method: 'POST',
                body: {...values.body}
            })
        }),
    })
})

export const { /* extended api features */
    usePostRecomUserInterestMutation,
} = recomApiSlice


