import './UserProfileExternal.scss'

import { useSelector } from 'react-redux';
import CardExperiencia from '../../components/CardExperiencia/CardExperiencia';

import GetJobTitles from '../../hooks/dimensions/GetJobTitles/GetJobTitles';
import GetIndustries from '../../hooks/dimensions/GetIndustries/GetIndustries';
import GetCompanies from '../../hooks/dimensions/GetCompanies/GetCompanies';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

const UserProfileExternalExperiencias = ({ user_expertises, is_loading_user_expertises }: any) => {

    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    let companies = GetCompanies()
    let jobtitles = GetJobTitles()
    let industries = GetIndustries()

    let lista_experiencias_html = <></>
    if (is_loading_user_expertises) {
        lista_experiencias_html = <div className='d-flex justify-content-center w-100'>
            <p>{textPublicProfile.label_10}</p>
        </div>
    } else {
        if (user_expertises.length > 0) {
            lista_experiencias_html = <div className="row no-gutters">
                {user_expertises.map((item: any, iter: any) => {
                    return (
                        <div className='col-12 mb-4'>
                            <CardExperiencia
                                number={iter}
                                industria={item.IndustryId ? industries.find((element: any) => element.Id == item.IndustryId)?.Value : ""}
                                compania={item.CompanyId ? companies.find((element: any) => element.Id == item.CompanyId)?.Value : ""}
                                foto_compania={(item.Company && item.Company.Picture) ? item.Company.Picture : ""}
                                cargo={item.JobTitleId ? jobtitles.find((element: any) => element.Id == item.JobTitleId)?.Value : ""}
                                anioInicio={item.StartDate ? item.StartDate : ""}
                                anioTermino={item.EndDate ? item.EndDate : ""}
                                canEdit={false}
                            />

                        </div>
                    )
                })}
            </div>

        } else {
            lista_experiencias_html = <div className='d-flex justify-content-center w-100'>
                <p>{textPublicProfile.label_6}</p>
            </div>

        }
    }

    return (
        <div className="user-profile-experiencias-laborales padding-layout-x">
            <div className='row  no-gutters'>
                <div className="col-12 ">
                    <h4 className='mb-4 text-white fs-titulo-profile font-italic'>
                        <b>{textPublicProfile.titulo_4}</b>
                    </h4>
                </div>
                <div className='col-12'>
                    {lista_experiencias_html}
                </div>
            </div>
        </div>
    )
}

export default UserProfileExternalExperiencias;