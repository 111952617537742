
import { Link } from 'react-router-dom';

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { useSelector } from 'react-redux'

const SignUpPageConfirm = () => {
    const textSignup= useSelector(selectCurrentDictonary).signup
    return (
        <div className='px-lg-3 px-xl-5'>
            <div className='mb-3 mb-lg-4 mt-3 mt-lg-4'>
                <h3 className='text-center mb-3'>{textSignup.titulo_2}</h3>
                <div className='d-flex justify-content-center align-items-center mb-4'>                    
                    <p>{textSignup.titulo_2_1}<div className='text-primary d-inline mb-0'>meet</div></p>
                </div>
            </div>

            <div className='row mt-1 mb-5'>
                <div className='col-12 '>
                    <div className='d-flex justify-content-center mb-3'>
                        <h6>{textSignup.titulo_3}</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>{textSignup.titulo_3_1}<Link to="/sign-in">{textSignup.btn_2}</Link> </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SignUpPageConfirm;