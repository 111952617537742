import { useField } from 'formik'
import "./Inputs.scss"

const Checkbox = ({ children, ...props }:any) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <div>
      <label>
      <input className="checkbox-input" type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ?
        <div>{meta.error}</div> : null}
    </div>
  )
}

export default Checkbox
