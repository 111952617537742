import React, { useEffect, useRef, useState } from 'react';
import { Room as VideoRoom } from 'twilio-video';
import Timer from '../Timer/Timer';
import Participant from './Participant';
import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';
import { useSelector } from 'react-redux';

import "./twillio.scss"
import "./Room.scss"


import { Dialog, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

const { connect, LocalVideoTrack, createLocalAudioTrack, createLocalVideoTrack } = require('twilio-video');


interface RoomProps {
    room: VideoRoom;
    setRoom: any;
    parentSid: string;
    /* joinRoom: (roomSid: string, breakout: boolean) => void; */
    leaveRoom: () => void;
    /* activeVideo: boolean;
    activeAudio: boolean; */
    UserRequestedEndTime?: string;
    UserRequestedStartTime?: string;
    UserRequestedProfilePicture?: string;
    UserRequestProfilePicture?: string;
    UserRequestName?: string;
    UserRequestLastName?: string;
    UserRequestedName?: string;
    UserRequestedLastName?: string;
    UserRequestId?: number;
    UserRequestedId?: number;
}
const Room = ({
    room,
    setRoom,
    parentSid,
    leaveRoom,
    UserRequestedEndTime,
    UserRequestedStartTime,
    UserRequestedProfilePicture,
    UserRequestProfilePicture,
    UserRequestName,
    UserRequestLastName,
    UserRequestedName,
    UserRequestedLastName,
    UserRequestId,
    UserRequestedId
}: RoomProps) => {
    const [remoteParticipants, setRemoteParticipants] = useState(Array.from(room.participants.values()));
    const [tracksAudioDevices, setTracksAudioDevices] = useState<any>([]);
    const [tracksVideoDevices, setTracksVideoDevices] = useState<any>([]);
    //document.getElementById('wrapperA').appendChild(document.getElementById('superWidget'));


    const [audioOutputDevices, setAudioOutputDevices] = useState<any>([])
    const [audioInputDevices, setAudioInputDevices] = useState<any>([])
    const [videoInputDevices, setVideoInputDevices] = useState<any>([])

    console.log("room", room)
    // Whenever the room changes, set up listeners
    useEffect(() => {
        room.on('participantConnected', (participant: any) => {
            //console.log(`${participant.identity} has entered the chat`);
            setRemoteParticipants(prevStateRemoteParticipants => ([...prevStateRemoteParticipants, participant])); // agrega participante a la lista
        });
        room.on('participantDisconnected', (participant: any) => {
            //console.log(`${participant.identity} has left the chat`);
            setRemoteParticipants(prevStateRemoteParticipants => prevStateRemoteParticipants.filter((p: any) => p.identity !== participant.identity)); // elimina participante de lista
        });


        const tracksAudioDevices = Array.from(room.localParticipant.audioTracks.values()).map(
            function (trackPublication) {
                return trackPublication.track;
            })
        setTracksAudioDevices(tracksAudioDevices)
        /* const tracksAudioDevicesNames = Array.from(room.localParticipant.audioTracks.values()).map(
            function (trackPublication) {
                return trackPublication.track.mediaStreamTrack.label;
            }) */
        const tracksVideoDevices = Array.from(room.localParticipant.videoTracks.values()).map(
            function (trackPublication) {
                return trackPublication.track;
            })
        setTracksVideoDevices(tracksVideoDevices)
        /* const tracksVideoDevicesNames = Array.from(room.localParticipant.videoTracks.values()).map(
            function (trackPublication) {
                return trackPublication.track.mediaStreamTrack.label;
            }) */

        // audio salida
        selectAudioOutputDevices()
        // audio entrada
        selectAudioInputDevices()
        // video entrada
        selectVideoInputDevices()
    }, [room]);


    async function selectAudioOutputDevices() {
        await navigator.mediaDevices.enumerateDevices().then(devices => {
            let devices_aux = devices.filter(device => device.kind === 'audiooutput'); //.find(device => device.kind === 'audiooutput');
            // console.log("devices devices_aux", devices_aux)
            setAudioOutputDevices(devices_aux)
        })
    }
    // console.log("devices audioInputDevices", audioInputDevices)
    async function selectAudioInputDevices() {
        await navigator.mediaDevices.enumerateDevices().then(devices => {
            let devices_aux = devices.filter(device => device.kind === 'audioinput'); //.find(device => device.kind === 'audiooutput');
            setAudioInputDevices(devices_aux)
        })
    }
    // console.log("devices VideoInputDevices", videoInputDevices)
    async function selectVideoInputDevices() {
        await navigator.mediaDevices.enumerateDevices().then(devices => {
            let devices_aux = devices.filter(device => device.kind === 'videoinput'); //.find(device => device.kind === 'Videooutput');
            setVideoInputDevices(devices_aux)
        })
    }

    const textVideollamada = useSelector(selectCurrentDictonary).videollamada

    const [activeFullScreen, setActiveFullScreen] = useState(false);
    const [activeAudio, setActiveAudio] = useState(true);
    const [activeVideo, setActiveVideo] = useState(true);
    const [activeDevicesModal, setActiveDevicesModal] = useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [openChangeDevicesModal, setOpenChangeDevicesModal] = React.useState(false);

    /* DONAR TIEMPO */
    const handleClickOpenChangeDevicesModal = () => { 
        
        // audio salida
        selectAudioOutputDevices()
        // audio entrada
        selectAudioInputDevices()
        // video entrada
        selectVideoInputDevices()
        setOpenChangeDevicesModal(true); 
    };

    const handleCloseChangeDevicesModal = () => { setOpenChangeDevicesModal(false); };

    const [selected_device_audio_output, set_selected_device_audio_output] = useState(0)
    const [selected_device_audio, set_selected_device_audio] = useState(0)
    const [selected_device_video, set_selected_device_video] = useState(0)



    function toggleDevicesModal() {
        setActiveDevicesModal(!activeDevicesModal)
    }

    function toggleAudio() {
        setActiveAudio(!activeAudio)
        if (activeAudio) {
            room.localParticipant.audioTracks.forEach(
                publication => {
                    publication.track.disable()
                    /* publication.track.stop();
                    publication.unpublish(); */
                }
            );
        } else {
            room.localParticipant.audioTracks.forEach(
                publication => publication.track.enable()
            );
        }
    }
    async function toggleVideo() {
        setActiveVideo(!activeVideo)
        if (activeVideo) {
            
            /* removeVideoTracks() */
            room.localParticipant.videoTracks.forEach(
                publication => {
                    publication.track.disable()
                    /* publication.track.stop();
                    publication.unpublish(); */
                }
            );
        } else {
            if(videoInputDevices && videoInputDevices.length > 0){
                /* removeVideoTracks() */
                /* const new_track = await createLocalVideoTrack({ deviceId: { exact: videoInputDevices[selected_device_video].deviceId } });
                room.localParticipant.publishTrack(new_track); */

                room.localParticipant.videoTracks.forEach(
                    publication => publication.track.enable()
                );
            }
        }
    }
    function toggleFullScreen() {
        setActiveFullScreen(!activeFullScreen)
    }



    // console.log("console.log room", room)
    // console.log("console.log room.localParticipant", room.localParticipant)


    async function setAudioOutputDevice(track: any, elindex: any) {
        // console.log("setAudioOutputDevice", track)
        let deviceId = track.deviceId
        // AUDIO OUTPUT CON FUNCIONAMIENTO DISTINTO
        // disable old video input output devices
        // room.localParticipant.audioTracks.forEach((publication: any) => {
        //     publication.track.stop();
        //     room.localParticipant.unpublishTrack(publication.track);
        // });
        // const new_track = await createLocalAudioTrack({ deviceId: { exact: deviceId } });
        // set_selected_device_audio_output(elindex)
        //room.localParticipant.publishTrack(new_track);

    }
    async function setAudioDevice(track: any, elindex: any) {
        let deviceId = track.deviceId
        
        removeAudioTracks()

        const new_track = await createLocalAudioTrack({ deviceId: { exact: deviceId } });
        room.localParticipant.publishTrack(new_track);
        set_selected_device_audio(elindex)

    }
    async function setVideoDevice(track: any, elindex: any) {
        let deviceId = track.deviceId
        
        removeVideoTracks()

        const new_track = await createLocalVideoTrack({ deviceId: { exact: deviceId } });
        room.localParticipant.publishTrack(new_track);
        set_selected_device_video(elindex)

    }
    function removeAudioTracks(){
        room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
        });
    }
    function removeVideoTracks(){
        room.localParticipant.videoTracks.forEach((publication: any) => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
        }); 
    }

    /*  const [audioOutputDevices, setAudioOutputDevices] = useState<any>([])
     const [audioInputDevices, setAudioInputDevices] = useState<any>([])
     const [videoInputDevices, setVideoInputDevices] = useState<any>([]) */

    let lista_dispositivos_audio_output_html = <>{audioOutputDevices?.map((audioTrack: any, ind: any) => {
        //let nombre = audioTrack?.mediaStreamTrack?.label
        let nombre = audioTrack?.label
        if (selected_device_audio_output == ind) {
            return (
                <div className='d-flex justify-content-between mb-1'>
                    {/* <div>{nombre}</div> */}
                    <div className='text-success'><b>{nombre}</b></div>
                    <div><p className='text-success p-1 px-3'><b>{textVideollamada.param_active}</b></p></div>
                </div>
            )
        } else {
            return (
                <div className='d-flex justify-content-between  mb-1'>
                    {/* <div>{nombre}</div> */}
                    <div>{nombre}</div>
                    <div><button className='btn btn-primary btn-sm' onClick={() => setAudioDevice(audioTrack, ind)}>{textVideollamada.btn_1}</button></div>
                </div>
            )
        }
    })}</>
    let lista_dispositivos_audio_html = <>{audioInputDevices?.map((audioTrack: any, ind: any) => {
        /* let nombre = audioTrack.mediaStreamTrack.label */
        let nombre = audioTrack?.label
        if (selected_device_audio == ind) {
            return (
                <div className='d-flex justify-content-between mb-1'>
                    {/* <div>{nombre}</div> */}
                    <div className='text-success'><b>{nombre}</b></div>
                    <div><p className='text-success'><b>{textVideollamada.param_active}</b></p></div>
                </div>
            )
        } else {
            return (
                <div className='d-flex justify-content-between  mb-1'>
                    {/* <div>{nombre}</div> */}
                    <div>{nombre}</div>
                    <div><button className='btn btn-primary btn-sm' onClick={() => setAudioDevice(audioTrack, ind)}>{textVideollamada.btn_1}</button></div>
                </div>
            )
        }
    })}</>
    let lista_dispositivos_video_html = <>{videoInputDevices?.map((videoTrack: any, ind: any) => {
        /* let nombre = videoTrack.mediaStreamTrack.label */
        let nombre = videoTrack?.label
        if (selected_device_video == ind) {
            return (
                <div className='d-flex justify-content-between mb-1'>
                <div className='text-success'><b>{nombre}</b></div>
                    <div><p className='text-success'><b>{textVideollamada.param_active}</b></p></div>
                </div>
            )
        } else {
            return (
                <div className='d-flex justify-content-between  mb-1'>
                    <div>{nombre}</div>
                    <div><button className='btn btn-primary btn-sm' onClick={() => setVideoDevice(videoTrack, ind)}>{textVideollamada.btn_1}</button></div>
                </div>
            )
        }
    })}</>
    if(videoInputDevices.length == 0){
        lista_dispositivos_video_html = <div>
            {textVideollamada.titulo_3}
        </div>
    }
    //let audioTracksDevices_html = <>{tracksAudioDevices[0]}</>
    //let videoTracksDevices_html = <>{tracksVideoDevices}</>


    async function handleShareScreen() {

        // disable old video input devices
        room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
        });

        const stream = await navigator.mediaDevices.getDisplayMedia({ video: { frameRate: 15 } });
        const screenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: 'myscreenshare' });
        room.localParticipant.publishTrack(screenTrack)
        //setRoom(room);



        //const new_track = await createLocalVideoTrack({ deviceId: { exact: deviceId } });
        //room.localParticipant.publishTrack(new_track);

    }

    /* Pantalla completa activado por defecto en pantallas pequeñas */
    const windowWidth: any = useRef(window.innerWidth);
    if (windowWidth.current && windowWidth.current < 1000 && !activeFullScreen) {
        setActiveFullScreen(true);
    }

    const mkDeviceChangeHandler = (room: any) => async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        if (!audioDevices[0]) return;
        const track = await createLocalAudioTrack({ deviceId: { exact: audioDevices[0].deviceId } });
        room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
        });
        room.localParticipant.publishTrack(track);
    };

    navigator.mediaDevices.ondevicechange = mkDeviceChangeHandler(room);

    return (
        <>
            <div className="room row no-gutters h-100">
                {
                    remoteParticipants.length > 0 ?
                        <div className={activeFullScreen ? 'col-12 h-100' : 'col-12 col-lg-6 h-100'}>
                            <div className='participant-container  p-2 pl-0 pl-lg-2 w-100 h-100'>
                                <Participant
                                    localParticipant={false}
                                    activeFullScreen={activeFullScreen}
                                    activeVideo={activeVideo}
                                    activeAudio={activeAudio}
                                    key={remoteParticipants[0].identity}
                                    participant={remoteParticipants[0]}
                                    UserRequestedProfilePicture={UserRequestedProfilePicture}
                                    UserRequestProfilePicture={UserRequestProfilePicture}
                                    UserRequestName={UserRequestName}
                                    UserRequestLastName={UserRequestLastName}
                                    UserRequestedName={UserRequestedName}
                                    UserRequestedLastName={UserRequestedLastName}
                                    UserRequestId={UserRequestId}
                                    UserRequestedId={UserRequestedId}
                                />
                            </div></div> : <></>
                }

                {
                    !activeFullScreen ?
                        <div className={remoteParticipants.length > 0 ? 'col-12 col-lg-6 h-100' : 'col-12 h-100'}>
                            <div className='participant-container p-2 w-100 h-100'>
                                <Participant

                                    localParticipant={true}
                                    activeFullScreen={activeFullScreen}
                                    activeVideo={activeVideo}
                                    activeAudio={activeAudio}
                                    key={room.localParticipant.identity}
                                    participant={room.localParticipant}

                                    UserRequestedProfilePicture={UserRequestedProfilePicture}
                                    UserRequestProfilePicture={UserRequestProfilePicture}
                                    UserRequestName={UserRequestName}
                                    UserRequestLastName={UserRequestLastName}
                                    UserRequestedName={UserRequestedName}
                                    UserRequestedLastName={UserRequestedLastName}
                                    UserRequestId={UserRequestId}
                                    UserRequestedId={UserRequestedId}
                                />
                            </div>
                        </div> :
                        <Participant

                            localParticipant={true}
                            activeFullScreen={activeFullScreen}
                            activeVideo={activeVideo}
                            activeAudio={activeAudio}
                            key={room.localParticipant.identity}
                            participant={room.localParticipant}

                            UserRequestedProfilePicture={UserRequestedProfilePicture}
                            UserRequestProfilePicture={UserRequestProfilePicture}
                            UserRequestName={UserRequestName}
                            UserRequestLastName={UserRequestLastName}
                            UserRequestedName={UserRequestedName}
                            UserRequestedLastName={UserRequestedLastName}
                            UserRequestId={UserRequestId}
                            UserRequestedId={UserRequestedId}
                        />
                }
                {/* <div className={activeFullScreen ? 'col-12 h-100' : 'col-12 col-lg-6 h-100'}>
                    <div className='participant-container p-2 w-100 h-100'>
                        <Participant

                            localParticipant={true}
                            activeFullScreen={activeFullScreen}
                            activeVideo={activeVideo}
                            activeAudio={activeAudio}
                            key={room.localParticipant.identity}
                            participant={room.localParticipant}

                            UserRequestedProfilePicture={UserRequestedProfilePicture}
                            UserRequestProfilePicture={UserRequestProfilePicture}
                            UserRequestName={UserRequestName}
                            UserRequestLastName={UserRequestLastName}
                            UserRequestedName={UserRequestedName}
                            UserRequestedLastName={UserRequestedLastName}
                            UserRequestId={UserRequestId}
                            UserRequestedId={UserRequestedId}
                        />
                    </div>
                </div> */}

            </div>
            {/* modal */}


            {/* MODAL (DIALOG) */}
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="md"
                open={openChangeDevicesModal}
                onClose={handleCloseChangeDevicesModal}
                scroll={"paper"}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseChangeDevicesModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, minWidth: 120 }} variant="h6" component="div">
                            {"Devices"}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className=''>{/* my-3 px-3 px-lg-4 px-xl-4 */}
                        {/* <hr />
                        <div className="">
                            <h5 className="mb-1 text-primary"><b>{textVideollamada.titulo_4}</b></h5>
                            {lista_dispositivos_audio_output_html}
                        </div> */}
                        <hr />
                        <div className="">
                            <h5 className="mb-1"><b>{textVideollamada.titulo_5}</b></h5>
                            {lista_dispositivos_audio_html}
                        </div>
                        <hr />
                        <div className="">
                            <h5 className="mb-1"><b>Video</b></h5>
                            {lista_dispositivos_video_html}
                        </div>
                        <hr />
                    </div>

                </DialogContent>
            </Dialog>
            {/* fin modal */}

            {/* footer */}
            <footer className="footer room-footer fixed-bottom  w-100 py-2 padding-layout-x">

                <div className='d-flex justify-content-between w-100'>
                    <div className='d-flex justify-content-start w-100'>
                        {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Open modal for @mdo</button> */}

                        <button className="btn btn-room-margin btn-primary " onClick={handleClickOpenChangeDevicesModal} ><i className="fas fa-cog"></i></button>
                        {activeAudio && tracksAudioDevices.length > 0 ? <button className="btn btn-room-margin btn-success " onClick={toggleAudio}><i className="fas fa-microphone"></i></button> : <button className="btn btn-room-margin btn-danger " onClick={toggleAudio}><i className="fas fa-microphone-slash"></i></button>}

                        {activeVideo && tracksVideoDevices.length > 0 ? <button className="btn btn-room-margin btn-success " onClick={toggleVideo}><i className="fas fa-video"></i></button> : <button className="btn btn-room-margin btn-danger " onClick={toggleVideo}><i className="fas fa-video-slash"></i></button>}
                       
                        {/* <button className="btn btn-room-margin btn-danger  d-none d-lg-block" onClick={handleShareScreen}>
                            <div className='d-flex  justify-content-center align-items-center'>
                                <i className="fas fa-desktop mr-2"></i>
                                <p>{textVideollamada.btn_pantalla}</p>
                            </div>
                        </button> */}
                        {
                            activeFullScreen ?
                                <button className="d-none d-lg-block btn btn-room-margin btn-primary " onClick={toggleFullScreen}><i className="fas fa-compress-arrows-alt"></i></button> :
                                <button className="d-none d-lg-block btn btn-room-margin btn-primary " onClick={toggleFullScreen}><i className="fas fa-expand-arrows-alt"></i></button>
                        }

                    </div>
                    <div>
                        <button className="btn btn-room btn-danger btn-rounded" onClick={leaveRoom}>{textVideollamada.btn_salir}</button>
                    </div>
                </div>
            </footer>
            {/* footer */}
        </>
    );
}

export default Room;