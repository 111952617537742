//import { useParams } from 'react-router-dom'


import Card from '../../../components/Card/Card'
import { selectCurrentUser } from "../../../redux/features/auth/authSlice"
import { useSelector } from "react-redux"

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


const ChangePassword = () => {
    //const params = useParams()
    const textChangePass= useSelector(selectCurrentDictonary).change_pass

    return (
        <>
                <Card className="w-100">
                    <div className='d-flex justify-content-start  mt-2 mb-4 px-4'>
                        <h3>{textChangePass.titulo_1}</h3>
                    </div>
                    <div className='row no-gutters '>
                        <div className='col-12 col-lg-6 px-4 mb-4'>
                            <label >{textChangePass.param_1}</label>
                            <br />
                            <input className="w-100" type="text" name="" value="" />
                        </div>
                        <div className='col-12 col-lg-6 px-4 mb-4'>
                            <label >{textChangePass.param_2}</label>
                            <br />
                            <input className="w-100" type="text" name="" value="" />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end px-4 mb-2'>
                        <button className="btn btn-primary" type="submit">{textChangePass.btn_1}</button>
                    </div>
                </Card>
        </>
    )
}

export default ChangePassword;