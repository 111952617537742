import { apiSlice } from "../../api/apiSlice";

export const meetingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        /* DONATED TIME */
        getUserDonatedTime: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetDonatedTimeByUser/' + values.user_id  + (values.type !== undefined ? '/' + values.type : ''), // añadí el + (values.type !== undefined ? '/' + values.type : '')
                method: 'GET'
            })
        }),
        postUserDonatedTime: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/DonateTime',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        postDeleteUserDonatedTime: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/RemoveDonatedTime',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        /* SOLICITUDES DE REUNION */
        getUserSolicitudesEnviadas: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserMeetingRequests/' + values.user_id,
                method: 'GET'
            })
        }),
        getUserSolicitudesRecibidas: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserRequestedMeetingsRequests/' + values.user_id,
                method: 'GET'
            })
        }),
        postUserCrearSolicitud: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/CreateMeetingRequest/',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        postUserRespuestaSolicitudRecibida: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/ConfirmMeetingRequest/',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        postUserCancelarSolicitudEnviada: builder.mutation({ /* FALTA */
            query: (values: any) => ({
                url: '/api/Meetings/GetDonatedTimeByUser/',
                method: 'POST',
                body: { ...values.body }
            })
            /* 
            {
            }
            */
        }),
        /* REUNIONES */

        getUserMeetings: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserMeeting/' + values.user_id,
                method: 'GET'
            })
        }),
        getUserMeetingsHistorial: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserMeetingHistorial/' + values.user_id,
                method: 'GET'
            })
        }),

        GetUserRequestedMeetingsRequestsHistorial: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserRequestedMeetingsRequestsHistorial/' + values.user_id,
                method: 'GET'
            })
        }),

        GetUserMeetingRequestsHistorial: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserMeetingRequestsHistorial/' + values.user_id,
                method: 'GET'
            })
        }),

        postCreateZoomMeeting: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/CreateZoomMeeting/' + values.available_meeting_id,
                method: 'POST'
            })
        }),

        deleteZoomMeeting: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/DeleteZoomMeeting/' + values.meeting_id,
                method: 'DELETE'
            })
        }),

        getZoomMeeting: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetParamsZoomMeeting/' + values.meeting_id,
                method: 'GET'
            })
        }),

        /* EVALUACIONES / FEEDBACK */
        getUserFeedbacks: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUserFeedbacks/' + values.user_id,
                method: 'GET'
            })
        }),

        postUserFeedback: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/CreateUserEvaluation/',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        postCreateTimeRequest: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/CreateTimeRequest/',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        getUserMeetingRequestTimes: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetMeetingRequestTimes/' + values.user_id,
                method: 'GET'
            })
        }),
        postCreateTimeRequest2: builder.mutation({ // Este endpoint se utiliza para eliminar tiempos.
            query: (values: any) => ({
                url: '/api/Meetings/CreateTimeRequest2/' + values.user_id,
                method: 'PUT',
                body: {...values.body}
            })
        }),
        getUserDirectAvailableTimes: builder.mutation({
            query: (values: any) => {
              return {
                url: '/api/Meetings/GetDirectAvailableTimes/' + values.userId,
                method: 'GET'
              };
            },
          }),
          postDeleteDirectDonations: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/DeleteDirectDonations/',
                method: 'PUT',
                body: { ...values.body }
            })
        }),
        postCreateNotification: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/CreateNotification/',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        getUserNotifyTime: builder.mutation({
            query: (values: any) => {
              return {
                url: '/api/Meetings/GetRequestsWithoutUpdate/' + values.userId,
                method: 'GET'
              };
            },
          }),
          getUsersAvailableTimes: builder.mutation({
            query: (values: any) => ({
                url: '/api/Meetings/GetUsersAvailableTimes/',
                method: 'GET'
            })
        })
       
    })
})


export const {
    // tiempo donado
    useGetUserDonatedTimeMutation,
    usePostUserDonatedTimeMutation,
    usePostDeleteUserDonatedTimeMutation,
    // solicitudes
    useGetUserSolicitudesEnviadasMutation,
    useGetUserSolicitudesRecibidasMutation,
    usePostUserCrearSolicitudMutation,
    usePostUserRespuestaSolicitudRecibidaMutation,
    usePostUserCancelarSolicitudEnviadaMutation,
    // reuniones
    useGetUserMeetingsMutation,
    useGetUserMeetingsHistorialMutation,
    useGetUserMeetingRequestsHistorialMutation,
    useGetUserRequestedMeetingsRequestsHistorialMutation,
    /* zoom */
    usePostCreateZoomMeetingMutation,
    useDeleteZoomMeetingMutation,
    useGetZoomMeetingMutation,
    /* evaluaciones/feedback */
    useGetUserFeedbacksMutation,
    usePostUserFeedbackMutation,
    usePostCreateTimeRequestMutation,
    //useGetUserDonatedTimeMutation, // solicitudes
    useGetUserMeetingRequestTimesMutation,
    usePostCreateTimeRequest2Mutation,
    useGetUserDirectAvailableTimesMutation,
    usePostDeleteDirectDonationsMutation,
    usePostCreateNotificationMutation,
    useGetUserNotifyTimeMutation,
    useGetUsersAvailableTimesMutation
     
} = meetingsApiSlice