
import { motion } from "framer-motion";
import "./HomeBuscador.scss"
import { useSelector } from "react-redux";
import {selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import imagenRedonda from "./imagenBuscador.png";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addAlert, deleteAlert } from "../../redux/features/alerts/alertsSlice";
import { personalData } from "../../interfaces/dimentions";
import { selectPersonalData } from "../../redux/features/user/userSlice";
import { usePostRecomUserInterestMutation } from "../../redux/features/recom/recomApiSlice";

const HomeBuscador = ({
    busqueda_texto,
    botones
    }:any) => {

    const lenguage = useSelector(selectCurrentLanguage)
    const textBuscador = useSelector(selectCurrentDictonary).buscador
    
    const titulo = () => {
        if (lenguage === 'es') return (
            <>¡CONÉCTA CON TU PAR <strong>IMPROBABLE</strong>!</>
        )
        else return <>CONNECT WITH YOUR <strong>UNLIKELY</strong> PAIR!</>
    }
    return (
        <div className='fondo-imagen'>
            <div className="row">
                <div className='col-12 col-md-12 col-lg-12 col-xl-6 mb-4 text-container'>
                    <p className="title">{titulo()}</p>
                    <p className="subtitle">{textBuscador.label}</p>
                </div>
            </div>
            <div className="row">
                <div className='col-12 col-md-12 col-lg-12 col-xl-5 mb-4 btn-container'>
                    {botones()}
                </div>
            </div>
            <div className="row">
                <div className='col-12 col-md-12 col-lg-12 col-xl-6 mb-4'>
                   {busqueda_texto()}
                </div>
            </div>
        </div>
    )
}

export default HomeBuscador;