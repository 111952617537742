
import { feedbackQuestionsData, Question } from './feedbackQuestionsData';
import { Rol, Actividad } from './constants';

interface UseFeedbackQuestionsResult {
    questions: Question[];
}

export const useFeedbackQuestions = (rol: Rol, actividad: Actividad): UseFeedbackQuestionsResult => {
    if (!feedbackQuestionsData[actividad] || !feedbackQuestionsData[actividad][rol]) {
        console.warn(`No se encontraron preguntas para la actividad: ${actividad} y rol: ${rol}`);
        return { questions: [] };
    }

    const questions: Question[] = feedbackQuestionsData[actividad][rol];
    return { questions };
};

export default useFeedbackQuestions;
