import { apiSlice } from "../../api/apiSlice";

export const dimensionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/roles',
                method: 'GET',
            })
        }),
        getCountries: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/countries',
                method: 'GET',
            })
        }),
        getRegions: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/regions/' + values.parent_id,
                method: 'GET',
            })
        }),
        getCities: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/cities/' + values.parent_id,
                method: 'GET',
            })
        }),
        getTagsList: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Tags',
                method: 'GET',
            })
        }),
        getLanguagesList: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Languages',
                method: 'GET',
            })
        }),
        getSponsors: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Sponsors',
                method: 'GET',
            })
        }),

        getJobTitles: builder.mutation({
            query: () => ({
                url: '/api/Dimensions/JobTitles',
                method: 'GET',
            })
        }),
        getInterestsTypes: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/InterestType',
                method: 'GET',
            })
        }),
        getManagements: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Managements',
                method: 'GET',
            })
        }),
        getInterests: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Interests/'+values.parent_id,
                method: 'GET',
            })
        }),
        getStarValues: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/StarValues',
                method: 'GET',
            })
        }),
        getAgeRanges: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/AgeRanges',
                method: 'GET',
            })
        }),
        getUniversities: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Universities',
                method: 'GET',
            })
        }),
        getDocumentTypes: builder.mutation({ // no se va a utilizar, limita el uso de diferentes paises
            query: values => ({
                url: '/api/Dimensions/DocumentType',
                method: 'GET',
            })
        }),
        getMacroIndustries: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/MacroIndustries',
                method: 'GET',
            }) // {\"Id\":37,\"Value\":\"AGRICULTURA Y GANADER\\u00CDA\",\"AltText\":null}
        }),
        getIndustries: builder.mutation({
            query: () => ({
                url: '/api/Dimensions/Industries/FilteredBy',
                method: 'GET',
            })
        }),
        getIndustriesFilteredBy: builder.mutation({ //  
            query: values => ({
                url: '/api/Dimensions/Industries/FilteredBy?macro_industry_id='+values.parent_id,
                method: 'GET',
            })
        }),
        getCompanies: builder.mutation({ //  
            query: () => ({
                url: '/api/Dimensions/Companies/FilteredBy',
                method: 'GET',
            })
        }),
        postAddCompany: builder.mutation({ //  
            query: (values:any) => ({
                url: '/api/Companies',
                method: 'POST',
                body: values.body
            })
        }),
        getCompaniesFilteredBy: builder.mutation({ //  
            query: values => ({
                url: '/api/Dimensions/Companies/FilteredBy?industry_id='+values.parent_id,
                method: 'GET',
            })
        }),
        getInvestmentSourceLevels: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/InvestmentSourceLevels',
                method: 'GET',
            })
        }),
        getDegrees: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/Degrees',
                method: 'GET',
            })
        }),
        getAppStats: builder.mutation({
            query: values => ({
                url: '/api/Dimensions/AppStats',
                method: 'GET',
            })
        }),
    })
})


export const { /* siempre poner use al inicio del metodo */
    useGetRolesMutation,
    useGetCountriesMutation,
    useGetRegionsMutation,
    useGetCitiesMutation,
    useGetTagsListMutation,
    useGetLanguagesListMutation,
    useGetSponsorsMutation,
    useGetJobTitlesMutation,
    useGetInterestsTypesMutation,
    useGetManagementsMutation,
    useGetInterestsMutation,
    useGetStarValuesMutation,
    useGetAgeRangesMutation,
    useGetUniversitiesMutation,
    useGetDocumentTypesMutation,
    useGetMacroIndustriesMutation,
    useGetIndustriesMutation,
    useGetIndustriesFilteredByMutation,
    useGetCompaniesMutation,
    usePostAddCompanyMutation,
    useGetCompaniesFilteredByMutation,
    useGetInvestmentSourceLevelsMutation,
    useGetDegreesMutation,
    useGetAppStatsMutation,
} = dimensionsApiSlice


