import { createSlice } from "@reduxjs/toolkit"

const initialState = {
}

const recomSlice = createSlice({
    name: 'recom',
    initialState: initialState,
    reducers: {
    },
})


export default recomSlice.reducer
