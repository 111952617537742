import "./UserPublicData.scss";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { setUserTags } from '../../../redux/features/user/userSlice';
import { usePutUserTagsMutation } from '../../../redux/features/user/userApiSlice';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";


interface publicDataOptionsInterface {
    label: string
    title: string
    value: number
    key: number
}
interface publicDataInterface {
    Id: number
    TagId: number
    TagName: string
    UserId: number
}
interface UserPublicDataTagsInterface {
    tags: any[]
    initialUserTags: publicDataInterface[]
    userId: number
}

const UserPublicDataTags = ({ tags, initialUserTags, userId }: UserPublicDataTagsInterface) => {

    const textPublicData = useSelector(selectCurrentDictonary).public_data
    const [putUserTags, { isLoadingPutUserTags }]: any = usePutUserTagsMutation()

    const dispatch = useDispatch()
    const [value, setValue] = useState<publicDataOptionsInterface[]>([])
    const [formValidated, setFormValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initialValue: publicDataOptionsInterface[] = initialUserTags?.map((item: publicDataInterface) => {
        return {
            label: item.TagName,
            title: item.TagName,
            value: item.TagId,
            key: item.TagId,
        };
    });
    useEffect(() => {
        setValue(initialValue)
    }, [initialUserTags])


    const handleChange = (event: any, newValue: any) => {
        if (newValue != initialValue) {
            setFormValidated(true)
            setValue(newValue)
        } else {
            setFormValidated(false)
        }
    }


    async function handleSubmit() {
        setIsLoading(true)
        dispatch(deleteAlert({ alert_id: "error_user_tags" }))
        dispatch(deleteAlert({ alert_id: "cargando_user_tags" }))
        let new_alert = { id: "cargando_user_tags", type: "loading", title: textPublicData.titulo_7, desc: textPublicData.titulo_12, close: false }
        dispatch(addAlert({ alert: new_alert }))

        let tags_user_format = value.map((item: any) => {
            return {
                "TagId": item.value,
                "UserId": userId,
                "TagName": item.label
            };
        });

        let body_tags = tags_user_format // JSON.stringify(tags_user_format);
        try {
            const res: any = await putUserTags({ user_id: userId, body: body_tags })
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                dispatch(deleteAlert({ alert_id: "cargando_user_tags" }))
                let new_alert = { type: "success", title: "Editar etiquetas", desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))

                const resdata = JSON.parse(res.data.responseData)
                dispatch(setUserTags({ user_tags: tags_user_format }))

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_user_tags" }))
                let new_alert2 = { id: "error_user_tags", type: "error", title: "Error en Editar etiquetas", desc: "", close: true, timeout: 50000 }
                dispatch(addAlert({ alert: new_alert2 }))
            }
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "cargando_user_tags" }))
            let new_alert2 = { id: "error_user_tags", type: "error", title: "Error en Editar etiquetas", desc: "", close: true, timeout: 50000 }
            dispatch(addAlert({ alert: new_alert2 }))
        }
        setIsLoading(false)
    }


    let button_submit = <></>
    button_submit = <div className='d-flex justify-content-end mt-3'>
        <button disabled={isLoading || !formValidated} className='btn btn-primary btn-rounded' onClick={() => handleSubmit()}>{textPublicData.btn_guardar}</button>
    </div>

    let form_html = <></>
    if (tags && tags.length > 0) {
        form_html = <div className='w-100'>
            <Autocomplete
                multiple
                options={tags?.map((item: any) => {
                    return {
                        label: item.Value,
                        title: item.Value,
                        value: item.Id,
                        key: item.Id,
                    };
                })}
                className="w-100"
                getOptionLabel={(option: any) => option.title}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        label={""}
                    />
                )}
                onChange={handleChange}
                value={value}
            />
            {button_submit}
        </div>
    } else {
        form_html = <p>{textPublicData.titulo_7}</p>
    }

    return (

        <div className=''>
            <div className='d-flex justify-content-start mb-4'>
                <div>
                    <h5><b>{textPublicData.titulo_4}</b></h5>
                    <p>
                        {textPublicData.desc_3}
                    </p>
                </div>
            </div>
            {form_html}

        </div>
    )
}

export default UserPublicDataTags;