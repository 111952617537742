import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetSponsorsMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectSponsors, setSponsors } from '../../../redux/features/dimensions/dimensionsSlice';


export default function GetSponsors() {

    const dispatch = useDispatch()
    const value = useSelector(selectSponsors)
    const [get, { isLoadingGet }]: any = useGetSponsorsMutation()
    

    const fetchCountries = async () => {
        const res: any = await get();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setSponsors({sponsors: resData }))
    }

    useEffect(() => {
        if (!value || value.length == 0) {
            fetchCountries()
        }
        /*  */
    }, [value]);

    return value;
}
