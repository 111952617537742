import { apiSlice } from "../../api/apiSlice";

export const meetingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({   
        postAddNotification: builder.mutation({
            query: (values: any) => ({
                url: '/api/Notification/AddNotification',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        

    })
})


export const {
    
    usePostAddNotificationMutation,
} = meetingsApiSlice