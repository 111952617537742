import { useEffect, useState } from 'react';
import TextInput from '../../components/Form/TextInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useAssignMentorByAdminMutation,
    useAssignMenteeByAdminMutation,
    useCancelMenteeByAdminMutation,
    useCancelMentorByAdminMutation,
} from '../../redux/features/events/eventsApiSlice';
import AccreditationButton from './AccreditationButton';

const BlockScheduleForm = ({
    handleClose,
    handleSubmit,
    isSubmitting,
    initialValues,
    onSuccess,
}: any) => {
    const dispatch = useDispatch();
    const [assignMentorByAdmin] = useAssignMentorByAdminMutation();
    const [assignMenteeByAdmin] = useAssignMenteeByAdminMutation();
    const [cancelMenteeByAdmin] = useCancelMenteeByAdminMutation();
    const [cancelMentorByAdmin] = useCancelMentorByAdminMutation();
    const [mentorEmail, setMentorEmail] = useState(initialValues.mentorEmail || '');
    const [menteeEmail, setMenteeEmail] = useState(initialValues.menteeEmail || '');
    const [mentorAssigned, setMentorAssigned] = useState(!!initialValues.mentorEmail);
    const [menteeAssigned, setMenteeAssigned] = useState(!!initialValues.menteeEmail);
    const [mentorName, setMentorName] = useState(initialValues.mentorName || '');
    const [menteeName, setMenteeName] = useState(initialValues.menteeName || '');
    const [blockId] = useState(initialValues.id);
    const [openConfirmMentorDialog, setOpenConfirmMentorDialog] = useState(false);
    const [openConfirmMenteeDialog, setOpenConfirmMenteeDialog] = useState(false);
    const [openConfirmRemoveMenteeDialog, setOpenConfirmRemoveMenteeDialog] = useState(false);
    const [openConfirmRemoveMentorDialog, setOpenConfirmRemoveMentorDialog] = useState(false);
    const [payloadToConfirm, setPayloadToConfirm] = useState<any>(null);
    const [activityId] = useState(initialValues.activityId || null);

    const validationSchema = Yup.object().shape({
        mentor: Yup.string().email('Correo inválido'),
        mentee: Yup.string().email('Correo inválido'),
    });

    const defaultValues = {
        mentorEmail: '',
        menteeEmail: '',
        id: null,
    };

    const mergedInitialValues = { ...defaultValues, ...initialValues };

    const handleMentorEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMentorEmail(e.target.value);
        setMentorAssigned(false);
    };

    const handleMenteeEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMenteeEmail(e.target.value);
        setMenteeAssigned(false);
    };

    const handleConfirmAssignMentor = () => {
        const payload = { email: mentorEmail.trim(), blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmMentorDialog(true);
    };

    const handleConfirmAssignMentee = () => {
        const payload = { email: menteeEmail.trim(), blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmMenteeDialog(true);
    };

    const handleConfirmedAssignMentor = async () => {
        let new_alert = {
            id: `assigning_mentor_${Date.now()}`,
            type: 'loading',
            title: 'Asignando mentor...',
            desc: '',
            close: false,
            timeout: 2000,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await assignMentorByAdmin({ email: mentorEmail.trim(), blockId }).unwrap();
            setMentorAssigned(true);
            setMentorEmail(mentorEmail.trim());
            setMentorName(result.mentorName || mentorEmail.trim());
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = {
                id: `mentor_assigned_${Date.now()}`,
                type: 'success',
                title: 'Mentor asignado con éxito',
                desc: '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: success_alert }));
            if (onSuccess) onSuccess();
        } catch (error: any) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = {
                id: `assign_mentor_error_${Date.now()}`,
                type: 'error',
                title: 'Error al asignar mentor',
                desc: error?.data?.message || '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmMentorDialog(false);
        }
    };

    const handleConfirmedAssignMentee = async () => {
        let new_alert = {
            id: `assigning_mentee_${Date.now()}`,
            type: 'loading',
            title: 'Asignando mentee...',
            desc: '',
            close: false,
            timeout: 2000,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await assignMenteeByAdmin({ email: menteeEmail.trim(), blockId }).unwrap();
            setMenteeAssigned(true);
            setMenteeEmail(menteeEmail.trim());
            setMenteeName(result.menteeName || menteeEmail.trim());
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = {
                id: `mentee_assigned_${Date.now()}`,
                type: 'success',
                title: 'Mentee asignado con éxito',
                desc: '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: success_alert }));
            if (onSuccess) onSuccess();
        } catch (error: any) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = {
                id: `assign_mentee_error_${Date.now()}`,
                type: 'error',
                title: 'Error al asignar mentee',
                desc: error?.data?.message || '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmMenteeDialog(false);
        }
    };

    const handleConfirmRemoveMentee = () => {
        const payload = { email: menteeEmail.trim(), blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmRemoveMenteeDialog(true);
    };

    const handleConfirmedRemoveMentee = async () => {
        let new_alert = {
            id: `removing_mentee_${Date.now()}`,
            type: 'loading',
            title: 'Eliminando mentee...',
            desc: '',
            close: false,
            timeout: 2000,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await cancelMenteeByAdmin({ email: menteeEmail.trim(), blockId }).unwrap();
            setMenteeAssigned(false);
            setMenteeEmail('');
            setMenteeName('');
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = {
                id: `mentee_removed_${Date.now()}`,
                type: 'success',
                title: 'Mentee eliminado con éxito',
                desc: '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: success_alert }));
            if (onSuccess) onSuccess();
        } catch (error: any) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = {
                id: `remove_mentee_error_${Date.now()}`,
                type: 'error',
                title: 'Error al eliminar mentee',
                desc: error?.data?.message || '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmRemoveMenteeDialog(false);
        }
    };

    const handleConfirmRemoveMentor = () => {
        const payload = { email: mentorEmail.trim(), blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmRemoveMentorDialog(true);
    };

    const handleConfirmedRemoveMentor = async () => {
        let new_alert = {
            id: `removing_mentor_${Date.now()}`,
            type: 'loading',
            title: 'Eliminando mentor...',
            desc: '',
            close: false,
            timeout: 2000,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await cancelMentorByAdmin({ email: mentorEmail.trim(), blockId }).unwrap();
            setMentorAssigned(false);
            setMentorEmail('');
            setMentorName('');
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = {
                id: `mentor_removed_${Date.now()}`,
                type: 'success',
                title: 'Mentor eliminado con éxito',
                desc: '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: success_alert }));
            if (onSuccess) onSuccess();
        } catch (error: any) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = {
                id: `remove_mentor_error_${Date.now()}`,
                type: 'error',
                title: 'Error al eliminar mentor',
                desc: error?.data?.message || '',
                close: true,
                timeout: 2000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmRemoveMentorDialog(false);
        }
    };

    useEffect(() => {
        if (!mentorAssigned) {
            setMentorEmail('');
            setMentorName('');
        }
        if (!menteeAssigned) {
            setMenteeEmail('');
            setMenteeName('');
        }
    }, [mentorAssigned, menteeAssigned]);

    return (
        <>
            <Formik initialValues={mergedInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
                {() => (
                    <Form>
                        <p style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '10px' }}>
                            Los correos para asignar horarios deben ser los registrados en EtMmeet
                        </p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} container spacing={2} alignItems="flex-end">
                                <Grid item xs={8}>
                                    {mentorName && (
                                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                            {mentorName}
                                        </Typography>
                                    )}
                                    <TextInput name="mentor" label="Mentor" style={{ width: '100%' }} placeholder="Sin asignar, ingrese correo..." value={mentorEmail} onChange={handleMentorEmailChange} />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <IconButton sx={{ alignSelf: 'flex-end', marginTop: '30px', paddingLeft: '8px' }} onClick={mentorAssigned ? handleConfirmRemoveMentor : handleConfirmAssignMentor}>
                                        {mentorAssigned ? <PersonRemoveIcon style={{ color: 'black' }} /> : <PersonAddIcon style={{ color: 'black' }} />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <div style={{ opacity: mentorAssigned && activityId && mentorEmail ? 1 : 0.5, pointerEvents: mentorAssigned && activityId && mentorEmail ? 'auto' : 'none' }}>
                                        <AccreditationButton email={mentorAssigned ? mentorEmail : undefined} activityId={activityId} />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container spacing={2} alignItems="flex-end">
                                <Grid item xs={8}>
                                    {menteeName && (
                                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                            {menteeName}
                                        </Typography>
                                    )}
                                    <TextInput name="mentee" label="Mentee" style={{ width: '100%' }} placeholder="Sin asignar, ingrese correo..." value={menteeEmail} onChange={handleMenteeEmailChange} />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <IconButton sx={{ alignSelf: 'flex-end', marginTop: '30px', paddingLeft: '8px' }} onClick={menteeAssigned ? handleConfirmRemoveMentee : handleConfirmAssignMentee}>
                                        {menteeAssigned ? <PersonRemoveIcon style={{ color: 'black' }} /> : <PersonAddIcon style={{ color: 'black' }} />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <div style={{ opacity: menteeAssigned && activityId && menteeEmail ? 1 : 0.5, pointerEvents: menteeAssigned && activityId && menteeEmail ? 'auto' : 'none' }}>
                                        <AccreditationButton email={menteeAssigned ? menteeEmail : undefined} activityId={activityId} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="button" variant="contained" color="primary" onClick={handleClose} disabled={isSubmitting}>Volver</Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Dialog open={openConfirmMentorDialog} onClose={() => setOpenConfirmMentorDialog(false)} disableAutoFocus disableRestoreFocus>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas añadir al mentor?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedAssignMentor} color="primary">Confirmar</Button>
                    <Button onClick={() => setOpenConfirmMentorDialog(false)} color="primary">Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmMenteeDialog} onClose={() => setOpenConfirmMenteeDialog(false)} disableAutoFocus disableRestoreFocus>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas añadir al mentee?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedAssignMentee} color="primary">Confirmar</Button>
                    <Button onClick={() => setOpenConfirmMenteeDialog(false)} color="primary">Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmRemoveMenteeDialog} onClose={() => setOpenConfirmRemoveMenteeDialog(false)} disableAutoFocus disableRestoreFocus>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas eliminar al mentee?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedRemoveMentee} color="primary">Confirmar</Button>
                    <Button onClick={() => setOpenConfirmRemoveMenteeDialog(false)} color="primary">Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmRemoveMentorDialog} onClose={() => setOpenConfirmRemoveMentorDialog(false)} disableAutoFocus disableRestoreFocus>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas eliminar al mentor?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedRemoveMentor} color="primary">Confirmar</Button>
                    <Button onClick={() => setOpenConfirmRemoveMentorDialog(false)} color="primary">Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BlockScheduleForm;
