import { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom"
import './MainLayout.scss'

import BottomNav from '../../Navegation/BottomNav/BottomNav'

function MainLayout(){

	const [sidebaropen,setSidebaropen] = useState(true)

	const toggleSidebar = () => {
		setSidebaropen(!sidebaropen)
	}

	
    useEffect(() => {
    }, [])

	return (
		<>
			<BottomNav isOpen={sidebaropen}></BottomNav>
				
			<div className='body-container '>{/* padding-layout-x */}
					<Outlet />
			</div>
		</>
	)
}
export default MainLayout;
