import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import moment from "moment"
import "../Meetings.scss"
import "./MeetingsTiempo.scss"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


const MeetingsDonarTiempoPaso1 = ({ actual_values, setActualValues }: any) => {

    const textGeneral = useSelector(selectCurrentDictonary).general



    function handleChange(value: any) {
        let value_aux: any = []
        value?.map((item: any) => {
           
            let month = item.month.number
            if(month < 10){
                month = "0" + month
            }
            let day = item.day
            if(day < 10){
                day = "0" + day
            }
            
             
            
            var date = item.year + "-" + month + "-" + day
            // console.log(item, "item");
            // console.log(date , "date");
            value_aux.push(date)
        })
        setActualValues({ ...actual_values, days: value_aux })
    }

    function eliminarDiaSeleccionado(value: string) {
        let values_aux = [...actual_values.days]
        for (let i = 0; i < values_aux.length; i++) {
            const element = values_aux[i];
            if (element == value) {
                values_aux.splice(i, 1);
            }
        }
        setActualValues({ ...actual_values, days: values_aux })
    }

    function orderArrayByDates(actual_days: any) {
        let daysArray = [...actual_days]
        daysArray.sort(function (left, right) {
            return moment.utc(left).diff(moment.utc(right))
        });
        return daysArray
    }

    return (
        <div className='row '>
            <div className='col-12 col-lg-6 mb-4'>
                <div className='d-flex justify-content-center calendario-container'>
                    <Calendar

                        className='calendario2 rmdp-mobile'
                        multiple
                        shadow={false}
                        value={actual_values.days}
                        onChange={handleChange}
                        minDate={moment().toDate()}
                        maxDate={moment().add(3, "months").toDate()} // 3 meses
                        weekStartDayIndex={1}
                    />

                </div>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
                <div className='dias-seleccionados-container'>
                    {orderArrayByDates(actual_values.days)?.map((item: any) => {
                        // console.log("itemazul", item);
                
                        let date: any = moment(item,'YYYY-MM-DD')
                        let date_str = date.format('DD-MM-YYYY');
                        let day:any = date.day()
                        let day_aux = textGeneral.days[day]

                        var date2 = day_aux + " " + date_str;
                        return (
                            <div className='dia-seleccionado'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h6 className="dia-seleccionado-date p-0">{date2}</h6>
                                    <button className='close-button' onClick={() => eliminarDiaSeleccionado(item)}>
                                        <div><i className="fa-solid fa-xmark"></i></div>
                                    </button>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default MeetingsDonarTiempoPaso1;