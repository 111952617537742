import "./UserPublicData.scss";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import { usePutUserPublicDataMutation } from '../../../redux/features/user/userApiSlice'
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'
import { setUserPublicData } from "../../../redux/features/user/userSlice";
import { isValidUrl } from "../../../utils/utilsForms";
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";
import { initial } from "lodash";


const UserPublicDataSocials = ({ public_data, isLoadingGetUserPublicData, userId }: any) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const textPublicData = useSelector(selectCurrentDictonary).public_data

    const [putUserPublicData]: any = usePutUserPublicDataMutation()

    let initialValues = {
        "LinkedIn": ( public_data.LinkedIn && public_data.LinkedIn !== "https://null" && public_data.LinkedIn !== "null" && public_data.LinkedIn !== "https://") ? public_data.LinkedIn : "",
        "Facebook": ( public_data.Facebook && public_data.Facebook !== "https://null" && public_data.Facebook !== "null" && public_data.Facebook !== "https://" ) ? public_data.Facebook : "",
        "Instagram":( public_data.Instagram && public_data.Instagram !== "https://null" && public_data.Instagram !== "null" && public_data.Instagram !== "https://") ? public_data.Instagram : "",
        "Twitter": ( public_data.Twitter && public_data.Twitter !== "https://null" && public_data.Twitter !== "null" && public_data.Twitter !== "https://")? public_data.Twitter : "",
        "Youtube": ( public_data.Youtube && public_data.Youtube !== "https://null" && public_data.Youtube !== "null" && public_data.Youtube !== "https://") ? public_data.Youtube : "",
        
    }

// console.log(initialValues);

    useEffect(() => {

    }, [public_data])

    //Funciones para agregar https
    const isValidUrl = (str: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + 
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + 
          '((\\d{1,3}\\.){3}\\d{1,3}))' + 
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + 
          '(\\?[;&a-z\\d%_.~+=-]*)?' + 
          '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(str);
      };
  
      const prependHttpsIfNeeded = (url: string) => {
          if (!/^https?:\/\//i.test(url)) {
              return "https://" + url;
          }
          return url;
      };
//funcions para validacion general.
    const validate = (values: any) => {

        const errors: any = {}

        if (values.Instagram.length > 0) {
            if (!isValidUrl(values.Instagram)) {
                errors.Instagram = textPublicData.msj_url_invalido
            }
        }
        if (values.Twitter.length > 0) {
            if (!isValidUrl(values.Twitter)) {
                errors.Twitter = textPublicData.msj_url_invalido
            }
        }
        if (values.LinkedIn.length > 0) {
            if (!isValidUrl(values.LinkedIn)) {
                errors.LinkedIn = textPublicData.msj_url_invalido
            }
        }
        if (values.Youtube.length > 0) {
            if (!isValidUrl(values.Youtube)) {
                errors.Youtube = textPublicData.msj_url_invalido
            }
        }
        if (values.Facebook.length > 0) {
            if (!isValidUrl(values.Facebook)) {
                errors.Facebook = textPublicData.msj_url_invalido
            }
        }

        if (!(Object.entries(errors).length > 0)) {
            setIsValidated(true)
        } else {
            setIsValidated(false)
        }

        return errors
    }


    const handleSubmit = async (values: any) => {
        setIsLoading(true)
        dispatch(deleteAlert({ alert_id: "error_user_redes" }))
        dispatch(deleteAlert({ alert_id: "cargando_user_redes" }))
        let new_alert = { id: "cargando_user_redes", type: "loading", title: textPublicData.titulo_7, desc: textPublicData.titulo_8, close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let body_aux = { ...public_data, ...values }
            let formData = new FormData();
            //para agregar https://
            formData.append('Name', public_data.Name);
            formData.append('Description', public_data.Description);
            formData.append('LastName', public_data.LastName);
            formData.append('Instagram', prependHttpsIfNeeded(values.Instagram || public_data.Instagram));
            formData.append('Twitter', prependHttpsIfNeeded(values.Twitter || public_data.Twitter));
            formData.append('LinkedIn', prependHttpsIfNeeded(values.LinkedIn || public_data.LinkedIn));
            formData.append('Youtube', prependHttpsIfNeeded(values.Youtube || public_data.Youtube));
            formData.append('Facebook', prependHttpsIfNeeded(values.Facebook || public_data.Facebook));
    
            if (values.Instagram) {
                formData.append('Instagram', values.Instagram);
            } else {
                formData.append('Instagram', public_data.Instagram);
            }
            if (values.Twitter) {
                formData.append('Twitter', values.Twitter);
            } else {
                formData.append('Twitter', public_data.Twitter);
            }
            if (values.LinkedIn) {
                formData.append('LinkedIn', values.LinkedIn);
            } else {
                formData.append('LinkedIn', public_data.LinkedIn);
            }
            if (values.Youtube) {
                formData.append('Youtube', values.Youtube);
            } else {
                formData.append('Youtube', public_data.Youtube);
            }
            if (values.Facebook) {
                formData.append('Facebook', values.Facebook);
            } else {
                formData.append('Facebook', public_data.Facebook);
            }

            const res: any = await putUserPublicData({ user_id: userId, body: formData })
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                dispatch(deleteAlert({ alert_id: "cargando_user_redes" }))
                let new_alert = { type: "success", title: "Editar redes sociales", desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))
                dispatch(setUserPublicData({ user_public_data: body_aux }))

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_user_redes" }))
                let new_alert2 = { id: "error_user_redes", type: "error", title: "Error en editar redes sociales", desc: "", close: true, timeout: 50000 }
                dispatch(addAlert({ alert: new_alert2 }))
            }
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "cargando_user_redes" }))
            let new_alert2 = { id: "error_user_redes", type: "error", title: "Error en editar redes sociales", desc: "", close: true, timeout: 50000 }
            dispatch(addAlert({ alert: new_alert2 }))
        }
        setIsLoading(false)

    }

    let button_html = <></>
    if (isValidated) {
        button_html = <div className='col-12 mt-3'>
            <div className='d-flex justify-content-end'>
                <button type="submit" disabled={isLoading} className='btn btn-primary btn-rounded '>{textPublicData.btn_guardar}</button>
            </div>
        </div>
    } else {
        button_html = <div className='col-12 mt-3'>
            <div className='d-flex justify-content-end'>
                <button disabled type="submit" className='btn btn-primary btn-rounded '>{textPublicData.btn_guardar}</button>
            </div>
        </div>

    }


    let form_html = <>{textPublicData.titulo_7}</>
    if (!isLoadingGetUserPublicData) {
        form_html = <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form>
                <div className='row '>
                    <div className='col-12 col-lg-6 mb-4'>
                        <TextInput name="LinkedIn" label="LinkedIn" type="text" showErrorText={true} defaultValue={initialValues.LinkedIn} />
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <TextInput name="Facebook" label="Facebook" type="text" showErrorText={true} defaultValue={initialValues.Facebook} />
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <TextInput name="Instagram" label="Instagram" type="text" showErrorText={true} defaultValue={initialValues.Instagram} />
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <TextInput name="Twitter" label="Twitter" type="text" showErrorText={true} defaultValue={initialValues.Twitter} />
                    </div>
                    <div className='col-12 col-lg-6 '>
                        <TextInput name="Youtube" label="Youtube" type="text" showErrorText={true} defaultValue={initialValues.Youtube} />
                    </div>
                    {button_html}
                </div>
            </Form>
        </Formik>
    } else {
        form_html = <div>
            {textPublicData.titulo_7}
        </div>
    }

    return (
        <div className='pb-5'>
            <div className='d-flex justify-content-start mt-2 mb-4 '>
                <div>
                    <h5><b>{textPublicData.titulo_6}</b></h5>
                    <p>{textPublicData.desc_5}</p>
                </div>
            </div>
            <div className='row no-gutters '>
                <div className='col-12 mb-4'>
                    {form_html}
                </div>
            </div>
        </div>
    )
}
export default UserPublicDataSocials;