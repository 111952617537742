import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signin: builder.mutation({
            query: credentials => ({
                url: '/Api/Auth/SignIn',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        signup: builder.mutation({
            query: values => ({
                url: '/Api/Auth/Register',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        signupWithData: builder.mutation({
            query: values => ({
                url: '/Api/Auth/RegisterWithData',
                method: 'POST',
                body: { ...values.body }
            })
        }),
        passwordrecover: builder.mutation({
            query: credentials => ({
                url: '/Api/Auth/ForgotPassword',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        forgotPasswordSendCode: builder.mutation({
            query: credentials => ({
                url: '/Api/Auth/ForgotPassword',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        forgotPasswordResetPassword: builder.mutation({
            query: credentials => ({
                url: '/Api/Auth/ResetPasswordWithConfirmationCode',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        checkUserExists: builder.mutation({
            query: credentials => ({
                url: '/api/Users/PersonalData/CheckUserExists?userEmail='+credentials.EmailAddress,
                method: 'GET',
            })
        }),
    })
})

export const { /* extended api features */
    useSigninMutation,
    useSignupMutation,
    useSignupWithDataMutation,
    usePasswordrecoverMutation,
    useForgotPasswordSendCodeMutation, 
    useForgotPasswordResetPasswordMutation,
    useCheckUserExistsMutation,
} = authApiSlice