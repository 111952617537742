import './UserProfileExternal.scss'
import CardStartup from '../../components/CardStartup/CardStartup';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';


const UserProfileExternalStartups = ({ user_startups, is_loading_user_startups }: any) => {

    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    let startups_list_html = <></>
    if (is_loading_user_startups) {
        startups_list_html = <div className="d-flex justify-content-center">
            <p>{textPublicProfile.label_10}</p>
        </div>
    } else {

        if (user_startups.length > 0) {
            startups_list_html = <div className='row no-gutters'>
                {
                    (user_startups?.map((startup: any, iter: any) => {
                        return (
                            <div className='col-12 mb-4'>
                                <CardStartup
                                    number={iter}
                                    startup={startup}
                                    canEdit={false}
                                ></CardStartup>
                            </div>
                        )
                    }))
                }
            </div>
        } else {
            startups_list_html = <div className="d-flex justify-content-center">
                <p>{textPublicProfile.label_5}</p>
            </div>
        }
    }

    return (
        <div className="user-profile-startups padding-layout-x">
            <div className='row'>
                <div className="col-12 ">
                    <h4 className='mb-4 text-white fs-titulo-profile font-italic'>
                        <b>{textPublicProfile.titulo_3}</b>
                    </h4>
                </div>
                <div className='col-12'>
                    {startups_list_html}
                </div>
            </div>
        </div>
    )
}

export default UserProfileExternalStartups;