import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import FormularioSolicitarReunion from './FormularioSolicitarReunion';
import FormularioSolicitarTiempo from './FormularioSolicitarTiempo';
import { useEffect, useState } from 'react';
import { useGetUserDonatedTimeMutation } from '../../redux/features/meetings/meetingsApiSlice';

export const DialogTiempoReunion = ({ user_id_profile, sol_reu_open, handleClickDialog, textPublicProfile, QR }: any) => {
  const theme = useTheme();
  const [horasDonadas, setHorasDonadas] = useState([])
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [get]: any = useGetUserDonatedTimeMutation()
  // let GetUserDonatedTime_aux = GetUserDonatedTime(user_id_profile);
  // let user_donated_times = GetUserDonatedTime_aux.value
  // console.log("QR dialogtiempo reunion", QR)
  useEffect(() => {

    async function getTiempoDonado() {
      // console.log("solicitandoooo")
      const res: any = await get({ user_id: user_id_profile, type: 0 });
      const resData = JSON.parse(res.data.responseData)
      //console.log(resData)
      setHorasDonadas(resData)
    }

    if (sol_reu_open) {
      //console.log("pidiendo...")
      getTiempoDonado()
    }
  }, [sol_reu_open])


  let solicitar_reunion_dialog = <></>
  if (horasDonadas && horasDonadas.length > 0) {
    solicitar_reunion_dialog =
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="lg"
        open={sol_reu_open}
        onClose={handleClickDialog}
      >
        <DialogTitle >
          {textPublicProfile.btn_solicitar_reunion}
        </DialogTitle>
        <DialogContent>
          <FormularioSolicitarReunion setHorasDonadas={setHorasDonadas} user_donated_times={horasDonadas} user_id_profile={user_id_profile} handleOnClose={handleClickDialog} QR = {QR}></FormularioSolicitarReunion>
        </DialogContent>
      </Dialog>
  } else {
    solicitar_reunion_dialog = <Dialog
      fullWidth
      /* fullScreen={fullScreen} */
      maxWidth="md"
      open={sol_reu_open}
      onClose={handleClickDialog}
    >
      <DialogTitle >
        {textPublicProfile.btn_solicitar_tiempo}
      </DialogTitle>
      <DialogContent>
        <FormularioSolicitarTiempo solicitudes_tiempo_usuario={[]} user_id_profile={user_id_profile} handleOnClose={handleClickDialog} QR = {QR}></FormularioSolicitarTiempo>
      </DialogContent>
    </Dialog>

  }


  return (
    <>{solicitar_reunion_dialog}</>
  )
}
