import MeetingsSolicitudesEnviadas from './MeetingsSolicitudesEnviadas';
import MeetingsSolicitudesRecibidas from './MeetingsSolicitudesRecibidas';
import "./MeetingsSolicitudes.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import Label from '../../../components/Form/Label';
import { usuario_html } from '../../../utils/meetings';
import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import { changeDateTimeZone, changeDonatedTimeZone } from '../../../utils/utilsForms';
import moment from "moment"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import MeetingSolicitudesTiempo from './MeetingSolicitudesTiempo';
import MiTabla from './MiTabla'


 
const MeetingsSolicitudes = () => {

    const actual_time_zone = GetActualTimeZone()
    const textMeetings= useSelector(selectCurrentDictonary).meetings

    /* enviada = 0 => es solicitud recibida */
    /* enviada = 1 => es solicitud enviada */
    function handleOpenDetalleSolicitud(enviada: number, id_solicitud: number, solicitud: any) {
        set_solicitud(solicitud)
        handleClickDialogDetalle()
    }
    const [open_dialog_detalle, set_open_dialog_detalle] = useState(false)
    const [solicitud, set_solicitud] = useState<any>({})
    function handleClickDialogDetalle() {
        set_open_dialog_detalle(!open_dialog_detalle)
    }

    let detalle_solicitud_html = <></>
    if (solicitud.UserRequestedId) {
        /* status */
        let RequestStatusId = solicitud.RequestStatusId
        let request_status = textMeetings.alerta_1
        let request_status_html_class = ""
        if (RequestStatusId == 2) {
            request_status = textMeetings.alerta_2
            request_status_html_class = "text-success"
        }
        if (RequestStatusId == 1) {
            request_status = textMeetings.alerta_3
            request_status_html_class = "text-danger"
        }
        let request_status_html = <div className={request_status_html_class}>
            {request_status}
        </div>
        /* usuarios */
        /* usuario 1 */
        let requested_user_id = solicitud.UserRequestedId
        let requested_user_nombre = solicitud.UserRequestedName
        let requested_user_apellido = solicitud.UserRequestedLastName
        let requested_user_url_foto = solicitud.UserRequestedProfilePicture
        let requested_user_roles = solicitud.UserRequestedRoles
        let requested_user = <div className='d-flex justify-content-start'>
            {usuario_html(requested_user_id, requested_user_nombre, requested_user_apellido, requested_user_url_foto, requested_user_roles)}
        </div>
        /* usuario 2 */
        let request_user_id = solicitud.UserRequestId
        let request_user_nombre = solicitud.UserRequestName
        let request_user_apellido = solicitud.UserRequestLastName
        let request_user_url_foto = solicitud.UserRequestProfilePicture
        let request_user_roles = solicitud.UserRequestRoles
        let request_user = <div className='d-flex justify-content-start'>
            {usuario_html(request_user_id, request_user_nombre, request_user_apellido, request_user_url_foto, request_user_roles)}
        </div>
        /* hora reunion */
        let UserRequestedStartTime = solicitud.UserRequestedStartTime
        let UserRequestedEndTime = solicitud.UserRequestedEndTime
        let fecha_inicio = UserRequestedStartTime.split("T")[0]
        let hora_inicio = UserRequestedStartTime.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime.split("T")[1].split(":")[1]
        let hora_termino = UserRequestedEndTime.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime.split("T")[1].split(":")[1]

        let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)

        let hora_reunion = <div className='d-flex flex justify-content-start align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div>
                    {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </div>
                <div>
                    {dict.startTime + " - " + dict.endTime}
                </div>
            </div>
        </div>

        /* hora de envio */
        let dict_createdAd = changeDateTimeZone(actual_time_zone, solicitud.CreatedAd)

        let hora_envio = <div className='d-flex flex justify-content-start align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div>
                    {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </div>
                <div>
                    {dict_createdAd.time}

                </div>
            </div>
        </div>

        /* html final detalle */
        detalle_solicitud_html = <>
            <div className='row'>

                <div className='col-12  mb-4'>
                    <Label label = {textMeetings.modal_principal_enviada_el}></Label>
                    <p>{hora_envio}</p>
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.modal_principal_enviada_por}></Label>
                    {request_user}
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.modal_principal_recibida_por}></Label>
                    {requested_user}
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.modal_principal_hora_solicitada}></Label>
                    <p>{hora_reunion}</p>
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.modal_principal_estado}></Label>
                    <p>{request_status_html}</p>
                </div>
                <div className='col-12  mb-4'>
                    <Label label={textMeetings.modal_principal_tipo_reunion}></Label>
                    <p>{solicitud.MeetingTypeName}</p>
                </div>
                <div className='col-12 mb-4'>
                    <Label label={textMeetings.modal_principal_mensaje}></Label>
                    <p>{solicitud.RequestStatusReason}</p>
                </div>
            </div>
        </>
    }

    return (
        <>

            <MeetingsSolicitudesEnviadas handleOpenDetalleSolicitud={handleOpenDetalleSolicitud}></MeetingsSolicitudesEnviadas>
            <MeetingsSolicitudesRecibidas handleOpenDetalleSolicitud={handleOpenDetalleSolicitud} ></MeetingsSolicitudesRecibidas>
            <MeetingSolicitudesTiempo handleClickDialogDetalle={handleOpenDetalleSolicitud}></MeetingSolicitudesTiempo>
     
            
            
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open_dialog_detalle}
                onClose={handleClickDialogDetalle}
            >
                <DialogTitle >
                    {textMeetings.modal_principal_titulo}
                </DialogTitle>
                <DialogContent>
                    {detalle_solicitud_html}
                </DialogContent>
                <DialogActions>
                    <div className='d-flex justify-content-end'>
                        {/* <button onClick={handleClickDialogDetalle} className="btn btn-danger mr-2">{"textMeetings.modal_principal_cancelar"}</button> */}
                        <button onClick={handleClickDialogDetalle} className="btn btn-primary">{textMeetings.modal_principal_cerrar}</button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MeetingsSolicitudes;

