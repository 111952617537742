import { useEffect, useState } from 'react';
import './BottomNav.scss'
import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';  
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../../../redux/features/auth/authSlice';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { useGetUserAdminRoleMutation } from '../../../redux/features/user/userApiSlice';

const BottomNav = (isOpen: any) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  // const token = useSelector(selectCurrentToken)
  const textNavbar = useSelector(selectCurrentDictonary).bottomNav;
  const userPersonalData = useSelector(selectPersonalData);
  const location = useLocation();
  const on_zoom_meeting = location.pathname == "/zoom-meeting" || location.pathname == "/meeting";
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUserAdminRole] = useGetUserAdminRoleMutation();

  useEffect(() => {
    if (userPersonalData) {
      getUserAdminRole(userPersonalData.Id)
        .unwrap()
        .then(response => {
          const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
          setIsAdmin(isAdmin);
        })
        .catch(error => {
          console.error("Error fetching user role:", error);
        });
    }
  }, [userPersonalData, getUserAdminRole]);

  /* zoom-meeting */
  // if (token && !on_zoom_meeting) {
  if (!on_zoom_meeting && userPersonalData) {
    return (
      <footer className="footer fixed-bottom bottomnav mt-auto ">
        <div className="container">
          <Box sx={{ width: "100%" }} className="w-100">
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event: any, newValue: any) => {
                setValue(newValue);
                navigate(newValue);
              }}
              className="w-100"
            >
              <BottomNavigationAction value="/home" label={textNavbar.buscar} icon={<SearchOutlined/>} />
              <BottomNavigationAction value="/meetings" label={textNavbar.reuniones} icon={<GroupsOutlinedIcon />} />
              <BottomNavigationAction value="/Contacts" label={textNavbar.contactos} icon={<ContactsOutlinedIcon />} />
              <BottomNavigationAction value="/events" label={textNavbar.eventos} icon={<EventOutlinedIcon />} />
              {isAdmin && (
                <BottomNavigationAction value="/Admin-Panel" label={textNavbar.administrador} icon={<BuildOutlinedIcon />} />
              )}
              
              {/* ESTA SECCIÓN ES PARA VERSIONES MÓVILES, RESPONSIVE 
              <BottomNavigationAction value="/favoritos" label={textNavbar.menu3} icon={<StarBorderOutlinedIcon />} /> */}
            </BottomNavigation>
          </Box>
        </div>
      </footer>
    );
  } else {
    return (<></>);
  }
}

export default BottomNav;
