import Card from '../../components/Card/Card'

const Messages = () => {
    return (
        <>
            <Card>
                Notificaciones
            </Card>
        </>
    )
}

export default Messages;