import React, { useState } from 'react';
import { DataGrid, esES, enUS, GridPaginationModel } from '@mui/x-data-grid'; // Eliminado GridFilterModel
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../../src/redux/features/language/LanguageSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Tabla.scss';

// Crear un tema personalizado
const customTheme = createTheme();

interface TablaProps {
  columnas: any;
  datos: any;
  height?: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  onPageChange: (page: number) => void;
  page: number;
  autoHeight?: boolean;
  getRowId?: (row: any) => number | string; // Agregar getRowId a los props
}

export default function Tabla({
  columnas,
  datos,
  height = 500,
  pageSize,
  setPageSize,
  onPageChange,
  page,
  autoHeight = false,
  getRowId, // Añadir getRowId aquí
}: TablaProps) {
  // Obtener el idioma actual desde Redux
  const currentLanguage = useSelector(selectCurrentLanguage);

  // Determinar el localeText basado en el idioma del selector
  const localeText =
    currentLanguage === 'en'
      ? enUS.components.MuiDataGrid.defaultProps.localeText
      : esES.components.MuiDataGrid.defaultProps.localeText;

  // Asegurarse de que el modelo de paginación tenga valores válidos
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    if (model.page !== undefined && model.pageSize !== undefined) {
      onPageChange(model.page); // Llamar a la función onPageChange correctamente
      setPageSize(model.pageSize); // Llamar a la función setPageSize correctamente
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div
        style={{
          width: '100%',
          height: '600px', // Altura mínima para que siempre sea visible
          maxHeight: '600px', // Limitar la altura máxima del contenedor
          overflowY: 'auto', // Permitir scroll vertical cuando sea necesario
          overflowX: 'auto', // Permitir scroll horizontal cuando sea necesario
          paddingBottom: '80px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <DataGrid
          className="table"
          rows={datos}
          columns={columnas}
          disableRowSelectionOnClick={true}
          disableDensitySelector={true}
          checkboxSelection={false}
          localeText={localeText}
          pagination
          paginationMode="client" // Usar 'client' para paginación local
          pageSizeOptions={[5, 10, 20, 50, 100]} // Añadir opciones de tamaño de página
          paginationModel={{ pageSize, page }} // Utilizar paginationModel
          onPaginationModelChange={handlePaginationModelChange} // Manejar cambio en el modelo de paginación
          autoPageSize={false}
          autoHeight={false} // Cambiar autoHeight a false para permitir el scroll
          getRowId={getRowId} // Pasar getRowId a DataGrid
          rowHeight={72} // Establecer altura mínima de fila a 72px
          initialState={{
            pagination: {
              paginationModel: { pageSize: pageSize, page: page },
            },
          }}
          sx={{
            '& .MuiTablePagination-actions button': {
              color: '#000 !important', // Forzar el color negro para los botones de flecha
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center', // Centrar contenido verticalmente
              minHeight: '72px', // Altura mínima de 72px para las celdas
              whiteSpace: 'normal', // Permitir que el texto se ajuste en múltiples líneas
              overflow: 'hidden', // Esconder el contenido que desborde
              textOverflow: 'ellipsis', // Mostrar puntos suspensivos si el contenido es demasiado largo
            },
            '& .MuiDataGrid-row': {
              minHeight: '72px', // Altura mínima de 72px para las filas
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
}
