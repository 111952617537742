

import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData, selectPublicProfile } from "../../../redux/features/user/userSlice";


import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik'
import CardStartup from '../../../components/CardStartup/CardStartup';
import FormularioEmprendimiento from '../../../components/formularios/FormularioEmprendimiento';
import { addAlert, deleteAlert } from "../../../redux/features/alerts/alertsSlice";
import { useDeleteUserStartupsMutation, useGetUserStartupsMutation, usePostUserStartupsMutation, usePutUserStartupsMutation } from '../../../redux/features/startups/startupsApiSlice';
import { personalData } from '../../../interfaces/dimentions';
import GetUserStartups from '../../../hooks/user/GetUserStartups/GetUserStartups';
import { setStartupsList } from '../../../redux/features/startups/startupsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


const UserStartups = () => {
    const textStartups = useSelector(selectCurrentDictonary).startups

    const dispatch = useDispatch()

    const [postUserStartups, { isLoadingPostUserStartups }]: any = usePostUserStartupsMutation()
    const [deleteUserStartups, { isLoadingDeleteUserStartups }]: any = useDeleteUserStartupsMutation()
    const [putUserStartups, { isLoadingPutUserStartups }]: any = usePutUserStartupsMutation()

    const user = useSelector(selectPersonalData)
    // public data vars
    const public_profile = useSelector(selectPublicProfile)
    /* const user_startups = public_profile?.Startups */
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let GetUserStartups_aux = GetUserStartups(user_personal_data.Id);
    let user_startups = GetUserStartups_aux.value
    let is_loading_user_startups = GetUserStartups_aux.isLoading
    /*  */

    const empty_values = {
        "startup_id": "",
        "startup_Name": "",
        "startup_DocumentValue": "",
        "startup_Description": "",
        "startup_WebPage": "",
        "startup_FacebookUrl": "",
        "startup_InstagramUrl": "",
        "startup_Stage": 0, // intereses
        "startup_InvestmentSourceLevelId": 0,
        "startup_CountryId": 0,
        "startup_IndustryId": 0,
        "startup_EmployeesNumberId": 0,
        "startup_GenderEqualityPolicies": 0,
        "startup_DiversityInclusionPolicies": 0,
        "startup_LedByWomen": 0,
        "startup_StartupPhoto": "",
        "startup_StartupVideo": "",
        "startup_CreationDateYear": "",
        "startup_CreationDateMonth": "",
        "startup_CreationDateDay": "",
    }
    const [actual_values_create, setActualValuesCreate] = useState<any>({ ...empty_values });
    const [actual_photo_create, setActualPhotoCreate] = useState<any>("");
    const [actual_photo_edit, setActualPhotoEdit] = useState<any>("");
    /* const [actual_video_create, setActualVideoCreate] = useState<any>(""); */

    const [actual_values_edit, setActualValuesEdit] = useState<any>({ ...empty_values });

    const [actualDeleteId, setActualDeleteId] = useState(0)

    /* variables y funciones modal (dialog) --------------------------------------------------------------------------------------------------------- */
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);


    const [webPageError, setWebPageError] = useState<string | null>(null);
    const [facebookError, setFacebookError] = useState<string | null>(null);
    const [instagramError, setInstagramError] = useState<string | null>(null);



    useEffect(() => {
        /* public_profile */
        if (!public_profile) {
            const fetchUserPublicProfile = async () => {
                try {
                    reloadStartups(user.Id)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchUserPublicProfile().catch(console.error);
        }
    }, [])


    /* Crear */
    const handleClickOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };
    /* editar */
    const handleClickOpenEdit = (startup: any) => {
        let actual_values = {
            startup_id: startup.Id,
            startup_Name: startup.Name,
            startup_Description: startup.Description,
            startup_Stage: startup.Stage + "",
            startup_IndustryId: startup.Industry,
            startup_InvestmentSourceLevelId: startup.InvestmentSource,
            startup_EmployeesNumberId: startup.EmployeesNumber,
            startup_CountryId: startup.Country,
            startup_GenderEqualityPolicies: startup.GenderEqualityPolicies,
            startup_DiversityInclusionPolicies: startup.DiversityInclusionPolicies,
            startup_LedByWomen: startup.LedByWomen,
            startup_BillingRange: startup.BillingRange ? startup.BillingRange + "" : "",
            startup_WebPage: startup.WebPage,
            startup_FacebookUrl: startup.FacebookUrl,
            startup_InstagramUrl: startup.InstagramUrl,
            startup_DocumentValue: startup.DocumentValue,
            startup_CreationDateYear: startup.StartDate ? parseInt(startup.StartDate.split("T")[0].split("-")[0]) : "",
            startup_CreationDateMonth: startup.StartDate ? parseInt(startup.StartDate.split("T")[0].split("-")[1]) : "",
            startup_CreationDateDay: startup.StartDate ? startup.StartDate.split("T")[0].split("-")[2] : "",

        }
        setActualValuesEdit(actual_values)
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    /* Eliminar */
    const handleClickOpenDelete = (startup_id: any) => {
        setActualDeleteId(startup_id)
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    let startups_list_html = <></>
    if (is_loading_user_startups) {
        startups_list_html = <div className="mt-4 d-flex justify-content-center align-items-center">
            <CircularProgress className="settings-loading-icon mr-2" size="1.5rem" />
            <p>{textStartups.label_26}</p>
        </div>
    } else {

        if (user_startups.length > 0) {
            startups_list_html = <div className='row no-gutters'>
                {
                    (user_startups?.map((startup: any, iter: any) => {
                        return (
                            <div className='col-12 mb-4'>
                                <CardStartup
                                    number={iter}
                                    startup={startup}
                                    canEdit={true}
                                    handleEditBtn={() => handleClickOpenEdit(startup)}
                                    handleDeleteBtn={() => handleClickOpenDelete(startup.Id)}
                                ></CardStartup>
                            </div>
                        )
                    }))
                }
            </div>
        } else {
            startups_list_html = <div className="d-flex justify-content-center">
                <p>{textStartups.label_1}</p>
            </div>
        }
    }


    const [getUserStartups, { isLoadingGetUserStartups }]: any = useGetUserStartupsMutation()
    /* Variables y funciones formulario --------------------------------------------------------------------------------------------------------- */
    async function reloadStartups(user_id: any) {
        const res: any = await getUserStartups({ user_id: user_id });
        const resCode: any = res.data.statusCode
        if (resCode == 200) {
            const resData = JSON.parse(res.data.responseData)
            dispatch(setStartupsList({ startups_list: resData }))
        } else {
        }
    }


    const isValidURL = (url:any) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocolo (http, https)
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // dominio
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // puerto y ruta
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(url);

    }
      
          const prependHttpsIfNeeded = (url: string) => {
              if (!/^https?:\/\//i.test(url)) {
                  return "https://" + url;
              }
              return url;
          };
    
          function ensureProtocol(url: string): string {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                return 'https://' + url;
            }
            return url;
        }

        
    /* create */
    function handleSubmitCreate(values: any, { setStatus, setErrors }: any) {
        let new_alert = { id: "save_startups", type: "loading", title: "Guardando emprendimiento...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
        const fetchSave = async () => {
            let formData = new FormData();
            formData.append('Name', actual_values_create.startup_Name);
            formData.append('Description', actual_values_create.startup_Description);
            formData.append('Stage', actual_values_create.startup_Stage);

            if (actual_values_create.startup_DocumentValue) {
                formData.append('DocumentValue', actual_values_create.startup_DocumentValue);
            }
            if (actual_values_create.startup_CreationDateYear && actual_values_create.startup_CreationDateMonth && actual_values_create.startup_CreationDateDay) {
                let start_date_aux = actual_values_create.startup_CreationDateYear + "-" + actual_values_create.startup_CreationDateMonth + "-" + actual_values_create.startup_CreationDateDay;
                formData.append('StartDate', start_date_aux);
            }
            /* intereses */
            if (actual_values_create.startup_IndustryId && actual_values_create.startup_IndustryId != 0) {
                formData.append('Industry', actual_values_create.startup_IndustryId);
            }
            if (actual_values_create.startup_InvestmentSourceLevelId && actual_values_create.startup_InvestmentSourceLevelId != 0) {
                formData.append('InvestmentSource', actual_values_create.startup_InvestmentSourceLevelId);
            }
            if (actual_values_create.startup_EmployeesNumberId && actual_values_create.startup_EmployeesNumberId != 0) {
                formData.append('EmployeesNumber', actual_values_create.startup_EmployeesNumberId);
            }
            if (actual_values_create.startup_CountryId && actual_values_create.startup_CountryId != 0) {
                formData.append('Country', actual_values_create.startup_CountryId);
            }
            if (actual_values_create.startup_GenderEqualityPolicies && actual_values_create.startup_GenderEqualityPolicies != 0) {
                formData.append('GenderEqualityPolicies', actual_values_create.startup_GenderEqualityPolicies);
            }
            if (actual_values_create.startup_DiversityInclusionPolicies && actual_values_create.startup_DiversityInclusionPolicies != 0) {
                formData.append('DiversityInclusionPolicies', actual_values_create.startup_DiversityInclusionPolicies);
            }
            if (actual_values_create.startup_LedByWomen && actual_values_create.startup_LedByWomen != 0) {
                formData.append('LedByWomen', actual_values_create.startup_LedByWomen);
            }
            if (actual_values_create.startup_BillingRange && actual_values_create.startup_BillingRange != 0) {
                formData.append('BillingRange', actual_values_create.startup_BillingRange);
            }

         if (actual_values_create.startup_WebPage && !isValidURL(actual_values_create.startup_WebPage)) {
        setWebPageError("URL inválido");
        return; // Salir de la función si la URL no es válida
    } else {
        setWebPageError(null); // Limpiar el error si la URL es válida
        formData.append('WebPage', actual_values_create.startup_WebPage);
    }

    if (actual_values_create.startup_FacebookUrl && !isValidURL(actual_values_create.startup_FacebookUrl)) {
        setFacebookError("URL inválido");
        return; // Salir de la función si la URL no es válida
    } else {
        setFacebookError(null); // Limpiar el error si la URL es válida
        formData.append('FacebookUrl', actual_values_create.startup_FacebookUrl);
    }

    if (actual_values_create.startup_InstagramUrl && !isValidURL(actual_values_create.startup_InstagramUrl)) {
        setInstagramError("URL inválido");
        return; // Salir de la función si la URL no es válida
    } else {
        setInstagramError(null); // Limpiar el error si la URL es válida
        formData.append('InstagramUrl', actual_values_create.startup_InstagramUrl);
    }
            /* foto y video */
            if (actual_photo_create) {
                formData.append('StartupPhoto', actual_photo_create);
            }
            /* if (actual_video_create) {
                formData.append('StartupVideo', actual_video_create);
            } */
            postUserStartups({ body: formData, user_id: user.Id });
            dispatch(deleteAlert({ alert_id: "save_startups" }));
            handleCloseCreate()
            reloadStartups(user_personal_data.Id)
        }
        fetchSave().catch(console.error);
    }
    function handleChangeDataCreate(new_values: any) {
        setActualValuesCreate({ ...new_values });
    }

    const validate = (values: any) => {

//aqui 


    }

    const validateCreate = (values: any) => {
        const errors: any = {}

        if (!values.startup_Name) {
            errors.startup_Name = textStartups.label_10
        }

        if (!values.startup_Description) {
            errors.startup_Description = textStartups.label_10
        }
        if (!values.startup_Stage || values.startup_Stage == 0) {
            errors.startup_Stage = textStartups.label_10
        }

        handleChangeDataCreate(values)
        return errors
    }

    /* edit */
    function handleSubmitEdit(values: any, { setStatus, setErrors }: any) {
        let new_alert = { id: "save_startups", type: "loading", title: "Guardando emprendimiento...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
        const fetchSave = async () => {
            let formData = new FormData();
            formData.append('Id', actual_values_edit.startup_id);
            formData.append('Name', actual_values_edit.startup_Name);
            formData.append('Description', actual_values_edit.startup_Description);
            formData.append('Stage', actual_values_edit.startup_Stage);

            if (actual_values_edit.startup_DocumentValue) {
                formData.append('DocumentValue', actual_values_edit.startup_DocumentValue);
            }
            if (actual_values_edit.startup_CreationDateYear && actual_values_edit.startup_CreationDateMonth && actual_values_edit.startup_CreationDateDay) {
                let start_date_aux = actual_values_edit.startup_CreationDateYear + "-" + actual_values_edit.startup_CreationDateMonth + "-" + actual_values_edit.startup_CreationDateDay;
                formData.append('StartDate', start_date_aux);
            }
            /* intereses */
            if (actual_values_edit.startup_IndustryId && actual_values_edit.startup_IndustryId != 0) {
                formData.append('Industry', actual_values_edit.startup_IndustryId);
            }
            if (actual_values_edit.startup_InvestmentSourceLevelId && actual_values_edit.startup_InvestmentSourceLevelId != 0) {
                formData.append('InvestmentSource', actual_values_edit.startup_InvestmentSourceLevelId);
            }
            if (actual_values_edit.startup_EmployeesNumberId && actual_values_edit.startup_EmployeesNumberId != 0) {
                formData.append('EmployeesNumber', actual_values_edit.startup_EmployeesNumberId);
            }
            if (actual_values_edit.startup_CountryId && actual_values_edit.startup_CountryId != 0) {
                formData.append('Country', actual_values_edit.startup_CountryId);
            }
            if (actual_values_edit.startup_GenderEqualityPolicies && actual_values_edit.startup_GenderEqualityPolicies != 0) {
                formData.append('GenderEqualityPolicies', actual_values_edit.startup_GenderEqualityPolicies);
            }
            if (actual_values_edit.startup_DiversityInclusionPolicies && actual_values_edit.startup_DiversityInclusionPolicies != 0) {
                formData.append('DiversityInclusionPolicies', actual_values_edit.startup_DiversityInclusionPolicies);
            }
            if (actual_values_edit.startup_LedByWomen && actual_values_edit.startup_LedByWomen != 0) {
                formData.append('LedByWomen', actual_values_edit.startup_LedByWomen);
            }
            if (actual_values_edit.startup_BillingRange && actual_values_edit.startup_BillingRange != 0) {
                formData.append('BillingRange', actual_values_edit.startup_BillingRange);
            }
            if (actual_values_edit.startup_WebPage && !isValidURL(actual_values_edit.startup_WebPage)) {
                setWebPageError("URL inválido");
                return; // Salir de la función si la URL no es válida
            } else {
                setWebPageError(null); // Limpiar el error si la URL es válida
            }
        
            if (actual_values_edit.startup_FacebookUrl && !isValidURL(actual_values_edit.startup_FacebookUrl)) {
                setFacebookError("URL inválido");
                return; // Salir de la función si la URL no es válida
            } else {
                setFacebookError(null); // Limpiar el error si la URL es válida
            }
        
            if (actual_values_edit.startup_InstagramUrl && !isValidURL(actual_values_edit.startup_InstagramUrl)) {
                setInstagramError("URL inválido");
                return; // Salir de la función si la URL no es válida
            } else {
                setInstagramError(null); // Limpiar el error si la URL es válida
            }
            /* foto y video */
            if (actual_photo_edit) {
                formData.append('StartupPhoto', actual_photo_edit);
            }
            /* if (actual_video_edit) {
                formData.append('StartupVideo', actual_video_edit);
            } */

            await putUserStartups({ body: formData, user_id: user.Id });
            dispatch(deleteAlert({ alert_id: "save_startups" }));
            handleCloseEdit()
            reloadStartups(user_personal_data.Id)
        }
        fetchSave().catch(console.error);
    }
    function handleChangeDataEdit(new_values: any) {
        setActualValuesEdit({ ...new_values });
    }
    const validateEdit = (values: any) => {
        const errors: any = {}

        if (!values.startup_Name) {
            errors.startup_Name = textStartups.label_10
        }

        if (!values.startup_Description) {
            errors.startup_Description = textStartups.label_10
        }
        handleChangeDataEdit(values)
        return errors
    }
    /*  */

    async function handleConfirmDelete() {
        let new_alert = { id: "delete_startup", type: "loading", title: "Eliminando emprendimiento...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
        try {
            const res: any = await deleteUserStartups({ startup_id: actualDeleteId });
            dispatch(deleteAlert({ alert_id: "delete_startup" }));
            const rescode = res.data.statusCode

            dispatch(deleteAlert({ alert_id: "delete_startup" }));
            if (rescode == 200) {
                let new_alert_2 = { id: "delete_startup_success", type: "success", title: "Emprendimiento eliminada correctamente", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

                reloadStartups(user_personal_data.Id);
                handleCloseDelete();
            } else {
                let new_alert_2 = { id: "delete_startup_error", type: "error", title: "Error al eliminar emprendimiento", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
            }

        } catch (error) {
            dispatch(deleteAlert({ alert_id: "delete_startup" }));
            let new_alert_2 = { id: "delete_startup_error", type: "error", title: "Error al eliminar emprendimiento", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert_2 }))

        }
    }

    /* dialogo html */
    const dialogs_html = <>
        <button className='mt-3 mt-lg-0 btn btn-primary btn-rounded' onClick={handleClickOpenCreate}>
            + {textStartups.btn_1}
        </button>
        {/* create */}
        <Dialog
            fullScreen={true}
            open={openCreate}
            onClose={handleCloseCreate}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <div className='mb-2 mt-4'>{textStartups.btn_1}</div>
            </DialogTitle>
            <DialogContent >
                <Formik
                    initialValues={actual_values_create}
                    validate={validateCreate}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={handleSubmitCreate}

                >
                    <Form>
                        <FormularioEmprendimiento
                            actual_values={actual_values_create}
                            handleChangeData={handleChangeDataCreate}
                            setActualPhotoCreate={setActualPhotoCreate}
                            actual_photo_create={actual_photo_create}

                        ></FormularioEmprendimiento>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-rounded btn-outline-danger mr-2' onClick={handleCloseCreate}>{textStartups.btn_cancel}</button>
                            <button className='btn btn-rounded btn-primary' type='submit' >{textStartups.btn_add}</button>
                        </div>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
        {/* edit */}
        <Dialog
            fullScreen={true}
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <div className='mb-2 mt-4'>{textStartups.titulo_8}</div>
            </DialogTitle>
            <DialogContent >
                <Formik
                    initialValues={actual_values_edit}
                    validate={validateEdit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={handleSubmitEdit}

                >
                    <Form>
                        <FormularioEmprendimiento
                            actual_values={actual_values_edit}
                            handleChangeData={handleChangeDataEdit}
                            setActualPhotoCreate={setActualPhotoEdit}
                            actual_photo_create={actual_photo_edit}

                        ></FormularioEmprendimiento>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-rounded btn-outline-danger mr-2' onClick={handleCloseEdit}>{textStartups.btn_cancel}</button>
                            <button className='btn btn-rounded btn-primary' type='submit' >{textStartups.btn_add}</button>
                        </div>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
        {/* ELIMINAR */}
        <Dialog
            /* fullWidth */
            maxWidth="sm"
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {textStartups.confirm}
            </DialogTitle>
            <DialogContent>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-rounded btn-outline-danger mr-2" onClick={handleCloseDelete}>
                        {textStartups.btn_cancel}
                    </button>
                    <button type="submit" className="btn btn-rounded btn-primary" onClick={handleConfirmDelete}>
                        {textStartups.btn_delete}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    </>

    /* RETURN */
    return (
        <div className="px-lg-4 pb-5">
            <div className="row align-items-center justify-content-between">
                <div className='col-auto'>
                    <h3 className='font-italic'><b>{textStartups.titulo_1}</b></h3>
                    <p className='parrafo1'>{textStartups.desc_1}</p>
                </div>
                <div className='col-auto'>
                    {dialogs_html}
                </div>
            </div>
            <div className='pb-3'>
                <hr />
            </div>
            <div className='pb-5'>
                {startups_list_html}
            </div>
        </div>
    )
}

export default UserStartups;