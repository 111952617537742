import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { useSelector } from "react-redux"

const SignUpStepper = ({step}: any) => {
    const textSignup= useSelector(selectCurrentDictonary).signup
    const steps = [
        textSignup.paso_1,
        textSignup.paso_2_1,
        textSignup.paso_2_2,
        textSignup.paso_3,
    ];
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )

}
export default SignUpStepper;

