import { useParams } from 'react-router-dom'


import { useEffect, useState } from 'react';
import "./UserPublicData.scss"
import { Avatar } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'
import { usePutUserPublicDataMutation, usePostUserEliminarFotoPerfilPublicoMutation } from '../../../redux/features/user/userApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';

const UserPublicDataImage = ({ public_data, userId, fetchUserPublicData }: any) => {
    const dispatch = useDispatch()
    const textPublicData = useSelector(selectCurrentDictonary).public_data

    const [isLoading, setIsLoading] = useState(false)
    const [putPublicData, { isLoadingPutPublicData }]: any = usePutUserPublicDataMutation();
    const [postUserEliminarFotoPerfilPublicoMutation, { isLoadingPostUserEliminarFotoPerfilPublicoMutation }]: any = usePostUserEliminarFotoPerfilPublicoMutation();
    const [refreshKey, setRefreshKey] = useState(0);
    const [isDeleteBtnSelected, setIsDeleteBtnSelected] = useState(false);
    const [avatarKey, setAvatarKey] = useState(Date.now()); // usa un timestamp como key inicial

    useEffect(() => {
        if (public_data && public_data.ProfilePicture) {
            setActualPhotoCreate(public_data.ProfilePicture);
        }
    }, [public_data]);
   
   
   
   
   
   
    function size_file_text(size: number) {
        if (size < 10000000000) {
            return (Math.round(size / 10) / 100 + " KB")
        } else if (size < 1000000000) {
            return (Math.round(size / 10000) / 100 + " MB")
        } else if (size < 100000000000) {
            return (Math.round(size / 10000000) / 100 + " GB")
        } else {
            return size
        }
    }




    async function onSubmitHandler() {
        setIsLoading(true)

        dispatch(deleteAlert({ alert_id: "error_user_img" }))
        dispatch(deleteAlert({ alert_id: "cargando_user_img" }))

        let new_alert = { id: "cargando_user_img", type: "loading", title: textPublicData.titulo_7, desc: textPublicData.titulo_10, close: false }
        dispatch(addAlert({ alert: new_alert }))

        const formData = new FormData();

        formData.append('Description', public_data.Description);
        formData.append('Name', public_data.Name);
        formData.append('LastName', public_data.LastName);
        formData.append('Instagram', public_data.Instagram);
        formData.append('Twitter', public_data.Twitter);
        formData.append('LinkedIn', public_data.LinkedIn);
        formData.append('Youtube', public_data.Youtube);
        formData.append('Facebook', public_data.Facebook);

        if (actual_photo_create) {
            formData.append('ProfilePhoto', actual_photo_create);
           
        }
        try {
            const res: any = await putPublicData({ user_id: userId, body: formData });

            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                dispatch(deleteAlert({ alert_id: "cargando_user_img" }))
                let new_alert = { type: "success", title: "Editar imagen", desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))
                fetchUserPublicData(userId)

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_user_img" }))

                let new_alert2 = { id: "error_user_img", type: "error", title: textPublicData.error_editar_imagen, desc: textPublicData.error_max_size, close: true, timeout: 2000 }
                dispatch(addAlert({ alert: new_alert2 }))
                // Aquí incrementamos el valor de refreshKey
                setRefreshKey(prevKey => prevKey + 1);
            }

        } catch (error) {

            dispatch(deleteAlert({ alert_id: "cargando_user_img" }))
            let new_alert2 = { id: "error_user_img", type: "error", title: textPublicData.error_editar_imagen, desc: textPublicData.error_max_size, close: true, timeout: 2000 }
            dispatch(addAlert({ alert: new_alert2 }))
        }

    };


    const [keyPhoto, setKeyPhoto] = useState("")
    const [actual_photo_create, setActualPhotoCreate] = useState<any>("");
    

//funcion para el boton de borrar se marque antes de borrar.
    function handleDeleteBtnClick() {
        if (!isDeleteBtnSelected) {
            setIsDeleteBtnSelected(true);
        } else {
            eliminarFotoUsuario();
            setIsDeleteBtnSelected(false);  // Reiniciar el estado después de la eliminación
        }
    }

    function formulario_photo() {
        let btn_eliminar = <></>;
        if (actual_photo_create  && !public_data.ProfilePicture) {
            
            btn_eliminar = <button onClick={() => {
                setActualPhotoCreate("");

                let randomString = Math.random().toString(36);
                setKeyPhoto(randomString)
            }
            }
                className='btn btn-sm btn-danger'>
                {textPublicData.btn_eliminar_cancelar}
            </button>
        }
        return (
        <div>
            <div>
                <input id="startup_StartupPhoto" name="startup_StartupPhoto" key={keyPhoto || ''} type="file" onChange={(event: any) => {
                    if (event.currentTarget.files[0]) {
                        setActualPhotoCreate(event.currentTarget.files[0]);
                    } else {
                        setActualPhotoCreate("");
                    }
                }} />
                {btn_eliminar}
            </div>
            <div>
                <p>{actual_photo_create?.name}</p>
                <p>{size_file_text(actual_photo_create?.size)}</p>
                <p>{actual_photo_create?.type}</p>
            </div>
        </div>)
    }

    let btn_html = <></>
    if (actual_photo_create) {
        btn_html = <div className='d-flex justify-content-end mt-3'>
        <button 
            className={`btn ${isDeleteBtnSelected ? 'btn-warning' : 'btn-danger'} mr-2 btn-rounded`} 
            onClick={handleDeleteBtnClick}
        >
            {isDeleteBtnSelected ? textPublicData.btn_confirmar_borrar : textPublicData.btn_borrar_foto_actual} 
        </button> 
        <button className='btn btn-primary btn-rounded' onClick={onSubmitHandler}>{textPublicData.btn_guardar_foto}</button>
    </div>

    } else {
        btn_html = <div className='d-flex justify-content-end mt-3'>

            <button disabled className='btn btn-primary btn-rounded'>{textPublicData.btn_guardar_foto}</button>
            
        </div>

    }
    
    async function eliminarFotoUsuario() {
       // const res = await eliminarFotoUsuario({ user_id: userId });
        try {
            const res: any = await postUserEliminarFotoPerfilPublicoMutation({ user_id: userId });
            
            // Suponiendo que la API devuelve un código de estado para indicar éxito/fallo
            if (res.data.statusCode === 200) {
                setActualPhotoCreate(null);
                setAvatarKey(Date.now());

                fetchUserPublicData(userId);
                // Aquí podrías actualizar el estado para eliminar la foto del componente, si es necesario.
                // Por ejemplo: setActualPhotoCreate(null);
                setRefreshKey(prevKey => prevKey + 1);
                let success_alert = {
                    type: "success",
                    title: "Eliminar imagen",  // Ajusta este texto según tu preferencia
                    desc: "OK",                // Ajusta este texto según tu preferencia
                    timeout: 2000,
                    close: true
                };

                
            } else {
                // Mostrar una alerta indicando que algo salió mal
                let new_alert = {
                    id: "error_eliminar_foto",
                    type: "error",
                    title: "Error al eliminar foto",
                    desc: "No se pudo eliminar la foto. Intente nuevamente.",
                    close: true,
                    timeout: 2000
                };
                dispatch(addAlert({ alert: new_alert }));
            }
        } catch (error: any) {
            // Manejar el error y mostrar una alerta al usuario
            let new_alert = {
                id: "error_eliminar_foto",
                type: "error",
                title: "Error al eliminar foto",
                desc: error.message,  // Puedes usar `error.message` para mostrar el mensaje de error del servidor o API
                close: true,
                timeout: 2000
            };
            dispatch(addAlert({ alert: new_alert }));
        }
   
   
    }

    return (
        <div>
            <div className='d-flex flex-column flex-lg-row justify-content-start align-items-lg-start '>
                <Avatar
                    key={avatarKey}
                    className='d-none d-lg-block mr-0 mr-lg-4'
                    src={(public_data && public_data.ProfilePicture) ? `${public_data.ProfilePicture}?${avatarKey}` 
                    : "/media/iconos/icono-persona.svg"}
                    sx={{ width: 75, height: 75 }}
                />
                <div className='d-flex d-lg-none justify-content-center w-100 mb-2'>
                    <Avatar
                        key={avatarKey}
                        className=''
                        src={(public_data && public_data.ProfilePicture) ?`${public_data.ProfilePicture}?${avatarKey}` 
                        : "/media/iconos/icono-persona.svg"}
                        sx={{ width: 75, height: 75 }}
                    />
                </div>
                <div>
                    <div>
                        <h5><b>{textPublicData.titulo_2}</b></h5>
                        <p>{textPublicData.desc_2}</p>
                    </div>
                    {formulario_photo()}
                </div>
            </div>

            {btn_html}

        </div>
    )
}

export default UserPublicDataImage;