import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    user_donated_times: [],
    user_meetings: [],
    user_meetings_historial: [],
    user_solicitudes_enviadas: [],
    user_solicitudes_recibidas: [],
    data_meeting_zoom: [],
    meeting_status: [],
    user_solicitudes_directas: []
}

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: initialState,
    reducers: {
        setUserDonatedTimes: (state, action) => {
            const { user_donated_times } = action.payload
            state.user_donated_times = user_donated_times
        },
        setUserMeetings: (state, action) => {
            const { user_meetings } = action.payload
            state.user_meetings = user_meetings
        },
        setUserMeetingsHistorial: (state, action) => {
            const { user_meetings_historial } = action.payload
            state.user_meetings_historial = user_meetings_historial
        },
        setUserSolicitudesEnviadas: (state, action) => {
            const { user_solicitudes_enviadas } = action.payload
            state.user_solicitudes_enviadas = user_solicitudes_enviadas
        },
        setUserSolicitudesRecibidas: (state, action) => {
            const { user_solicitudes_recibidas } = action.payload
            state.user_solicitudes_recibidas = user_solicitudes_recibidas
        },
        setDataMeetingZoom: (state, action) => {
            const { data_meeting_zoom } = action.payload
            state.data_meeting_zoom = data_meeting_zoom
        },
        setMeetingStatus: (state, action) => {
            const { meeting_status } = action.payload
            state.meeting_status = meeting_status
        },
        setUserSolicitudesDirectas: (state, action) => {
            const { user_solicitudes_directas} = action.payload
            state.user_solicitudes_directas = user_solicitudes_directas
        }
    },
})

export const {
    setUserDonatedTimes,
    setUserMeetings,
    setUserMeetingsHistorial,
    setUserSolicitudesEnviadas,
    setUserSolicitudesRecibidas,
    setDataMeetingZoom,
    setMeetingStatus,
    setUserSolicitudesDirectas
} = meetingsSlice.actions

export default meetingsSlice.reducer


//recordar que se debe agregar el reducer en el store.ts

export const selectUserDonatedTimes = (state: any) => state.meetings.user_donated_times;
export const selectUserMeetings = (state: any) => state.meetings.user_meetings;
export const selectUserMeetingsHistorial = (state: any) => state.meetings.user_meetings_historial;
export const selectUserSolicitudesEnviadas = (state: any) => state.meetings.user_solicitudes_enviadas;
export const selectUserSolicitudesRecibidas = (state: any) => state.meetings.user_solicitudes_recibidas;
export const selectDataMeetingZoom = (state: any) => state.meetings.data_meeting_zoom;
export const selectMeetingStatus = (state: any) => state.meetings.meeting_status;
export const selectUserSolicitudesDirectas = (state: any) => state.meetings.user_solicitudes_directas
