import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { personalData } from '../../interfaces/dimentions';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import QRCodeGenerator from './QRCodeGenerator';
import HourDonation from './HourDonation';
import ScheduleMeeting from './ScheduleMeeting';
import EmailSender from './EmailSender';
import Events from './Events';
import { useGetUserAdminRoleMutation } from '../../redux/features/user/userApiSlice';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`contacts-tabpanel-${index}`}
            aria-labelledby={`contacts-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `contacts-tab-${index}`,
        'aria-controls': `contacts-tabpanel-${index}`,
    };
}

const AdminPanel = () => {
    const navigate = useNavigate();
    const textMeetings = useSelector(selectCurrentDictonary).meetings;
    const [value, setValue] = useState(0);
    const userPersonalData: personalData = useSelector(selectPersonalData);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [getUserAdminRole] = useGetUserAdminRoleMutation();

    useEffect(() => {
        if (userPersonalData) {
            getUserAdminRole(userPersonalData.Id)
                .unwrap()
                .then(response => {
                    const isAdmin = response.message.includes("Administrator");
                    const isSuperAdmin = response.message.includes("Super Administrator");
                    setIsAuthorized(isAdmin || isSuperAdmin);
                    if (!isAdmin && !isSuperAdmin) {
                        navigate('/home');
                    }
                })
                .catch(error => {
                    console.error("Error fetching user role:", error);
                    navigate('/home');
                });
        }
    }, [userPersonalData, navigate, getUserAdminRole]);

    if (!isAuthorized) {
        return null;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClickOpenDialogPublicProfile = (userId: number) => {
        setSelectedUserId(userId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className='meetings-container padding-layout-x'>
            <div className='d-block d-lg-none'>
                <div className='row no-gutters'>
                    {['Información Usuarios', 'Donación de horas', 'Agendar reuniones', 'Enviar correo', 'Actividades'].map((label, index) => (
                        <div className='col-12' key={index}>
                            <div
                                className={`meetings-menu-item ${value === index ? "active" : ""}`}
                                onClick={() => setValue(index)}
                            >
                                {label}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box className="d-none d-lg-block" sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab className="meetings-menu-itemtab" label={"Información Usuarios"} {...a11yProps(0)} />
                        <Tab className="meetings-menu-itemtab" label={"Donación de horas"} {...a11yProps(1)} />
                        <Tab className="meetings-menu-itemtab" label={"Agendar reuniones"} {...a11yProps(2)} />
                        <Tab className="meetings-menu-itemtab" label={"Envío correo"} {...a11yProps(3)} />
                        <Tab className="meetings-menu-itemtab" label={"Eventos"} {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <QRCodeGenerator />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <HourDonation />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ScheduleMeeting />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <EmailSender />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Events />
                </TabPanel>
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent className='p-0'>
                    {/* espacio para un posible futuro diálogo */}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AdminPanel;
