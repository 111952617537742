import { Grid, Menu, MenuItem } from '@mui/material';
import languageOptions from "../../utils/settingsOptionsLangBar.json"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { CircleFlag } from 'react-circle-flags';
import "./LanguageMenu.scss"

export const LanguageMenu = ({ handleClose, anchorEl, handleLanguageChange }: any) => {

  const textLenguajes = useSelector(selectCurrentDictonary).lenguajes
  const open = Boolean(anchorEl);

  return (
    <div>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languageOptions.map((language, item) =>

          <MenuItem key={item} onClick={() => handleLanguageChange(language.id)}>
            <Grid container alignItems={"center"}>
              <Grid item className='mr-3' >
                <CircleFlag countryCode={language.icon} height="23" />
              </Grid>
              <Grid className='textMenu'>
                {textLenguajes[language.id]}
              </Grid>
            </Grid>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
