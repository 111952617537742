import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from "moment"
import "../Meetings.scss"
import MeetingsDonarTiempoPaso1 from './MeetingsDonarTiempoPaso1';
import MeetingsDonarTiempoPaso2 from './MeetingsDonarTiempoPaso2';
import MeetingsDonarTiempoPaso3 from './MeetingsDonarTiempoPaso3';
import { useDispatch } from 'react-redux';
import { usePostUserDonatedTimeMutation, useGetUserMeetingRequestTimesMutation, usePostCreateNotificationMutation, useGetUserNotifyTimeMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import { useSelector } from 'react-redux';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { useGetUserNotificationsMutation } from '../../../redux/features/user/userApiSlice';
import { changeDonatedTimeZone } from '../../../utils/utilsForms';
import {
    selectPersonalData,
    selectUserNotifications,
    setUserNotifications,
} from '../../../redux/features/user/userSlice';
import { personalData } from '../../../interfaces/dimentions';
import { selectNotificationMeeting } from '../../../redux/features/notifications/notificationsSlice';
import { addNotification } from '../../../redux/features/notifications/notificationsSlice';
import { usePostAddNotificationMutation } from '../../../redux/features/notifications/notificationsApiSlice'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
  } from '@mui/material';

const MeetingsDonarTiempo = ({ reloadDonatedTimes, handleCloseDonarTiempo, directId, onBooleanChange }: any) => {

    //modal de confirmacion
    const [openConfirmDonate, setOpenConfirmDonate] = useState(false);
    const handleOpenConfirmDonate = () => {
        setOpenConfirmDonate(true);
      };
      const handleDonateConfirmed = () => {
        handleSubmitDonatedTimes();
        executeNotifications();
        setOpenConfirmDonate(false);
      };
      const handleCloseConfirmDonate = () => {
        setOpenConfirmDonate(false);
      };
      //fin modal de confirmacion
    const [timeRequests, { isLoad }]: any = useGetUserNotifyTimeMutation();
    const [createNotification, { isLoadingCreateNotification }]: any = usePostCreateNotificationMutation();
    const [notificationsSent, setNotificationsSent] = useState(false); // estado que controla solo la ejecución única del envío de notificaciones.

    // const notification = useSelector(selectUserNotifications)
    // console.log(notification)
    const actual_time_zone = GetActualTimeZone()

    const [pageSize, setPageSize] = useState<number>(10); // Tamaño de página
const [page, setPage] = useState<number>(0); // Página actual


    const textMeetings = useSelector(selectCurrentDictonary).meetings
    const user_personal_data: personalData = useSelector(selectPersonalData);
    const addNotification: any = usePostAddNotificationMutation();
    const [data, setData] = useState<any[]>([]);
    let user_id = user_personal_data.Id;

    const sendNotification = async () => {

        try {
            if (directId) {
                // console.log("ESTAMOS REALIZANDO UNA DONACION DIRECTA")
                // console.log("ESTAMOS CREANDO UNA NOTIFICACION DE TIEMPO DIRECTO DONADO.")
                let body = {
                    UserId: directId,
                    UserVinculated: user_id,
                    NotificationTypeId: 12
                }
                await createNotification({ body: body })
                onBooleanChange(true)
            }

        }
        catch (error) {
            // console.log(error)
        }



    }
    const sendNotification2 = async () => {

        try {
            if (!directId) {
                // console.log("ESTAMOS REALIZANDO DONACION PUBLICA")

                // console.log("USER ID", user_id)

                const res: any = await timeRequests({ userId: user_id }).unwrap();
                const resData = JSON.parse(res.responseData);
                const userRequestIds = resData.UserRequestIds;
                // console.log(userRequestIds)

                for (const id of userRequestIds) {
                    // console.log("ESTAMOS CREANDO UNA NOTIFICACION DE TIEMPO NEEW.")
                    let body = {
                        UserId: id,
                        UserVinculated: user_id,
                        NotificationTypeId: 11
                    }
                    await createNotification({ body: body })
                }
            }
        }
        catch (error) {
            // console.log(error)
        }



    }
    const executeNotifications = async () => {
        if (!notificationsSent) {
            await sendNotification();
            await sendNotification2();
            setNotificationsSent(true);
        }
    };

    // useEffect(() => {
    //     sendNotification();
    //     sendNotification2();
    // }, []);



    let initialValues = {
        step: 1,
        days: [],
        startTime: "",
        endTime: "",
        timeBlocks: [],
        timeZone: actual_time_zone, // desde -12 a 12 
        duration: 0,
        bloques_reunion: [],
        bloques_reunion_dia: []
    }
    const [actual_values, setActualValues] = useState(initialValues)


    const dispatch = useDispatch()
    const [postUserDonatedTime, { isLoadingPostUserDonatedTime }]: any = usePostUserDonatedTimeMutation()


    let formulario_actual = <></>
    if (actual_values.step == 1) {
        formulario_actual = <MeetingsDonarTiempoPaso1 actual_values={actual_values} setActualValues={setActualValues}></MeetingsDonarTiempoPaso1>
    } else if (actual_values.step == 2) {
        formulario_actual = <MeetingsDonarTiempoPaso2 actual_values={actual_values} setActualValues={setActualValues}></MeetingsDonarTiempoPaso2>
    } else if (actual_values.step == 3) {
        formulario_actual = <MeetingsDonarTiempoPaso3 actual_values={actual_values} setActualValues={setActualValues}></MeetingsDonarTiempoPaso3>
    } else {
        formulario_actual = <></>
    }

    let botones_html = <></>
    if (actual_values.step == 1) {
        if (actual_values.days && actual_values.days.length > 0) {
            botones_html = <div className='d-flex justify-content-end'>
                <button className='btn btn-sm btn-primary' onClick={() => setActualValues({ ...actual_values, step: actual_values.step + 1 })}>{textMeetings.btn_continuar}</button>
            </div>
        } else {
            botones_html = <div className='d-flex justify-content-end'>
                <button disabled className='btn btn-sm btn-primary'>{textMeetings.btn_continuar}</button>
            </div>
        }
    } else if (actual_values.step == 2) {

        if (actual_values.days && actual_values.startTime && actual_values.endTime) {
            botones_html = <div className='d-flex justify-content-end'>
                <button className='btn btn-sm btn-white mr-2' onClick={() => setActualValues({ ...actual_values, step: actual_values.step - 1 })}>{textMeetings.btn_volver}</button>
                <button className='btn btn-sm btn-primary' onClick={() => setActualValues({ ...actual_values, step: actual_values.step + 1 })}>{textMeetings.btn_continuar}</button>
            </div>
        } else {
            botones_html = <div className='d-flex justify-content-end'>
                <button className='btn btn-sm btn-white mr-2' onClick={() => setActualValues({ ...actual_values, step: actual_values.step - 1 })}>{textMeetings.btn_volver}</button>
                <button disabled className='btn btn-sm btn-primary'>{textMeetings.btn_continuar}</button>
            </div>
        }
    } else if (actual_values.step == 3) { // step == 3, acá está el button submit 
        botones_html = <div className='d-flex justify-content-end'>
            <button className='btn btn-sm btn-white' onClick={() => setActualValues({ ...actual_values, step: actual_values.step - 1 })}>{textMeetings.btn_volver}</button>
            <button className='btn btn-sm btn-primary mr-2' onClick={handleOpenConfirmDonate}>
                
                {textMeetings.btn_donar}</button>
        </div>
    } else {
        botones_html = <div className='d-flex justify-content-end'>
            <p className="text-danger">error</p>
        </div>

    }


    /* create */

    function handleSubmitDonatedTimes() {
        handleCloseDonarTiempo();

        let new_alert = {
            id: "guardando_tiempos_donados",
            type: "loading",
            title: textMeetings.titulo_6,
            desc: "" + actual_values.bloques_reunion_dia.length,
            timeout: 2000,
            close: false,
        };
        dispatch(addAlert({ alert: new_alert }));

        // Crear un conjunto para almacenar UserRequestId únicos
        const uniqueUserRequestIds = new Set();

        actual_values.bloques_reunion_dia.forEach(async (bloque_reunion_dia: any, index: number) => {
            let startTime: any = moment(bloque_reunion_dia.date + " " + bloque_reunion_dia.startTime, 'DD-MM-YYYY HH:mm')
            let endTime: any = moment(bloque_reunion_dia.date + " " + bloque_reunion_dia.endTime, 'DD-MM-YYYY HH:mm')
            let timeZoneOffset = actual_time_zone

            startTime = startTime.add(timeZoneOffset * -1, 'hours'); // se pasa de zona horaria X a GMT-0
            endTime = endTime.add(timeZoneOffset * -1, 'hours'); // se pasa de zona horaria X a GMT-0

            let date = startTime.format('DD-MM-YYYY');  // se usa zona horaria de starttime para que se ajuste al cambio de horario 
            startTime = startTime.format('HH:mm');
            endTime = endTime.format('HH:mm');


            try {



                // resData.forEach((obj: any) => {
                //   uniqueUserRequestIds.add(obj.UserRequestId);
                // });
                // const uniqueUserRequestIdsArray = Array.from(uniqueUserRequestIds); // IDS UNICOS DE LAS PERSONAS CON LAS QUE SE TIENEN REQUESTTIMES

                let bloque_reunion_dia_aux = {
                    ...bloque_reunion_dia, timeZoneOffset: timeZoneOffset, startTime: startTime, endTime: endTime, date: date
                    , directId: directId
                }

                const postDeny = await postUserDonatedTime({ body: bloque_reunion_dia_aux });


                // Comprobar si la respuesta es un error
                if (postDeny.data.statusCode && postDeny.data.statusCode === 500) {
                    try {
                        const responseObj = JSON.parse(postDeny.data.responseMessage);
                        if (responseObj.Message === "Time was already donated in the platform") {
                          

                            const dto = JSON.parse(responseObj.Data);
                            // console.log("DTO:", dto);

                            // Convertir la fecha a un formato que JavaScript pueda interpretar
                            const dateParts = dto.Date.split('-');
                            const formattedDate = `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`;

                            const dateTime = new Date(`${formattedDate} ${dto.StartTime}`);

                            // Usar actual_time_zone en lugar de dto.TimeZoneOffset
                            const offsetInMinutes = actual_time_zone * 60;

                            // Ajustar la fecha y la hora al horario local
                            dateTime.setMinutes(dateTime.getMinutes() + offsetInMinutes);

                          
                            // console.log("Fecha de inicio:", dateTime.toLocaleDateString());
                            // console.log("Hora de inicio:", dateTime.toLocaleTimeString());

                            
                            const endTime = new Date(dateTime.getTime() + 20 * 60000); // Añadir 20 minutos
                            // console.log("Hora de término:", endTime.toLocaleTimeString());
                            let new_alert2 = {
                                type: "error",
                                timeout: null,
                                title: textMeetings.este_bloque_ya_ha_sido_donado_previamente,
                                desc: `${dateTime.toLocaleDateString()}\n${dateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} - ${endTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`,
                                close: true
                            }
                            dispatch(addAlert({ alert: new_alert2 }))
                        }
                    } catch (e) {
                        console.error("Error al obtener datos del endpoint:", e);
                    }
                }
                // ACÁ EXISTEN 2 PROBLEMAS: EL TIEMPO SIEMPRE SE LE NOTIFICARÁ A LAS PERSONAS QUE SE DONPO
                // POR LO QUE HABRÍA QUE HACER ALGUN FILTRADO EN EL ENDPOINT (GETMEETINGREQUESTTIMES => URL) PARA QUE SOLO RETORNE LOS VALORES QUE NO SE LES HAYA NOTIFICADO ANTES, PORQUE SI NO SE LES VA A NOTIFICAR SIEMPRE
                // A TODAS LAS PERSONAS QUE ALGUNA VEZ TE HAYAN REALIZADO UNA SOLICITUD DE TIEMPO
                // Y EL SEGUNDO PROBLEMA, ES QUE AL HACER UNA DONACIÓN DIRECTA, TAMBIÉN SE LES NOTIFICARÁ A TODAS LAS PERSONAS DEL RECIPIENTID..., SE DEBERÍA NOTIFICAR
                // SOLO A LA PERSONA QUE REALIZÓ LA DONACIÓN DIRECTA
                // await addNotification({user_id: user_id, vinculated_user_id: 18580});
                reloadDonatedTimes();
                // executeNotifications();

                if (index === actual_values.bloques_reunion_dia.length - 1) {
                    dispatch(deleteAlert({ alert_id: "guardando_tiempos_donados" }));
                    let new_alert2 = { id: "guardando_tiempos_donados_correctamente", type: "success", title: textMeetings.titulo_7, timeout: 2000, desc: "", close: true }
                    dispatch(addAlert({ alert: new_alert2 }));

                    // Convertir el conjunto en un arreglo de UserRequestId únicos

                    //console.log("UserRequestId únicos:", uniqueUserRequestIdsArray);
                    //console.log(resData)
                }
            } catch (error) {
                console.error("Error al obtener datos del endpoint:", error);

            }
        });
    }


    return (
        <>
        <div className='padding-layout-x px-lg-3 px-xl-3'>
            <div className='row mt-4'>
                <div className='col-12 mb-4'>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={actual_values.step - 1} alternativeLabel>
                            {textMeetings.steps.map((label: string) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
                <div className='col-12'>
                    {formulario_actual}
                </div>
                <div className='col-12'>
                    {botones_html}
                </div>
            </div>
        </div>
            <Dialog open={openConfirmDonate} onClose={handleCloseConfirmDonate}>
            <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
            <DialogContent>
              <p>{textMeetings.confirmacion_donar}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDonateConfirmed} color="primary">
                Sí
              </Button>
              <Button onClick={handleCloseConfirmDonate} color="primary">
                No
              </Button>
            </DialogActions>
          </Dialog>
    </>
        
    )
}

export default MeetingsDonarTiempo;


