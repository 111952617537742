

import './RecoverPassword.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { Formik, Form } from 'formik'


import TextInput from '../../../components/Form/TextInput'
import Checkbox from '../../../components/Form/Checkbox'

import { useForgotPasswordSendCodeMutation, useForgotPasswordResetPasswordMutation } from '../../../redux/features/auth/authApiSlice'

import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'

import { validatePassword } from '../../../utils/utilsForms'
import { encryptPassword } from '../../../utils/utilsForms'

import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


const RecoverPassword = () => {

    const textRecoveryPass = useSelector(selectCurrentDictonary).recovery_pass
    const textSignup = useSelector(selectCurrentDictonary).signup

    const [ForgotPasswordSendCode]: any = useForgotPasswordSendCodeMutation()
    const [ForgotPasswordResetPassword]: any = useForgotPasswordResetPasswordMutation()
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState("")
    const [msj, setMsj] = useState("")
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState(-1)
    const [showpasswords, setShowpasswords] = useState(false)
    let type_passwords = "password"
    if (showpasswords) {
        type_passwords = "text"
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSubmitSendCode = async (values: any) => {
        setLoading(true)
        let new_alert = { id: "cargando_login", type: "loading", title: textRecoveryPass.param_3, desc: textRecoveryPass.param_4, close: false }
        dispatch(addAlert({ alert: new_alert }))
        setMsj("")
        try {
            const res_ForgotPasswordSendCode: any = await ForgotPasswordSendCode({ emailAddress: values.email })
            const resdata_res_ForgotPasswordSendCode = JSON.parse(res_ForgotPasswordSendCode.data.responseData)
            if (!resdata_res_ForgotPasswordSendCode.IsSuccess) {
                if (!resdata_res_ForgotPasswordSendCode.UserId || resdata_res_ForgotPasswordSendCode.UserId === -1) {
                    setMsj("Usuario no existe")
                    dispatch(deleteAlert({ alert_id: "cargando_login" }))
                    let new_alert2 = { type: "error", title: "Error", desc: textRecoveryPass.msn_error_1, timeout: 10000, close: true }
                    dispatch(addAlert({ alert: new_alert2 }))
                } else {
                    setMsj("Error")
                    dispatch(deleteAlert({ alert_id: "cargando_login" }))
                    let new_alert2 = { type: "error", title: "Error", desc: textRecoveryPass.param_4, timeout: 10000, close: true }
                    dispatch(addAlert({ alert: new_alert2 }))
                }

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_login" }))
                let new_alert = { type: "success", title: textRecoveryPass.param_4, desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))

                setUserId(resdata_res_ForgotPasswordSendCode.UserId)
                setEmail(values.email)
                setStep(2)
            }

        } catch (err) {

            dispatch(deleteAlert({ alert_id: "cargando_login" }))
            let new_alert2 = { type: "error", title: "Error", desc: textRecoveryPass.paran_4, timeout: 10000, close: true }
            dispatch(addAlert({ alert: new_alert2 }))

            console.log(err)
        }
        setLoading(false)
    }

    async function cambiar_clave_request(password_aux: any, values: any, userId: any) {
        const res_ForgotPasswordResetPassword = await ForgotPasswordResetPassword({ newPassword: password_aux, confirmationCode: values.code, userId: userId })
    }
    const handleSubmitResetPassword = async (values: any) => {
        let new_alert = { id: "cargando_login", type: "loading", title: textRecoveryPass.param_3, desc: textRecoveryPass.param_5, close: false }
        dispatch(addAlert({ alert: new_alert }))
        setLoading(true)
        setMsj("")
        try {
            let password_aux = "" /* encrypt_password(values.password) */
            //password_aux = values.password
            encryptPassword(values.password).then((hash) => {
                password_aux = hash;
                cambiar_clave_request(password_aux, values, userId)

                dispatch(deleteAlert({ alert_id: "cargando_login" }))
                let new_alert = { type: "success", title: textRecoveryPass.param_5, desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))

                navigate('/sign-in')

            }).catch((error) => {

                dispatch(deleteAlert({ alert_id: "cargando_login" }))
                let new_alert2 = { type: "error", title: "Error", desc: textRecoveryPass.param_5, timeout: 10000, close: true }
                dispatch(addAlert({ alert: new_alert2 }))

                setMsj("Error")
                setLoading(false)
            })

            /* INICIAR SESION AUTOMATICAMENTE */
            /* try {
                const res_passwordRecover: any = await signin({ EmailAddress: email, Password: password_aux })
                if (res_passwordRecover.data.statusCode == 200) {

                    dispatch(deleteAlert({ alert_id: "cargando_login" }))
                    let new_alert = { type: "success", title: "Recuperar contraseña", desc: "OK", timeout: 2000, close: true }
                    dispatch(addAlert({ alert: new_alert }))

                    navigate('/sign-in')
                } else {
                    dispatch(deleteAlert({ alert_id: "cargando_login" }))
                    let new_alert2 = { type: "error", title: "Error", desc: "Recuperar contraseña", timeout: 10000, close: true }
                    if (res_passwordRecover.data.statusCode == 500) {
                        new_alert2 = { type: "error", title: "Error", desc: "Recuperar contraseña", timeout: 10000, close: true }
                    }
                    dispatch(addAlert({ alert: new_alert2 }))
                }
                setLoading(false)
            } catch (err) {
                dispatch(deleteAlert({ alert_id: "cargando_login" }))
                let new_alert2 = { type: "error", title: "Error", desc: "Recuperar contraseña", timeout: 10000, close: true }
                dispatch(addAlert({ alert: new_alert2 }))

                console.log("ERROR")
                console.log(err)
                setMsj("Error")
                setLoading(false)
            } */
            /* fin iniciar sesion */
        } catch (err) {
            console.log(err)
            setLoading(false)
            setMsj("Error")
        }
    }



    const validateSendCode = (values: any) => {

        const errors: any = {}
        if (!values.email) {
            errors.email = textSignup.error_requiered
        }

        return errors
    }

    const validateResetPassword = (values: any) => {

        const errors: any = {}

        if (!values.code) {
            errors.code = textRecoveryPass.msj_error_email
        }
        let password_error = validatePassword(values.password, textSignup)
        if (password_error) {
            errors.password = password_error
        }
        if (!values.confirmpassword) {
            errors.confirmpassword = textRecoveryPass.msj_error_email
        }
        if (!(!values.password) && !(!values.confirmpassword)) {
            if (values.confirmpassword != values.password) {
                errors.confirmpassword = textRecoveryPass.msj_pass_1
            }
        }
        if (values.showpasswords != showpasswords) {
            setShowpasswords(values.showpasswords)
        }
        return errors
    }


    let actual_form = <></>
    if (step == 1) {
        actual_form = <Formik
            initialValues={{ email: '' }}
            validate={validateSendCode}
            onSubmit={values => handleSubmitSendCode(values)}

        >
            <Form key="1">
                <div className='mb-4'>
                    <h3 className=''>{textRecoveryPass.titulo_1}</h3>
                    <p>{textRecoveryPass.desc_1}</p>
                </div>
                <div className='row no-gutters'>
                    <div className='col-12 mb-3'>
                        <TextInput name="email" type="email" label={textRecoveryPass.param_1} placeholder={textRecoveryPass.placeholder_1} showErrorText={true} />
                    </div>
                    <div className='col-12 mt-4'>
                        <button type="submit" disabled={loading} className='btn btn-rounded btn-primary btn-block'>{textRecoveryPass.btn_3}</button>
                    </div>
                    <div className='col-12 mt-2'>
                        <button onClick={() => { navigate("/sign-in") }} className='btn btn-rounded btn-outline-primary btn-block'>{textRecoveryPass.btn_2}</button>
                    </div>
                    <div className='col-12 mt-3 mb-3'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <p>{msj}</p>
                        </div>
                    </div>
                </div>

            </Form>
        </Formik>
    }
    if (step == 2) {
        actual_form = <Formik
            initialValues={{ email: email, password: '', confirmpassword: '', code: '' }}
            validate={validateResetPassword}
            onSubmit={(values) => handleSubmitResetPassword(values)}
        >
            <Form key="2">
                <div className='mb-4'>
                    <h3 className=''>{textRecoveryPass.titulo_2}</h3>
                    <p>{textRecoveryPass.desc_2}<a className='text-primary'>{email}</a></p>
                </div>
                <div className='row no-gutters'>
                    {/* <div className='col-12 mb-3'>
                        <TextInput name="email" type="email" disabled label="Email" showErrorText={true} />
                    </div> */}
                    <div className='col-12 mb-3'>
                        <TextInput name="code" label={textRecoveryPass.param_2_1} placeholder={"123456"} showErrorText={true} />{/* enviado a "+email+" */}
                    </div>
                    <div className='col-12 col-xl-6 pr-xl-2 mb-3 mb-xl-0'>
                        <TextInput name="password" type={type_passwords} placeholder={textRecoveryPass.placeholder_2} label={textRecoveryPass.param_2_2} showErrorText={true} />
                    </div>
                    <div className='col-12  col-xl-6  pl-xl-2'>
                        <TextInput name="confirmpassword" type={type_passwords} placeholder={textRecoveryPass.placeholder_2} label={textRecoveryPass.param_2_3} showErrorText={true} />
                    </div>
                    <div className='col-12 mt-3 mb-3'>
                        <Checkbox name="showpasswords">
                            {textRecoveryPass.param_2_4}
                        </Checkbox>
                    </div>
                    <div className='col-12 mt-4'>
                        <button type="submit" disabled={loading} className='btn btn-rounded btn-primary btn-block'>{textRecoveryPass.btn_1}</button>
                    </div>
                    <div className='col-12 mt-2'>
                        <button onClick={() => { setStep(1) }} disabled={loading} className='btn btn-rounded btn-outline-primary btn-block'>{textRecoveryPass.btn_2}</button>
                    </div>
                    <div className='col-12 mt-3 mb-3'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <p>{msj}</p>
                        </div>
                    </div>
                </div>

            </Form>
        </Formik>
    }
    return (
        <div className='body-container'>
            {/* <div className='recoverPassword'> */}
            <div className='row no-gutters'>
                <div className='col-12 col-lg-7 col-xl-6  recoverPassword-container '>
                    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                        <div className='recoverPassword-content padding-layout-x'>
                            {actual_form}
                        </div>
                    </div>

                </div>
                <div className='col-12 col-lg-5 col-xl-6  recoverPassword-sidebar-background-container'>

                </div>
            </div>
        </div>
    )
}

export default RecoverPassword;