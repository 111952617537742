import Card from '../Card/Card';
import { Avatar } from '@mui/material';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePutUpdatedNotificationReadMutation } from '../../redux/features/user/userApiSlice';
import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';
import { usePutDeleteNotificationMutation } from '../../redux/features/user/userApiSlice'
import { text } from 'stream/consumers';
import CloseIcon from '@mui/icons-material/Close';

const NotificationItem = ({ id, redirect, profilePicture, classNameAvatar, NameUserRequest, AppMessageName, NotificationTime, nextMeetingTime, ReadedByUser, notifications, setNotification, timeZoneOffset, handleClick, reload, redirectProfile }: any) => {

	const textNotification = useSelector(selectCurrentDictonary).notifications
	const navigate = useNavigate();
	const [timeElapsed, setTimeElapsed] = useState(getTimeElapsed(NotificationTime, timeZoneOffset, textNotification));
	const [putNotificationRead]: any = usePutUpdatedNotificationReadMutation()
	const [putNotificationDelete]: any = usePutDeleteNotificationMutation()

	let initial_class_notification = "card card-msj card-msj-no-visto"
	if (ReadedByUser == 1) {
		initial_class_notification = "card card-msj card-msj-visto"
	}
	const [cardColor, setCardColor] = useState(initial_class_notification)

	useEffect(() => {
		const interval = setInterval(() => {

			setTimeElapsed(getTimeElapsed(NotificationTime, timeZoneOffset, textNotification));
		}, 1000000);

		return () => {
			clearInterval(interval);
		};
	}, [NotificationTime]);


	async function handleNotification(idNotification: any, redirect: any) {
		 
		if (!ReadedByUser) {
			try {
				let item = {
					id: idNotification,
					readedByUser: 1
				}
				const res: any = await putNotificationRead({ body: item });
				const rescode = res.data.statusCode
				const updatedNotifications = notifications.map((notification: any) => {
					if (notification.Id === idNotification) {
						return {
							...notification,
							ReadedByUser: 1,
						};
					};
					return notification;

				});
				setNotification(updatedNotifications);
				setCardColor('card card-msj card-msj-visto');
			 
				navigate(redirect)
			} catch (error) {
				console.error("Error al marcar notificaciones como leídas: ", error)

			}
		}
		handleClick()
		navigate(redirect)
		reload()
	}
	// nueva funcion para el boton eliminar
	const handleDeleteNotification = async (e: any) => {
		e.stopPropagation();

		 

		try {

			const res = await putNotificationDelete({ notificationId: id });

			 

			const remainingNotifications = notifications.filter((notification: any) => notification.Id !== id);
			 
			setNotification(remainingNotifications);
			// let initial_class_notification2 = "card card-msj card-msj-no-visto"
			// if (ReadedByUser == 1) {
			// initial_class_notification2 = "card card-msj card-msj-visto"
			// }
			// setCardColor(initial_class_notification2)
			reload()


		} catch (error) {
			console.error("Error al eliminar la notificación", error);

		}

	};



	return (
		<Card className={cardColor} handleOnClick={() => handleNotification(id, redirect)}>
			<div className='d-flex justify-content-start align-items-center'>
				<div className='h-100 mr-2'>
					{profilePicture ? (
						<img className="profile-picture" src={profilePicture} alt="Foto de perfil" />
					) : (
						<Avatar className={"mr-2 " + classNameAvatar}>
							<ContactPhoneOutlinedIcon />
						</Avatar>
					)}
				</div>
				{nextMeetingTime ? (
					<div>
						<div> <p className='notificacion-texto-titulo' >{AppMessageName}</p></div>
						<div> <p className='notificacion-texto-desc' >{nextMeetingTime}</p> </div>
						<div> <p className='notificacion-texto-time' >{timeElapsed}</p> </div>
					</div>
				) : (
					<div>
						<div> <p className='notificacion-texto-titulo' onClick={(event) => {
							event.stopPropagation()
							
							navigate(redirectProfile)
							
						}} >{NameUserRequest}</p></div>
						<div> <p className='notificacion-texto-desc' >{AppMessageName}</p> </div>
						<div> <p className='notificacion-texto-time' >{timeElapsed}</p> </div>
					</div>
				)}
				<CloseIcon onClick={handleDeleteNotification} style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }} />

			</div>
		</Card>
	);
};

function getTimeElapsed(timestamp: any, timeZoneOffset: any, textNotification: any) {

	const timezone = timeZoneOffset * 60 * 60 * 1000;
	const currentTime = new Date().getTime();
	const notificationTime = new Date(timestamp).getTime() + timezone;
	const milliseconds = currentTime - notificationTime;

	const seconds = Math.floor(milliseconds / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);

	const lambda_funct = (cant: any, txt: any) => {
		if (cant == 1) {
			if (txt == 'meses') { txt = txt.slice(0, -2) }
			else txt = txt.slice(0, -1);
		}
		return `${textNotification.time_7} ${cant} ${txt}`
	};

	let message = ""
	if (seconds) message = lambda_funct(seconds, textNotification.time_1)//`Hace ${seconds} segundos`;
	if (minutes) message = lambda_funct(minutes, textNotification.time_2)//`Hace ${minutes} minutos`;
	if (hours) message = lambda_funct(hours, textNotification.time_3)//`Hace ${hours} horas`;
	if (days) message = lambda_funct(days, textNotification.time_4)//`Hace ${days} días`;
	if (weeks) message = lambda_funct(weeks, textNotification.time_5)//`Hace ${weeks} semanas`;
	if (months) message = lambda_funct(months, textNotification.time_6)//`Hace ${months} meses`;

	return message
}

export default NotificationItem;