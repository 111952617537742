// src/pages/UserProfileExternal/UserProfileExternal.tsx

import './UserProfileExternal.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery, useTheme, Button } from '@mui/material';
import FormularioSolicitarReunion from './FormularioSolicitarReunion';
import UserProfileExternalTopCard from './UserProfileExternalTopCard';
import { useGetUserStartupsMutation } from '../../redux/features/startups/startupsApiSlice';
import {
    useGetUserExpertisesMutation,
    useGetUserLanguagesMutation,
    useGetUserPublicDataMutation,
    useGetUserRolesMutation,
    useGetUserTagsMutation
} from '../../redux/features/user/userApiSlice';
import UserProfileExternalStartups from './UserProfileExternalStartups';
import UserProfileExternalExperiencias from './UserProfileExternalExperiencias';
import UserProfileExternalIdiomasTags from './UserProfileExternalIdiomasTags';
import BottomNav from '../../components/Navegation/BottomNav/BottomNav';
import { usePostAddUserContactMutation } from '../../redux/features/user/userApiSlice';
import { useGetActivitiesByIdsFeedbackQuery, useSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import FormularioSolicitarTiempo from './FormularioSolicitarTiempo';
import { useGetUserFeedbacksMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { DialogTiempoReunion } from './DialogTiempoReunion';
import FeedbackDialog from '../Events/FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { ACTIVITIES, Actividad } from '../Events/FeedbackQuestions/constants';

// Definición de tipos e interfaces directamente en el archivo

interface AccreditationByBlock {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    statusId: number | null;
    statusName: string;
    hasFeedback: boolean;
    counterpart: boolean;
}

interface RoleInActivity {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    role: 'Mentor' | 'Mentee';
    statusId: number | null;
    statusName: string;
    hasFeedback: boolean;
}

interface FeedbackData {
    availableTimeId: number[];
    mentorAccreditationByBlock: AccreditationByBlock[];
    menteeAccreditationByBlock: AccreditationByBlock[];
    roleInActivity: RoleInActivity;
    activityType: Actividad; // Asegúrate de que esta propiedad esté presente en la respuesta de la API
    // Otros campos que pueda tener feedbackData
}

// Hook personalizado para manejar los parámetros de la URL
function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

const UserProfileExternal = ({ user_id_param, handleClickCloseDialogPublicProfile, handleLoadContactsMain }: any) => {
    const params: any = useParams();
    const query = useQuery(); // ProfileQR
    const mode = query.get('mode');  // ProfileQR
    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile;
    const navigate = useNavigate(); // Hook para navegación
    const dispatch = useDispatch(); // Inicializar useDispatch

    const user_personal_data: personalData = useSelector(selectPersonalData); // acceder a datos del usuario logueado
    const user_id = user_personal_data?.Id || 0;

    let default_user = -1;
    let isExternal = 0;
    let isModal = 0;
    let clase_external = "";

    const [isModalQROpen, setIsModalQROpen] = useState(false);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

    const [sendActivityFeedback] = useSendActivityFeedbackMutation();  // Mutación de feedback

    // Definir el estado 'actividad' y 'setActividad'
    const [actividad, setActividad] = useState<Actividad | null>(null);

    // Determinar el usuario por defecto y el modo externo o interno
    if (!params.userid || parseInt(params.userid) === user_id) { // no hay parametros en la ruta o es el propio usuario
        if (user_id_param && user_id_param > 0) { // perfil publico dentro de modal
            default_user = user_id_param;
            isExternal = 1;
            isModal = 1;
            clase_external = "interno";
        } else { // perfil personal
            default_user = user_id;
            isExternal = 0;
            clase_external = "interno";
        }
    } else { // hay parametros en la ruta y es otro usuario
        isExternal = 1;
        isModal = 0;
        clase_external = "externo body-container";
        default_user = parseInt(params.userid);
    }
    const user_id_profile: number = default_user;

    /* -------------- SOLICITUDES DE DATOS ---------------------------------------------------------------------- */
    const [getUserStartups, { isLoading: isLoadingGetUserStartups }] = useGetUserStartupsMutation();
    const [user_startups, setUserStartups] = useState<any[]>([]);

    const fetchUserStartups = async (user_id: number) => {
        try {
            const res: any = await getUserStartups({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserStartups(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    const [addUserContact] = usePostAddUserContactMutation();

    const handleAddContact = async () => {
        try {
            const userId = user_personal_data.Id;
            const contactUserId = user_id_profile;
            await addUserContact({ userId, contactUserId }).unwrap();
            setIsModalQROpen(false);
        } catch (error) {
            console.error('Error al añadir contacto', error);
        }
    };

    /* Experiencia laboral */
    const [getUserExpertises, { isLoading: isLoadingGetUserExpertises }] = useGetUserExpertisesMutation();
    const [user_expertises, setUserExpertises] = useState<any[]>([]);

    const fetchUserExpertises = async (user_id: number) => {
        try {
            const res: any = await getUserExpertises({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserExpertises(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Datos públicos */
    const [getUserPublicData, { isLoading: isLoadingGetUserPublicData }] = useGetUserPublicDataMutation();
    const [user_public_data, setUserPublicData] = useState<any>({});

    const fetchUserPublicData = async (user_id: number) => {
        try {
            const res: any = await getUserPublicData({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserPublicData(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Tags */
    const [getUserTags, { isLoading: isLoadingGetUserTags }] = useGetUserTagsMutation();
    const [user_tags, setUserTags] = useState<any>({});

    const fetchUserTags = async (user_id: number) => {
        try {
            const res: any = await getUserTags({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserTags(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Idiomas */
    const [getUserLanguages, { isLoading: isLoadingGetUserLanguages }] = useGetUserLanguagesMutation();
    const [user_languages, setUserLanguages] = useState<any>({});

    const fetchUserLanguages = async (user_id: number) => {
        try {
            const res: any = await getUserLanguages({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserLanguages(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Roles */
    const [getUserRoles, { isLoading: isLoadingGetUserRoles }] = useGetUserRolesMutation();
    const [user_roles, setUserRoles] = useState<any[]>([]);
    const [esEmprendedor, setEsEmprendedor] = useState<boolean>(false);
    const [esExperto, setEsExperto] = useState<boolean>(false);

    const fetchRoles = async (user_id: number) => {
        try {
            const res: any = await getUserRoles({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            let arr: any[] = [];
            resdata.forEach((element: any) => {
                if (element.Active === 1) {
                    if (element.RolId === 1) { // Emprendedor
                        setEsEmprendedor(true);
                        arr.push({ name: "Emprendedor", id: element.RolId });
                    }
                    if (element.RolId === 6) { // Experto
                        setEsExperto(true);
                        arr.push({ name: "Experto", id: element.RolId });
                    }
                }
            });
            setUserRoles(arr);
        } catch (error) {
            console.log(error);
        }
    };

    /* Feedbacks */
    const [getUserFeedbacks, { isLoading: isLoadingGetUserFeedbacks }] = useGetUserFeedbacksMutation();
    const [user_feedbacks, setUserFeedbacks] = useState<any[]>([]);
    const [user_feedbacks_prom_estrellas, setUserFeedbacksPromEstrellas] = useState<number | null>(null);

    const fetchUserFeedbacks = async (user_id: number) => {
        setUserFeedbacksPromEstrellas(null);
        try {
            const res: any = await getUserFeedbacks({ user_id: user_id });
            const resdata: any[] = JSON.parse(res.data.responseData);
            if (resdata && resdata.length > 0) {
                let suma = 0;
                let cont_n = 0;

                resdata.forEach((element: any) => {
                    cont_n += 1;
                    suma += element.UserEvaluationStars;
                });
                setUserFeedbacksPromEstrellas(suma / cont_n);
            }

            setUserFeedbacks(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* -------------- FIN SOLICITUDES DE DATOS ---------------------------------------------------------------------- */

    /* -------------- USEEFFECT ---------------------------------------------------------------------- */
    useEffect(() => {
        fetchUserStartups(user_id_profile).catch(console.error);
        fetchUserExpertises(user_id_profile).catch(console.error);
        fetchUserPublicData(user_id_profile).catch(console.error);
        fetchUserTags(user_id_profile).catch(console.error);
        fetchUserLanguages(user_id_profile).catch(console.error);
        fetchRoles(user_id_profile).catch(console.error);
        fetchUserFeedbacks(user_id_profile).catch(console.error);
    }, [params, user_id_profile]); // Asegurarse de incluir user_id_profile en las dependencias

    /* Llamada a la API para obtener los datos de feedback */
    const { data: feedbackData, isFetching: isFetchingFeedbackData, error } = useGetActivitiesByIdsFeedbackQuery(
        {
            id1: user_id,
            id2: parseInt(params.userid)
        },
        {
            skip: !(mode === "QR" && params.userid)  // Evitar el fetch si no se cumplen las condiciones
        }
    );

    const [isMentor, setIsMentor] = useState(false); // Usar useState para isMentor
    const [isMentee, setIsMentee] = useState(false); // Usar useState para isMentee

    useEffect(() => {
        if (mode === "QR" && !isFetchingFeedbackData && feedbackData && feedbackData.roleInActivity) {
            const { roleInActivity, activityType } = feedbackData;

            console.log("Role In Activity:", roleInActivity);
            console.log("Activity Type:", activityType);

            // Validar que activityType es válido
            const validActivityType = Object.values(ACTIVITIES).includes(activityType)
                ? activityType
                : ACTIVITIES.MENTORIA; // Valor por defecto

            // Asignar el tipo de actividad dinámicamente
            setActividad(validActivityType);

            // Determinar el rol directamente desde roleInActivity
            if (roleInActivity.role === 'Mentor') {
                setIsMentor(true);
                setIsMentee(false);
                if (!roleInActivity.hasFeedback) {
                    console.log("Mostrar FeedbackDialog para Mentor");
                    setShowFeedbackDialog(true);
                } else {
                    console.log("No mostrar FeedbackDialog para Mentor porque ya dio feedback");
                }
            } else if (roleInActivity.role === 'Mentee') {
                setIsMentor(false);
                setIsMentee(true);
                if (!roleInActivity.hasFeedback) {
                    console.log("Mostrar FeedbackDialog para Mentee");
                    setShowFeedbackDialog(true);
                } else {
                    console.log("No mostrar FeedbackDialog para Mentee porque ya dio feedback");
                }
            } else {
                console.log("Usuario no es ni Mentor ni Mentee en esta actividad");
                setIsMentor(false);
                setIsMentee(false);
            }
        }
    }, [mode, user_id_profile, feedbackData, isFetchingFeedbackData]);

    const handleFeedbackSubmit = async (values: any) => {
        if (!feedbackData || !feedbackData.roleInActivity || !feedbackData.roleInActivity.availableTimeId || feedbackData.roleInActivity.availableTimeId.length === 0) {
            console.error("No hay datos disponibles para enviar feedback.");
            return;
        }

        let availableTimeIdToUse = null;

        if (isMentor || isMentee) {
            availableTimeIdToUse = feedbackData.availableTimeId && feedbackData.availableTimeId.length > 0
                ? feedbackData.availableTimeId[0]
                : null;
        }

        if (!availableTimeIdToUse) {
            console.error("No hay availableTimeId disponibles para enviar feedback.");
            return;
        }

        const feedbackDataToSend = {
            ...values,
            availableTimeId: availableTimeIdToUse, // Usar el availableTimeId correspondiente
            isMentor, // Si es mentor o no
            activityType: actividad, // Incluir el tipo de actividad
        };

        // Crear alerta de envío de feedback
        let new_alert = {
            id: `sending_feedback_${Date.now()}`,
            type: "loading",
            title: "Enviando feedback",
            desc: "",
            close: false,
            timeout: 0
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            // Enviar feedback utilizando la mutación
            await sendActivityFeedback(feedbackDataToSend).unwrap();
            // Eliminar alerta de carga y agregar alerta de éxito
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = {
                id: `feedback_sent_${Date.now()}`,
                type: "success",
                title: "Feedback enviado",
                desc: "El feedback ha sido enviado exitosamente",
                close: true,
                timeout: 5000
            };
            dispatch(addAlert({ alert: success_alert }));
            // Cerrar el diálogo de feedback
            setShowFeedbackDialog(false);
            console.log("FeedbackDialog cerrado después de enviar el feedback");
        } catch (error) {
            // Eliminar alerta de carga y agregar alerta de error
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = {
                id: `feedback_error_${Date.now()}`,
                type: "error",
                title: "Error al enviar feedback",
                desc: "Hubo un error al enviar el feedback",
                close: true,
                timeout: 5000
            };
            dispatch(addAlert({ alert: error_alert }));
            console.error('Error al enviar feedback', error);
        }
    };

    /* Manejo de Errores */
    // Ya está incluido en el return

    /* Mantenimiento de la Funcionalidad Original y Renderización del Perfil */
    /* ROLES */
    let startups_html = <></>;
    if (esEmprendedor) {
        startups_html = (
            <div className='col-12'>
                <UserProfileExternalStartups
                    user_startups={user_startups}
                    is_loading_user_startups={isLoadingGetUserStartups}
                />
            </div>
        );
    }

    let expert_html = <></>;
    if (esExperto) {
        expert_html = (
            <div className='col-12'>
                <UserProfileExternalExperiencias
                    user_expertises={user_expertises}
                    is_loading_user_expertises={isLoadingGetUserExpertises}
                />
            </div>
        );
    }

    /* -------------- TIEMPO DONADO DEL PERFIL ---------------------------------------------------------------------- */
    let GetUserDonatedTime_aux = GetUserDonatedTime(user_id_profile);
    let user_donated_times = GetUserDonatedTime_aux.value;

    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false);
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open);
    }

    /* Manejo de carga */
    const isLoading = isLoadingGetUserStartups || isLoadingGetUserExpertises || isLoadingGetUserPublicData ||
        isLoadingGetUserTags || isLoadingGetUserLanguages || isLoadingGetUserRoles || isLoadingGetUserFeedbacks;

    /* HTML de perfil público */
    const public_profile_html = () => {
        if (isLoading) {
            let fullscreenClass = "";
            if (isModal === 0) {
                fullscreenClass = "fullscreen";
            }
            return (
                <div className={'loading-backdrop ' + fullscreenClass}>
                    <h2>
                        <i className="fas fa-spinner fa-spin mr-4"></i>
                        <span className='d-inline'>{textPublicProfile.label_loading}</span>
                    </h2>
                </div>
            );
        } else {
            return (
                <div className={'user-profile-container ' + clase_external}>
                    <div className='row no-gutters'>
                        <div className='col-12'>
                            <UserProfileExternalTopCard
                                user_feedbacks_prom_estrellas={user_feedbacks_prom_estrellas}
                                user_id_profile={user_id_profile}
                                esEmprendedor={esEmprendedor}
                                esExperto={esExperto}
                                user_expertises={user_expertises}
                                user_donated_times={user_donated_times}
                                isModal={isModal}
                                handleClickCloseDialogPublicProfile={handleClickCloseDialogPublicProfile}
                                user_public_data={user_public_data}
                                is_loading_public_data={isLoadingGetUserPublicData}
                                handleClickDialog={handleClickDialog}
                                isExternal={isExternal}
                                handleAddContact={handleAddContact}
                                handleLoadContactsMain={handleLoadContactsMain}
                                currentUrl={window.location.href}
                                isQRMode={mode === "QR"}
                            />
                        </div>
                        <div className='col-12'>
                            <UserProfileExternalIdiomasTags
                                user_languages={user_languages}
                                is_loading_user_languages={isLoadingGetUserLanguages}
                                user_tags={user_tags}
                            />
                        </div>
                    </div>
                    <div className='row no-gutters user-profile-startups-experiences-container'>
                        {startups_html}
                        {expert_html}
                    </div>
                </div>
            );
        }
    };

    let navegation_html = <></>;
    if (isModal === 0) {
        navegation_html = <BottomNav />;
    }

    return (
        <>
            {navegation_html}
            {public_profile_html()}

            <DialogTiempoReunion
                user_id_profile={user_id_profile}
                sol_reu_open={sol_reu_open}
                handleClickDialog={handleClickDialog}
                textPublicProfile={textPublicProfile}
                QR={mode === "QR"}
            />

            {/* FeedbackDialog */}
            {showFeedbackDialog && feedbackData && feedbackData.roleInActivity && (
                <FeedbackDialog
                    open={showFeedbackDialog}
                    onClose={() => setShowFeedbackDialog(false)}
                    onSubmit={handleFeedbackSubmit}  // Conexión del envío
                    counterpartName={
                        isMentor
                            ? (feedbackData.menteeAccreditationByBlock && feedbackData.menteeAccreditationByBlock.length > 0
                                ? feedbackData.menteeAccreditationByBlock[0].fullName
                                : "la contraparte")
                            : (feedbackData.mentorAccreditationByBlock && feedbackData.mentorAccreditationByBlock.length > 0
                                ? feedbackData.mentorAccreditationByBlock[0].fullName
                                : "la contraparte")
                    }
                    isMentor={isMentor}
                    actividad={actividad as Actividad}  // Uso dinámico de actividad
                />
            )}

            {/* Manejo de errores de feedback */}
            {error && (
                <div className="error-message">
                    <p>{(error as any).data?.mensaje || 'Error al cargar los datos de feedback.'}</p>
                </div>
            )}
        </>
    );
}

export default UserProfileExternal;
