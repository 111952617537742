import { NavLink } from 'react-router-dom';
import Card from '../../Card/Card';
import './SettingsLayoutItem.scss'
import { useState } from 'react';
import List from '@mui/material/List'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'

import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

import SettingsLayoutItemComponent from './SettingsLayoutItemComponent'

function SettingsLayoutItem({ name, link, Icon, items, handleClickOption, isChildren=false }: any) {
    const isExpandable = items && items.length > 0
    const [open, setOpen] = useState(false)

    function handleClick(isExpandable:any) {
        setOpen(!open)
        if(!isExpandable){
            handleClickOption()
        }
    }

    const MenuItemRoot = (
        <SettingsLayoutItemComponent link={link} onClick={()=>handleClick(isExpandable)} isExpandable={isExpandable} isChildren={isChildren}>
            {/* Display an icon if any */}
            {/* {!!Icon && (
                <ListItemIcon >
                    <Icon />
                </ListItemIcon>
            )} */}
            <ListItemText primary={name} /* inset={!Icon} */ />
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
        </SettingsLayoutItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                {items.map((item:any, index:any) => (
                    <SettingsLayoutItem {...item} handleClickOption={handleClickOption} key={index} isChildren={true} />
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}
export default SettingsLayoutItem;
