import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetUserStartupsMutation } from '../../../redux/features/startups/startupsApiSlice';
import { selectStartupsList, setStartupsList } from '../../../redux/features/startups/startupsSlice';


export default function GetUserStartups(user_id:any) {

    const dispatch = useDispatch()
    const value = useSelector(selectStartupsList)
    const [get, { isLoadingGet }]: any = useGetUserStartupsMutation()
    const [isLoading, setIsLoading] = useState(false)    
    
    const [initialCharge, setInitialCharge] = useState(true)    

    const fetch = async () => {
        setIsLoading(true)
        const res: any = await get({user_id:user_id});
        const resData = JSON.parse(res.data.responseData)
        dispatch(setStartupsList({startups_list: resData }))
        setIsLoading(false)
        setInitialCharge(false)
    }

    useEffect(() => {
        if (initialCharge) {
            fetch()
        }
    }, [value]);

    return {value:value, isLoading:isLoading};
}
