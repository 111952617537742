import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Card, CardContent, Avatar } from '@mui/material';
import QRCode from 'qrcode.react';
import { useLazyGetUserByEmailQuery, useGetUserAdminRoleMutation } from '../../redux/features/user/userApiSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';

const QRCodeGenerator = () => {
  const [email, setEmail] = useState('');
  const [qrValue, setQrValue] = useState('');
  const [userProfile, setUserProfile] = useState<any>(null);
  const [notFound, setNotFound] = useState(false);
  const [lastSearchedEmail, setLastSearchedEmail] = useState('');
  const [trigger, { data, error }] = useLazyGetUserByEmailQuery();
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUserAdminRole] = useGetUserAdminRoleMutation();

  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);

  useEffect(() => {
    if (userPersonalData) {
      getUserAdminRole(userPersonalData.Id)
        .unwrap()
        .then(response => {
          const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
          setIsAdmin(isAdmin);
          if (!isAdmin) navigate('/home');
        })
        .catch(error => {
          console.error("Error fetching user role:", error);
          navigate('/home');
        });
    }
  }, [userPersonalData, navigate, getUserAdminRole]);

  useEffect(() => {
    if (data && data.responseData) {
      try {
        const parsedData = JSON.parse(data.responseData);
        setUserProfile(parsedData);
        setQrValue(`www.ETMmeet.org/user-profile/${parsedData.Id}?mode=QR`);
        setNotFound(false);
      } catch (e) {
        console.error('Error parsing user data:', e);
        setUserProfile({});
        setNotFound(false);
      }
    } else if (error || (data && !data.responseData)) {
      setUserProfile({});
      setNotFound(true);
    }
  }, [data, error]);

  if (!isAdmin) {
    return null;
  }

  const handleGenerateQR = () => {
    if (email !== lastSearchedEmail) {
      setUserProfile({});
      setQrValue('');
      setNotFound(false);
      setLastSearchedEmail(email);
      trigger(email);
    }
  };

  const renderProfileField = (label: string, value: any) => (
    <Box mb={1}>
      <Typography variant="subtitle2" component="span"><strong>{label}:</strong> </Typography>
      <Typography variant="body2" component="span">{value || 'Vacío'}</Typography>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h6" component="div" gutterBottom>
        Buscar perfil de usuario
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Correo Electrónico"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleGenerateQR} disabled={!email}>
        Obtener información
      </Button>
      {error && <Typography color="error">Error: No se pudo obtener la información del usuario.</Typography>}
      {notFound && <Typography color="error">Correo no encontrado.</Typography>}
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">Información del Usuario</Typography>
              {renderProfileField('ID', userProfile?.Id)}
              {renderProfileField('Fecha de Nacimiento', userProfile?.DateOfBirth)}
              {renderProfileField('Género', userProfile?.Genre)}
              {renderProfileField('Documento', userProfile?.DocumentValue)}
              {renderProfileField('Nombre', userProfile?.Name)}
              {renderProfileField('Apellido', userProfile?.LastName)}
              {renderProfileField('Correo', userProfile?.Email)}
              {renderProfileField('Teléfono', userProfile?.Phone)}
              {renderProfileField('Dirección', userProfile?.Address)}
              {renderProfileField('Descripción', userProfile?.Description)}
              {renderProfileField('Página Web', userProfile?.WebPage)}
              {renderProfileField('Facebook', userProfile?.Facebook)}
              {renderProfileField('Instagram', userProfile?.Instagram)}
              {renderProfileField('LinkedIn', userProfile?.Linkedin)}
              {renderProfileField('Twitter', userProfile?.Twitter)}
              {renderProfileField('Country ID', userProfile?.CountryId)}
              {renderProfileField('Origen', userProfile?.Origin)}
              {renderProfileField('Activo', userProfile?.Active ? "Sí" : "No")}
              {renderProfileField('Última Conexión', userProfile?.LastConnection)}
              {renderProfileField('Creado el', userProfile?.CreatedAt)}
              {renderProfileField('Actualizado el', userProfile?.UpdatedAt)}
              {renderProfileField('YouTube', userProfile?.Youtube)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          {qrValue && (
            <Box display="flex" justifyContent="center">
              <QRCode value={qrValue} size={256} />
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            <Avatar
              alt="Profile Picture"
              src={userProfile?.ProfilePicture}
              sx={{ width: 150, height: 150 }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QRCodeGenerator;
