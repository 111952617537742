import { useField } from 'formik'
import "./Inputs.scss"

const Radio = ({ label, desc, boldLabel=false, showErrorText=true, ...props }: any) => {


  const [field, meta] = useField({ ...props, type: 'radio' })
  let label_html = label
  if(boldLabel){
    label_html = <b>{label}</b>
  }
  const desc_html = desc ? <text>{desc}</text> : <></>
  return (
    <div>
      <label className=''>
        <input className='mr-2' type='radio' {...field} {...props} />
        {label_html}
      </label>
      {meta.touched && meta.error && showErrorText ? <div className="error text-danger">{meta.error}</div> : null}
      <div>
        {desc_html}
      </div>

    </div>
  )
}

export default Radio
