import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetTagsListMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectTags, setTags } from '../../../redux/features/dimensions/dimensionsSlice';


export default function GetActualTimeZone() {

    const dispatch = useDispatch()
    const [actual_time_zone, setActualTimeZone] = useState(0)

    useEffect(() => {
        const timezoneOffset = (new Date()).getTimezoneOffset()/60*-1;
        setActualTimeZone(timezoneOffset)
    }, []);

    return actual_time_zone
}
