import React, { useState, useEffect, useRef } from 'react';
import { AudioTrack, VideoTrack, Participant as VideoParticipant, } from 'twilio-video';

import "./twillio.scss"
import "./Participant.scss"

interface ParticipantProps {
    participant: VideoParticipant;
    localParticipant: boolean;
    activeVideo: boolean;
    activeAudio: boolean;
    UserRequestedProfilePicture?: string;
    UserRequestProfilePicture?: string;
    UserRequestName?: string;
    UserRequestLastName?: string;
    UserRequestedName?: string;
    UserRequestedLastName?: string;
    UserRequestId?: number;
    UserRequestedId?: number;
    activeFullScreen?: boolean;
}

const Participant = ({ 
    participant, 
    localParticipant, 
    activeVideo, 
    activeAudio,
    UserRequestedProfilePicture,
    UserRequestProfilePicture,
    UserRequestName,
    UserRequestLastName,
    UserRequestedName,
    UserRequestedLastName,
    UserRequestId,
    UserRequestedId,
    activeFullScreen
}: ParticipantProps) => {

    const [videoTracks, setVideoTracks] = useState<(VideoTrack | null)[]>([]);
    const [audioTracks, setAudioTracks] = useState<(AudioTrack | null)[]>([]);

    // Create refs for the HTML elements to attach audio and video to in the DOM
    // For now, set them to null
    const videoRef = useRef<HTMLVideoElement>(null);
    const audioRef = useRef<HTMLMediaElement>(null);

    // Get the audio and video tracks from the participant, filtering out the tracks that are null
    const getExistingVideoTracks = (participant: VideoParticipant) => {
        const videoPublications = Array.from(participant.videoTracks.values());
        const existingVideoTracks = videoPublications.map(publication => publication.track).filter(track => track !== null);
        return existingVideoTracks;
    }

    const getExistingAudioTracks = (participant: VideoParticipant) => {
        const audioPublications = Array.from(participant.audioTracks.values());
        const existingAudioTracks = audioPublications.map(publication => publication.track).filter(track => track !== null);
        return existingAudioTracks;
    }

    // When a new track is added or removed, update the video and audio tracks in the state
    useEffect(() => {
        const trackSubscribed = (track: AudioTrack | VideoTrack) => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else {
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track: AudioTrack | VideoTrack) => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else {
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };

        setVideoTracks(getExistingVideoTracks(participant));
        setAudioTracks(getExistingAudioTracks(participant));
        // console.log("setVideoTracks")
        // console.log(videoTracks)
        // console.log(audioTracks)
        // Set up event listeners
        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        // console.log("videoTracks", videoTracks)

        // Clean up at the end by removing all the tracks and the event listeners
        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
            participant.videoTracks.forEach((track) => track.isEnabled = false)
        };
    }, [participant]);

    // When a new videoTrack or audioTrack is subscribed, add it to the DOM.
    // When unsubscribed, detach it
    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoRef && videoRef.current) {
            if (videoTrack) {
                videoTrack.attach(videoRef.current);
                return () => {
                    videoTrack.detach();
                };
            }
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];

        if (audioRef && audioRef.current) {
            if (audioTrack) {
                audioTrack.attach(audioRef.current);
                return () => {
                    audioTrack.detach();
                };
            }
        }

    }, [audioTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        const videoTrack = videoTracks[0];
        // console.log("videoTrack", videoTrack)
        // console.log("audioTrack", audioTrack)
        /* if(localParticipant){
            if(activeVideo){
                videoTrack?.enable;
            }else{
                videoTrack.disable();
            }
            if(activeAudio){
                audioTrack.enable();
            }else{
                audioTrack.disable();
            }
        } */
    }, [localParticipant, activeVideo, activeAudio]);

    
    let fullName:any = "nombre"
    let foto:any = "foto"
    let id:any = "foto"
    // console.log("participant.identity", participant.identity)
    if(parseInt(participant.identity.split("id")[1]) == UserRequestId){
        fullName = UserRequestName + " " + UserRequestLastName
        foto = UserRequestProfilePicture
        id=UserRequestId
    }else{
        fullName = UserRequestedName + " " + UserRequestedLastName
        foto = UserRequestedProfilePicture
        id=UserRequestedId
    }
    let verperfil_html = <></>
    if(!localParticipant){
        verperfil_html = <a className="btn btn-primary btn-sm btn-rounded" href={"https://etmmeet.org/user-profile/"+id} target='_blank'>ver perfil</a> // desarrollo.etmmeet.org

    }

    /* VIDEO TRACK */
    let video_track_html = <></>
    if (videoTracks.length > 0) {
        video_track_html = <video className="participant-video" ref={videoRef} autoPlay={true} />
    } else {
        video_track_html = <div className='participant-video no-video'>no-video</div>
        video_track_html = <div className='participant-foto-container'>
            <img className='participant-foto' src={foto?foto:"/media/iconos/icono-persona.svg"} alt="" />
        </div>
    }

    let audio_track_html = <></>
    let audioLevel_html = <></>
    if (audioTracks.length > 0) {
        // console.log("audioTracks")
        // console.log("audioTracks[0]", audioTracks[0]) // local
        // console.log("audioTracks[1]", audioTracks[1]) // externo
        
        audio_track_html = <audio className="participant-audio" ref={audioRef} autoPlay={true} muted={false} />

    } else {
        audio_track_html = <div className='participant-audio no-audio'></div>
    }

    let activeAudio_html = <></>
    let activeVideo_html = <></>
    let identity_html = participant.identity
    if(localParticipant){
        identity_html = "Yo"
        if(activeAudio && audioTracks.length > 0){
            activeAudio_html = <i className="fas fa-microphone"></i>
        }else{
            activeAudio_html = <i className="fas fa-microphone-slash"></i>
        }
        if(activeVideo && videoTracks.length > 0){
            activeVideo_html = <i className="fas fa-video"></i>
        }else{
            activeVideo_html = <i className="fas fa-video-slash"></i>
        }
    }

    
    let participantClassName = "participant"
    if(activeFullScreen){
        if(localParticipant){
            participantClassName = "participant participant-fullscreen-local"
        }else{
            participantClassName = "participant participant-fullscreen-remote"
        }
    }
    /* RETURN */
    return (
        <div className={participantClassName} id={participant.identity}>
            {video_track_html}
            {audio_track_html}
            <div className="identity participant-footer">
                <div className='d-flex justify-content-start align-items-center'>
                    <div className='mr-2 name'><p>{fullName}</p></div>
                    <div className='mr-2 devices'>{activeAudio_html}</div>
                    <div className='devices'>{activeVideo_html}</div>
                    <div className='verperfil'>{verperfil_html}</div>
                </div>
            </div>
        </div>
    );
};

export default Participant;