
import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import Select from '../../../components/Form/Select'
import Label from '../../../components/Form/Label';
import Radio from '../../../components/Form/Radio';
import { useEffect, useState } from 'react';
import { useSignupMutation } from '../../../redux/features/auth/authApiSlice';

import { useSelector } from 'react-redux';
import { usePutPersonalDataMutation } from '../../../redux/features/user/userApiSlice';

import {
    get_arr_meses,
    get_arr_anios,
    get_arr_dias,
    get_opciones_dias,
    get_opciones_anios,
    get_opciones_meses,
    get_opciones_generos,
    get_opciones_countries,
    get_opciones_companies,
    get_opciones_industries,
    get_opciones_jobtitles,
    validatePassword,
    validateEmail
} from '../../../utils/utilsForms';

import { selectInterests } from '../../../redux/features/dimensions/dimensionsSlice';
import SignUpStepper from './SignUpStepper';
import FormularioExperiencia from '../../../components/formularios/FormularioExperiencia';
import FormularioEmprendimiento from '../../../components/formularios/FormularioEmprendimiento';
import GetCompanies from '../../../hooks/dimensions/GetCompanies/GetCompanies';
import GetJobTitles from '../../../hooks/dimensions/GetJobTitles/GetJobTitles';
import GetIndustries from '../../../hooks/dimensions/GetIndustries/GetIndustries';

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { textFieldClasses } from '@mui/material';
import { text } from 'stream/consumers';



const SignUpPageThree = (
    
    {
        ChangeFormState, 
        actual_values,
        actual_photo_create,
        setActualPhotoCreate
    }: any) => {

    let companies = GetCompanies()
    let jobtitles = GetJobTitles()
    let industries = GetIndustries()

    const interests = useSelector(selectInterests)
    const [signUp, { isLoadingSignUp }]: any = useSignupMutation()
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    const [errorsDict, setErrorsDict] = useState<any>({})

    const [userRoles, setUserRoles] = useState(actual_values.userRoles) // 0: no seleccionado, 1: emprendedor, 2: experto, 3: ambos

    const [actual_values_create, setActualValuesCreate] = useState<any>({ ...actual_values });
    
    const textSignup= useSelector(selectCurrentDictonary).signup
    const textIndustrias= useSelector(selectCurrentDictonary).industries

    const validate = (values: any) => {
        
        const errors: any = {}
        ChangeFormState({ ...actual_values, ...values, userRoles: actual_values.userRoles }, 3)

        if (!userRoles) {
            errors.userRoles = textSignup.error_p3_rol
        }

        /* EMPRENDEDOR o AMBOS (validacionformulario emprendimientos)*/
        if (userRoles == 1 || userRoles == 3) {
            if (!values.startup_Name) {
                errors.startup_Name = textSignup.error_required
            }
            if (!values.startup_Description) {
                errors.startup_Description = textSignup.error_required
            }
            if (!values.startup_Stage || values.startup_Stage == 0) {
                errors.startup_Stage = textSignup.error_required
            }
        }
        /* FIN EMPRENDEDOR o AMBOS (validacionformulario emprendimientos)*/

        /* EXPERTO o AMBOS (validacionformulario emprendimientos)*/
        if (userRoles == 2 || userRoles == 3) { /* EMPRENDEDOR */
            if ((values.expertises_conCompany == "1") && !(values.expertises_companyId > 0)) {
                errors.expertises_companyId = textSignup.error_required
            }
            if (!values.expertises_startDate) {
                errors.expertises_startDate = textSignup.error_required
            }
            if (!values.expertises_endDate) {
                errors.expertises_endDate = textSignup.error_required
            }
            if (!values.expertises_industry) {
                errors.expertises_industry = textSignup.error_required
            }
            if (!values.expertises_jobTitle) {
                errors.expertises_jobTitle = textSignup.error_required
            }
        }
        /* FIN EXPERTO o AMBOS (validacionformulario emprendimientos)*/



        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
            setErrorsDict({})
        } else {
            setErrorsDict(errors)
            setValidated(false)
        }
        return errors
    }

    const Volver = (e: any) => {
        e.preventDefault();
        ChangeFormState("", 2)
    }

    async function handleSubmit(values: any, { setStatus, setErrors }: any) {
        ChangeFormState({ ...actual_values, userRoles: userRoles }, 4)
    }




    useEffect(() => {


    }, [actual_values])

    // ================================================================================================================================================================ 
    // ==================================================================== OPCIONES SELECTS ==========================================================================
    // ================================================================================================================================================================ 




    let formulario_emprendedores = <div className='row'>
        <div className='col-12 mb-3'>
            <div className='d-flex flex-column align-items-center justify-content-center mb-3 mt-3'>
                <h4 className='mb-0'>{textSignup.titulo_8}</h4>
                <p>{textSignup.titulo_8_1}</p>
                <p>{textSignup.titulo_8_2}</p>
            </div>
        </div>
        <div className='col-12 mb-3'>
            <FormularioEmprendimiento actual_values={actual_values}
                handleChangeData={(value: any) => ChangeFormState(value, 2)}
                setActualPhotoCreate={setActualPhotoCreate}
                actual_photo_create={actual_photo_create}
            ></FormularioEmprendimiento>
        </div>
    </div>


    let formulario_expertos = <div className='row'>
        <div className='col-12 mb-3'>
            <div className='d-flex flex-column align-items-center justify-content-center mb-3 mt-3'>
                <h4 className='mb-0'>{textSignup.titulo_9}</h4>
                <p>{textSignup.titulo_8_1}</p>
                <p>{textSignup.titulo_9_1}</p>
            </div>
        </div>
        <div className='col-12'>
            <FormularioExperiencia
                handleChangeData={(value: any) => ChangeFormState(value, 2)}
                actual_values={actual_values}
                companies={companies} jobtitles={jobtitles} industries={industries}
                registro={true}
            ></FormularioExperiencia>
        </div>
    </div>
    let formulario_rol = <></>
    if (actual_values.userRoles == 1) {
        formulario_rol = formulario_emprendedores
    }
    if (actual_values.userRoles == 2) {
        formulario_rol = formulario_expertos
    }
    if (actual_values.userRoles == 3) {
        formulario_rol = <>
            {formulario_emprendedores}
            <hr />
            {formulario_expertos}
        </>
    }


    return (
        <Formik
            initialValues={actual_values}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            <Form className='px-lg-3 px-xl-5'>
                <div className='mb-3 mb-lg-4 mt-3 mt-lg-4'>
                    <h3 className='text-center mb-3'>{textSignup.titulo_1}<div className='text-primary d-inline mb-0'>meet</div></h3>
                    <div className='d-flex justify-content-center align-items-start mb-4'>

                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <SignUpStepper step={actual_values.step - 1}></SignUpStepper>
                        </div>
                    </div>
                </div>

                {formulario_rol}

                <div className='row mt-4'>
                    <div className='col-12 '>
                        <div className='d-flex justify-content-between'>
                            <button /* disabled={loading || !validated} */ onClick={Volver} className='btn btn-rounded btn-outline-primary'>
                                {textSignup.btn_3}
                            </button>
                            <button disabled={loading || !validated} type="submit" className='btn btn-rounded btn-primary'>
                                {textSignup.btn_1}
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )

}

export default SignUpPageThree;