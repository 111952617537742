
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import './ConfirmationDialog.scss';

interface ConfirmationDialogProps {
    open: boolean;
    title?: string;
    message: string;
    onAccept: () => void;
    onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    title = 'Confirmación',
    message,
    onAccept,
    onCancel,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby='confirm-registration-dialog-title'
            aria-describedby='confirm-registration-dialog-description'
        >
            <DialogTitle id='confirm-registration-dialog-title'>Confirmar Inscripción</DialogTitle>
            <DialogContent>
                <Typography id='confirm-registration-dialog-description'>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancelar
                </Button>
                <Button onClick={onAccept} color='primary' autoFocus>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
