
import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import moment from "moment"



const Timer = ({
  UserRequestedEndTime,
  UserRequestedStartTime,
  leave,
  setExtraTime
}: any) => {


  var startdate = moment(UserRequestedStartTime).utc(true)
  let utcOffset = moment().utcOffset()
  var enddate = moment(UserRequestedEndTime).add(utcOffset, 'minutes')


  const textVideollamada = useSelector(selectCurrentDictonary).videollamada

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [diffSeconds, setdiffSeconds] = useState(0);


  const getTime = () => {
    let time1 = moment.duration(enddate.diff(moment()));
    let time = time1.asSeconds();
    setdiffSeconds(time)
    setDays(Math.floor(time / (60 * 60 * 24)));
    setHours(Math.floor((time / (60 * 60)) % 24));
    setMinutes(Math.floor((time / 60) % 60));
    setSeconds(Math.floor((time) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, []);

  if (diffSeconds < 0 && diffSeconds < -3 * 60) {
    leave()
  }
  if (diffSeconds < 0) { // || seconds < 30  || true
    setExtraTime(true)
    let tiempoTerminado_html = <p className='text-end'>{textVideollamada.titulo_1}</p>
    return (
      <div className="timer text-warning text-right">
      <div className=' px-2'>
        <div><p><b>{textVideollamada.param_1}</b></p></div>
      </div>
        {/* <div className=' px-2'>
          <div><p><b>{textVideollamada.titulo_2}</b></p></div>
        </div> */}
        <div className='d-flex align-items-center justify-content-end'>
          <div className='d-flex px-2'>
            <div className='mr-2'><p><b>{textVideollamada.titulo_2}</b></p></div>
            <p className=''><b>{minutes}</b></p>
            <p className='ml-1'>{textVideollamada.param_min}</p>
          </div>
          <div className='d-flex px-2'>
            <p className=''><b>{seconds}</b></p>
            <p className='ml-1'>{textVideollamada.param_seg} seg</p>
          </div>
        </div>
      </div>
    )
  }else{
    setExtraTime(false)
  }

  return (
    <div className="timer text-white">
      <div className=' px-2'>
        <div><p><b>{textVideollamada.param_1}</b></p></div>
      </div>
      <div className='d-flex'>
        <div className='d-flex px-2'>
          <p className=''><b>{days}</b></p>
          <p className='ml-1'>{textVideollamada.param_dias}</p>
        </div>
        <div className='d-flex px-2'>
          <p className=''><b>{hours}</b></p>
          <p className='ml-1'>{textVideollamada.param_horas}</p>
        </div>
        <div className='d-flex px-2'>
          <p className=''><b>{minutes}</b></p>
          <p className='ml-1'>{textVideollamada.param_min}</p>
        </div>
        <div className='d-flex px-2'>
          <p className=''><b>{seconds}</b></p>
          <p className='ml-1'>{textVideollamada.param_seg} seg</p>
        </div>
      </div>
    </div>
  );
}

export default Timer;