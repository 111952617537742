import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
} from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { useNewSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import { useFeedbackQuestions } from './FeedbackQuestions/useFeedbackQuestions';
import * as Yup from 'yup';
import RatingSection from './FeedbackDialog/RatingSection';
import ContactToggle from './FeedbackDialog/ContactToggle';
import { ROLES, Actividad, Rol } from './FeedbackQuestions/constants';
import { Question } from './FeedbackQuestions/feedbackQuestionsData';
import './FeedbackDialog.scss';

interface FeedbackDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit?: (values: any) => void;
    counterpartName: string;
    isMentor: boolean;
    actividad: Actividad;
    contrapartes?: string[];
    availableTimeIds?: number[];
}

interface FeedbackFormValues {
    [key: string]: any;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
    open,
    onClose,
    onSubmit,
    counterpartName,
    isMentor,
    actividad,
    contrapartes = [],
    availableTimeIds,
}) => {
    const dispatch = useDispatch();
    const role: Rol = isMentor ? ROLES.EXPERTO : ROLES.EMPRENDEDOR;
    const { questions } = useFeedbackQuestions(role, actividad);
    const [newSendActivityFeedback] = useNewSendActivityFeedbackMutation();

    const feedbackMappings: Record<string, Record<string, string>> = {
        [`${ROLES.EXPERTO}_Rueda de Contactos`]: {
            puntosClave: 'questionOne',
            eleccionEmprendedor: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Rueda de Contactos`]: {
            elecciónexperto: 'questionOne',
            utilidadActividad: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Classic Car Pitch`]: {
            informacionUtil: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Classic Car Pitch`]: {
            puntosClave: 'questionOne',
            satisfaccionGeneral: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Mentoría`]: {
            puntosClave: 'questionOne',
            satisfaccionGeneral: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Mentoría`]: {
            informacionUtil: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
    };

    const getMappingKey = (): string => `${role}_${actividad}`;

    const applyMapping = (feedback: Record<string, any>): Record<string, any> => {
        const mapping = feedbackMappings[getMappingKey()];
        if (!mapping) {
            return feedback;
        }
        const standardizedFeedback: Record<string, any> = {};
        Object.entries(feedback).forEach(([key, value]) => {
            const standardizedKey = mapping[key] || key;
            standardizedFeedback[standardizedKey] = value;
        });
        console.log('Valores mapeados después del mapeo:', standardizedFeedback);
        return standardizedFeedback;
    };

    const generateValidationSchema = (questions: Question[]): Yup.ObjectSchema<any> => {
        const shape: { [key: string]: Yup.AnySchema } = {};

        questions.forEach((question) => {
            if (question.type === 'rating') {
                shape[question.name] = Yup.number()
                    .min(1, 'Mínimo 1 estrella')
                    .max(5, 'Máximo 5 estrellas')
                    .required('Este campo es obligatorio');
            } else if (question.type === 'toggle') {
                shape[question.name] = Yup.boolean().required('Este campo es obligatorio');
            }
        });

        return Yup.object().shape(shape);
    };

    const validationSchema = generateValidationSchema(questions);

    const handleComplete = async (feedback: FeedbackFormValues) => {
        console.log(`Tipo de actividad: ${actividad}, Rol: ${role}`);
        const standardizedFeedbacks = applyMapping(feedback);

        const dataToSend = {
            availableTimeId: availableTimeIds?.[0] ?? 0,
            questionOne: standardizedFeedbacks['questionOne'] ?? null,
            questionTwo: standardizedFeedbacks['questionTwo'] ?? null,
            questionThree: standardizedFeedbacks['questionThree'] ?? null,
            keepContact: standardizedFeedbacks['keepContact'] ?? null,
            isMentor,
        };

        console.log('Payload final enviado al endpoint:', dataToSend);

        // Notificaciones
        const new_alert = {
            id: `sending_feedback_${Date.now()}`,
            type: 'loading',
            title: 'Enviando feedback',
            desc: '',
            close: false,
            timeout: 0,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const response = await newSendActivityFeedback(dataToSend).unwrap();
            console.log('Respuesta del endpoint:', response);
            dispatch(deleteAlert({ alert_id: new_alert.id }));

            const success_alert = {
                id: `feedback_sent_${Date.now()}`,
                type: 'success',
                title: 'Feedback enviado',
                desc: 'El feedback ha sido enviado exitosamente',
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: success_alert }));

            if (onSubmit) onSubmit(dataToSend);
            onClose();
        } catch (error) {
            console.error('Error al enviar los datos al endpoint:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));

            const error_alert = {
                id: `feedback_error_${Date.now()}`,
                type: 'error',
                title: 'Error al enviar feedback',
                desc: 'Hubo un error al enviar el feedback',
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
        }
    };

    const dialogTitle = `Feedback para "${counterpartName}"`;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Formik<FeedbackFormValues>
                    initialValues={{}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log('Valores guardados en el formulario:', values);
                        setSubmitting(false);
                        handleComplete(values);
                    }}
                >
                    {({ isSubmitting, setFieldValue, errors, touched }) => (
                        <Form noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                {questions.map((question) => (
                                    <Grid item xs={12} key={question.name}>
                                        {question.type === 'rating' ? (
                                            <Field name={question.name}>
                                                {({ field }: FieldProps) => (
                                                    <RatingSection
                                                        question={question}
                                                        value={field.value || 0}
                                                        onChange={(value: number) => {
                                                            console.log(`Seleccionado en ${question.name}:`, value);
                                                            setFieldValue(field.name, value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        ) : (
                                            <Field name={question.name}>
                                                {({ field }: FieldProps) => (
                                                    <ContactToggle
                                                        maintainContact={field.value}
                                                        onToggle={(val: boolean) => {
                                                            console.log(`Seleccionado en ${question.name}:`, val);
                                                            setFieldValue(field.name, val);
                                                        }}
                                                        counterpartName={counterpartName}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                        {errors[question.name] &&
                                            touched[question.name] &&
                                            typeof errors[question.name] === 'string' && (
                                                <div className="error-message">{String(errors[question.name])}</div>
                                            )}
                                    </Grid>
                                ))}
                            </Grid>
                            <DialogActions>
                                <Button onClick={onClose} className="cancel-button">
                                    CANCELAR
                                </Button>
                                <Button type="submit" className="send-button" disabled={isSubmitting}>
                                    ENVIAR
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default FeedbackDialog;
