
export const ROLES = {
    EMPRENDEDOR: 'emprendedor',
    EXPERTO: 'experto',
} as const;

export type Rol = typeof ROLES[keyof typeof ROLES];

export const ACTIVITIES = {
    MENTORIA: 'Mentoría',
    CLASSIC_CAR_PITCH: 'Classic Car Pitch',
    RUEDA_DE_CONTACTOS: 'Rueda de Contactos',
    ELECTRIC_CAR_PITCH: 'Electric Car Pitch',
    WHEEL_PITCH: 'Wheel Pitch',
    ELEVATOR_PITCH: 'Elevator Pitch',
} as const;

export type Actividad = typeof ACTIVITIES[keyof typeof ACTIVITIES];

export const FEEDBACK_CONFIG = {
    MAX_COMMENT_LENGTH: 500,
    RATING_SCALE: 5,
} as const;
