import "./SelectorSponsors.scss"

import Slider from 'react-slick';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { capitalizeFirstLetter } from '../../utils/utilsText';

const SelectorSponsors = ({ sponsors, handleSponsorClick, selectedSponsors }: any) => {

    const PrevArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <ArrowBackIosOutlinedIcon />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <ArrowForwardIosOutlinedIcon />
            </div>
        );
    };

    var settings = {
        dots: true,
        infinite: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: 5,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false
                }
            }
        ]
    };

    function sponsors_sponsor_html(sponsor: any, selected: any) {
        let class_sponsor = 'sponsor';
        if (selected) {
            class_sponsor = 'sponsor sponsor-selected';
        }
        return (
            <div className="sponsor-container">
                <div onClick={(event) => handleSponsorClick(event, sponsor)} className={class_sponsor + " d-flex flex-column align-items-center justify-content-center mr-0 mr-lg-3"}>
                    <div className='img-sponsor-container'>
                        <img src={sponsor.AltText} className='img-sponsor' title={sponsor.Value} />
                    </div>
                    <h6 className="text-center">{capitalizeFirstLetter(sponsor.Value)}</h6>
                </div>
            </div>
        )
    }

    return (
        <div className='slick-slider-container '>
            <Slider {...settings}>
                {
                    sponsors?.map((sponsor: any, index: number) => {
                        let selected = selectedSponsors.some((x: any) => x === sponsor);
                        return (
                            <div key={sponsor.id || index}>
                                {sponsors_sponsor_html(sponsor, selected)}
                            </div>
                        );
                    })
                }
            </Slider>
        </div>
    );

}

export default SelectorSponsors;
