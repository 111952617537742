import "./Inputs.scss"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';






const Label = ({ label="", classNameLabel="label-primary", required=false}:any) => {
  let required_html = <></>

  const textLanding= useSelector(selectCurrentDictonary).landing

  if(required){
    required_html = <b className='required-label' data-toggle="tooltip" data-placement="right" title={textLanding.titulo_16}>*</b>
  }
  return (
      <label className={"label "+classNameLabel}>{label}{required_html}</label>
  )
}

export default Label
