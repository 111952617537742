
import React, { useEffect, useState, useLayoutEffect } from 'react';


import { selectCurrentDictonary, selectCurrentLanguage } from '../../redux/features/language/LanguageSlice';
import { useDispatch, useSelector } from "react-redux"
import "./Home.scss"
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import SelectorSponsors from './SelectorSponsors';
import { Sponsor, personalData } from '../../interfaces/dimentions';
import GetSponsors from '../../hooks/dimensions/GetSponsors/GetSponsors';

/* Iconos */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { selectPersonalData, selectUserInterests, setUserInterests } from '../../redux/features/user/userSlice';
import { useGetUserInterestsMutation } from '../../redux/features/user/userApiSlice';
import { usePostRecomUserInterestMutation } from '../../redux/features/recom/recomApiSlice';
import UserProfileExternal from '../UserProfileExternal/UserProfileExternal';
import HomeResultados from './HomeResultados';
import HomeColumnaFiltros from './HomeColumnaFiltros';

import { useGetUsersAvailableTimesMutation } from '../../redux/features/meetings/meetingsApiSlice';


import Switch from '@mui/material/Switch';
import HomeBuscador from '../Buscador/HomeBuscador';
const Home = () => {
    const dispatch = useDispatch()
    const textHome = useSelector(selectCurrentDictonary).home
    const { Country_origiId: Country, industries_origiId: industries } = useSelector(selectCurrentDictonary)
    const textIntereses = useSelector(selectCurrentDictonary).filtros_interes
    const lenguajeId = useSelector(selectCurrentLanguage)
    const userInterests = useSelector(selectUserInterests)
    /* sponsors vars */
    const [selectedSponsors, setSelectedSponsors] = useState<any>([])
    const sponsors = GetSponsors();
    const [postRecomUserInterest]: any = usePostRecomUserInterestMutation()
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id

    const [getUserInterests]: any = useGetUserInterestsMutation()
    const [isLoadingUserInterests, setIsLoadingUserInterests] = useState(false)
    const [initialCharge, setInitialCharge] = useState(false)
    const [get]: any = useGetUsersAvailableTimesMutation()
    const [imprimir, setImprimir] = useState(false);
    const [idsToFilter, setidsToFilter] = useState<any>([])
    const [mobileversion, setMobileVersion] = useState(false);
    const textMeetings = useSelector(selectCurrentDictonary).meetings
    /*
   let empty_intereses = {
       "interes-auto-9": [
           {key: 141,
               label: "BIOTECNOLOGÍA",
               parent_id: 9,
               title: "Prototipo",
               value: 141}
       ],
       "interes-auto-4": [
           {key: 294,
               label: "Chile",
               parent_id: 4,
               title: "Chile",
               value: 294}
       ],
       "interes-auto-7": [{key: 120,
                           label: "Prototipo",
                           parent_id: 7,
                           title: "Prototipo",
                           value: 120}],
       "interes-auto-1": [],
       "interes-auto-15": [],
       "interes-auto-10": [],
       "interes-auto-14": [],
       "interes-auto-8": [],
       "interes-auto-5": [],
       }
   */
    // let empty_intereses2 = {
    //     "interes-auto-9": [
    //         {key: 141,
    //             label: "BIOTECNOLOGÍA",
    //             parent_id: 9,
    //             title: "Prototipo",
    //             value: 141}
    //     ],
    //     "interes-auto-4": [
    //         {key: 294,
    //             label: "Chile",
    //             parent_id: 4,
    //             title: "Chile",
    //             value: 294}
    //     ],
    //     "interes-auto-7": [{key: 120,
    //                         label: "Prototipo",
    //                         parent_id: 7,
    //                         title: "Prototipo",
    //                         value: 120}],
    //     "interes-auto-1": [],
    //     "interes-auto-15": [],
    //     "interes-auto-10": [],
    //     "interes-auto-14": [],
    //     "interes-auto-8": [],
    //     "interes-auto-5": [],
    //     }
    let empty_intereses = {
        "interes-auto-9": [],
        "interes-auto-4": [],
        "interes-auto-7": [],
        "interes-auto-1": [],
        "interes-auto-15": [],
        "interes-auto-10": [],
        "interes-auto-14": [],
        "interes-auto-8": [],
        "interes-auto-5": [],
    }
    let empty_initialState: any = {
        "busqueda_texto": "",
        "intereses": { ...empty_intereses },
        "sponsors": [],
        "tipo_busqueda": 1 // 0 emprendimientos, 1 expertos 
    }

    let initialState: any = { ...empty_initialState }

    const [actual_values, setActualValues] = useState(initialState);
    const [lista_resultados, set_lista_resultados] = useState<any>([])
    const [isLoadingAcciones, setIsLoadingAcciones] = useState(false)
    const [resetSwitch, setResetSwitch] = useState(false)
    const [esPrimerClick, setEsPrimerClick] = useState(true);


    const getTextDictionary = (idType: number) => {

        if (idType === 1) return textIntereses.bill_ranges
        else if (idType === 4) return Country
        else if (idType === 5) return textIntereses.politicainclusion
        else if (idType === 7) return textIntereses.formaidea
        else if (idType === 8) return textIntereses.politicagenero
        else if (idType === 9) return industries
        else if (idType === 10) return textIntereses.investmentsource
        else if (idType === 14) return textIntereses.womenleader
        else if (idType === 15) return textIntereses.numeroempleados
        else return {}
    }

    const setInitialInterests = (user_interests: any) => {
        let initialState: any = { ...empty_initialState }
        if (user_interests) {
            let intereses_aux = { ...initialState["intereses"] }
            user_interests.forEach((interes_usuario: any) => {
                let arr_aux: any = []
                if (intereses_aux["interes-auto-" + interes_usuario.InterestTypeId]) {
                    arr_aux = [...intereses_aux["interes-auto-" + interes_usuario.InterestTypeId]];
                }
                const dictionary = getTextDictionary(interes_usuario.InterestTypeId)
                arr_aux.push({
                    key: interes_usuario.InterestId,
                    label: dictionary[interes_usuario.InterestId],
                    parent_id: interes_usuario.InterestTypeId,
                    title: dictionary[interes_usuario.InterestId],
                    value: interes_usuario.InterestId
                })
                intereses_aux["interes-auto-" + interes_usuario.InterestTypeId] = [...arr_aux];
            });
            initialState["intereses"] = { ...initialState["intereses"], ...intereses_aux }
        }
        setActualValues({ ...actual_values, "intereses": initialState["intereses"] })
    }
    function transformUserInterests(userInterests: any): any {
        // Inicializar el objeto con claves específicas en el orden dado
        let empty_intereses: { [key: string]: any[] } = {
            "interes-auto-9": [],
            "interes-auto-4": [],
            "interes-auto-7": [],
            "interes-auto-1": [],
            "interes-auto-15": [],
            "interes-auto-10": [],
            "interes-auto-14": [],
            "interes-auto-8": [],
            "interes-auto-5": [],
        };


        userInterests.forEach((interest: any) => {

            let interestData: any = {
                key: interest.InterestId,
                label: interest.InterestName,
                parent_id: interest.InterestTypeId,
                title: interest.InterestName,
                value: interest.InterestId
            };


            let interestKey = `interes-auto-${interest.InterestTypeId}`;


            if (empty_intereses.hasOwnProperty(interestKey)) {
                empty_intereses[interestKey].push(interestData);
            }
        });

        return empty_intereses;
    }

    async function handleReloadUserInterests() {

        if (user_id) {

            setIsLoadingUserInterests(true)
            const res_intereses: any = await getUserInterests({ user_id: user_id });
            const resData = JSON.parse(res_intereses.data.responseData)
            const user_interests = resData
            dispatch(setUserInterests({ user_interests: resData }))

            setInitialInterests(user_interests)
            // console.log("ACTUAL VALUES FROM FIRST", actual_values)
            // Asumiendo que actual_values e initialState son objetos ya definidos
            const interesesEstanVacios = Object.values(actual_values.intereses).every(
                (interes) => Array.isArray(interes) && interes.length === 0
            );
            if (interesesEstanVacios) {
                actual_values.intereses = transformUserInterests(user_interests);
            }
            else {
                // console.log("BORRAR BARRA LATERAL.")

            }

            //    console.log("ACTUAL VALUES FROM REALOAD", actual_values)



            handleClickFiltrar({ ...actual_values, "intereses": initialState["intereses"] })
            setIsLoadingUserInterests(false)
        }
    }

    // Handle fields change
    const ChangeFormState = (new_state: any) => {
        if (new_state) {
            setActualValues({ ...new_state });
        } else {
            setActualValues({ ...actual_values });
        }
    }

    /* SPONSORS ----------------------------------------- */
    function handleSponsorClick(event: any, sponsor: any) {
        event.preventDefault();
        let encontrado = 0;
        let selectedSponsors_aux: Sponsor[] = [...selectedSponsors]
        for (let i = 0; i < selectedSponsors.length; i++) {
            const element = selectedSponsors[i];
            if (element == sponsor) {
                encontrado = 1;
                selectedSponsors_aux.splice(i, 1)
            }
        }

        if (encontrado == 0) {
            selectedSponsors_aux = [...selectedSponsors, sponsor]
        }
        setSelectedSponsors(selectedSponsors_aux)
        setActualValues({ ...actual_values, "sponsors": selectedSponsors_aux })
    }
    /* FIN SPONSORS --------------------------------------------------------- */


    function changeSearchType(tipo_busqueda: number) {
        setResetSwitch(true)
        if (tipo_busqueda == 1) { // expertos
            let values = {
                ...actual_values,
                "sponsors": [],
                "busqueda_texto": "",
                "tipo_busqueda": 1
            }
            setActualValues(values)
            setSelectedSponsors([])
            handleClickFiltrar(values)
        }
        if (tipo_busqueda == 0) { // emprendimientos
            let values = {
                ...actual_values,
                "sponsors": [],
                "busqueda_texto": "",
                "tipo_busqueda": 0
            }
            setActualValues(values)
            setSelectedSponsors([])
            handleClickFiltrar(values)
        }
    }
    const switchContainerStyle = {
        display: 'flex',
       
        alignItems: 'center',   
        justifyContent: 'center',
        marginTop: '8px',  
      
        minHeight: 'calc(0.6rem + 1.6rem)',  
      };
      
     

    /* Botones tipo de busqueda (emprendimiento o experto)*/
    function botones_tipo_busqueda() {
        let tipo_busqueda = 0
        let icono_emprendimientos = <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
        let icono_expertos = <UnpublishedOutlinedIcon></UnpublishedOutlinedIcon>
        let onClick_emprendimientos: any = null
        let onClick_expertos: any = null
        if (actual_values["tipo_busqueda"] == 1) {
            tipo_busqueda = 1
            icono_expertos = <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
            icono_emprendimientos = <UnpublishedOutlinedIcon></UnpublishedOutlinedIcon>
            onClick_emprendimientos = () => changeSearchType(0)
        } else {
            onClick_expertos = () => changeSearchType(1)
        }
        let botones_tipo_busqueda_html = <div className='row'>
            <div className='col-12 col-lg-6'>
                <button disabled={isLoadingUserInterests || isLoadingAcciones} onClick={() => {
                    onClick_emprendimientos();
                    setEsPrimerClick(true);
                }} className={'boton-tipo-busqueda mb-2 ' + (tipo_busqueda == 0 ? "" : "boton-inactivo")}>
                   
                    <h6 className='label-boton-tipo-busqueda ml-2'>
                        <b className=''>{textHome.btn_buscar_emprendedores}</b>
                    </h6>
                </button>
            </div>
            <div className='col-12 col-lg-6'>
                <button disabled={isLoadingUserInterests || isLoadingAcciones} onClick={() => {
                    onClick_expertos();
                    setEsPrimerClick(true)
                }} className={'boton-tipo-busqueda btn-experto ' + (tipo_busqueda == 1 ? "" : "boton-inactivo")}>
                   
                    <h6 className='label-boton-tipo-busqueda ml-2'>
                        <b className=''>{textHome.btn_buscar_mentores}</b>
                    </h6>
                </button>
            </div>
        </div>
        return (botones_tipo_busqueda_html)
    }

    /* Botones tipo de busqueda */
    function selector_sponsors() {
        let selector_sponsors_html = <div></div>
        if (actual_values["tipo_busqueda"] == 1) {
            selector_sponsors_html = <div className='row no-gutters'>
                <div className='col-12'>
                    <SelectorSponsors
                        sponsors={sponsors}
                        handleSponsorClick={(event: any, sponsor: any) => handleSponsorClick(event, sponsor)}
                        selectedSponsors={selectedSponsors}
                    ></SelectorSponsors>

                </div>
            </div>
        }
        return (selector_sponsors_html)
    }

    /* busqueda de texto */
    function busqueda_texto() {
        return (
            <div className="d-flex justify-content-start align-items-center mb-2">
                <SearchOutlinedIcon className='mr-2'></SearchOutlinedIcon>
                <input value={actual_values["busqueda_texto"]} 
                onChange={handleChangeBusquedaTexto} 
                onKeyDown={SearchWithEnter}
                className="w-100" type="text" />
            </div>
        )
    }
    function busqueda_texto_2() {
        return (
            <div className="buscador-texto-container d-none d-lg-flex d-md-flex">
                <SearchOutlinedIcon className='mr-2'></SearchOutlinedIcon>
                <input value={actual_values["busqueda_texto"]} 
                onChange={handleChangeBusquedaTexto} 
                onKeyDown={SearchWithEnter}
                className="w-100 input-style" type="text" placeholder={textHome.busqueda}/>
            </div>
        )
    }
    const SearchWithEnter = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            console.log("Enter");
            handleClickFiltrar(actual_values);
        }
    };
    function handleChangeBusquedaTexto(event: any) {
        let value = event.target.value
        setActualValues({ ...actual_values, "busqueda_texto": value })
    }

    /* ACCIONES */
    const delta_cant_mostrada = 12
    const [cant_mostrada, set_cant_mostrada] = useState(delta_cant_mostrada)
    const [maximo_cant_mostrada, set_maximo_cant_mostrada] = useState(200)
    function handleClickMostrarMas() {
        if (cant_mostrada < maximo_cant_mostrada) {
            set_cant_mostrada(cant_mostrada + delta_cant_mostrada)
        }
    }

    async function handleClickFiltrar(values: any) {
        // console.log("VALES FROM handlelcickfiltrar", values)
        setIsLoadingAcciones(true)
        set_open_dialog_filtros_home_sm(false)
        dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        dispatch(deleteAlert({ alert_id: "error_filtrar" }))
        let new_alert = { id: "cargando_filtrar", type: "loading", title: textHome.titulo_5, desc: textHome.titulo_6, close: false }
        dispatch(addAlert({ alert: new_alert }))


        let maximo_cant_mostrada = 0
        let lista_id_intereses: any = []
        let intereses_actuales = values["intereses"]
        for (const key in intereses_actuales) {
            let key_aux = key;
            if (key_aux.split("-")[0] == "interes") {
                intereses_actuales[key]?.forEach((element: any) => {
                    lista_id_intereses.push(element.value)
                });
            }
        }

        let sponsors_aux: any = []
        values["sponsors"]?.forEach((sponsor_data: any) => {
            sponsors_aux.push(sponsor_data.Id)
        });
        try {
            let item = {
                "user_id": user_id,
                "interests": lista_id_intereses,
                "sponsors": sponsors_aux,
                "searchText": values["busqueda_texto"],
                "searchType": (values["tipo_busqueda"] + 1)
            }
            const res_recom: any = await postRecomUserInterest({ body: item });
            const statusCode_recom = res_recom.data.statusCode
            if (statusCode_recom == 200) {
                const responseData_recom = JSON.parse(res_recom.data.responseData)

                if (responseData_recom && Array.isArray(responseData_recom) && responseData_recom.length > 0) {
                    set_lista_resultados(responseData_recom)
                    maximo_cant_mostrada = responseData_recom.length
                } else {
                    set_lista_resultados([])
                    maximo_cant_mostrada = 0

                }
                let new_alert_2 = { type: "success", title: textHome.titulo_8, desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
                dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
            } else {
                let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
                dispatch(addAlert({ alert: new_alert_error }))
                dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
            }

        } catch (error) {
            console.log(error)
            // Alerta error intereses
            let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
            dispatch(addAlert({ alert: new_alert_error }))
            dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        }

        let cant_mostrada_inicio = 0
        if (delta_cant_mostrada > maximo_cant_mostrada) {
            cant_mostrada_inicio = maximo_cant_mostrada
        } else {
            cant_mostrada_inicio = delta_cant_mostrada
        }
        set_cant_mostrada(cant_mostrada_inicio)
        set_maximo_cant_mostrada(maximo_cant_mostrada)
        setIsLoadingAcciones(false)
        // console.log("LISTA Filtrar original", lista_resultados)
    }
    async function handleClickFiltrarMeetings(values: any) {
        // console.log("VALES FROM PARAMS")
        // setIsLoadingAcciones(true)
        // set_open_dialog_filtros_home_sm(false)
        // dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        // dispatch(deleteAlert({ alert_id: "error_filtrar" }))
        // let new_alert = { id: "cargando_filtrar", type: "loading", title: textHome.titulo_5, desc: textHome.titulo_6, close: false }
        // dispatch(addAlert({ alert: new_alert }))


        // let maximo_cant_mostrada = 0
        // let lista_id_intereses: any = []
        // let intereses_actuales = values["intereses"]
        // for (const key in intereses_actuales) {
        //     let key_aux = key;
        //     if (key_aux.split("-")[0] == "interes") {
        //         intereses_actuales[key]?.forEach((element: any) => {
        //             lista_id_intereses.push(element.value)
        //         });
        //     }
        // }

        // let sponsors_aux: any = []
        // values["sponsors"]?.forEach((sponsor_data: any) => {
        //     sponsors_aux.push(sponsor_data.Id)
        // });
        // try {
        //     let item = {
        //         "user_id": user_id,
        //         "interests": lista_id_intereses,
        //         "sponsors": sponsors_aux,
        //         "searchText": values["busqueda_texto"],
        //         "searchType": (values["tipo_busqueda"] + 1)
        //     }
        //     const res_recom: any = await postRecomUserInterest({ body: item });
        //     const statusCode_recom = res_recom.data.statusCode
        //     if (statusCode_recom == 200) {
        //         const responseData_recom = JSON.parse(res_recom.data.responseData)

        //         if (responseData_recom && Array.isArray(responseData_recom) && responseData_recom.length > 0) {
        //             set_lista_resultados(responseData_recom)
        //             maximo_cant_mostrada = responseData_recom.length
        //         } else {
        //             set_lista_resultados([])
        //             maximo_cant_mostrada = 0

        //         }
        //         let new_alert_2 = { type: "success", title: textHome.titulo_8, desc: "OK", timeout: 2000, close: true }
        //         dispatch(addAlert({ alert: new_alert_2 }))
        //         dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        //     } else {
        //         let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
        //         dispatch(addAlert({ alert: new_alert_error }))
        //         dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        //     }

        // } catch (error) {
        //     console.log(error)
        //     // Alerta error intereses
        //     let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
        //     dispatch(addAlert({ alert: new_alert_error }))
        //     dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        // }

        // let cant_mostrada_inicio = 0
        // if (delta_cant_mostrada > maximo_cant_mostrada) {
        //     cant_mostrada_inicio = maximo_cant_mostrada
        // } else {
        //     cant_mostrada_inicio = delta_cant_mostrada
        // }
        setIsLoadingAcciones(true)
        set_open_dialog_filtros_home_sm(false)
        dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        dispatch(deleteAlert({ alert_id: "error_filtrar" }))
        let new_alert = { id: "cargando_filtrar", type: "loading", title: textHome.titulo_5, desc: textHome.titulo_6, close: false }
        dispatch(addAlert({ alert: new_alert }))


        let maximo_cant_mostrada = 0
        let lista_id_intereses: any = []
        let intereses_actuales = values["intereses"]
        for (const key in intereses_actuales) {
            let key_aux = key;
            if (key_aux.split("-")[0] == "interes") {
                intereses_actuales[key]?.forEach((element: any) => {
                    lista_id_intereses.push(element.value)
                });
            }
        }

        let sponsors_aux: any = []
        values["sponsors"]?.forEach((sponsor_data: any) => {
            sponsors_aux.push(sponsor_data.Id)
        });
        try {
            let item = {
                "user_id": user_id,
                "interests": lista_id_intereses,
                "sponsors": sponsors_aux,
                "searchText": values["busqueda_texto"],
                "searchType": (values["tipo_busqueda"] + 1)
            }
            const res_recom: any = await postRecomUserInterest({ body: item });
            const statusCode_recom = res_recom.data.statusCode
            if (statusCode_recom == 200) {
                const responseData_recom = JSON.parse(res_recom.data.responseData)

                if (responseData_recom && Array.isArray(responseData_recom) && responseData_recom.length > 0) {
                    set_lista_resultados(responseData_recom)

                    maximo_cant_mostrada = responseData_recom.length
                } else {
                    set_lista_resultados([])
                    maximo_cant_mostrada = 0

                }
                let new_alert_2 = { type: "success", title: textHome.titulo_8, desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
                dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
            } else {
                let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
                dispatch(addAlert({ alert: new_alert_error }))
                dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
            }

        } catch (error) {
            console.log(error)
            // Alerta error intereses
            let new_alert_error = { id: "error_filtrar", type: "error", title: textHome.titulo_9, desc: "Error", close: true }
            dispatch(addAlert({ alert: new_alert_error }))
            dispatch(deleteAlert({ alert_id: "cargando_filtrar" }))
        }

        let cant_mostrada_inicio = 0
        if (delta_cant_mostrada > maximo_cant_mostrada) {
            cant_mostrada_inicio = maximo_cant_mostrada
        } else {
            cant_mostrada_inicio = delta_cant_mostrada
        }
        set_cant_mostrada(cant_mostrada_inicio)
        set_maximo_cant_mostrada(1)

        const res: any = await get({});
        const resData = JSON.parse(res.data.responseData)
        //console.log("RESDATA", resData)
        setidsToFilter(resData)


        //const filteredList: any[] = lista_resultados.filter((obj: any) => idsToFilter.includes(obj.id));









        // const idsToFilter = [
        //     14527, 18270, 19057, 19075
        // ];
        // const res: any = await get({});
        // // console.log("sad")
        // const resData = JSON.parse(res.data.responseData)
        // console.log("RESDATA", resData)
        // const idsToFilter = resData 
        // console.log("idsto filter", idsToFilter)
        // const filteredList: any[] = lista_resultados.filter((obj: any) => resData.includes(obj.id));
        // alert('La longitud de la lista filtrada es:' + filteredList.length);
        setImprimir(true)
        // Actualiza el estado con la nueva lista
        // set_lista_resultados(filteredList);
        // set_cant_mostrada(10)
        // set_maximo_cant_mostrada(10)

        //setIsLoadingAcciones(false)
    }

    // useEffect(() => {
    //     console.log("idsto filter updated", idsToFilter)

    // }, [idsToFilter])
    // console.log("lista_resultados",lista_resultados)
    useEffect(() => {
        if (imprimir) {
            //   console.log("Lista de valores actualizada por el botón:", lista_resultados);
            //   console.log("idsto filter", idsToFilter)

            let filteredList: any[] = []
            if (actual_values.tipo_busqueda == 1) {

                filteredList = lista_resultados.filter((obj: any) => idsToFilter.includes(obj.id));
            }
            if (actual_values.tipo_busqueda == 0) {
                filteredList = lista_resultados.filter((obj: any) => idsToFilter.includes(obj.user_id));
            }
            // console.log("Lista resultados to filter")
            // console.log(lista_resultados)
            // console.log("idstofilter", idsToFilter)
            // console.log("actual_values", actual_values)


            set_lista_resultados(filteredList);
            // set_lista_resultados(filteredList);
            // set_cant_mostrada(10)
            // set_maximo_cant_mostrada(10)

            // setIsLoadingAcciones(false)

            setImprimir(false); // Esta es una bandera para que no se vuelva a re-renderizar toda la vista dentro de l ux
            setIsLoadingAcciones(false)
        }
    }, [lista_resultados, imprimir, idsToFilter]);

    async function handleClickGuardar() {
        setIsLoadingAcciones(true)

        dispatch(deleteAlert({ alert_id: "error_signup_intereses" }))
        dispatch(deleteAlert({ alert_id: "cargando_guardar_intereses" }))
        let new_alert = { id: "cargando_guardar_intereses", type: "loading", title: textHome.titlo_5, desc: textHome.titulo_7, close: false }
        dispatch(addAlert({ alert: new_alert }))

        let lista_id_intereses: any = []

        let intereses_guardar = actual_values["intereses"]

        for (const key in intereses_guardar) {
            let key_aux = key;
            if (key_aux.split("-")[0] == "interes") {
                intereses_guardar[key]?.forEach((element: any) => {
                    lista_id_intereses.push(element.value)
                });
            }
        }
        try {
            let new_alert_2 = { type: "success", title: "Intereses guardados correctamente ", desc: "OK", timeout: 1500, close: true }
            dispatch(addAlert({ alert: new_alert_2 }))
            dispatch(deleteAlert({ alert_id: "cargando_guardar_intereses" }))

        } catch (error) {
            // Alerta error intereses
            let new_alert_error = { id: "error_signup_intereses", type: "error", title: "Error al guardados intereses", desc: "Error", close: true }
            dispatch(addAlert({ alert: new_alert_error }))
            dispatch(deleteAlert({ alert_id: "cargando_guardar_intereses" }))
        }

        setIsLoadingAcciones(false)

    }

    async function handleClickLimpiar() {
        setIsLoadingAcciones(true)
        setActualValues({ ...empty_initialState, tipo_busqueda: actual_values["tipo_busqueda"] })
        setSelectedSponsors([])

        const timer = setTimeout(() => {
            setIsLoadingAcciones(false)
        }, 1500);
        return () => clearTimeout(timer);


    }

    async function handleClickRestablecer() {
        setIsLoadingAcciones(true)
        handleReloadUserInterests()
        setIsLoadingAcciones(false)
    }


    const [open_dialog_filtros_home_sm, set_open_dialog_filtros_home_sm] = useState(false)
    function handleClickDialogFiltrosHomeSm() {
        setEsPrimerClick(true)

        set_open_dialog_filtros_home_sm(!open_dialog_filtros_home_sm)

    }

    const [open_dialog_public_profile, set_open_dialog_public_profile] = useState(false)
    const [user_id_public_profile, set_user_id_public_profile] = useState(0)
    function handleClickOpenDialogPublicProfile(user_id: any) {
        set_user_id_public_profile(user_id)
        set_open_dialog_public_profile(!open_dialog_public_profile)
    }
    function handleClickCloseDialogPublicProfile() {
        set_open_dialog_public_profile(!open_dialog_public_profile)
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => {
        if (open_dialog_filtros_home_sm) {
            setMobileVersion(true)

        } else {
            setMobileVersion(false)
        }
    }, [open_dialog_filtros_home_sm]);


    /* useEffect ================================================================================================================== */
    // useEffect(() => {
    //     if (!initialCharge) {
    //         handleReloadUserInterests()
    //         setInitialCharge(true)
    //     }
    // }, []);

    useEffect(() => {
        setInitialInterests(userInterests)
    }, [lenguajeId])

    useEffect(() => {
        handleReloadUserInterests()
    }, [user_personal_data])
    // useEffect(() => {
    //     alert("USEFFECT")
    // }, [ ])



    //  useLayoutEffect(() => {
    //     // Crear una función asíncrona dentro de useEffect
    //    // handleClickFiltrar(actual_values)
    // }, [])

    //     useEffect(() => {

    //     changeSearchType(1);
    //     changeSearchType(0);
    // }, []); 


    /* RETURN ================================================================================================================== */
    // console.log(" ACTUAL VALUES", actual_values)
    // console.log(" LISTA RESULTADOS", lista_resultados)
    const labelStyle = {
        fontWeight: 'bold',
        color: '#009EE3',
        marginRight: '10px',
        marginBottom: '5px',
        fontSize: '1rem'
    };
    const toggleFiltrar = () => {
        if (esPrimerClick) {
            handleClickFiltrarMeetings(actual_values);
        } else {
            handleClickFiltrar(actual_values);
        }
        setEsPrimerClick(!esPrimerClick);
    };
    return (
        <div className=''>
            <div className=''>
                <HomeBuscador busqueda_texto={busqueda_texto_2} botones={botones_tipo_busqueda}></HomeBuscador>
            </div>
            <div className='home-content-row row no-gutters bg-white'>
                <div className='col-12 col-lg-5 col-xl-4 d-none d-lg-none flex-column columna-filtros h-100 pt-4'>
                    <div className='padding-layout-x'> {/* Esto es toda la columna derechad, dedsde itnerés de búsqueda hacia abajo */}
                        <HomeColumnaFiltros
                            lightBg={false}
                            mostrarSponsors={false}
                            busqueda_texto={busqueda_texto}
                            selector_sponsors={selector_sponsors}
                            isLoadingAcciones={isLoadingAcciones}
                            isLoadingUserInterests={isLoadingUserInterests}
                            handleClickFiltrar={handleClickFiltrar}
                            handleClickGuardar={handleClickGuardar}
                            handleClickLimpiar={handleClickLimpiar}
                            handleClickRestablecer={handleClickRestablecer}
                            selectedSponsors={selectedSponsors}
                            handleSponsorClick={handleSponsorClick}
                            ChangeFormState={ChangeFormState}
                            actual_values={actual_values}
                            lista_resultados={lista_resultados}
                            set_lista_resultados={set_lista_resultados}
                            handleClickFiltrarMeetings={handleClickFiltrarMeetings}
                            esPrimerClick={esPrimerClick}
                            setEsPrimerClick={setEsPrimerClick}
                            mobileversion={mobileversion}
                        ></HomeColumnaFiltros>
                    </div>
                </div>
                <div className='col-12 col-lg-12 col-xl-12 '>
                    <div className='padding-layout-x  be pt-4'>
                        <div className=''>
                            {/*{botones_tipo_busqueda()}  Buscar emprendedores / expertos botones */}
                        </div>
                        <div className=''>
                            <div className='d-none d-lg-block '> {/* Filtros pantallas pequeñas */}
                                <button className='btn btn-primary btn-rounded btn-block mt-2'
                                    onClick={handleClickDialogFiltrosHomeSm}
                                >
                                {textHome.busqueda_avanzada_lg} {/* Botón filtrar, para resoluciones pequeñas*/}
                                </button>
                            </div>
                            <div className='d-block d-lg-none '> {/* Filtros pantallas pequeñas */}
                                <button className='btn btn-primary btn-rounded btn-block mt-2'
                                    onClick={handleClickDialogFiltrosHomeSm}
                                >
                                {textHome.busqueda_avanzada_sm} {/* Botón filtrar, para resoluciones pequeñas*/}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-12 col-xl-12'>
                    <div className='padding-layout-x  be pt-4'>
                        
                    </div>
                </div>
                <div className='col-12 col-lg-12 col-xl-12 '>
                    <div className='padding-layout-x  be pt-4'>
                        <HomeResultados
                            isLoading={isLoadingUserInterests || isLoadingAcciones}
                            actual_values={actual_values}
                            lista_resultados={lista_resultados}
                            cant_mostrada={cant_mostrada}
                            maximo_cant_mostrada={maximo_cant_mostrada}
                            handleClickMostrarMas={handleClickMostrarMas}
                            handleClickOpenDialogPublicProfile={handleClickOpenDialogPublicProfile}
                        >
                        </HomeResultados>
                    </div>
                </div>

            </div>


            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="lg"
                open={open_dialog_public_profile}
                onClose={handleClickCloseDialogPublicProfile}
            >
                <DialogContent className='p-0'>
                    <UserProfileExternal
                        user_id_param={user_id_public_profile}
                        handleClickCloseDialogPublicProfile={handleClickCloseDialogPublicProfile}
                    >
                    </UserProfileExternal>
                </DialogContent>
            </Dialog>


            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="lg"
                open={open_dialog_filtros_home_sm}
                onClose={handleClickDialogFiltrosHomeSm}
            >
                {/* esto es para la version mobile . borrar este comentario y apretar <= para borrar y quita esta linea*/}
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div>{textHome.title_filtros}</div>
                        <div className="cursor-pointer" onClick={handleClickDialogFiltrosHomeSm}>X</div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <HomeColumnaFiltros
                        lightBg={true}
                        mostrarSponsors={true}
                        busqueda_texto={busqueda_texto}
                        selector_sponsors={selector_sponsors}
                        isLoadingAcciones={isLoadingAcciones}
                        isLoadingUserInterests={isLoadingUserInterests}
                        handleClickFiltrar={handleClickFiltrar}
                        handleClickGuardar={handleClickGuardar}
                        handleClickLimpiar={handleClickLimpiar}
                        handleClickRestablecer={handleClickRestablecer}
                        selectedSponsors={selectedSponsors}
                        handleSponsorClick={handleSponsorClick}
                        ChangeFormState={ChangeFormState}
                        actual_values={actual_values}
                        closeModal={handleClickCloseDialogPublicProfile}
                        handleClickFiltrarMeetings={handleClickFiltrarMeetings}
                        esPrimerClick={esPrimerClick}
                        setEsPrimerClick={setEsPrimerClick}
                        mobileversion={mobileversion}
                    ></HomeColumnaFiltros>

                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Home;

