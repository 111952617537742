import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useGetUserAdminRoleMutation } from '../../redux/features/user/userApiSlice';

const HourDonation = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUserAdminRole] = useGetUserAdminRoleMutation();

  useEffect(() => {
    if (userPersonalData) {
      getUserAdminRole(userPersonalData.Id)
        .unwrap()
        .then(response => {
          const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
          setIsAdmin(isAdmin);
          if (!isAdmin) navigate('/home');
        })
        .catch(error => {
          console.error("Error fetching user role:", error);
          navigate('/home');
        });
    }
  }, [userPersonalData, navigate, getUserAdminRole]);

  if (!isAdmin) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Donación de horas
      </Typography>
      <TextField
        label="Correo Electrónico"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Seleccionar hora
      </Button>
    </Box>
  );
};

export default HourDonation;
