import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import './EventsUsers.scss';
import {
  useConfirmAssistMutation,
  useGetAllActivitiesTicketQuery,
  useCheckUserInTicketSalesEtMtuesdayQuery,
  useConfirmUserRegistrationMutation,
  useGetActiveEventsByUserQuery,
} from '../../redux/features/events/eventsApiSlice';
import ActivityDayTableUser from './ActivityDayTableUser';
import UpcomingActivitiesUser from './UpcomingActivitiesUser';
import ActivityHistory from './ActivityHistory';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { TabPanelProps } from '../../types/types';
import { addAlert } from '../../redux/features/alerts/alertsSlice';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query/react';

interface Event {
  eventId: number;
  eventName: string;
  eventDate: string;
  isSoldOut: boolean;
}

interface Ticket {
  ticketId: number | null;
  pack: number | null;
}

interface Registration {
  ticketId: number;
  eventId: number;
  eventName: string;
  eventDate: string;
  userId: number;
}

interface ErrorResponse {
  message: string;
}

interface ConfirmDialogState {
  open: boolean;
  message: string;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`events-tabpanel-${index}`}
      aria-labelledby={`events-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `events-tab-${index}`,
    'aria-controls': `events-tabpanel-${index}`,
  };
}

function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
  return error && typeof error === 'object' && 'status' in error;
}

interface ConfirmRegistrationDialogProps {
  open: boolean;
  message: string;
  onAccept: () => void;
  onCancel: () => void;
}

const ConfirmRegistrationDialog: React.FC<ConfirmRegistrationDialogProps> = ({
  open,
  message,
  onAccept,
  onCancel,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby='confirm-registration-dialog-title'
    aria-describedby='confirm-registration-dialog-description'
  >
    <DialogTitle id='confirm-registration-dialog-title'>Confirmar Inscripción</DialogTitle>
    <DialogContent>
      <Typography id='confirm-registration-dialog-description'>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color='primary'>
        Cancelar
      </Button>
      <Button onClick={onAccept} color='primary' autoFocus>
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

const EventsUsers: React.FC = () => {
  const dispatch = useDispatch();
  const user_personal_data = useSelector(selectPersonalData);
  const user_id = user_personal_data?.Id;
  const user_email = user_personal_data?.email;

  const navigate = useNavigate();
  const location = useLocation();
  const { idactividad } = useParams<{ idactividad?: string }>();

  const [value, setValue] = useState(0);

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogState>({
    open: false,
    message: '',
  });

  const [confirmRegistrationDialog, setConfirmRegistrationDialog] = useState<ConfirmDialogState>({
    open: false,
    message: '',
  });

  const [validationMessage, setValidationMessage] = useState('');

  const [userState, setUserState] = useState<1 | 2 | 3>(1);

  const [isModifying, setIsModifying] = useState<boolean>(false);

  const [previousSelectedEvents, setPreviousSelectedEvents] = useState<number[]>([]);

  const [confirmUserRegistration, { isLoading: isConfirmingRegistration }] = useConfirmUserRegistrationMutation();

  const {
    data: activitiesTicketData,
    isLoading: isLoadingActivitiesTicket,
    error: activitiesTicketError,
    refetch: refetchActivitiesTicket,
  } = useGetAllActivitiesTicketQuery(user_id ? { userId: user_id } : skipToken);

  const [confirmAssist] = useConfirmAssistMutation();

  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [totalTickets, setTotalTickets] = useState<number>(0);
  const [remainingTickets, setRemainingTickets] = useState<number>(0);

  const {
    data: activeEventsData,
    isLoading: isLoadingActiveEvents,
    error: activeEventsError,
    refetch: refetchActiveEvents,
  } = useGetActiveEventsByUserQuery(user_id ?? skipToken);

  const [activeEventIds, setActiveEventIds] = useState<number[]>([]);

  const {
    data: ticketData,
    isLoading: isLoadingTicket,
    error: ticketError,
    refetch: refetchTicket,
  } = useCheckUserInTicketSalesEtMtuesdayQuery(user_id ?? skipToken);

  const hasTickets = totalTickets > 0;

  useEffect(() => {
    if (!user_id) return;

    const mode = new URLSearchParams(location.search).get('mode');
    if (idactividad && mode === 'QR') {
    

      confirmAssist({ userId: user_id, qrCode: idactividad })
        .unwrap()
        .then((response: any) => {
        

          if (response === 'Asistencia registrada exitosamente.') {
      
            let successAlert = {
              id: `activity_confirmed_${Date.now()}`,
              type: 'success',
              title: 'Confirmación de Asistencia',
              desc: 'Asistencia registrada exitosamente.',
              close: true,
              timeout: 6000,
            };
            dispatch(addAlert({ alert: successAlert }));
            setConfirmDialog({ open: true, message: 'Asistencia registrada exitosamente.' });
          } else if (response === 'No se encontró el tipo de actividad con el QR proporcionado.') {
            console.warn('QR inválido:', response);
            let errorAlert = {
              id: `invalid_qr_${Date.now()}`,
              type: 'error',
              title: 'QR Inválido',
              desc: 'El QR proporcionado no es válido.',
              close: true,
              timeout: 6000,
            };
            dispatch(addAlert({ alert: errorAlert }));
            setConfirmDialog({ open: true, message: 'El QR proporcionado no es válido.' });
          } else if (response === 'No se encontraron actividades que coincidan con los criterios.') {
            
            let infoAlert = {
              id: `no_activities_${Date.now()}`,
              type: 'info',
              title: 'Sin Actividades',
              desc: 'No se encontraron actividades asociadas',
              close: true,
              timeout: 6000,
            };
            dispatch(addAlert({ alert: infoAlert }));
            setConfirmDialog({
              open: true,
              message: 'No se encontraron actividades asociadas',
            });
          } else {
           
            let infoAlert = {
              id: `unexpected_response_${Date.now()}`,
              type: 'info',
              title: 'Error',
              desc: response,
              close: true,
              timeout: 6000,
            };
            dispatch(addAlert({ alert: infoAlert }));
            setConfirmDialog({ open: true, message: response });
          }
        })
        .catch((error) => {
        
          let errorMessage = 'Ocurrió un error al registrar la asistencia. Por favor, intenta nuevamente.';

          if (error?.data) {
            if (typeof error.data === 'string') {
              errorMessage = error.data;
            } else if (error.data.message) {
              errorMessage = error.data.message;
            }
          }

          let errorAlert = {
            id: `activity_confirmation_error_${Date.now()}`,
            type: 'error',
            title: 'Error al Registrar Asistencia',
            desc: 'Error',
            close: true,
            timeout: 6000,
          };
          dispatch(addAlert({ alert: errorAlert }));
          setConfirmDialog({
            open: true,
            message: 'Error',
          });
        });
    }
  }, [idactividad, location.search, user_id, confirmAssist, dispatch]);

  useEffect(() => {
    if (!user_id) return;

    if (isLoadingTicket || isLoadingActivitiesTicket || isLoadingActiveEvents) {
      return;
    }

    if (ticketError) {
      let new_alert = {
        id: `ticket_verification_error_${Date.now()}`,
        type: 'error',
        title: 'Error verificando tickets',
        desc: `Error verificando tickets: ${ticketError.toString()}`,
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      setUserState(1);
      return;
    }

    if (activitiesTicketError) {
      let new_alert = {
        id: `activities_ticket_error_${Date.now()}`,
        type: 'error',
        title: 'Error cargando datos de actividades',
        desc: `Error cargando datos de actividades de tickets: ${activitiesTicketError.toString()}`,
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      return;
    }

    if (activeEventsError && isFetchBaseQueryError(activeEventsError)) {
      if (
        'data' in activeEventsError &&
        (activeEventsError.data as ErrorResponse)?.message ===
          'No se encontraron eventos activos para este usuario.'
      ) {
      } else {
        let new_alert = {
          id: `active_events_error_${Date.now()}`,
          type: 'error',
          title: 'Error cargando eventos activos',
          desc: `Error cargando eventos activos: ${activeEventsError.toString()}`,
          close: true,
          timeout: 6000,
        };
        dispatch(addAlert({ alert: new_alert }));
        return;
      }
    }

    if (activitiesTicketData?.ticket?.pack != null) {
      const pack = activitiesTicketData.ticket.pack;
      setTotalTickets(pack);

      const activeIds = activeEventsData?.map((event: any) => event.eventId) || [];
      setActiveEventIds(activeIds);
      setSelectedEvents(activeIds);

      const remaining = pack - activeIds.length;
      setRemainingTickets(remaining >= 0 ? remaining : 0);

      if (pack > 0 && activeIds.length > 0) {
        setUserState(3);
      } else if (pack > 0 && activeIds.length === 0) {
        setUserState(2);
      } else {
        setUserState(1);
      }
    } else {
      setUserState(1);
      setTotalTickets(0);
    }
  }, [
    user_id,
    isLoadingTicket,
    isLoadingActivitiesTicket,
    isLoadingActiveEvents,
    activitiesTicketData,
    activeEventsData,
    ticketError,
    activitiesTicketError,
    activeEventsError,
    dispatch,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isLoadingActivitiesTicket || isLoadingTicket || isLoadingActiveEvents) return;
    setValue(newValue);
  };

  const handleClick = (buttonId: string, tabIndex: number) => {
    if (isLoadingActivitiesTicket || isLoadingTicket || isLoadingActiveEvents) return;
    setValue(tabIndex);
  };

  const confirmRegistration = async () => {
    if (isLoadingActivitiesTicket || isLoadingTicket || isConfirmingRegistration) {
      return;
    }

    const ticket = activitiesTicketData?.ticket;
    const pack = ticket?.pack ?? 0;

    if (!user_id) {
      let new_alert = {
        id: `error_user_id_${Date.now()}`,
        type: 'error',
        title: 'Error de usuario',
        desc: 'No se encontró el ID de usuario. Por favor, inicia sesión nuevamente.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      return;
    }

    if (pack <= 0) {
      let new_alert = {
        id: `no_tickets_to_confirm_${Date.now()}`,
        type: 'warning',
        title: 'Sin Tickets Disponibles',
        desc: 'No tienes tickets disponibles para confirmar inscripción.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      setValidationMessage('No tienes tickets disponibles para confirmar inscripción.');
      return;
    }

    if (selectedEvents.length !== pack) {
      let new_alert = {
        id: `validation_error_${Date.now()}`,
        type: 'warning',
        title: 'Validación',
        desc: 'Debes seleccionar exactamente la cantidad de eventos correspondiente a tus tickets.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      return;
    }

    const newSelectedEvents = selectedEvents.filter((eventId) => !activeEventIds.includes(eventId));

    if (newSelectedEvents.length === 0) {
      let new_alert = {
        id: `no_new_events_${Date.now()}`,
        type: 'warning',
        title: 'Sin nuevos eventos',
        desc: 'No has seleccionado nuevos eventos para confirmar.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
      return;
    }

    const selectedEventsPayloadDetails =
      activitiesTicketData?.events
        ?.filter((event: Event) => selectedEvents.includes(event.eventId))
        .map((event: Event) => ({
          eventId: event.eventId,
          eventName: event.eventName,
          eventDate: event.eventDate,
        })) || [];

    const registrationPayload = {
      ticketData: {
        ticketId: ticket.ticketId,
        pack: ticket.pack,
      },
      userData: {
        id: user_id,
        email: user_personal_data.Email,
      },
      selectedEventsPayloadDetails: selectedEventsPayloadDetails,
    };

    try {
      await confirmUserRegistration(registrationPayload).unwrap();

      let new_alert = {
        id: `registration_success_${Date.now()}`,
        type: 'success',
        title: 'Inscripción confirmada',
        desc: 'Inscripción confirmada exitosamente.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));

      setUserState(3);

      setRemainingTickets(0);

      refetchActivitiesTicket();
      refetchTicket();
      refetchActiveEvents();
    } catch (error: any) {
      let new_alert = {
        id: `registration_error_${Date.now()}`,
        type: 'error',
        title: 'Error al confirmar inscripción',
        desc: error?.data?.message || 'Error al confirmar la inscripción. Por favor, inténtelo nuevamente.',
        close: true,
        timeout: 6000,
      };
      dispatch(addAlert({ alert: new_alert }));
    }
  };

  const handleConfirmRegistration = () => {
    if (isModifying) {
      setConfirmRegistrationDialog({
        open: true,
        message:
          'Al cambiar las fechas, todas las actividades agendadas hasta el momento se cancelarán. Debes considerar que podría no haber disponibilidad en las nuevas fechas. ¿Deseas continuar con este cambio?',
      });
    } else {
      confirmRegistration();
    }
  };

  const handleAcceptRegistration = () => {
    setConfirmRegistrationDialog({ open: false, message: '' });
    setIsModifying(false);
    confirmRegistration();
  };

  const handleCancelRegistration = () => {
    setConfirmRegistrationDialog({ open: false, message: '' });
    setSelectedEvents(previousSelectedEvents);
    setUserState(3);
    setIsModifying(false);
  };

  const handleCheckboxChange = (eventId: number) => {
    if (selectedEvents.includes(eventId)) {
      setSelectedEvents(selectedEvents.filter((id) => id !== eventId));
      setRemainingTickets((prev) => prev + 1);
    } else {
      if (selectedEvents.length < totalTickets) {
        setSelectedEvents([...selectedEvents, eventId]);
        setRemainingTickets((prev) => prev - 1);
      } else {
        let new_alert = {
          id: `no_tickets_left_${Date.now()}`,
          type: 'warning',
          title: 'Tickets agotados',
          desc: 'No tienes tickets restantes para seleccionar más eventos.',
          close: true,
          timeout: 6000,
        };
        dispatch(addAlert({ alert: new_alert }));
        setValidationMessage('No tienes tickets restantes para seleccionar más eventos.');
      }
    }
  };

  const handleModify = () => {
    if (isLoadingActivitiesTicket || isLoadingTicket || isLoadingActiveEvents) return;

    setPreviousSelectedEvents(selectedEvents);
    setIsModifying(true);
    setUserState(2);

    let new_alert = {
      id: `modification_info_${Date.now()}`,
      type: 'info',
      title: 'Modificar selección',
      desc: 'Puedes modificar tus selecciones de eventos.',
      close: true,
      timeout: 6000,
    };
    dispatch(addAlert({ alert: new_alert }));
  };

  const toggleDrawer = (open: boolean) => () => {
    if (!(isLoadingActivitiesTicket || isLoadingTicket || isLoadingActiveEvents)) {
      setDrawerOpen(open);
    }
  };

  const handleViewSchedules = (eventId: number) => {
    if (isLoadingActivitiesTicket || isLoadingTicket || isLoadingActiveEvents) return;
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, message: '' });
  };

  const events: Event[] = activitiesTicketData?.events ? activitiesTicketData.events : [];

  const eventsToDisplay =
    userState === 2
      ? events
      : hasTickets
      ? events.filter((event) => selectedEvents.includes(event.eventId))
      : events;

  const isLoading =
    isLoadingActivitiesTicket || isLoadingTicket || isConfirmingRegistration || isLoadingActiveEvents;

  if (!user_id) {
    return (
      <div className='loader-container'>
        <CircularProgress />
        <Typography variant='body1'>Cargando información del usuario...</Typography>
      </div>
    );
  }

  return (
    <div className='events-users-container meetings-container padding-layout-x'>
      <style>
        {`
          .menu-item.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        `}
      </style>

      <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          className='drawer-box'
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Typography variant='h6' gutterBottom>
            Menú de Eventos
          </Typography>
          <List>
            {events.map((event: Event) => (
              <ListItem
                button
                key={event.eventId}
                onClick={() => handleClick(`inscripcion-${event.eventId}`, 0)}
                disabled={isLoading}
              >
                <ListItemText
                  primary={`${event.eventName} (${moment(event.eventDate).format('DD-MM-YYYY')})`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <div className='mobile-menu d-block d-lg-none'>
        <div className='menu-row'>
          <div
            className={`menu-item ${value === 0 ? 'active' : ''} ${
              isLoading || !activitiesTicketData ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!isLoading && activitiesTicketData) {
                handleClick('inscripcion-day', 0);
              }
            }}
          >
            {`Inscripción Actividades EtMday ${new Date().getFullYear()}`}
          </div>
          <div
            className={`menu-item ${value === 1 ? 'active' : ''} ${isLoading ? 'disabled' : ''}`}
            onClick={() => {
              if (!isLoading) {
                handleClick('proximas-actividades', 1);
              }
            }}
          >
            Próximas actividades
          </div>
          <div
            className={`menu-item ${value === 2 ? 'active' : ''} ${
              isLoading || !ticketData ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!isLoading && ticketData) {
                handleClick('activity-history', 2);
              }
            }}
          >
            Historial de actividades
          </div>
        </div>
      </div>

      <Box sx={{ width: '100%' }} className='desktop-tabs d-none d-lg-block'>
        <Tabs
          value={value}
          onChange={!isLoading ? handleChange : undefined}
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab
            className='meetings-menu-itemtab'
            label={`Inscripción Actividades EtMday ${new Date().getFullYear()}`}
            {...a11yProps(0)}
            disabled={isLoading || !activitiesTicketData}
          />
          <Tab
            className='meetings-menu-itemtab'
            label='Próximas actividades'
            {...a11yProps(1)}
            disabled={isLoading}
          />
          <Tab
            className='meetings-menu-itemtab'
            label='Historial de actividades'
            {...a11yProps(2)}
            disabled={isLoading || !ticketData}
          />
        </Tabs>
      </Box>

      <Box className='tabpanels-container'>
        <TabPanel value={value} index={0}>
          {isLoading ? (
            <div className='loader-container'>
              <CircularProgress />
              <Typography variant='body1'>Cargando datos de los tickets...</Typography>
            </div>
          ) : (
            <div className='inscription-day-section'>
              <div className='header-section'>
                <h3 className='section-title'>Inscripción EtMday {new Date().getFullYear()}</h3>
                <IconButton
                  className='refresh-button'
                  onClick={
                    !isLoading
                      ? () => {
                          refetchActivitiesTicket();
                          refetchActiveEvents();
                          refetchTicket();
                        }
                      : undefined
                  }
                  aria-label='actualizar'
                  disabled={isLoading}
                >
                  <AutorenewIcon fontSize='small' />
                </IconButton>
              </div>

              <Typography variant='body1' className='instruction-text'>
                Selecciona los días en que asistirás al evento de acuerdo al Pack adquirido para elegir las
                actividades de conexión según tu interés.
                <br />
                Podrás cambiar las fechas seleccionadas de acuerdo a disponibilidad.
              </Typography>

              {hasTickets && (
                <Typography variant='body1' className='instruction-text'>
                  Tienes <strong>{remainingTickets}</strong>{' '}
                  {remainingTickets === 1 ? 'ticket disponible' : 'tickets disponibles'}.
                </Typography>
              )}

              {userState === 1 && (
                <Typography variant='body1' className='instruction-text'>
                  No posees tickets disponibles. Por favor, adquiere tus entradas para inscribirte en las actividades.
                </Typography>
              )}

              <FormGroup className='events-checkboxes'>
                {activitiesTicketData?.events.map((event: Event) => (
                  <FormControlLabel
                    key={event.eventId}
                    control={
                      <Checkbox
                        checked={selectedEvents.includes(event.eventId)}
                        onChange={() => handleCheckboxChange(event.eventId)}
                        disabled={
                          userState === 1 ||
                          event.isSoldOut ||
                          (userState === 3 && activeEventIds.includes(event.eventId)) ||
                          (hasTickets &&
                            selectedEvents.length >= totalTickets &&
                            !selectedEvents.includes(event.eventId))
                        }
                        color='primary'
                      />
                    }
                    label={`${event.eventName} (${moment(event.eventDate).format('DD-MM-YYYY')}) ${
                      event.isSoldOut ? '- Agotado' : ''
                    }`}
                  />
                ))}
              </FormGroup>

              {validationMessage && (
                <div
                  className={`validation-container ${
                    validationMessage.startsWith('Debes') ? 'warning' : 'error'
                  }`}
                >
                  {validationMessage}
                </div>
              )}

              <div className='buttons-section'>
                {userState === 1 && (
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => window.open('https://producers.passline.com/etm/', '_blank')}
                    className='buy-ticket-button'
                    disabled={isLoading}
                  >
                    ADQUIERE TU ENTRADA
                  </Button>
                )}

                {userState === 2 && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleConfirmRegistration}
                    disabled={
                      isLoading ||
                      isConfirmingRegistration ||
                      (hasTickets && selectedEvents.length !== totalTickets) ||
                      totalTickets === 0
                    }
                    className='confirm-button'
                  >
                    {isConfirmingRegistration ? 'Confirmando...' : 'CONFIRMAR INSCRIPCIÓN'}
                  </Button>
                )}

                {userState === 3 && (
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleModify}
                    disabled={isLoading}
                    className='modify-button'
                  >
                    MODIFICAR SELECCIÓN
                  </Button>
                )}
              </div>

              <div className='activities-section'>
                {eventsToDisplay.map((event: Event) => (
                  <Accordion key={event.eventId} className='activity-accordion'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-content-${event.eventId}`}
                      id={`panel-header-${event.eventId}`}
                    >
                      <Typography variant='subtitle1'>
                        {event.eventName} - {moment(event.eventDate).format('DD/MM/YYYY')}
                        {event.isSoldOut && ' - Agotado'}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ActivityDayTableUser
                        handleViewSchedules={handleViewSchedules}
                        selectedEvent={event.eventId}
                        activitiesTicketData={activitiesTicketData}
                        userState={userState}
                        pack={totalTickets}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {isLoading ? (
            <div className='loader-container'>
              <CircularProgress />
              <Typography variant='body1'>Cargando próximas actividades...</Typography>
            </div>
          ) : (
            <div className='upcoming-activities-section'>
              <div className='header-section'>
                <h3 className='section-title'>Próximas Actividades</h3>
                <IconButton
                  className='refresh-button'
                  onClick={
                    !isLoading
                      ? () => {
                          refetchActivitiesTicket();
                          refetchTicket();
                          refetchActiveEvents();
                        }
                      : undefined
                  }
                  aria-label='actualizar'
                  disabled={isLoading}
                >
                  <AutorenewIcon fontSize='small' />
                </IconButton>
              </div>
              <Typography variant='body1' className='instruction-text'>
                Aquí encontrarás una lista de las próximas actividades programadas.
              </Typography>
              <UpcomingActivitiesUser />
            </div>
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {isLoading ? (
            <div className='loader-container'>
              <CircularProgress />
              <Typography variant='body1'>Cargando historial de actividades...</Typography>
            </div>
          ) : (
            <div className='activities-history-section'>
              <div className='header-section'>
                <h3 className='section-title'>Historial de Actividades</h3>
                <IconButton
                  className='refresh-button'
                  onClick={() => {
                    refetchActivitiesTicket();
                    refetchTicket();
                    refetchActiveEvents();
                  }}
                  aria-label='actualizar'
                  disabled={isLoading}
                >
                  <AutorenewIcon fontSize='small' />
                </IconButton>
              </div>
              <Typography variant='body1' className='instruction-text'>
                Revisa las actividades que ya has realizado.
              </Typography>
              <ActivityHistory />
            </div>
          )}
        </TabPanel>
      </Box>

      <Dialog open={confirmDialog.open} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmación de Asistencia</DialogTitle>
        <DialogContent>
          <Typography>{confirmDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color='primary'>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmRegistrationDialog
        open={confirmRegistrationDialog.open}
        message={confirmRegistrationDialog.message}
        onAccept={handleAcceptRegistration}
        onCancel={handleCancelRegistration}
      />
    </div>
  );
};

export default EventsUsers;
