import React, { useState } from 'react';
import Tabla from '../../../components/Tabla/Tabla';
// new modal imports
import { useMediaQuery, useTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MeetingsDonarTiempo from '../MeetingsTiempoDonado/MeetingsDonarTiempo';
import { useGetUserDonatedTimeMutation, usePostDeleteUserDonatedTimeMutation, useDeleteZoomMeetingMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import { setUserDonatedTimes } from '../../../redux/features/meetings/meetingsSlice';
// end new modal imports
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  useGetUpcomingMeetingsMutation,
  useGetUserRolesMutation,
} from '../../../redux/features/user/userApiSlice';
import { useEffect } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { usuario_html } from '../../../utils/meetings';
import { personalData } from '../../../interfaces/dimentions';
import { useSelector } from 'react-redux';
import './MeetingsSolicitudes.scss';
import {
  selectUserSolicitudesRecibidas,
  setUserSolicitudesRecibidas,
  selectUserDonatedTimes,
  selectUserMeetings,
  selectMeetingStatus
} from '../../../redux/features/meetings/meetingsSlice';
import {
  useGetUserSolicitudesRecibidasMutation,
  usePostUserRespuestaSolicitudRecibidaMutation,
  usePostCreateZoomMeetingMutation,
  useGetUserMeetingRequestTimesMutation,
  usePostCreateTimeRequest2Mutation,
} from '../../../redux/features/meetings/meetingsApiSlice';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { changeDateTimeZone, changeDonatedTimeZone } from '../../../utils/utilsForms';
import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import moment from 'moment';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import {
  selectPersonalData,
  selectUserNotifications,
  setUserNotifications,
} from '../../../redux/features/user/userSlice';
import { useGetUserNotificationsMutation } from '../../../redux/features/user/userApiSlice';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { addNotificationTime, selectNotificationMeeting } from '../../../redux/features/notifications/notificationsSlice'

interface UserRequest {
  Id: number;
  UserRequestId: number;
  UserRequestLastName: string;
  UserRequestName: string;
  UserRequestedId: number;
  UserRequestedLastName: string;
  UserRequestedName: string;
}




function MeetingSolicitudesTiempo({ handleClickDialogDetalle }: any) {

  // NEW MODAL ELEMENTS
  const [directId, setDirectId] = useState<number | null>(null); // Este id se busca pasar para la donación directa.
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [openDonarTiempo, setOpenDonarTiempo] = React.useState(false);
  const [getUserDonatedTime, { isLoadingGetUserDonatedTime }]: any = useGetUserDonatedTimeMutation()
  const handleClickOpenDonarTiempo = () => {
    setOpenDonarTiempo(true);
  };

  const handleCloseDonarTiempo = () => {
    setOpenDonarTiempo(false);
  };


  const [pageSize, setPageSize] = useState<number>(10); // Añadir estado para el tamaño de página
  const [page, setPage] = useState<number>(0); // Añadir estado para la página actual


  const handleBooleanFromChild = async (childState: any) => {
    if (childState) {
      // Donacion directa, se busca eliminar la fila
      const const2 = await deleTimeRequest({ user_id: rowtoDelete });
      handleReload()

    } else {

    }
  };

  function reloadDonatedTimes() {
    const fetch = async () => {
      const res: any = await getUserDonatedTime({ user_id: user_id });
      const resData = JSON.parse(res.data.responseData)
      dispatch(setUserDonatedTimes({ user_donated_times: resData }))

    }
    fetch()
  }


  const actual_time_zone = GetActualTimeZone();
  const donatedTime: any = useSelector(selectUserMeetings);
  // const [Estado, setEstadoSol]: any = useState('Solicitudddd de tiempo. Recuerda donar horaaaas.')

  const user_personal_data: personalData = useSelector(selectPersonalData);
  let user_id = user_personal_data.Id;
  const [deleTimeRequest, { IsLoad2 }]: any = usePostCreateTimeRequest2Mutation();
  const [timeRequests, { isLoad }]: any = useGetUserMeetingRequestTimesMutation()

  const user_solicitudes_recibidas: any = useSelector(
    selectUserSolicitudesRecibidas
  );
  const dispatch = useDispatch();
  const notification = useSelector(selectUserNotifications)
  //Modal confirmacion para borra
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);
  const handleOpenConfirmDelete = (row: any) => {
    setSelectedRowToDelete(row);
    setOpenConfirmDelete(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRowToDelete) {
      handleEliminarClick(selectedRowToDelete);
      setOpenConfirmDelete(false);
    }
  };
  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };



  //Final modal confirmación para borrar
  //const notification = useSelector(selectNotificationMeeting)
  //  console.log(notification)
  // const = notificationTime = dispatch(AddNotificationTime)
  const [isLoading, setIsLoading] = useState(false);
  const textMeetings = useSelector(selectCurrentDictonary).meetings; //Accede al diccionario
  const [idGuardando, setIdGuardando] = useState<number | null>(null);
  const [counter, setCounter] = useState(0);
  const [rowtoDelete, setRowtoDelete] = useState<number | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [notifications, setNotifications] = useState([]);
  const notificaciones: any = useSelector(selectUserNotifications);
  const [getSol] = useGetUserNotificationsMutation();
  useEffect(() => {
    if (user_id) {
      handleReload();
    }
  }, [user_personal_data]);

  async function handleReload() {
    setIsLoading(true)
    const res: any = await getSol({ user_id: user_id });
    const resData = JSON.parse(res.data.responseData);
    const resTime: any = await timeRequests({ user_id: user_id })
    const resData2 = JSON.parse(resTime.data.responseData)
    const notificationTime = dispatch(addNotificationTime(resData2))
    setData(resData2) ///// nos sirve data o resdata2
    //console.log(resData2)

    dispatch(addNotificationTime(data))
    setIsLoading(false)
    // console.log(deleTimeRequest())
    dispatch(setUserNotifications({ user_notifications: resData.reverse() }));
    setNotifications(resData);
    const unreadNotifications = resData.filter(
      (notification: any) => !notification.ReadedByUser
    );
    setCounter(unreadNotifications.length);

  }
  const handleEliminarClick = async (row: any) => {
    const { id, UserRequestedCompleteName } = row;


    //console.log("ID:", id);
    //console.log("UserRequestedCompleteName:", UserRequestedCompleteName);
    const const2 = await deleTimeRequest({ user_id: id });
    handleReload()
    //await deleTimeRequest(id);
  };


  const uniqueUserRequestIds = Array.from(new Set(data.map(item => item.UserRequestId)));

  // console.log(uniqueUserRequestIds)  


  // console.log(notificaciones)
  let datos = [];



  if (data) {
    // console.log(data);
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.DonatedTime == 4) {
        continue;  // Si DonatedTime es 4, salta al siguiente elemento del bucle
      }

      // Si DonatedTime no es 4, ejecuta el siguiente código
      const Estado = textMeetings.msj_tabla_2;
      datos.push({
        CreatedAt: element.CreatedAt,
        id: element.Id,
        MeetingReasonDescription: element.MeetingReasonDescription,
        UserRequestId: element.UserRequestId,
        UserRequestLastName: element.UserRequestLastName,
        UserRequestName: element.UserRequestName,
        UserRequestedId: element.UserRequestedId,
        UserRequestedLastName: element.UserRequestedLastName,
        UserRequestedName: element.UserRequestedName,
        UserRequestProfilePicture: element.UserRequestProfilePicture,
        UserRequestedCompleteName: `${element.UserRequestName} ${element.UserRequestLastName}`,
        Estado: Estado,
        donatedTime: element.donatedTime,

        UserRequestRoles: element.UserRequestRoles,
      });
    }
  }

  let columnas: GridColDef[] = [
    {
      field: 'UserRequestedCompleteName',
      headerName: textMeetings.table_1.header_3,
      flex: 1,
      minWidth: 130,
      renderCell: (cellvalues) => {
        // return <div>{params.row.UserRequestedCompleteName}</div>;
        let row = cellvalues.row
        let user_id = row.UserRequestId
        let nombre = row.UserRequestName
        let apellido = row.UserRequestLastName
        let url_foto = row.UserRequestProfilePicture
        let roles = row.UserRequestRoles
        return (

          <div className='d-flex flex justify-content-center align-items-center w-100'>
            {usuario_html(user_id, nombre, apellido, url_foto, roles)}
          </div>
        )
      },
    },
    {
      field: 'CreatedAt',
      headerName: textMeetings.table_1.header_1,
      flex: 1,
      minWidth: 130,
      renderCell: (params: any) => {
        const fecha = moment(params.value).format('DD - MM - YYYY');
        const hora = moment(params.value).format('HH:mm');
        return (
          <div style={{ textAlign: 'center' }}>
            <div>{fecha}</div>
            <div>{hora}</div>
          </div>
        );
      },
    }
    
    ,
    {
      field: 'Estado',
      headerName: textMeetings.table_1.header_5,
      flex: 1,
      minWidth: 250,
      // renderCell: (params: { row: { RequestStatusId: number } }) => {
      //   const RequestStatusId = params.row.RequestStatusId;
      //   let request_status = textMeetings.alerta_1;
      //   let request_status_html_class = '';

      //   if (RequestStatusId == 2) {
      //     request_status = textMeetings.alerta_2;
      //     request_status_html_class = 'text-success';
      //   }
      //   if (RequestStatusId == 1) {
      //     request_status = textMeetings.alerta_3;
      //     request_status_html_class = 'text-danger';
      //   }

      //   return <div className={request_status_html_class}>{request_status}</div>;
      // },
    },
    {
      field: 'Opciones',
      headerName: textMeetings.table_1.header_6,
      flex: 2,
      minWidth: 150,
      maxWidth:200,
      sortable: false,
      renderCell: (params: { row: { id: number, UserRequestId: number } }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
            <button
              className="btn btn-primary flex-grow-1 p-1"
              onClick={() => handleVerDetalles(params.row)} // Cambio aquí para pasar la fila completa  clase previa: btn-outline-dark
            >
              {textMeetings.modal_4_detalles}
            </button>
            <button
              className="btn btn-primary flex-grow-1 p-1"
              onClick={() => {
                handleClickOpenDonarTiempo();
                setDirectId(params.row.UserRequestId);
                setRowtoDelete(params.row.id);
                //console.log("direct id from buttom", directId) //BORAR

              }

              }// Cambio aquí para pasar la fila completa  clase previa: btn-outline-dark onClick={handleClickOpenDonarTiempo}
            >
              {textMeetings.modal_6_donacion_directa}
            </button>
            <button
              className="btn btn-danger flex-grow-1 p-1"
              onClick={() => handleOpenConfirmDelete(params.row)} // Cambio aquí para pasar la fila completa  clase previa: btn-outline-dark
            > {textMeetings.modal_5_borrar}
            </button>


          </div>
        );
      },
    },
  ];

  // Estado para controlar la apertura/cierre del modal
  const [openModal, setOpenModal] = useState(false);
  // Estado para almacenar los detalles de la solicitud seleccionada
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  // Función para abrir el modal y mostrar los detalles de la solicitud
  const handleVerDetalles = (rowData: any) => {
    setSelectedRowData(rowData);
    setOpenModal(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setSelectedRowData(null);
    setOpenModal(false);
  };

  let table_html = (
    <div className='table-container'>
      <Tabla 
  columnas={columnas} 
  datos={datos} 
  pageSize={pageSize}        // Añadir propiedad pageSize
  setPageSize={setPageSize}  // Añadir propiedad setPageSize
  page={page}                // Añadir propiedad page
  onPageChange={setPage}     // Añadir propiedad onPageChange
/>

    </div>
  );



  return (
    <div className='row'>
      <div className='col-12 mb-4'>
        <div className='d-flex justify-content-start align-items-center mb-2'>
          <h3 className='mb-0 mr-2 font-italic'>
            <b>{textMeetings.titulo_8}</b>
          </h3>
          <div className="btn-refresh mt-1" onClick={handleReload}>
            <AutorenewIcon fontSize='small' />
          </div>
        </div>
        <p>{textMeetings.desc_3_2}</p>
      </div>
      <div className='col-12 mb-4'>
        <div className="table-container">
          {table_html}
        </div>
      </div>

      {/* Modal para mostrar los detalles */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{textMeetings.modal_5_titulo}</DialogTitle>
        <DialogContent>
          {selectedRowData && (
            <div>
              <p>{textMeetings.modal_1_nombre} {selectedRowData.UserRequestedCompleteName}</p>
              <p>{textMeetings.modal_2_motivo} {selectedRowData.MeetingReasonDescription}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {textMeetings.modal_3_cerrar}
          </Button>
        </DialogActions>
      </Dialog>

      <div className='col-12'>
        {/* MODAL (DIALOG) */}
        <Dialog
          fullWidth
          fullScreen={fullScreen}
          maxWidth="md"
          open={openDonarTiempo}
          onClose={handleCloseDonarTiempo}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDonarTiempo}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, minWidth: 120 }} variant="h6" component="div">
                {textMeetings.btn_donar}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className='my-5'>
            <MeetingsDonarTiempo reloadDonatedTimes={reloadDonatedTimes} handleCloseDonarTiempo={handleCloseDonarTiempo} directId={directId} onBooleanChange={handleBooleanFromChild}></MeetingsDonarTiempo>
          </div>
        </Dialog>
      </div>
      <Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
        <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
        <DialogContent>
          <p>{textMeetings.confirmacion_eliminar}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmed} color="primary">
            {textMeetings.positivo}
          </Button>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            {textMeetings.negativo}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}
export default MeetingSolicitudesTiempo;