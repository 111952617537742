import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import {
  useGetActivityAvailableTimesMenteeAllQuery,
  useAssignMenteeToBlockMutation,
  useAssignMenteeToContactWheelMutation,
} from '../../redux/features/events/eventsApiSlice';
import ActivityCards from '../../components/ActivityCards/ActivityCards';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import './ActivityDayTableUser.scss';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { Activity, ActivityDayTableUserProps, Block, Mentor } from '../../types/types';

const ActivityDayTableUser: React.FC<ActivityDayTableUserProps> = ({
  selectedEvent,
  activitiesTicketData,
  handleViewSchedules,
  userState,
  pack
}) => {

  const dispatch = useDispatch();
  const userPersonalData = useSelector(selectPersonalData);

  const userId = userPersonalData?.Id;
  const userEmail = userPersonalData?.Email;


  useEffect(() => {
    if (!userEmail) {
      console.warn("El correo electrónico del usuario no está disponible.");

    }
  }, [userEmail]);

  const [tableData, setTableData] = useState<Activity[]>([]);
  const [filteredData, setFilteredData] = useState<Activity[]>([]);


  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [selectedTheme, setSelectedTheme] = useState<string>('');
  const [selectedStartTime, setSelectedStartTime] = useState<string>('');
  const [selectedEndTime, setSelectedEndTime] = useState<string>('');
  const [selectedMentor, setSelectedMentor] = useState<string>('');

  const [open, setOpen] = useState(false);
  const [activityId, setActivityId] = useState<number | null>(null);
  const [schedules, setSchedules] = useState<Block[]>([]);
  const [assigningBlockId, setAssigningBlockId] = useState<number | null>(null);

  const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(true);

  const { data: schedulesData, error: schedulesError, refetch: refetchSchedules, isFetching: isFetchingSchedules } = useGetActivityAvailableTimesMenteeAllQuery(
    { activityId: activityId ?? -1, user_id: userId ?? -1 },
    {
      skip: activityId === null || userId === null,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {

  }, [schedulesData]);

  const [assignMenteeToBlock] = useAssignMenteeToBlockMutation();
  const [assignMenteeToContactWheel] = useAssignMenteeToContactWheelMutation();

  useEffect(() => {
    if (schedulesData) {
      const availableBlocks = schedulesData;
      setSchedules(availableBlocks);
      setIsLoadingSchedules(false);
    } else if (schedulesError) {
      setIsLoadingSchedules(false);
      let errorMessage = 'Error al cargar los horarios disponibles.';
      if ('status' in schedulesError) {
        errorMessage += ` Código de error: ${schedulesError.status}`;
      }
      let new_alert = {
        id: `error_fetching_schedules_${Date.now()}`,
        type: "error",
        title: "Error al Cargar Horarios",
        desc: errorMessage,
        close: true,
        timeout: 4000
      };
      dispatch(addAlert({ alert: new_alert }));
    } else {
      setIsLoadingSchedules(true);
    }
  }, [schedulesData, schedulesError, dispatch]);

  const handleCloseModal = () => {
    setOpen(false);
    setActivityId(null);
  };

  useEffect(() => {
    if (activitiesTicketData?.activities) {
      const filteredByEvent = activitiesTicketData.activities
        .filter((activity: Activity) => activity.eventId === selectedEvent);

      setTableData(filteredByEvent);
      filterData(filteredByEvent);
    }
  }, [activitiesTicketData, selectedEvent, selectedActivity, selectedTheme, selectedStartTime, selectedEndTime, selectedMentor]);

  const filterData = (data: Activity[]) => {
    let filtered = data;

    if (selectedActivity) {
      filtered = filtered.filter((item: Activity) => item.type === selectedActivity);
    }
    if (selectedTheme) {
      filtered = filtered.filter((item: Activity) => item.activityName === selectedTheme);
    }
    if (selectedMentor) {
      filtered = filtered.filter((item: Activity) =>
        item.mentors && item.mentors.some((mentor: Mentor) => `${mentor.name} ${mentor.lastName}` === selectedMentor)
      );
    }

    if (selectedStartTime || selectedEndTime) {
      let filterStart = selectedStartTime ? moment(selectedStartTime, "HH:mm") : null;
      let filterEnd = selectedEndTime ? moment(selectedEndTime, "HH:mm") : null;

      if (filterStart && filterEnd && filterEnd.isBefore(filterStart)) {
        const temp = filterStart.clone();
        filterStart = filterEnd.clone();
        filterEnd = temp.clone();
      }

      filtered = filtered.filter((item: Activity) => {
        const activityStart = moment(item.startTime, "HH:mm");
        const activityEnd = moment(item.endTime, "HH:mm");

        let isStartInRange = true;
        let isEndInRange = true;

        if (filterStart) {
          isStartInRange = activityStart.isSameOrAfter(filterStart);
        }

        if (filterEnd) {
          isEndInRange = activityEnd.isSameOrBefore(filterEnd);
        }

        return isStartInRange && isEndInRange;
      });
    }
    setFilteredData(filtered);
  };

  const handleChangeFilter = (
    setFilter: React.Dispatch<React.SetStateAction<string>>
  ) => (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
      const value = event.target.value;
      setFilter(value);
    };

  const getFilteredOptions = (key: keyof Activity, data: Activity[]) => {
    return Array.from(new Set(data.map(item => item[key])))
      .filter(option => option !== null && option !== '') as string[];
  };

  const getUniqueMentors = (data: Activity[]) => {
    const mentors = data.flatMap(activity => {

      if (activity.mentors && Array.isArray(activity.mentors)) {
        return activity.mentors.map((mentor: any) => {

          if (typeof mentor === 'object' && mentor.name && mentor.lastName) {
            return `${capitalizeWords(mentor.name)} ${capitalizeWords(mentor.lastName)}`;
          }

          return mentor;
        });
      }
      return [];
    });

    return Array.from(new Set(mentors))
      .filter(mentor => mentor !== 'N/A' && mentor.trim() !== '')
      .sort((a, b) => a.localeCompare(b));
  };

  const handleInscriptionClick = (activity: Activity) => {
    handleViewSchedules(activity.activityId);
    setActivityId(activity.activityId);
    setOpen(true);
  };

  const handleAssignMentee = async (blockId: number) => {

    setAssigningBlockId(blockId);
    let new_alert = {
      id: `assigning_mentee_${Date.now()}`,
      type: "info",
      title: "Registrando bloque de actividad",
      desc: "Por favor, espera mientras registramos tu bloque de actividad.",
      close: false,
      timeout: 0
    };

    dispatch(addAlert({ alert: new_alert }));

    try {
      await assignMenteeToBlock({ menteeId: userId, blockId }).unwrap();
      dispatch(deleteAlert({ alert_id: new_alert.id }));
      let new_alert2 = {
        id: `mentee_assigned_${Date.now()}`,
        type: "success",
        title: "Bloque Registrado",
        desc: "El bloque de la actividad ha sido registrado con éxito.",
        close: true,
        timeout: 2000
      };
      dispatch(addAlert({ alert: new_alert2 }));
      refetchSchedules();
      setOpen(false);
    } catch (error: any) {
      console.error("Error al asignar mentee:", error);
      dispatch(deleteAlert({ alert_id: new_alert.id }));

      let new_alert2;
      if (error.status === 409) {
        new_alert2 = {
          id: `assign_mentee_conflict_${Date.now()}`,
          type: "error",
          title: "Conflicto de Horarios",
          desc: "Ya cuentas con una actividad en este horario. Por favor, revisa tus próximas actividades.",
          close: true,
          timeout: 4000
        };
      } else {
        new_alert2 = {
          id: `assign_mentee_error_${Date.now()}`,
          type: "error",
          title: "Error al Registrar el Bloque",
          desc: "Hubo un error al registrar el bloque. Por favor, intenta nuevamente.",
          close: true,
          timeout: 4000
        };
      }

      dispatch(addAlert({ alert: new_alert2 }));
    } finally {
      setAssigningBlockId(null);
    }
  };

  const handleAssignContactWheel = async (activityId: number) => {

    setAssigningBlockId(activityId);
    let new_alert = {
      id: `assigning_contact_wheel_${Date.now()}`,
      type: "info",
      title: "Registrando inscripción",
      desc: "Por favor, espera mientras registramos tu inscripción a la Rueda de Contactos.",
      close: false,
      timeout: 0
    };

    dispatch(addAlert({ alert: new_alert }));




    try {
      await assignMenteeToContactWheel({
        email: userEmail!,
        activityId: activityId,
      }).unwrap();

      dispatch(deleteAlert({ alert_id: new_alert.id }));
      let success_alert = {
        id: `contact_wheel_assigned_${Date.now()}`,
        type: "success",
        title: "Inscripción Exitosa",
        desc: "Has sido inscrito con éxito en la Rueda de Contactos.",
        close: true,
        timeout: 2000
      };
      dispatch(addAlert({ alert: success_alert }));
      refetchSchedules();
      setOpen(false);
    } catch (error: any) {
      console.error("Error al asignar mentee a la Rueda de Contactos:", error);
      dispatch(deleteAlert({ alert_id: new_alert.id }));

      let error_alert;
      if (error.status === 409) {
        error_alert = {
          id: `assign_contact_wheel_conflict_${Date.now()}`,
          type: "error",
          title: "Conflicto de Horarios",
          desc: "Ya cuentas con una actividad en este horario.",
          close: true,
          timeout: 4000
        };
      } else {
        error_alert = {
          id: `assign_contact_wheel_error_${Date.now()}`,
          type: "error",
          title: "Error al Registrar",
          desc: "Hubo un error al intentar registrar tu inscripción en la Rueda de Contactos. Intenta nuevamente.",
          close: true,
          timeout: 4000
        };
      }

      dispatch(addAlert({ alert: error_alert }));
    } finally {
      setAssigningBlockId(null);
    }
  };


  const capitalizeWords = (str?: string | null): string => {
    if (!str) return "N/A";
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const canRegisterForBlock = (block: any) => {
    return userState === 3 && !(block.statusId === 3 || block.statusId === 5);
  };

  const generateActionButtons = (item: any): ActionButtonProps<any>[] => {




    if (userState !== 3) {
      return [];
    }


    if (item.activityTypeId === 6) {
      return [
        {
          label: 'Inscribirse',
          color: 'primary',
          onClick: () => handleAssignContactWheel(item.activityId),
          isLoading: assigningBlockId === item.activityId,
          disabled: !canRegisterForBlock(item),
        },
      ];
    }


    return [
      {
        label: 'Inscribirse',
        color: 'primary',
        onClick: () => handleAssignMentee(item.blockId),
        isLoading: assigningBlockId === item.blockId,
        disabled: !canRegisterForBlock(item),
      },
    ];
  };


  const mentorLabel = useMemo(() => {
    if (schedules.length === 0) return "Mentor(es)";
    const allSingleMentor = schedules.every(block => block.mentors.length === 1);
    return allSingleMentor ? "Mentor" : "Mentor(es)";
  }, [schedules]);


  const dialogColumns: string[] = ["Rango Horario", "Duración", mentorLabel, "Industrias"];

  const prepareDataForDialog = () => {


    return (schedules as any[]).map((block: any) => {




      const mentorCount = block.mentors ? block.mentors.length : 0;

      let mentorField: string[] = ['N/A'];
      if (mentorCount > 0) {
        mentorField = block.mentors
          .filter((mentor: any) => mentor !== null && mentor !== undefined)
          .map((mentor: any) => `${capitalizeWords(mentor.name)} ${capitalizeWords(mentor.lastName)}`)
          .filter((name: string) => name !== 'N/A N/A' && name.trim() !== '');
        if (mentorField.length === 0) {
          mentorField = ['N/A'];
        }
      }

      const industries = mentorCount > 0
        ? (Array.from(new Set(block.mentors
          .filter((mentor: any) => mentor !== null && mentor !== undefined && Boolean(mentor.industries))
          .flatMap((mentor: any) => mentor.industries || []))) as string[])
          .map(ind => capitalizeWords(ind))
        : 'N/A';


      const item = {
        id: block.id,
        "Rango Horario": `${block.startTime.slice(11, 16)} - ${block.endTime.slice(11, 16)}`,
        "Duración": `${block.duration} minutos`,
        [mentorLabel]: mentorField.length > 0 ? mentorField : 'N/A',
        "Industrias": industries || 'N/A',
        blockId: block.id,
        activityTypeId: block.activityTypeId,
        activityId: block.activityId,
        actionButtons: [] as ActionButtonProps<any>[],
      };

      item.actionButtons = generateActionButtons(item);
      return item;
    });
  };

  const dialogData = prepareDataForDialog();

  return (
    <div className='activity-day-table-user-container'>
      <Box sx={{ marginBottom: 2, display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
        <FormControl variant="outlined" size="small" className="common-form-control">
          <Select
            value={selectedActivity}
            onChange={handleChangeFilter(setSelectedActivity)}
            displayEmpty
            renderValue={(value) => (value === '' ? 'Actividad' : (value as string))}
            inputProps={{ 'aria-label': 'Actividad' }}
          >
            <MenuItem value="">
              <em>Todas las actividades</em>
            </MenuItem>
            {getFilteredOptions('type', tableData)
              .sort((a, b) => a.localeCompare(b))
              .map((activityType, index) => (
                <MenuItem key={index} value={activityType}>{activityType}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" className="common-form-control">
          <Select
            value={selectedTheme}
            onChange={handleChangeFilter(setSelectedTheme)}
            displayEmpty
            renderValue={(value) => (value === '' ? 'Temática' : (value as string))}
            inputProps={{ 'aria-label': 'Temática' }}
          >
            <MenuItem value="">
              <em>Todas las temáticas</em>
            </MenuItem>
            {getFilteredOptions('activityName', tableData)
              .sort((a, b) => a.localeCompare(b))
              .map((activityName, index) => (
                <MenuItem key={index} value={activityName}>{activityName}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" className="common-form-control">
          <Select
            value={selectedStartTime}
            onChange={handleChangeFilter(setSelectedStartTime)}
            displayEmpty
            renderValue={(value) => value === '' ? 'Inicio Hora' : (value as string)}
            inputProps={{ 'aria-label': 'Inicio Hora' }}
          >
            <MenuItem value="">
              <em>Selecciona Inicio Hora</em>
            </MenuItem>
            {Array.from(new Set(filteredData.map(activity => moment(activity.startTime, "HH:mm").format("HH:mm"))))
              .sort((a, b) => moment(a, "HH:mm").diff(moment(b, "HH:mm")))
              .map((time, index) => (
                <MenuItem key={index} value={time}>{time}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" className="common-form-control">
          <Select
            value={selectedEndTime}
            onChange={handleChangeFilter(setSelectedEndTime)}
            displayEmpty
            renderValue={(value) => value === '' ? 'Fin Hora' : (value as string)}
            inputProps={{ 'aria-label': 'Fin Hora' }}
          >
            <MenuItem value="">
              <em>Selecciona Fin Hora</em>
            </MenuItem>
            {Array.from(new Set(filteredData.map(activity => moment(activity.endTime, "HH:mm").format("HH:mm"))))
              .sort((a, b) => moment(a, "HH:mm").diff(moment(b, "HH:mm")))
              .map((time, index) => (
                <MenuItem key={index} value={time}>{time}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" className="common-form-control">
          <Select
            value={selectedMentor}
            onChange={handleChangeFilter(setSelectedMentor)}
            displayEmpty
            renderValue={(value) => (value === '' ? 'Experto' : (value as string))}
            inputProps={{ 'aria-label': 'Experto' }}
          >
            <MenuItem value="">
              <em>Todos los expertos</em>
            </MenuItem>
            {getUniqueMentors(tableData).map((mentor, index) => (
              <MenuItem key={index} value={mentor}>{mentor}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ActivityCards
        datos={filteredData}
        handleEditClick={handleInscriptionClick}
        isConfirmed={userState === 3}
      />

      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth="xs"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '90vw',
            maxWidth: 'none',
          },
        }}
      >
        <DialogTitle>Horarios y Bloques Disponibles</DialogTitle>
        <DialogContent>
          {isFetchingSchedules ? (
            <div className="loader-container">
              <CircularProgress size={50} aria-label="Cargando horarios disponibles..." />
              <Typography variant="body1">Cargando horarios disponibles...</Typography>
            </div>
          ) : (
            schedules.length === 0 ? (
              <Typography variant="body1">Actualmente, no hay horarios disponibles</Typography>
            ) : (
              <GenericCardList
                columns={dialogColumns}
                data={dialogData}
                isLoading={assigningBlockId !== null}
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default ActivityDayTableUser;
