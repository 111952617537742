import { createSlice } from "@reduxjs/toolkit"

import moment from 'moment';

const initialState = {
    languages: [],
    countries: [],
    regions: [],
    cities: [],
    tags: [],
    roles: [],
    sponsors: [],
    job_titles: [],
    interests_types: [],
    interests: [
        // type = 1: multiSelect
        // type = 2: singleSelect
        // typeStartup: usado dentro de la creacion de startups
        // typeSelector: usado dentro de los filtros/intereses
        { Id: 9, Value: "Industrias", Order: 0, TypeStartup: 1, TypeSelector: 2, Options: [] },
        { Id: 4, Value: "Países", Order: 1, TypeStartup: 1, TypeSelector: 1, Options: [] },
        { Id: 7, Value: "Nivel de formalización", Order: 2, TypeStartup: 1, TypeSelector: 1, Options: [] },
        { Id: 1, Value: "Rangos de facturación", Order: 3, TypeStartup: 1, TypeSelector: 1, Options: [] },
        { Id: 15, Value: "Número de empleados", Order: 4, TypeStartup: 2, TypeSelector: 1, Options: [] },
        { Id: 10, Value: "Niveles de inversión", Order: 5, TypeStartup: 2, TypeSelector: 1, Options: [] },
        { Id: 14, Value: "Políticas de liderazgo de mujeres", Order: 6, TypeStartup: 2, TypeSelector: 1, Options: [] },
        { Id: 8, Value: "Políticas de igualdad de género", Order: 7, TypeStartup: 2, TypeSelector: 1, Options: [] },
        { Id: 5, Value: "Políticas de diversidad e inclusión", Order: 8, TypeStartup: 2, TypeSelector: 1, Options: [] },
    ],
    universities: [],
    document_types: [],
    macro_industries: [],
    industries: [],
    companies: [],
    degrees: [],
}

const dimensionsSlice = createSlice({
    name: 'dimensions',
    initialState: initialState,
    reducers: {
        setCountries: (state, action) => {
            const { countries } = action.payload
            state.countries = countries
        },
        setTags: (state, action) => {
            const { tags } = action.payload
            state.tags = tags
        },
        setLanguages: (state, action) => {
            const { languages } = action.payload
            state.languages = languages
        },
        setRoles: (state, action) => {
            const { roles } = action.payload
            state.roles = roles
        },
        setSponsors: (state, action) => {
            const { sponsors } = action.payload
            state.sponsors = sponsors
        },
        setJobTitles: (state, action) => {
            const { job_titles } = action.payload
            state.job_titles = job_titles
        },
        setInterests: (state, action) => {
            const { interests } = action.payload
            state.interests = interests
        },
        setUniversities: (state, action) => {
            const { universities } = action.payload
            state.universities = universities
        },
        setDocumentTypes: (state, action) => {
            const { document_types } = action.payload
            state.document_types = document_types
        },
        setMacroIndustries: (state, action) => {
            const { macro_industries } = action.payload
            state.macro_industries = macro_industries
        },
        setIndustries: (state, action) => {
            const { industries } = action.payload
            state.industries = industries
        },
        setCompanies: (state, action) => {
            const { companies } = action.payload
            state.companies = companies
        },
        setDegrees: (state, action) => {
            const { degrees } = action.payload
            state.degrees = degrees
        },
    },
})

export const {
    setCountries,
    setTags,
    setLanguages,
    setRoles,
    setSponsors,
    setJobTitles,
    setInterests,
    setUniversities,
    setDocumentTypes,
    setMacroIndustries,
    setIndustries,
    setCompanies,
    setDegrees
} = dimensionsSlice.actions

export default dimensionsSlice.reducer

export const selectCountries = (state: any) => state.dimensions.countries
export const selectLanguages = (state: any) => state.dimensions.languages

export const selectTags = (state: any) => state.dimensions.tags
export const selectRoles = (state: any) => state.dimensions.roles
export const selectSponsors = (state: any) => state.dimensions.sponsors

export const selectDocumentTypes = (state: any) => state.dimensions.document_types
export const selectJobTitles = (state: any) => state.dimensions.job_titles
export const selectDegress = (state: any) => state.dimensions.degrees
export const selectUniversities = (state: any) => state.dimensions.universities

export const selectInterests = (state: any) => state.dimensions.interests

export const selectMacroIndustries = (state: any) => state.dimensions.macro_industries
export const selectIndustries = (state: any) => state.dimensions.industries
export const selectCompanies = (state: any) => state.dimensions.companies
