import { Link, useNavigate } from 'react-router-dom';
import './TarjetaEmprendimiento.scss'
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { Dialog, DialogContent, DialogTitle, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useGetUserDonatedTimeMutation } from '../../redux/features/meetings/meetingsApiSlice';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Button, Rating} from '@mui/material';
import { DialogTiempoReunion } from '../../pages/UserProfileExternal/DialogTiempoReunion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { capitalizeFirstLetter, getCharLimit } from '../../utils/utilsText';


function TarjetaEmprendimiento({ 
	handleClickOpenDialogPublicProfile, 
	StartupName, 
    StartupPicture, 
    StartupDescription, 
    UserName, 
    UserLastName, 
    UserProfilePicture, 
    UserId, 
	PercentageSim, 
	Industria,
	Industria_id
     }: any){

	const navigate = useNavigate()
	const [isSaved, setIsSaved] = useState(false)
	let user_image_url = UserProfilePicture ? UserProfilePicture : "/media/iconos/icono-persona.svg";
    let startup_image_url = StartupPicture && !StartupPicture.includes("static.iris") ? StartupPicture : "/media/iconos/icono-startup.svg";
	let nombre_corto_usuario = `${UserName.split(" ")[0]} ${UserLastName.split(" ")[0]}`;
	// nuevo
	const [isLoadingButton, setIsLoadingButton] = useState(true)
	const [valueDonatedTime, setValueDonatedTime] = useState<any>(false)
	const [initialChargeLoadingButton, setInitialChargeLoadingButton] = useState(true)
	const [get]: any = useGetUserDonatedTimeMutation()
	const [opiniones_open, set_opiniones_open] = useState(false)
	const textPublicProfile = useSelector(selectCurrentDictonary).public_profile
	const textHome = useSelector(selectCurrentDictonary).home
	const textIndustries = useSelector(selectCurrentDictonary).industries

	const fetchDonatedTime = async () => {
		setIsLoadingButton(true)
		const res: any = await get({ user_id: UserId, type:  0});
		const resData = JSON.parse(res.data.responseData)
		setValueDonatedTime(resData)
		setIsLoadingButton(false)
		setInitialChargeLoadingButton(false)
	}

	useEffect(() => {
			
		if (initialChargeLoadingButton) {
			// console.log("FETCHING EFFECT")
			fetchDonatedTime()
		}
	}, [valueDonatedTime]); 
	// fin nuevo
	
	let desc_html = textPublicProfile.sin_descripcion
	if (StartupDescription) {
		desc_html = getCharLimit(StartupDescription + "", 80)
	}
	let industria = textHome.sin_industria
	if(Industria){
		industria = getCharLimit(textIndustries[Industria_id] + "", 25)
	}
	
    const [sol_reu_open, set_sol_reu_open] = useState(false)
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open)
    }
	let btn_solicitar_reunion_html = <></>

	if (!isLoadingButton) {
		 
		if (valueDonatedTime && valueDonatedTime.length > 0) {
			
			 
            btn_solicitar_reunion_html = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn-agenda' onClick={handleClickDialog}>{textHome.agenda} <ArrowForwardIcon></ArrowForwardIcon></button>
			</>
        } else {
            btn_solicitar_reunion_html  = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn-agenda' onClick={handleClickDialog}>{textHome.agenda} <ArrowForwardIcon></ArrowForwardIcon></button>
			</>
        }
	
	} else {
		btn_solicitar_reunion_html = <>{textHome.cargando}</>
	}
	const handleClickMostrarPerfil = () => {
		handleClickOpenDialogPublicProfile();
	  };
	return (
		<div className="tarjeta-container">
			<div className="col-12 tarjeta-emprendimiento">				
				<div className='tarjeta-content'>
					<div className="tarjeta-imagen mt-2">
						<div className="img-container-2">
							<img src={startup_image_url} alt="imagen" className="imagen-user"
							onClick={handleClickMostrarPerfil}></img>
						</div>
						<Tooltip arrow title={textHome.match}>
						<p className='porcentaje'>{Math.floor(PercentageSim)}%</p>
						</Tooltip>			
					</div>
					<div className="etiqueta-container m-1">
						<p className='etiqueta-texto'>{industria}</p>
					</div>
					<strong className='tarjeta-title m-1' onClick={handleClickMostrarPerfil}>
						{getCharLimit(capitalizeFirstLetter(StartupName) + "", 20)}
					</strong>
					<p className={"tarjeta-descripcion"} 
					style={{ border: '', minHeight: '80px', width:'100%'}}>
						{desc_html} 						
					</p>	
                    {/*<strong className='nombre-usuario m-1' onClick={handleClickMostrarPerfil}>
						Emprendedor/a: 
					</strong>*/}
                    <Tooltip arrow title={textHome.ver_emprendedor}>
                        <p className='nombre-usuario m-1' 
                            onClick={handleClickMostrarPerfil}>
                                {textHome.por} {getCharLimit(capitalizeFirstLetter(nombre_corto_usuario) + "", 60)}
                        </p>
                    </Tooltip>
				</div>
				<div className='tarjeta-btn'>
					{btn_solicitar_reunion_html}
				</div>
			</div>
		</div>
		
	)
}
export default TarjetaEmprendimiento