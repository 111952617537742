import './UserProfileExternal.scss'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import "./FormularioSolicitarReunion.scss";
import Label from '../../components/Form/Label';
import { Form, Formik } from 'formik';
import TextAreaInput from '../../components/Form/TextAreaInput';
import { usePostCreateTimeRequestMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

const FormularioSolicitarTiempo = ({ user_id_profile, solicitudes_tiempo_usuario, handleOnClose,  QR }: any) => {

    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id; // Antes era const, ahora let

    const dispatch = useDispatch()
    const [postUserCrearSolicitudTiempo, { isLoadingPostUserCrearSolicitudTiempo }]: any = usePostCreateTimeRequestMutation()

    let inicialValues = {
        meetingReason: "",
    }
    // console.log("QR", QR)
   

    const [actual_values, set_actual_values] = useState(inicialValues)
    const [validated, set_validated] = useState(QR); // Inicializar a true si QR es true

    function validate(values: any) {
        let errors: any = {};
        set_actual_values({ ...actual_values, ...values });
    
        // se añde validación basada en QR
        if (!QR && (!values.meetingReason || values.meetingReason.length < 50)) {
            errors.meetingReason = "Tu mensaje debe contener al menos 50 caracteres.";
        }
    
        if (Object.keys(errors).length === 0) {
            set_validated(true);
        } else {
            set_validated(false);
        }
        return errors;
    }
    
    

    // MOSTRAR SELECTOR DE TIPO Y MENSAJE LUEGO DE SELECCIONAR UN BLOQUE DE HORA DONADA
let labelText = QR ? textPublicProfile.mensaje_solicitud_tiempo_qr : textPublicProfile.mensaje_solicitud_tiempo_noqr;
let buttonLabel = QR ? textPublicProfile.button_label_solicitar : textPublicProfile.btn_request;
let detailText = QR ? "" : textPublicProfile.mensaje_reunion_noqr;

// MOSTRAR SELECTOR DE TIPO Y MENSAJE LUEGO DE SELECCIONAR UN BLOQUE DE HORA DONADA
let formulario_request_html = <>
   <Formik
    initialValues={inicialValues}
    validate={validate}
    validateOnChange={true}
    validateOnBlur={true}
    onSubmit={() => { }}
>
    {({ errors }) => (
        <Form className='m-0'>
            <div className='row'>
                <div className='col-12 mb-4 pl-lg-2 pl-0'>
                    <Label classNameLabel='label-primary label' label={labelText} ></Label>
                    {!QR && (
    <TextAreaInput className="w-100" name="meetingReason" type="text" label="" showErrorText={false} defaultValue={""} />
)}

                    {/* Mostrar mensaje de error si existe */}
                    {errors.meetingReason && <div className="error-text">{errors.meetingReason}</div>}
                    <Label classNameLabel='label-normal' label={detailText}></Label>
                </div>
            </div>
        </Form>
    )}
</Formik>
</>

let user_id_confirm = user_personal_data.Id

    async function handleSubmit() {
        handleOnClose()
        let new_alert = { id: "enviando_solicitud", type: "loading", title: textPublicProfile.param_1, desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
       // console.log("aqui actualvalues",actual_values);
       
        try {
            if (user_id == 0) {
                if(user_id_confirm == 0){
                throw new Error("Error de ids");
            }else{
                user_id = user_id_confirm
            }
            }
            let item = {
                "userRequestedId": user_id_profile,
                "userRequestId": user_id,
                "meetingReasonDescription": QR ? "Solicitud de tiempo QR" : actual_values.meetingReason,
                "QR": QR ? true : false
            }

            // console.log("ITEM", item)

            const responseCrearSolicitudTiempo = await postUserCrearSolicitudTiempo({ body: item });
            if (responseCrearSolicitudTiempo.data.responseMessage === 'Error de UserRequestId'){
                console.log("Error desde el backend")
                throw new Error("Error de ids");
            }
            dispatch(deleteAlert({ alert_id: "enviando_solicitud" }));
            let new_alert2 = { type: "success", timeout: 2000, title: textPublicProfile.param_2, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "enviando_solicitud" }));
            let new_alert2 = { type: "error", title: textPublicProfile.param_3, desc: textPublicProfile.error0, close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 '>
                    {formulario_request_html}
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-rounded btn-white mr-2' onClick={handleOnClose}>{textPublicProfile.btn_cancel}</button>
                <button disabled={!validated} onClick={handleSubmit} className='btn btn-rounded btn-primary'>{textPublicProfile.btn_request}</button>
            </div>
        </>
    )
}

export default FormularioSolicitarTiempo;