import "./UserExperience.scss"
import React, { useEffect, useState } from 'react';
import { selectPersonalData, setUserExpertises } from '../../../redux/features/user/userSlice';
import { useDeleteUserExpertisesMutation, useGetUserExpertisesMutation, usePostUserExpertisesMutation, usePutUserExpertisesMutation } from '../../../redux/features/user/userApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { personalData } from '../../../interfaces/dimentions';
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { addAlert, deleteAlert } from "../../../redux/features/alerts/alertsSlice";
import FormularioExperiencia from "../../../components/formularios/FormularioExperiencia";
import CardExperiencia from "../../../components/CardExperiencia/CardExperiencia";
import GetUserExpertises from "../../../hooks/user/GetUserExpertises/GetUserExpertises";

import GetCompanies from '../../../hooks/dimensions/GetCompanies/GetCompanies';
import GetJobTitles from '../../../hooks/dimensions/GetJobTitles/GetJobTitles';
import GetIndustries from '../../../hooks/dimensions/GetIndustries/GetIndustries';

import CircularProgress from '@mui/material/CircularProgress';
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";




/* italica */
const UserExperience = () => {

    const textMyExp = useSelector(selectCurrentDictonary).experiences

    let companies = GetCompanies()
    let jobtitles = GetJobTitles()
    let industries = GetIndustries()

    const dispatch = useDispatch()
    const [postUserExpertises]: any = usePostUserExpertisesMutation()
    const [deleteUserExpertises]: any = useDeleteUserExpertisesMutation()
    const [putUserExpertises]: any = usePutUserExpertisesMutation()

    /* experiencias de usuario */
    const [getUserExpertises]: any = useGetUserExpertisesMutation()
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let GetUserExpertises_aux = GetUserExpertises(user_personal_data.Id);
    let user_expertises = GetUserExpertises_aux.value
    let is_loading_user_expertises = GetUserExpertises_aux.isLoading

    useEffect(() => {
    }, []);


    const theme = useTheme();
    const empty_values = {
        "expertises_industry": 0,
        "expertises_companyId": 0,
        "expertises_jobTitle": 0,
        "expertises_description": "",
        "expertises_startDate": "",
        "expertises_endDate": "",
        "expertises_conCompany": "1"
    }
    const [actual_values_create, setActualValuesCreate] = useState<any>({ ...empty_values });
    const [actual_values_edit, setActualValuesEdit] = useState<any>({ ...empty_values });
    const [actualEditID, setActualEditId] = useState<any>(0);

    const [actualDeleteId, setActualDeleteId] = useState(0)
    /* variables y funciones modal (dialog) --------------------------------------------------------------------------------------------------------- */
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    /* Crear */
    const handleClickOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };
    /* editar */
    const handleClickOpenEdit = (expertise: any) => {
        let actual_values = {
            "expertises_industry": expertise.IndustryId,
            "expertises_companyId": expertise.CompanyId,
            "expertises_jobTitle": expertise.JobTitleId,
            "expertises_conCompany": expertise.CompanyId ? "1" : "0",
            "expertises_startDate": expertise.StartDate,
            "expertises_endDate": expertise.EndDate,
        }
        setActualValuesEdit(actual_values)
        setActualEditId(expertise.ExpertiseId)
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    /* Eliminar */
    const handleClickOpenDelete = (expertise_id: any) => {
        setActualDeleteId(expertise_id)
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };


    /* Variables y funciones formulario --------------------------------------------------------------------------------------------------------- */
    async function reloadExpertises(user_id: any) {
        const res: any = await getUserExpertises({ user_id: user_id });
        const resData = JSON.parse(res.data.responseData)
        dispatch(setUserExpertises({ user_expertises: resData }))
    }
    /* create */
    async function handleSubmitCreate(values: any, { setStatus, setErrors }: any) {
        let new_alert = { id: "save_expertises", type: "loading", title: "Guardando experiencia...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let item = {
                "userId": user_personal_data.Id,
                "industryId": actual_values_create.expertises_industry,
                "companyId": actual_values_create.expertises_companyId,
                "jobTitleId": actual_values_create.expertises_jobTitle,
                "startDate": actual_values_create.expertises_startDate,
                "endDate": actual_values_create.expertises_endDate,
            }
            const res: any = await postUserExpertises(item);
            const rescode = res.data.statusCode

            dispatch(deleteAlert({ alert_id: "save_expertises" }));
            if (rescode == 200) {
                let new_alert_2 = { id: "save_expertises_success", type: "success", title: "Experiencia creara correctamente", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

                reloadExpertises(user_personal_data.Id);
                handleCloseCreate();

            } else {
                let new_alert_2 = { id: "save_expertises_error", type: "error", title: "Error al crear experiencia", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

            }

        } catch (error) {

            dispatch(deleteAlert({ alert_id: "save_expertises" }));
            let new_alert_2 = { id: "save_expertises_error", type: "error", title: "Error al crear experiencia", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert_2 }))
        }

    }

    function handleChangeDataCreate(new_values: any) {
        setActualValuesCreate({ ...new_values });
    }
    /* edit */
    async function handleSubmitEdit(values: any, { setStatus, setErrors }: any) {
        let new_alert = { id: "save_expertises", type: "loading", title: "Guardando experiencia...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
        try {

            let item = {
                "userId": user_personal_data.Id,
                "expertiseId": actualEditID,
                "industryId": actual_values_edit.expertises_industry,
                "companyId": parseInt(actual_values_edit.expertises_companyId),
                "jobTitleId": actual_values_edit.expertises_jobTitle,
                "startDate": actual_values_edit.expertises_startDate,
                "endDate": actual_values_edit.expertises_endDate,
            }
            const res: any = await putUserExpertises({ ...item });
            const resdata = JSON.parse(res.data.responseData)
            //dispatch(setUserDegrees({ user_degrees: resdata }))
            const rescode = res.data.statusCode

            dispatch(deleteAlert({ alert_id: "save_expertises" }));
            if (rescode == 200) {
                let new_alert_2 = { id: "save_expertises_success", type: "success", title: "Experiencia guardada correctamente", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

                reloadExpertises(user_personal_data.Id);
                handleCloseEdit();
            } else {
                let new_alert_2 = { id: "save_expertises_error", type: "error", title: "Error al editar experiencia", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
            }
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "save_expertises" }));
            let new_alert_2 = { id: "save_expertises_error", type: "error", title: "Error al editar experiencia", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert_2 }))

        }
    }
    function handleChangeDataEdit(new_values: any) {
        setActualValuesEdit({ ...new_values });
    }


    async function handleConfirmDelete() {
        let new_alert = { id: "delete_expertise", type: "loading", title: "Eliminando experiencia...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))
        try {
            const res: any = await deleteUserExpertises({ expertiseId: actualDeleteId });
            dispatch(deleteAlert({ alert_id: "delete_expertise" }));
            const rescode = res.data.statusCode

            dispatch(deleteAlert({ alert_id: "delete_expertise" }));
            if (rescode == 200) {
                let new_alert_2 = { id: "delete_expertise_success", type: "success", title: "Experiencia eliminada correctamente", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

                reloadExpertises(user_personal_data.Id);
                handleCloseDelete();
            } else {
                let new_alert_2 = { id: "delete_expertise_error", type: "error", title: "Error al eliminar experiencia", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
            }

        } catch (error) {
            dispatch(deleteAlert({ alert_id: "delete_expertise" }));
            let new_alert_2 = { id: "delete_expertise_error", type: "error", title: "Error al eliminar experiencia", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert_2 }))

        }
    }

    /* Lista con experiencias del usuario  --------------------------------------------------------------------------------------------------------- */
    let lista_experiencias_html = <></>
    if (is_loading_user_expertises) {
        lista_experiencias_html = <div className="mt-4 d-flex justify-content-center align-items-center">
            <CircularProgress className="settings-loading-icon mr-2" size="1.5rem" />
            <p>{textMyExp.param_10}</p>
        </div>
    } else {

        lista_experiencias_html = <div className="row no-gutters">
            {user_expertises?.map((item: any, iter: any) => {
                return (
                    <div className='col-12  p-2'>
                        <CardExperiencia
                            number={iter}
                            industria={item.IndustryId ? industries.find((element: any) => element.Id == item.IndustryId)?.Value : ""}
                            compania={item.CompanyId ? companies.find((element: any) => element.Id == item.CompanyId)?.Value : ""}
                            foto_compania={item.Company.Picture ? item.Company.Picture : ""}
                            cargo={item.JobTitleId ? jobtitles.find((element: any) => element.Id == item.JobTitleId)?.Value : ""}
                            anioInicio={item.StartDate ? item.StartDate : ""}
                            anioTermino={item.EndDate ? item.EndDate : ""}
                            canEdit={true}
                            handleEditBtn={() => handleClickOpenEdit(item)}
                            handleDeleteBtn={() => handleClickOpenDelete(item.ExpertiseId)}
                        />

                    </div>
                )
            })}
        </div>
    }


    // ================================================================================================================================================================ 
    // ==================================================================== VALIDACION ==========================================================================
    // ================================================================================================================================================================ 


    const validateCreate = (values: any) => {
        const errors: any = {}

        if ((values.expertises_conCompany == "1") && !(values.expertises_companyId > 0)) {
            errors.expertises_companyId = textMyExp.param_8
        }
        if (!values.expertises_startDate) {
            errors.expertises_startDate = textMyExp.param_8
        }
        if (!values.expertises_endDate) {
            errors.expertises_endDate = textMyExp.param_8
        }
        if (!values.expertises_industry) {
            errors.expertises_industry = textMyExp.param_8
        }
        if (!values.expertises_jobTitle) {
            errors.expertises_jobTitle = textMyExp.param_8
        }

        handleChangeDataCreate(values)
        return errors
    }
    const validateEdit = (values: any) => {
        const errors: any = {}


        if ((values.expertises_conCompany == "1") && !(values.expertises_companyId > 0)) {
            errors.expertises_companyId = textMyExp.param_8
        }
        if (!values.expertises_startDate) {
            errors.expertises_startDate = textMyExp.param_8
        }
        if (!values.expertises_endDate) {
            errors.expertises_endDate = textMyExp.param_8
        }
        if (!values.expertises_industry) {
            errors.expertises_industry = textMyExp.param_8
        }
        if (!values.expertises_jobTitle) {
            errors.expertises_jobTitle = textMyExp.param_8
        }

        handleChangeDataEdit(values)
        return errors
    }

    return (

        <div className="px-lg-4">
            <div className="row align-items-center justify-content-between">
                <div className='col-auto'>
                    <h3 className='font-italic'><b>{textMyExp.titulo_1}</b></h3>
                    <p className='parrafo1'>{textMyExp.desc_1}</p>
                </div>
                <div className='col-auto'>
                    <button className="mt-3 mt-lg-0 btn btn-primary btn-rounded" onClick={handleClickOpenCreate}>
                        <i className='fas fa-add'></i> {textMyExp.btn_agregar}
                    </button>
                </div>
            </div>
            <div className='pb-3'>
                <hr />
            </div>

            {lista_experiencias_html}

            {/* CREAR */}
            <Dialog
                fullScreen={fullScreen}
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {textMyExp.titulo_2}
                </DialogTitle>
                <DialogContent>

                    <Formik
                        initialValues={actual_values_create}
                        validate={validateCreate}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={handleSubmitCreate}
                    >
                        <Form>
                            <FormularioExperiencia

                                handleChangeData={(value: any) => setActualValuesCreate(value)}
                                actual_values={actual_values_create}
                                companies={companies} jobtitles={jobtitles} industries={industries}
                            ></FormularioExperiencia>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-rounded btn-outline-danger mr-2" onClick={handleCloseCreate}>
                                    {textMyExp.btn_cancel}
                                </button>
                                <button type="submit" className="btn btn-rounded btn-primary" /* onClick={handleClose} */>
                                    {textMyExp.btn_save}
                                </button>
                            </div>
                        </Form>
                    </Formik>


                </DialogContent>
            </Dialog>
            {/* EDITAR */}
            <Dialog
                fullScreen={fullScreen}
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {textMyExp.btn_editar}
                </DialogTitle>
                <DialogContent>

                    <Formik
                        initialValues={actual_values_edit}
                        validate={validateEdit}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={handleSubmitEdit}
                    >
                        <Form>
                            <FormularioExperiencia
                                handleChangeData={(value: any) => setActualValuesEdit(value)}
                                actual_values={actual_values_edit}
                                companies={companies} jobtitles={jobtitles} industries={industries}
                            ></FormularioExperiencia>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-rounded btn-outline-danger mr-2" onClick={handleCloseEdit}>
                                    {textMyExp.btn_cancel}
                                </button>
                                <button type="submit" className="btn btn-rounded btn-primary" /* onClick={handleClose} */>
                                    {textMyExp.btn_save}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
            {/* ELIMINAR */}
            <Dialog
                /* fullWidth */
                maxWidth="sm"
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"¿Estas seguro?"}
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-rounded btn-outline-danger mr-2" onClick={handleCloseDelete}>
                            {textMyExp.param_8}
                        </button>
                        <button type="submit" className="btn btn-rounded btn-primary" onClick={handleConfirmDelete}>
                            {textMyExp.param_9}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UserExperience;
