import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useGetUserAdminRoleMutation } from '../../redux/features/user/userApiSlice';

const ScheduleMeeting = () => {
  const [emailUser1, setEmailUser1] = useState(''); // Estado para el valor del correo electrónico del usuario 1
  const [emailUser2, setEmailUser2] = useState(''); // Estado para el valor del correo electrónico del usuario 2
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);
  const [getUserAdminRole] = useGetUserAdminRoleMutation();

  useEffect(() => {
    if (userPersonalData) {
      getUserAdminRole(userPersonalData.Id)
        .unwrap()
        .then(response => {
          const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
          setIsAdmin(isAdmin);
          if (!isAdmin) navigate('/home');
        })
        .catch(error => {
          console.error("Error fetching user role:", error);
          navigate('/home');
        });
    }
  }, [userPersonalData, navigate, getUserAdminRole]);

  if (!isAdmin) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Agendar reuniones
      </Typography>
      <TextField
        label="Correo Electrónico Usuario 1"
        variant="outlined"
        fullWidth
        value={emailUser1}
        onChange={(e) => setEmailUser1(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Correo Electrónico Usuario 2"
        variant="outlined"
        fullWidth
        value={emailUser2}
        onChange={(e) => setEmailUser2(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Seleccionar fecha y hora
      </Button>
    </Box>
  );
};

export default ScheduleMeeting;
