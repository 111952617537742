import { motion } from "framer-motion";
import "./HomePrincipal.scss"
import { useSelector } from "react-redux";
import {selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import HomeBuscador from "./HomeBuscador";
import imagenRedonda from "./imagenBuscador.png";

const HomePrincipal = () => {

    const lenguage = useSelector(selectCurrentLanguage)

    
    

    return (
        <div className=''>
            <HomeBuscador></HomeBuscador>
        </div>
    )
}

export default HomePrincipal;