import './SignIn.scss'

import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../redux/features/auth/authSlice'
import { setRoles, setSponsors } from '../../../redux/features/dimensions/dimensionsSlice'
import { setPersonalData, setUserPublicData, setUserPublicProfile } from '../../../redux/features/user/userSlice'
import { useSigninMutation } from '../../../redux/features/auth/authApiSlice'


import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import Checkbox from '../../../components/Form/Checkbox'
import { useEffect, useState } from 'react'
import { useGetRolesMutation, useGetSponsorsMutation } from '../../../redux/features/dimensions/dimensionsApiSlice'
import { useGetUserPersonalDataMutation, useGetUserPublicDataMutation } from '../../../redux/features/user/userApiSlice'
import { personalData } from '../../../interfaces/dimentions'
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'
//import bcrypt from 'bcryptjs';
//import CryptoJS from 'crypto-js';
import { encryptPassword } from '../../../utils/utilsForms'

import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { desencriptarToJSON, encriptarJSON } from '../../../utils/encrypt'

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const SignIn = () => {

    const textLogin = useSelector(selectCurrentDictonary).login

    const [signin, { isLoadingSignIn }]: any = useSigninMutation()
    const [getUserPersonalData, { isLoadingGetUserPersonalData }]: any = useGetUserPersonalDataMutation()
    const [getRoles, { isLoadingGetRoles }]: any = useGetRolesMutation()
    const [getSponsors, { isLoadingGetSponsors }]: any = useGetSponsorsMutation()
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [encryptedPassword, setEncryptedPassword] = useState('');
    const [getUserPublicData]: any = useGetUserPublicDataMutation()

    const [msj, setMsj] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingScreen, setLoadingScreen] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();
    const [showpasswords, setShowpasswords] = useState(false)



    // comeinzo redireccion qr si no se habia logueado antes
   
    // const [openModal, setOpenModal] = useState(false);

    const redirected = state?.redirected || false;
    const scannedUserId = state?.scannedUserId || null;
    const isQRMode = state?.isQRMode || false;


    // useEffect(() => {
    //     if (redirected) {

    //         setOpenModal(true);  // Mostrar el modal si el usuario fue redirigido
    //     }
    // }, [redirected, scannedUserId]);



    // fin redireccion qr si no se habia logueado antes


    let type_passwords = "password"
    if (showpasswords) {
        type_passwords = "text"
    }

    let initialValues = {
        email: '',
        password: '',
        accept: true
    }

    const validate = (values: any) => {

        const errors: any = {}

        if (!values.password) {
            errors.password = textLogin.error_password_email
        }

        if (!values.email) {
            errors.email = textLogin.error_password_email
        }

        if (values.showpasswords !== showpasswords) {
            setShowpasswords(values.showpasswords)
        }
        return errors
    }
    const getInfoUser = async (userId: string) => {
        //peticion de datos personales del usuario                
        const res_getUserPersonalData: any = await getUserPersonalData({ user_id: userId })
        const personal_data_aux: personalData = JSON.parse(res_getUserPersonalData.data.responseData)
        dispatch(setPersonalData({ personal_data: personal_data_aux }))

        if (!localStorage.getItem("userCache")) {
            // peticion de datos publicos
            const res: any = await getUserPublicData({ user_id: userId });
            const resdata = JSON.parse(res.data.responseData)
            dispatch(setUserPublicData({ user_public_data: resdata }))

            //traer listado de roles (dimensions)
            const res_getRoles: any = await getRoles()
            const roles_aux = JSON.parse(res_getRoles.data.responseData)
            dispatch(setRoles({ roles: roles_aux }))

            //traer listado de sponsors (dimensions)
            const res_getSponsors: any = await getSponsors()
            const sponsors_aux = JSON.parse(res_getSponsors.data.responseData)
            dispatch(setSponsors({ sponsors: sponsors_aux }))
            const userCache = {
                public: resdata,
                roles: roles_aux,
                sponsors: sponsors_aux
            }
            localStorage.setItem("userCache", JSON.stringify(userCache))
        }
        else {
            const cachePublicInfo = JSON.parse(localStorage.getItem("userCache")!)
            dispatch(setUserPublicData({ user_public_data: cachePublicInfo.public }))
            dispatch(setRoles({ roles: cachePublicInfo.roles }))
            dispatch(setSponsors({ sponsors: cachePublicInfo.sponsors }))
        }
    }
    // asi estaba antes de la redireccion no logueado por qr 
    // const redirectUser = () => {
    //     if (state && state.from && state.from.pathname && state.from.pathname !== '/sign-in') {
    //         navigate(state.from.pathname)
    //     } else {
    //         navigate('/home')
    //     }
    // }

    const redirectUser = () => {
        if (redirected && scannedUserId) {



            if (isQRMode) {
                navigate(`/user-profile/${scannedUserId}?mode=QR`);
            } else {
                navigate(`/user-profile/${scannedUserId}`);
            }
        } else if (state && state.from && state.from.pathname && state.from.pathname !== '/sign-in') {
            navigate(state.from.pathname);
        } else {
            navigate('/home');
        }
    };

    async function sing_in_requests(password_aux: any, values: any) {
        const res_signin: any = await signin({ EmailAddress: values.email, Password: password_aux })

        if (res_signin.data.statusCode === 200) {
            dispatch(deleteAlert({ alert_id: "cargando_login" }))
            let new_alert = { type: "success", title: textLogin.alerta_success_titulo, desc: "OK", timeout: 2000, close: true } // notificacion de exito tras cargar, es la segunda solicitud luego de cargando si no hay un error en las credenciales
            dispatch(addAlert({ alert: new_alert }))
            const resdata_signin_json = JSON.parse(res_signin.data.responseData)
            dispatch(setCredentials({ token: resdata_signin_json.Token, refresh_token: resdata_signin_json.RefreshToken, user_id: resdata_signin_json.UserId }))
            await getInfoUser(resdata_signin_json.UserId)
            const hashObject = encriptarJSON(JSON.stringify({ isLoggedIn: true, userId: resdata_signin_json.UserId }));
            localStorage.setItem('session', hashObject);
            setLoadingScreen(false)

            redirectUser()
        } else {
            dispatch(deleteAlert({ alert_id: "cargando_login" }))
            let new_alert2 = { type: "error", title: textLogin.alerta_error_titulo, desc: res_signin.data.responseMessage, timeout: 10000, close: true }
            dispatch(addAlert({ alert: new_alert2 }))
            setLoadingScreen(false)
        }

    }
    const handleSubmit = async (values: any) => {

        dispatch(deleteAlert({ alert_id: "error_login" }))
        dispatch(deleteAlert({ alert_id: "cargando_login" }))
        let new_alert = { id: "cargando_login", type: "loading", title: textLogin.alerta_cargando_titulo, desc: textLogin.alerta_succes_titulo, close: false } // notificacion de cargando, esta es la inicial
        dispatch(addAlert({ alert: new_alert }))
        setLoadingScreen(true)
        setMsj("")
        setLoading(true)
        let password_aux = ""

        encryptPassword(values.password).then((hash) => {
            password_aux = hash
            sing_in_requests(password_aux, values)
            setLoading(false)

        }).catch((error) => {

            dispatch(deleteAlert({ alert_id: "cargando_login" }))
            let new_alert2 = { type: "error", title: textLogin.alerta_error_titulo, timeout: 10000, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
            setLoading(false)

        })
        /* 
        "1.Aa$2a$10$LhxyBqmD9iNOAGcx8rwcDuPOOzQIlJor6Zc0SfMYlGWfClfuELUzG"
        "1.Aa$2a$10$ZTJK3.O5GBFHRvmBcXS3J.gnQvCN/Y8bLpwg5PC9eFK9Fav3z6T4G"
        
        "1.Aa$2a$10$OAvYN4rbZWT5RMQ47779QePZGmqIXtWvYXf/gcK6We2nCj/QdU9Ba"
        "1.Aa$2a$10$4HB5Ewp9K23KmzmNlBaFjuVMAU2c1beAxlPfpJcuJ35flcJL3Clwe"
        "1.Aa$2a$10$UFVH8ghug2k072myE6dZSeQVw7LJwbes2cadc5wxT/H0.N4G0SYgS"
        
        
        */
    }

    let form_html = <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={values => handleSubmit(values)}
    >
        <Form>
            <div className='row no-gutters'>
                <div className='col-12 mb-3'>
                    <TextInput name="email" type="email" label={textLogin.param_1} placeholder={textLogin.placeholder_1} showErrorText={true} />
                </div>
                <div className='col-12 '>
                    <TextInput name="password" type={type_passwords} placeholder={textLogin.placeholder_2} label={textLogin.param_2} showErrorText={true} />
                </div>
                <div className='col-12 mt-3 mb-3'>
                    <Checkbox name="showpasswords">
                        {textLogin.param_3}
                    </Checkbox>
                </div>
                <div className='col-12 mb-3'>
                    <p> {textLogin.param_4} <Link to="/password-recovery">{textLogin.param_5}</Link>.</p>

                </div>
                <div className='col-12 mt-4'>
                    <button type="submit" disabled={loading} className='btn btn-rounded btn-primary btn-block'>{textLogin.btn_1}</button>
                </div>
                <div className='col-12 mt-3 mb-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                        {/* <p className={(loading ? "d-flex justify-content-center" : "d-none")}>
                            <b className='d-inline mr-2'>
                                <i className="fa-solid fa-sync fa-spin"></i>
                            </b>
                            Cargando...
                        </p> */}
                        <p>{msj}</p>
                    </div>
                </div>
            </div>

        </Form>
    </Formik>

    // const handleCloseModal = () => {
    //     setOpenModal(false);
    // };

    return (
        <div className='body-container'>
            <div className='row no-gutters'>
                <div className='col-12 col-lg-6 col-xl-6 signIn-container '>
                    <div className='signIn-content-container'>
                        <div className='signIn-content padding-layout-x'>
                            {loadingScreen ? (

                                <img src="/media/gif/loopmeet01.gif" alt="Cargando..." />
                            ) : (
                                <>
                                    <div className='d-flex justify-content-center justify-content-lg-start  mb-4'>
                                        <h2 className=''>{textLogin.titulo_1}</h2>
                                    </div>
                                    {form_html}
                                </>
                            )}
                        </div>
                    </div>

                </div>
                <div className='col-0 col-lg-6 col-xl-6 signIn-sidebar-background-container'>
                </div>
            </div>

            {/* Modal de aviso para funcionalidades QR */}
            {/* <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="qr-modal-title"
                aria-describedby="qr-modal-description"
            >
                <DialogTitle id="qr-modal-title">{textLogin.titulo_modal}</DialogTitle>
                <DialogContent>
                    <p>{textLogin.descripcion_modal}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        {textLogin.aceptar}
                    </Button>
                </DialogActions>
            </Dialog> */}
        </div>
    )
}

export default SignIn;