import './SignOut.scss'

import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/features/auth/authSlice'

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { deleteInfoUser } from '../../../redux/features/user/userSlice';

const SignOut = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logOut({}))
        dispatch(deleteInfoUser())
        localStorage.removeItem("userCache")
        // localStorage.removeItem('session');
        // setIsLoggedIn(false);
        navigate("/sign-in")
        // window.location.reload()

    }, [])

    return (
        <>
            <div className=''>
            </div>
        </>
    )
}

export default SignOut;