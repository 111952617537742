import { useField } from 'formik'
import "./Inputs.scss"
import Label from './Label'
import { TextField } from '@mui/material'

const TextInput = ({ label="", classNameLabel="label-primary", showErrorText=true, required=false, ...props }:any) => {
  const [field, meta] = useField(props)
  
  return (
    <div className="control">
      <Label classNameLabel={classNameLabel} label={label} required={required}></Label>
      <br/>
      <input className={"text-input w-100 " + ((meta.touched && meta.error) ? " text-input-error" : "") } {...field} {...props}/>
      {meta.touched && meta.error && showErrorText ? <div className="error text-danger">{meta.error}</div> : null}
    </div>
  )
}

export default TextInput

/* 

    <div className="control">
      <Label classNameLabel={classNameLabel} label={label} required={required}></Label>
      <br/>
      <input className={"text-input w-100 " + ((meta.touched && meta.error) ? " text-input-error" : "") } {...field} {...props}/>
      {meta.touched && meta.error && showErrorText ? <div className="error text-danger">{meta.error}</div> : null}
    </div>
*/