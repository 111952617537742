import "./Landing.scss"

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import { Link } from "react-router-dom";

const LandingPerfiles = () => {
    const lenguage = useSelector(selectCurrentLanguage)
    const textLanding = useSelector(selectCurrentDictonary).landing
    const infoText06 = () => {
        if (lenguage === 'es') return <>Escoge el <b className="text-primary">perfil</b> de tu <b>usuario</b></>
        else return <>Choose your <b>user</b> <b className="text-primary">profile</b> </>
    }
    return(
        <div className="row">
            <div className="col-12 col-lg-4">
                <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-titulo-3 mb-4">
                    {infoText06()}
                </motion.h1>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card-contenedor">
                    <div className="card-landing-new card-emprendedor">
                        <div className="card-content">
                            <h2 className="card-title">{textLanding.titulo_4}</h2>
                            <p className="card-text">{textLanding.desc_3}</p>
                            <Link to="/settings/user-startups" className="card-button">{textLanding.btn_ingresa_emprendedor}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card-contenedor">
                    <div className="card-landing-new card-experto">
                        <div className="card-content">
                            <h2 className="card-title">{textLanding.titulo_5}</h2>
                            <p className="card-text">{textLanding.desc_4}</p>
                            <Link to="/settings/user-experience" className="card-button">{textLanding.btn_ingresa_experto}</Link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default LandingPerfiles;