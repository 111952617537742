import './UserProfileExternal.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { Avatar, Rating, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import GetCompanies from '../../hooks/dimensions/GetCompanies/GetCompanies';

import React, { useState } from 'react';

import GetJobTitles from '../../hooks/dimensions/GetJobTitles/GetJobTitles';
import GetIndustries from '../../hooks/dimensions/GetIndustries/GetIndustries';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { usePostDeleteUserContactMutation, useGetCheckMutualContactMutation } from '../../redux/features/user/userApiSlice'
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';

import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice'



const UserProfileExternalTopCard = (
    {
        user_id_profile,
        user_feedbacks_prom_estrellas,
        esEmprendedor,
        esExperto,
        user_expertises,
        user_donated_times,
        user_public_data,
        isExternal,
        is_loading_public_data,
        handleClickDialog,
        isModal,
        handleClickCloseDialogPublicProfile,
        handleAddContact,
        handleLoadContactsMain,
        isQRMode
       
    }: any) => {

    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

    const [showWebError, setShowWebError] = useState(false);
    const [showFacebookError, setShowFacebookError] = useState(false);
    const [showInstagramError, setShowInstagramError] = useState(false);
    const [showLinkedInError, setShowLinkedInError] = useState(false);
    const [showTwitterError, setShowTwitterError] = useState(false);
    const [showYoutubeError, setShowYoutubeError] = useState(false);
    const navigate = useNavigate();

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    const widthButton = textPublicProfile.titulo_1 === 'Idiomas' ? '290px' : '295px';

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalAction, setModalAction] = useState(() => () => {});
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    let user_id = user_personal_data?.Id || 0;
    const [checkContact, { data: isContact, isLoading: isCheckingContact }] = useGetCheckMutualContactMutation();
    const [valueButton, setValueButton] = useState(false)
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const dispatch = useDispatch()
    // if(isQRMode){
    //     console.log("modo:QR => id params: ",user_id_profile)
    // }else{
    //     console.log("modo: no qr => id params",user_id_profile)
    // }
    useEffect(() => {
        const handleLoadContacts = async () => {
            await checkContact({ UserId: user_id, ContactUserId: user_id_profile }).unwrap();
            // console.log("DATA FROM handlecontatcscheckcontats", isContact)


        }
        handleLoadContacts();
    }, [user_id, user_id_profile, checkContact]);

    const [deleteUserContact, { isLoading: isDeleting }] = usePostDeleteUserContactMutation();

    const handleContactClick = async () => {
        if (user_id === 0) {
            setModalMessage(textPublicProfile.no_log_contact_desc);
            setModalAction(() => () => navigate('/sign-in', { state: { redirected: true, scannedUserId: user_id_profile, isQRMode } }));
            setOpenLoginModal(true);
            return;
        }

        if (!isQRMode && !isContact) {
            setOpenConfirmModal(true);
            return;
        }

        if (!isQRMode && isContact) { // aca quitar el !isqrmode tanto par aceptar o rechazar, si se busca que en el modo qr tambien se haga la ocnfiramción.
            setOpenConfirmDeleteModal(true);
            return;
        }

        await handleAddOrDeleteContact();
    };

    const handleAddOrDeleteContact = async () => {
        setIsLoadingButton(true);

        const alertId = "check_contact";
        const new_alert = {
            id: alertId,
            type: "loading",
            title: textPublicProfile.not_titulo_carga_contacto,
            desc: textPublicProfile.not_desc_carga_contacto,
            timeout: 2000,
            close: true,
        };
        dispatch(addAlert({ alert: new_alert }));

        if (isContact) {
            try {
                await deleteUserContact({ userId: user_id, contactUserId: user_id_profile }).unwrap();
                await checkContact({ UserId: user_id, ContactUserId: user_id_profile }).unwrap();
                dispatch(deleteAlert({ alert_id: alertId }));
                handleLoadContactsMain?.();

                const new_alert_success = {
                    id: "check_contact_success",
                    type: "success",
                    title: textPublicProfile.not_titulo_eliminar_contacto_exito,
                    desc: textPublicProfile.not_desc_eliminar_contacto_exito,
                    timeout: 1000,
                    close: true,
                };
                dispatch(addAlert({ alert: new_alert_success }));
            } catch (error) {
                console.error('Error al eliminar el contacto:', error);
                dispatch(deleteAlert({ alert_id: alertId }));
                const new_alert_error = {
                    id: "check_contact_error",
                    type: "error",
                    title: textPublicProfile.not_titulo_eliminar_contacto_error,
                    desc: textPublicProfile.not_desc_eliminar_contacto_error,
                    timeout: 2000,
                    close: true,
                };
                dispatch(addAlert({ alert: new_alert_error }));
            }
        } else {
            try {
                await handleAddContact();
                dispatch(deleteAlert({ alert_id: alertId }));
                const new_alert_success = {
                    id: "check_contact_added_success",
                    type: "success",
                    title: textPublicProfile.not_titulo_añadir_contacto_exito,
                    desc: textPublicProfile.not_desc_añadir_contacto_exito,
                    timeout: 2000,
                    close: true,
                };
                dispatch(addAlert({ alert: new_alert_success }));
                await checkContact({ UserId: user_id, ContactUserId: user_id_profile }).unwrap();
                handleLoadContactsMain?.();
            } catch (error) {
                console.error('Error al añadir el contacto:', error);
                dispatch(deleteAlert({ alert_id: alertId }));
                const new_alert_error = {
                    id: "check_contact_error",
                    type: "error",
                    title: textPublicProfile.not_titulo_añadir_contacto_error,
                    desc: textPublicProfile.not_desc_añadir_contacto_error,
                    timeout: 2000,
                    close: true,
                };
                dispatch(addAlert({ alert: new_alert_error }));
            }
        }
        setIsLoadingButton(false);
    };
    const handleClickDialogOrRedirect = () => {
        if (user_id === 0) {
            setModalMessage(textPublicProfile.no_log_reu_desc);
            setModalAction(() => () => navigate('/sign-in', { state: { redirected: true, scannedUserId: user_id_profile, isQRMode } }));
            setOpenLoginModal(true);

        } else {
            handleClickDialog();
        }
    };


    // Cambiar el texto del botón basado en isContact
    const contactButtonText = isContact ? textPublicProfile.btn_eliminar_contacto : textPublicProfile.btn_añadir_contacto;




    const handleButtonClick = (setErrorState: any) => {
        setErrorState(true);
        setTimeout(() => {
            setErrorState(false);
        }, 4000);
    }


    let jobtitles = GetJobTitles()
    let industries = GetIndustries()

    let companies = GetCompanies()


    useEffect(() => {
        // console.log("Is Contact update", isContact)
    }, [isContact]);

    /* IMAGEN DE USUARIO */
    let image_url = (user_public_data.ProfilePicture) ? user_public_data.ProfilePicture : "/media/iconos/icono-persona.svg"
    let img_usr = <Avatar className="userprofile-user-image" alt={user_public_data.Name} src={image_url} ></Avatar>

    /* BOTON SOLICITUD DE REUNION O DONAR TIEMPO */
    let btn_solicitar_reunion_html = <></>
    if (isExternal == 1 && isModal == 1) {
        if (user_donated_times && user_donated_times.length > 0) {
            btn_solicitar_reunion_html = <button className='btn btn-primary btn-solicitar-reunion' onClick={handleClickDialogOrRedirect} style={{ width: widthButton }}>{textPublicProfile.btn_solicitar_reunion} </button>
        } else {
            btn_solicitar_reunion_html = <button className='btn btn-primary btn-solicitar-reunion' onClick={handleClickDialogOrRedirect} style={{ width: widthButton }}>{textPublicProfile.btn_solicitar_tiempo}</button>
        }
    }
    let btn_solicitar_reunion_html2 = <></>
    if (user_donated_times && user_donated_times.length > 0) {
        btn_solicitar_reunion_html2 = <button className='btn btn-primary btn-solicitar-reunion' onClick={handleClickDialogOrRedirect} style={{ width: widthButton }}>{textPublicProfile.btn_solicitar_reunion}</button>
    } else {
        btn_solicitar_reunion_html2 = <button className='btn btn-primary btn-solicitar-reunion' onClick={handleClickDialogOrRedirect} style={{ width: widthButton }}>{textPublicProfile.btn_solicitar_tiempo}</button>
    }


    /* PARTE SUPERIOR DEL MODAL  */
    let modal_btns_html = <></>
    if (isModal) {
        modal_btns_html = <div className='top-card-modal-buttons d-flex justify-content-end align-items-center'>
            <div data-toggle="popover" data-content="Disabled popover">
                <button className='btn btn-rounded btn-white mr-2' onClick={() => { navigator.clipboard.writeText(window.location.origin + "/user-profile/" + user_id_profile) }}>
                    <i className="fa fa-clone" ></i> {textPublicProfile.btn_url}
                </button>

            </div>
            <button className='btn btn-rounded btn-secondary' onClick={handleClickCloseDialogPublicProfile}>
                <i className="fas fa-times mr-2"></i> {textPublicProfile.btn_close}
            </button>
        </div>
    } else {
        modal_btns_html = <div className='top-card-modal-buttons d-flex justify-content-end align-items-center'>
            <div data-toggle="popover" data-content="Disabled popover">
                <button className='btn btn-rounded btn-white mr-2' onClick={() => { navigator.clipboard.writeText(window.location.origin + "/user-profile/" + user_id_profile) }}>
                    <i className="fa fa-clone" ></i> {textPublicProfile.btn_url}
                </button>
            </div>
        </div>

    }

    let ultima_compania_html = <></>
    if (user_expertises && user_expertises.length > 0) {
        let item = user_expertises[0]
        let item_id = item.CompanyId
        let companyPicture = (item_id) ? companies.find((el: any) => el.Id == item_id)?.Img : null
        let industria = item.IndustryId ? industries.find((element: any) => element.Id == item.IndustryId)?.Value : ""
        let compania = item.CompanyId ? companies.find((element: any) => element.Id == item.CompanyId)?.Value : ""
        let cargo = item.JobTitleId ? jobtitles.find((element: any) => element.Id == item.JobTitleId)?.Value : ""
        let anioInicio = item.StartDate ? item.StartDate : ""
        let anioTermino = item.EndDate ? item.EndDate : ""
        if (anioTermino == 9999) {
            anioTermino = "Presente"
        }
        let rangoTrabajo = anioInicio + "-" + anioTermino
        let company_img = (companyPicture && companyPicture != null) ? companyPicture : "/media/iconos/icono-empresa.svg"

        ultima_compania_html = <>
            <div className='primary-company-container d-none d-xl-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <p className='primary-company-text'>{cargo}</p>
                    <img src={company_img} alt="" className='primary-company-img' />
                    <p className='text-primary primary-company-text'>{compania}</p>
                    <p className='primary-company-text'> {industria}</p>
                    <p className='primary-company-text mb-0'> {rangoTrabajo}</p>
                </div>
            </div>
        </>
    }

    /* ROLES */
    let emprendedor_html = <></>
    if (esEmprendedor) {
        emprendedor_html = <div className='tag-rol mr-2'>
            {textPublicProfile.label_1}
        </div>
    }
    let experto_html = <></>
    if (esExperto) {
        experto_html = <div className='tag-rol '>
            {textPublicProfile.label_2}
        </div>
    }
    let roles_html = <div className='d-flex justify-content-center justify-content-md-start mb-3'>
        {emprendedor_html}
        {experto_html}

    </div>

    /* feedback */
    let feedback_html = <></>
    if (user_feedbacks_prom_estrellas) {
        feedback_html = <div className='d-flex justify-content-center justify-content-md-start  mb-4'>
            <Rating className="" name="read-only" defaultValue={user_feedbacks_prom_estrellas} value={user_feedbacks_prom_estrellas} readOnly />
        </div>
    } else {
        feedback_html = <div className='d-flex justify-content-center justify-content-md-start  mb-4'>
            <Rating className="" name="read-only" defaultValue={0} value={0} readOnly />
        </div>

    }


    /* RETURN */
    return (
        <div className="user-profile-top-card ">
            <div className='padding-layout-x'>
                {modal_btns_html}
            </div>
            <div className='padding-layout-x pr-xl-0'>
                <div className='row no-gutters'>
                    <div className="col-12 col-lg-4 col-xl-3 ">
                        <div className='d-flex justify-content-center w-100'>
                            {img_usr}
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-6  pr-0 pr-lg-2 pr-xl-3">
                        <div className='d-flex justify-content-center justify-content-md-start  mb-3 mt-4 mt-md-0'>
                            <h2 className='text-primary text-center '>
                                <b>{user_public_data.Name} {user_public_data.LastName}</b>
                            </h2>
                        </div>
                        {roles_html}

                        {feedback_html}


                        <p className='user-profile-top-card-desc text-justify mb-4 text-secondary'>{user_public_data.Description}</p>

                        <div className='userprofile-redes-sociales mb-2 d-flex d-flex justify-content-center justify-content-md-start '>

                            {user_public_data.WebPage && user_public_data.WebPage !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.WebPage}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/web.svg" alt="" />
                                </a> :
                                showWebError ? <p className="text-danger">{textPublicProfile.error_rrss_web}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowWebError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/web.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                            {user_public_data.Facebook && user_public_data.Facebook !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.Facebook}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/facebook.svg" alt="" />
                                </a> :
                                showFacebookError ? <p className="text-danger">{textPublicProfile.error_rrss_fb}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowFacebookError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/facebook.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                            {user_public_data.Instagram && user_public_data.Instagram !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.Instagram}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/instagram.svg" alt="" />
                                </a> :
                                showInstagramError ? <p className="text-danger">{textPublicProfile.error_rrss_insta}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowInstagramError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/instagram.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                            {user_public_data.LinkedIn && user_public_data.LinkedIn !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.LinkedIn}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/linkedin.svg" alt="" />
                                </a> :
                                showLinkedInError ? <p className="text-danger">{textPublicProfile.error_rrss_linkin}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowLinkedInError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/linkedin.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                            {user_public_data.Twitter && user_public_data.Twitter !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.Twitter}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/twitter.svg" alt="" />
                                </a> :
                                showTwitterError ? <p className="text-danger">{textPublicProfile.error_rrss_twitter}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowTwitterError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/twitter.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                            {user_public_data.Youtube && user_public_data.Youtube !== "https://null" ?
                                <a className='userprofile-boton-redes-sociales' target="_blank" rel="noopener noreferrer" href={user_public_data.Youtube}>
                                    <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/youtube.svg" alt="" />
                                </a> :
                                showYoutubeError ? <p className="text-danger">{textPublicProfile.error_rrss_yt}</p> :
                                    <div className="icon-container">
                                        <button style={{ border: 'none', background: 'transparent' }} onClick={() => handleButtonClick(setShowYoutubeError)}>
                                            <img className="userprofile-icono-boton-redes-sociales" src="/media/iconos/rrss/youtube.svg" alt="" />
                                        </button>
                                        <span className="asterisk">!</span>
                                    </div>
                            }

                        </div>

                        <div className='mt-4 d-flex d-flex justify-content-center justify-content-md-start'>
                            {btn_solicitar_reunion_html2}
                        </div>







                        <div className='mt-4 d-flex d-flex justify-content-center justify-content-md-start' >
                            {isCheckingContact ? (
                                <button
                                    className="btn btn-primary btn-solicitar-reunion"
                                    style={{width: widthButton}}
                                    
                                >
                                    {textPublicProfile.cargando_contacto}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary btn-solicitar-reunion"
                                    onClick={handleContactClick}
                                    disabled={isLoadingButton} // Deshabilita el botón mientras carga
                                    style={{ visibility: isLoadingButton ? 'hidden' : 'visible' , width: widthButton}} // Oculta el botón mientras carga
                                >
                                    {isLoadingButton ? textPublicProfile.cargando_contacto : contactButtonText}
                                </button>
                            )}



                        </div>
                    </div>
                    <div className="col-12 col-xl-3  ">
                        {ultima_compania_html}
                    </div>
                </div>
            </div>
            {/* Modal para mostrar mensajes de login */}
            <Dialog
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{textPublicProfile.modal_informacion_titulo}</DialogTitle>
                <DialogContent>
                    <div id="alert-dialog-description">
                        {modalMessage}
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={modalAction} color="primary" autoFocus>
                        {textPublicProfile.iniciar_sesión}
                    </Button>
                    <Button onClick={() => setOpenLoginModal(false)} color="primary">
                        {textPublicProfile.cerrar_modal}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Modal de confirmación para añadir contacto */}
            <Dialog
                open={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{textPublicProfile.titulo_confirmacion}</DialogTitle>
                <DialogContent>
                    <div id="confirm-dialog-description">
                        {textPublicProfile.confirmación_modal_añadir_contacto}
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { setOpenConfirmModal(false); handleAddOrDeleteContact(); }} color="primary" autoFocus>
                        {textPublicProfile.confirmacion_modal_boton}
                    </Button>
                    <Button onClick={() => setOpenConfirmModal(false)} color="primary">
                        {textPublicProfile.cancelar_modal_boton}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmDeleteModal}
                onClose={() => setOpenConfirmDeleteModal(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{textPublicProfile.modal_informacion_titulo}</DialogTitle>
                <DialogContent>
                    <div id="confirm-dialog-description">
                        {textPublicProfile.confirmacion_modal_eliminar_contacto}
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { setOpenConfirmDeleteModal(false); handleAddOrDeleteContact(); }} color="primary" autoFocus>
                        {textPublicProfile.confirmacion_modal_boton}
                    </Button>
                    <Button onClick={() => setOpenConfirmDeleteModal(false)} color="primary">
                        {textPublicProfile.cerrar_modal}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UserProfileExternalTopCard;