import MultiselectAuto from "../Form/MultiselectAuto/MultiselectAuto";

export const MultiSelectInteres = ({ item, diccionario = {}, placeholder }: any) => {

    const renderLabel = (labelValue: any) => {
        if (typeof labelValue === 'object' && labelValue !== null) {
            return "Cargando...";
        }
        return labelValue;
    };

    return (
        <div>
            <MultiselectAuto
                key={"interes-auto-" + item.Id}
                name={"interes-auto-" + item.Id}
                placeholder={renderLabel(placeholder)}
                options={item.Options.map((item_option: any) => {
                    const label = diccionario[item_option.OrigId ? item_option.OrigId : item_option.Id] || "Cargando...";
                    return {
                        label: renderLabel(label),
                        title: renderLabel(label),
                        value: item_option.Id,
                        key: item_option.Id,
                        parent_id: item.Id
                    };
                })}
            >
            </MultiselectAuto>
        </div>
    )
}
