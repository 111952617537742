import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

/* paginas auth */
import SignIn from './pages/Auth/SignIn/SignIn';
import SignUp from './pages/Auth/SignUp/SignUp';
import RecoverPassword from './pages/Auth/RecoverPassword/RecoverPassword';
import RequireAuth from './pages/Auth/RequireAuth/RequireAuth';

/* paginas */
import HomeBuscador from './pages/Buscador/HomeBuscador';
import Landing from './pages/Landing/Landing';
import Home from './pages/Home/Home';
import Messages from './pages/Messages/Messages';
import Meetings from './pages/Meetings/Meetings';
import Meeting from './pages/Meetings/ZoomMeeting/Meeting';
import Contacts from './pages/Contacts/Contacts'
import Favorites from './pages/Favorites/Favorites';
import Match from './pages/Match/Match';
import UserProfileExternal from './pages/UserProfileExternal/UserProfileExternal';
import AdminPanel from './pages/Admin/AdminPanel';
/* paginas ajustes */
import UserPublicData from './pages/Settings/UserPublicData/UserPublicData';
import ChangePassword from './pages/Settings/ChangePassword/ChangePassword';
import Roles from './pages/Settings/Roles/Roles';
import Language from './pages/Settings/Language/Language';
import Appareance from './pages/Settings/Appareance/Appareance';
import UserStartups from './pages/Settings/UserStartups/UserStartups';
import UserPersonalData from './pages/Settings/UserPersonalData/UserPersonalData';
import SignOut from './pages/Auth/SignOut/SignOut';
import UserExperience from './pages/Settings/UserExperience/UserExperience';



/* componentes */

import "./App.scss"
import Alerts from './components/Alerts/Alerts';

/* THEME MATERIAL UI */
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import Navbar from './components/Navegation/Navbar/Navbar';
import VideoCall from './components/TwillioComponents/VideoCall';
import QrReaderComponent from './components/QrReader/QrReaderComponent';
import { useEffect, useState } from 'react';
import { desencriptar, desencriptarToJSON } from './utils/encrypt';
import GetUserPersonalData from './hooks/user/GetUserPersonalData/GetUserPersonalData';
import { useDispatch } from 'react-redux';
import { personalData } from './interfaces/dimentions';
import { setPersonalData, setUserPublicData } from './redux/features/user/userSlice';
import { useGetUserPersonalDataMutation, useGetUserPublicDataMutation } from './redux/features/user/userApiSlice';
import { useGetRolesMutation, useGetSponsorsMutation } from './redux/features/dimensions/dimensionsApiSlice';
import { setRoles, setSponsors } from './redux/features/dimensions/dimensionsSlice';
import { CheckSession } from './components/CheckSession/CheckSession';
import EventsUsers from './pages/Events/EventsUsers';
import HomePrincipal from './pages/Buscador/HomePrincipal';



const etm_theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#009EE3',
    },
    secondary: {
      main: '#DDDDDD',
    },
    divider: 'rgba(0,0,0,0.58)',
  },
  typography: {
    fontSize: 12,
    htmlFontSize: 12,
    fontFamily: '"Lato"',
    fontWeightLight: 600,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  breakpoints: {
    values: {
      xs: 0, // Valor para pantallas extra pequeñas o dispositivos móviles
      sm: 600, // Valor para pantallas pequeñas
      md: 960, // Valor para pantallas medianas
      lg: 1280, // Valor para pantallas grandes
      xl: 1920, // Valor para pantallas extra grandes o de alta resolución
    },
  },
});


function App() {

  return (
    <ThemeProvider theme={etm_theme}>
      <CheckSession>
        <Navbar ></Navbar>
        <Alerts></Alerts>
        <Routes>
          {/* public routes */}
          <Route path="/" >
            <Route index element={<Landing />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="twillio" element={<VideoCall />} />
            <Route path="sign-out" element={<SignOut />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="password-recovery" element={<RecoverPassword />} />
            <Route path="user-profile/:userid" element={<UserProfileExternal />} />
            <Route path="qr-reader" element={<QrReaderComponent />} />
          </Route>
          <Route element={<RequireAuth />}> {/* protected routes */}
            <Route path="user-profile" element={<UserProfileExternal />} />
            <Route path="messages" element={<Messages />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="events" element={<EventsUsers />} />
            <Route path="events/:idactividad" element={<EventsUsers />} />
            <Route path="Admin-Panel" element={<AdminPanel />} />
            <Route path="meetings" element={<Meetings />} />

            {/* subpagina: [meetings_requests, donate_time, next_meetings, historial] */}
            <Route path="meetings/:subpagina" element={<Meetings />} />
            <Route path="zoom-meeting" element={<Meeting />} />
            <Route path="meeting" element={<Meeting />} />
            <Route path="favoritos" element={<Favorites />} />
            <Route path="home" element={<Home />} />
            <Route path="buscador" element={<HomePrincipal />} />
            <Route path="desafios" element={<Home />} />
            <Route path="match" element={<Match />} />
          </Route>
          <Route path="settings" element={<RequireAuth layout={"settings"} />}> {/* protected routes */}
            <Route path="user-public-profile" element={<UserPublicData />} />
            <Route path="user-personal-data" element={<UserPersonalData />} />
            <Route path="user-roles" element={<Roles />} />
            <Route path="user-startups" element={<UserStartups />} />
            <Route path="appareance" element={<Appareance />} />
            <Route path="language" element={<Language />} />
            <Route path="user-experience" element={<UserExperience />} />
            <Route path="change-password" element={<ChangePassword></ChangePassword>} />
          </Route>

        </Routes>
      </CheckSession>

    </ThemeProvider>
  );
}

export default App; 