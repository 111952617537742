

import React from 'react';
import { Rating, Typography } from '@mui/material';

interface RatingSectionProps {
    question: {
        text: string;
        type: 'rating';
        name: string;
        defaultValue?: number;
    };
    value: number;
    onChange: (value: number) => void;
}

const RatingSection: React.FC<RatingSectionProps> = ({ question, value, onChange }) => {
    return (
        <div className="rating-section">
            <Typography variant="subtitle1" gutterBottom className="label">
                {question.text}
            </Typography>
            <Rating
                name={question.name}
                value={value}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        onChange(newValue);
                    }
                }}
                max={5}
                sx={{
                    color: '#1e88e5',
                    fontSize: {
                        xs: '1.75rem',
                        sm: '2rem',
                        md: '2rem',
                        lg: '2rem',
                    },
                }}
            />
        </div>
    );
};

export default RatingSection;
