
export function capitalizeFirstLetter(str: string): string {
    let spaces = str.toLocaleLowerCase().split(" ");
    let str_aux = "";
    for (let i = 0; i < spaces.length; i++) {
        const str_i = spaces[i];
        let condicion_uniones_cortas_minusculas = (
            (str_i.toUpperCase() == "DE") ||             
            (str_i.toUpperCase() == "LA") ||
            (str_i.toUpperCase() == "Y") ||
            (str_i.toUpperCase() == "E") ||
            (str_i.toUpperCase() == "OF") ||
            (str_i.toUpperCase() == "UNA") ||
            (str_i.toUpperCase() == "A") ||
            (str_i.toUpperCase() == "A")
        )
        let condicion_uniones_cortas_mayusculas = (
            (str_i.toUpperCase() == "B2B") ||
            (str_i.toUpperCase() == "CEO") ||
            (str_i.toUpperCase() == "CTO") ||
            (str_i.toUpperCase() == "CIO") ||
            (str_i.toUpperCase() == "CFO") ||
            (str_i.toUpperCase() == "TI")
        )
        if (condicion_uniones_cortas_minusculas) {
            str_aux = str_aux + " " + str_i.toLowerCase();
        } else if (condicion_uniones_cortas_mayusculas) {
            str_aux = str_aux + " " + str_i.toUpperCase();
        } else {
            str_aux = str_aux + " " + str_i.charAt(0).toUpperCase() + str_i.slice(1);
        }

    }
    return str_aux;
}

export function getWordsLimit(str: string, word_limit: number): string {

    let spaces = str.toLocaleLowerCase().split(" ");
    let str_aux = "";

    for (let i = 0; i < word_limit; i++) {
        const str_i = spaces[i];
        str_aux = str_aux + " " + str_i.charAt(0).toUpperCase() + str_i.slice(1);
    }
    return str_aux;
}

export function getCharLimit(str: string, char_limit: number): string {
    let len = str.length
    if (len > char_limit) {
        return str.substring(0, char_limit) + "..."

    } else {
        return str
    }
}