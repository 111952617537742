import { createSlice } from "@reduxjs/toolkit"

import moment from 'moment';

const initialState = {
    user_personal_data: "",
    user_languages: [],
    user_tags: [],
    user_public_data: null,
    user_public_profile: null,
    user_degrees: [],
    user_expertises: [],
    roles: [],
    user_interests: [],
    user_managements: [],
    user_notifications: []
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setPersonalData: (state, action) => {
            const { personal_data } = action.payload
            let fecha = moment(personal_data.DateOfBirth).format('DD-MM-YYYY')
            let anio = moment(personal_data.DateOfBirth).format('YYYY')
            let mes = moment(personal_data.DateOfBirth).format('MM')
            let dia = moment(personal_data.DateOfBirth).format('DD')
            state.user_personal_data = { ...personal_data, AnioNacimiento: anio, MesNacimiento: mes, DiaNacimiento: dia }
        },
        setUserPublicProfile: (state, action) => {
            const { public_profile } = action.payload
            state.user_public_profile = public_profile
        },

        setUserTags: (state, action) => {
            const { user_tags } = action.payload
            state.user_tags = user_tags
        },

        setUserLanguages: (state, action) => {
            const { user_languages } = action.payload
            state.user_languages = user_languages
        },
        setUserPublicData: (state, action) => {
            const { user_public_data } = action.payload
            state.user_public_data = user_public_data
        },
        setRoles: (state, action) => {
            const { roles } = action.payload
            state.roles = roles
        },
        setUserExpertises: (state, action) => {
            const { user_expertises } = action.payload
            state.user_expertises = user_expertises
        },
        setUserDegrees: (state, action) => {
            const { user_degrees } = action.payload
            state.user_degrees = user_degrees
        },
        setUserInterests: (state, action) => {
            const { user_interests } = action.payload
            state.user_interests = user_interests
        },
        setUserManagements: (state, action) => {
            const { user_managements } = action.payload
            state.user_managements = user_managements
        },
        setUserNotifications: (state, action) => {
            const { user_notifications } = action.payload
            state.user_notifications = user_notifications
        },
        deleteInfoUser: () => initialState,

    },
})

export const {
    setPersonalData,
    setUserPublicProfile,
    setUserLanguages,
    setUserPublicData,
    setUserTags,
    setRoles,
    setUserExpertises,
    setUserDegrees,
    setUserInterests,
    setUserManagements,
    setUserNotifications,
    deleteInfoUser
} = userSlice.actions

export default userSlice.reducer


//recordar que se debe agregar el reducer en el store.ts

export const selectPersonalData = (state: any) => state.user.user_personal_data
export const selectPublicProfile = (state: any) => state.user.user_public_profile
export const selectUserTags = (state: any) => state.user.user_tags
export const selectLanguages = (state: any) => state.user.languages
export const selectUserLanguages = (state: any) => state.user.user_languages
export const selectPublicData = (state: any) => state.user.user_public_data
export const selectRoles = (state: any) => state.user.roles
export const selectUserDegrees = (state: any) => state.user.user_degrees
export const selectUserExpertises = (state: any) => state.user.user_expertises
export const selectUserInterests = (state: any) => state.user.user_interests
export const selectUserManagements = (state: any) => state.user.user_managements
export const selectUserNotifications = (state: any) => state.user.user_notifications
