
import { Chip } from '@mui/material';

const FiltrosHomeSeleccionados = ({ selectedSponsors, handleSponsorClick }: any) => {

    let selectedSponsors_html = <></>
    if (selectedSponsors && selectedSponsors.length > 0) {
        selectedSponsors_html = <div className="mb-3 "> {/* Filtros sponsors */}
            <br />
            <div>
                <p className="mb-2 font-italic">Sponsors</p>
            </div>
            <div className="row mb-2 filtros-sponsors no-gutters">
                {
                    selectedSponsors?.map((sponsor: any) => {
                        return (
                            <div className='col-auto mr-2 mb-2'>
                                <Chip label={sponsor.Value} className='' onDelete={(event) => { handleSponsorClick(event, sponsor) }} />
                            </div>
                        )

                    })
                }
            </div>
        </div>
    }

    return (
        <div>
            {selectedSponsors_html}
        </div>
    )

}

export default FiltrosHomeSeleccionados;
