
import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import Checkbox from '../../../components/Form/Checkbox'
import Select from '../../../components/Form/Select'
import Label from '../../../components/Form/Label'

import { useEffect, useState } from 'react';
import { useCheckUserExistsMutation } from '../../../redux/features/auth/authApiSlice';
import moment from 'moment';



/* funciones utiles formularios */
import {
    get_arr_meses,
    get_arr_anios,
    get_arr_dias,
    get_opciones_dias,
    get_opciones_anios,
    get_opciones_meses,
    get_opciones_generos,
    get_opciones_countries,
    get_opciones_companies,
    get_opciones_industries,
    get_opciones_jobtitles,
    validatePassword,
    validateEmail,
    validatePhoneNumber
} from '../../../utils/utilsForms';

import { useSelector } from 'react-redux'

import { selectCountries, setCountries } from '../../../redux/features/dimensions/dimensionsSlice';
import SignUpStepper from './SignUpStepper'
import { useDispatch } from 'react-redux'
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { useGetCountriesMutation } from '../../../redux/features/dimensions/dimensionsApiSlice'

const SignUpPageOne = ({ ChangeFormState, actual_values }: any) => {

    const [checkEmailExists, { isLoadingCheckEmailExists }]: any = useCheckUserExistsMutation()
    const [getCountries, { isLoadingCountries }]: any = useGetCountriesMutation()

    const textSignup = useSelector(selectCurrentDictonary).signup
    const textCountry = useSelector(selectCurrentDictonary).Country
    const textGeneral = useSelector(selectCurrentDictonary).general.generos
    const textMeses = useSelector(selectCurrentDictonary).general.meses

    let anio_actual = moment().year();
    let max_edad = 99;
    let min_anio = anio_actual - max_edad;

    const [actualAnioMes, setActualAnioMes] = useState(min_anio + "-01")
    const [diasMes, setDiasMes] = useState(moment(actualAnioMes, "YYYY-MM").daysInMonth())
    const [showPasswords, setShowpasswords] = useState(false)
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(false)

    const countries = useSelector(selectCountries)

    let type_passwords = "password"
    if (showPasswords) {
        type_passwords = "text"
    }

    const dispatch = useDispatch()


    // ================================================================================================================================================================ 
    // ==================================================================== OPCIONES SELECTS ==========================================================================
    // ================================================================================================================================================================ 
    let opciones_anios = get_opciones_anios(16, 100, textSignup)
    let opciones_meses = get_opciones_meses(textMeses)
    let opciones_dias = get_opciones_dias(actualAnioMes)

    // ================================================================================================================================================================ 
    // ==================================================================== VALIDACION ==========================================================================
    // ================================================================================================================================================================ 
    const validate = (values: any) => {
        const errors: any = {}
        /* setValidated(false)
 */

        // Validacion Nombre 
        if (!values.personal_data_name) {
            errors.personal_data_name = textSignup.error_required
        }

        // Validacion Apellido 
        if (!values.personal_data_parent_name) {
            errors.personal_data_parent_name = textSignup.error_required
        }
        // Validacion Pais 
        if (!values.personal_data_country) {
            errors.personal_data_country = textSignup.error_required
        }

        // Validacion EMAIL 
        let errors_email = validateEmail(values.personal_data_email, textSignup)
        if (errors_email) {
            errors.personal_data_email = errors_email
        }
        // Validacion PHONE 
        if (values.personal_data_phone && validatePhoneNumber(values.personal_data_phone)) {
            errors.personal_data_phone = textSignup.error_phone
        }
        /* if (values.personal_data_phone && values.personal_data_phone.length < 8) {
            errors.personal_data_phone = 'El numero de telefono debe tener 9 digitos'
        } */





        // Validacion CONFIRMPASSWORD 
        if (!values.personal_data_confirmpassword) {
            errors.personal_data_confirmpassword = textSignup.error_required
        }

        if (!(!values.personal_data_password) && !(!values.personal_data_confirmpassword)) {
            if (values.personal_data_confirmpassword !== values.personal_data_password) {
                errors.personal_data_confirmpassword = textSignup.param_1_11
            }
        }
        //  Validacion PASSWORD 


        let errors_psw = validatePassword(values.personal_data_password, textSignup)
        if (errors_psw) {
            errors.personal_data_password = errors_psw
        }


        // Validacion SHOW PASSWORD
        if (values.personal_data_showPasswords !== showPasswords) {
            setShowpasswords(values.personal_data_showPasswords)
        }


        if (!values.personal_data_anio_nacimiento) {
            errors.personal_data_anio_nacimiento = textSignup.error_required
        }
        if (!values.personal_data_mes_nacimiento) {
            errors.personal_data_mes_nacimiento = textSignup.error_required
        }
        if (!values.personal_data_dia_nacimiento) {
            errors.personal_data_dia_nacimiento = textSignup.error_required
        }

        if (values.personal_data_anio_nacimiento && values.personal_data_anio_nacimiento) {
            let anioMes = values.personal_data_anio_nacimiento + "-" + values.personal_data_mes_nacimiento
            if (anioMes !== actualAnioMes) {
                setActualAnioMes(anioMes)
                setDiasMes(moment(anioMes, "YYYY-MM").daysInMonth())
            }
        }





        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
        } else {
            setValidated(false)
        }
        return errors
    }



    // ================================================================================================================================================================ 
    // ==================================================================== SUMBMIT: Continuar ==========================================================================
    // ================================================================================================================================================================ 
    /* HANDLE FORM SUBMIT */
    async function handleSubmit(values: any, { setStatus, setErrors }: any) {

        let new_alert = { id: "cargando_correo", type: "loading", title: textSignup.alerta_cargando, desc: textSignup.alerta_email, close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {

            setLoading(true)
            let aux_email = values.personal_data_email.replace('@', '%40')
            const res_check_email: any = await checkEmailExists({ EmailAddress: aux_email });
            setLoading(false)
            if (res_check_email.data.statusCode === 200) {
                ChangeFormState({ ...values }, 2)

                dispatch(deleteAlert({ alert_id: "error_correo" }))
                dispatch(deleteAlert({ alert_id: "cargando_correo" }))
                let new_alert_2 = { type: "success", title: textSignup.alerta_mail_2, desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

            } else {
                setErrors({ email: textSignup.param_3 });
                // Alerta error 

                dispatch(deleteAlert({ alert_id: "cargando_correo" }))
                dispatch(deleteAlert({ alert_id: "error_correo" }))
                let new_alert_error = { id: "error_correo", type: "error", title: textSignup.param_3, desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_error }))
            }
        } catch (error) {
            // Alerta error 

            dispatch(deleteAlert({ alert_id: "cargando_correo" }))
            dispatch(deleteAlert({ alert_id: "error_correo" }))
            let new_alert_error = { id: "error_correo", type: "error", title: textSignup.alerta_mail_3, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert_error }))

        }

    }


    let opciones_generos = get_opciones_generos(textGeneral)
    let opciones_countries = get_opciones_countries(countries, isLoadingCountries, textCountry)
    let opciones_countries_html = opciones_countries.html
    let opciones_countries_disabled = opciones_countries.disabled



    useEffect(() => {
        if (!countries || countries.length == 0) {
            // Peticion inicial de paises
            const fetchCountries = async () => {
                const res: any = await getCountries();
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setCountries({ countries: resdata }))
            }
            fetchCountries().catch(console.error);
        }
    }, [])

    /* RETURN */
    return (
        <Formik
            initialValues={actual_values}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            <Form className='px-lg-3 px-xl-5'>
                <div className='mb-3 mb-lg-4 mt-3 mt-lg-4'>
                    <h3 className='text-center mb-3'>{textSignup.titulo_1}<div className='text-primary d-inline mb-0'>meet</div></h3>

                    <div className='d-flex justify-content-center align-items-center mb-4'>
                        <SignUpStepper step={actual_values.step - 1}></SignUpStepper>
                    </div>
                </div>
                <div className='row'>
                    {/* linea */}
                    <div className='col-12 col-lg-6 mb-3 mb-lg-4'>
                        <TextInput name="personal_data_name" label={textSignup.param_1_1} placeholder={textSignup.placeholder_2} required={true} />
                    </div>
                    <div className='col-12 col-lg-6 mb-3 mb-lg-4'>
                        <TextInput name="personal_data_parent_name" label={textSignup.param_1_2} placeholder={textSignup.placeholder_3} required={true} />
                    </div>
                    {/* linea */}
                    <div className='col-12 col-lg-6 mb-3 mb-lg-4'>
                        <TextInput name="personal_data_email" label={textSignup.param_1_3} placeholder={textSignup.placeholder_1} required={true} />
                    </div>
                    <div className='col-12 col-lg-6  mb-3 mb-lg-4'>
                        <TextInput name="personal_data_phone" type="tel" label={textSignup.param_1_4} placeholder={"+12312345678"} pattern="+[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{3}" />
                    </div>
                    {/* linea  */}
                    <div className='col-12 col-lg-6 mb-3 mb-lg-4'>
                        <Label classNameLabel='label-primary label' label={textSignup.param_1_5} required={true}></Label>
                        <div className='d-flex justify-content-start'>
                            <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" placeholder={textSignup.placerholder_6} name="personal_data_anio_nacimiento" label="">
                                {opciones_anios}
                            </Select>
                            <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" placeholder={textSignup.placeholder_7} name="personal_data_mes_nacimiento" label="">
                                {opciones_meses}
                            </Select>
                            <Select classNameLabel="label-secondary" placeholder={textSignup.placeholder_8} name="personal_data_dia_nacimiento" label="">
                                {opciones_dias}
                            </Select>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6  mb-3 '>
                        <Select className="w-100 " name="personal_data_gender" label={textSignup.param_1_6}>
                            {/* <option value="MASCULINO">Masculino</option>
                            <option value="FEMENINO">Femenino</option>
                            <option value="Prefiero no decir">Prefiero no decir</option>
                            <option value="Otro">Otro</option> */}
                            {opciones_generos}
                        </Select>
                    </div>
                    {/* Linea */}
                    <div className='col-12 col-lg-6  mb-3 '>

                        <Select className="w-100" disabled={opciones_countries_disabled} name="personal_data_country" label={textSignup.param_1_7} required={true}>
                            {opciones_countries_html}
                        </Select>
                    </div>
                    {/* <div className='col-12 col-lg-6  mb-3 '>
                        <TextInput name="language" label="Idioma" required={true} />
                    </div> */}

                    <div className='col-lg-6  mb-3 '>
                    </div>

                    {/* Linea */}
                    <div className='col-12 col-lg-6  mb-3 '>
                        <TextInput name="personal_data_password" type={type_passwords} placeholder={textSignup.placeholder_4} label={textSignup.param_1_8} required={true} />
                        <label className="definicion-password" htmlFor="">{textSignup.def_pass_1}</label><br />
                        <label className="definicion-password" htmlFor="">{textSignup.def_pass_2}</label><br />
                        <label className="definicion-password" htmlFor="">{textSignup.def_pass_3}</label><br />
                        <label className="definicion-password" htmlFor="">{textSignup.def_pass_4}</label><br />
                        <label className="definicion-password" htmlFor="">{textSignup.def_pass_5}</label><br />
                    </div>
                    <div className='col-12 col-lg-6  mb-0'>
                        <TextInput name="personal_data_confirmpassword" type={type_passwords} placeholder={textSignup.placeholder_4} label={textSignup.param_1_9} required={true} />
                    </div>
                    <div className='col-12 mt-3 '>
                        <Checkbox name="personal_data_showPasswords">
                            {textSignup.param_1_10}
                        </Checkbox>
                    </div>
                    <div className='col-12 '>
                        <div className='d-flex justify-content-end'>
                            <button disabled={!validated} type="submit" className='btn btn-rounded btn-primary'>
                                {textSignup.btn_1}
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

export default SignUpPageOne;