//import React, {useState} from 'react';
import './CardExperiencia.scss'

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Card from "../Card/Card"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

function CardExperiencia({ industria, compania, foto_compania, cargo, anioInicio, anioTermino, canEdit = false, handleEditBtn, handleDeleteBtn, number }: any) {

	const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

	let company_img = (foto_compania) ? foto_compania : "/media/iconos/icono-empresa.svg"

	let buttons_canEdit_html = <></>
	if (canEdit) {
		buttons_canEdit_html = <div className='d-flex flex-row justify-content-center align-items-center mt-2 w-100'>
			<button className='btn btn-block btn-rounded btn-sm btn-outline-primary m-0 mr-2 ' onClick={handleEditBtn}>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<CreateOutlinedIcon fontSize='small' />
					</div>
					<p>{textPublicProfile.btn_editar}</p>
				</div>
			</button>
			<button className='btn btn-block btn-rounded btn-sm  btn-outline-danger m-0' onClick={handleDeleteBtn}>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<DeleteForeverOutlinedIcon fontSize='small' />
					</div>
					<p>{textPublicProfile.btn_eliminar}</p>
				</div>
			</button>
		</div>
	}

	let fechas_aux = anioInicio + " - " + anioTermino
	if (anioTermino == 9999) {
		fechas_aux = anioInicio + " - Presente"
	}
	return (
		<>
			<Card className='CardExperiencia p-4 px-lg-5'>
				<div className='CardExperiencia-number'>
					{number + 1}
				</div>
				<div className='d-flex flex-column flex-lg-row justify-content-between'>
					<div className='d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start center align-items-center'>
						<div className='mr-0 mr-lg-3'>
							<div className={'CardExperiencia-fotoCompany-container' + (company_img ? " mb-4 mb-lg-0" : "")}>
								<img className="CardExperiencia-fotoCompany" src={company_img} alt="" />

							</div>
						</div>
						<div className=''>

							<h4 className="text-center text-lg-left CardExperiencia-cargo mb-1"><b>{cargo}</b></h4>
							<p className='text-center text-lg-left CardExperiencia-compania'>{compania}</p>
							<p className='text-center text-lg-left CardExperiencia-industria mb-1'>{industria}</p>
							<p className='text-center text-lg-left CardExperiencia-date'>{fechas_aux}</p>
						</div>
					</div>
				</div>
				<div className=''>
					{buttons_canEdit_html}
				</div>
			</Card>
		</>
	)
}
export default CardExperiencia;