import CryptoJS from "crypto-js";

export const encriptarJSON = (objeto: any) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(objeto),
    "pA%z57C$pw-AN_!/M}nP}yumGMKdgs"
  ).toString();
};

export const encriptarString = (string: string) => {
  return CryptoJS.AES.encrypt(string, "pA%z57C$pw-AN_!/M}nP}yumGMKdgs").toString();
};

export const desencriptarToJSON = (string: string) => {
  try {
    var bytes = CryptoJS.AES.decrypt(string, "pA%z57C$pw-AN_!/M}nP}yumGMKdgs");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.log(error)
    return null
  }
};

export const desencriptar = (string: string) => {
  var bytes = CryptoJS.AES.decrypt(string, "pA%z57C$pw-AN_!/M}nP}yumGMKdgs");
  return bytes.toString(CryptoJS.enc.Utf8);
};
