
import moment from 'moment';
//import bcrypt from 'bcryptjs'
export function validatePhoneNumber(input_str: string) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
}
export function hasSpecialCharacters(string: string) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(string)) {
        return true;
    } else {
        return false;
    }

}

/* import bcrypt from 'bcryptjs' */
export function isValidUrl(string: string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

export function changeDonatedTimeZone(timeZone: number, date: string, startTime: string, endTime: string) {
    let startDateTime = date + " " + startTime;
    let endDateTime = date + " " + endTime;
    let startTime_aux = moment(startDateTime, "YYYY-MM-DD HH:mm").add(timeZone, "hours").format("HH:mm")
    let endTime_aux = moment(endDateTime, "YYYY-MM-DD HH:mm").add(timeZone, "hours").format("HH:mm")
    let date_aux = moment(startDateTime, "YYYY-MM-DD HH:mm").add(timeZone, "hours").format("YYYY-MM-DD")

    let dict = {
        date: date_aux,
        startTime: startTime_aux,
        endTime: endTime_aux,
    }
    return dict;
}

export function changeDateTimeZone(timeZone: number, datetime: string) {
    if (!datetime || !datetime.includes("T")) { /* este if es nuevo, para evitar errores con los formatos de fecha,m solo se agregaron este bloque if, lo demás está tal cual*/ 
         
        return { date: "", time: "" };
    }
    let date = datetime.split("T")[0]
    let time = datetime.split("T")[1].split(":")[0] + ":" + datetime.split("T")[1].split(":")[1]
    let date_time_aux = date + " " + time;

    let date_aux = moment(date_time_aux, "YYYY-MM-DD HH:mm").add(timeZone, "hours").format("YYYY-MM-DD")
    let time_aux = moment(date_time_aux, "YYYY-MM-DD HH:mm").add(timeZone, "hours").format("HH:mm")

    let dict = { date: date_aux, time: time_aux }
    return dict;
}

/* ANIOS */
export function get_arr_anios(min_edad: any, max_edad: any) {
    let anio_actual = 2023;
    let max_anio = anio_actual - min_edad + 1;
    let min_anio = anio_actual - max_edad;
    let arr_anios = []
    for (let anio = max_anio; anio > min_anio; anio--) {
        arr_anios.push(anio)
    }
    return arr_anios
}

export function get_opciones_anios(min_edad: any, max_edad: any, textSignup: any, anio_actual: any = false, agregar_opcion_presente: any = false) {
    let opcion_presente = <></>
    if (agregar_opcion_presente) {
        opcion_presente = <option key={"opcion_anio_presente"} value={0}>{textSignup.exp_anios}</option>
    }
    let opciones_dias = <>
        <option key={"opcion_anio_vacia"} value={""}>{ }</option>
        {
            [...get_arr_anios(min_edad, max_edad)].map((anio) => {
                return (
                    <option key={"opcion_anio_" + anio} value={anio}>{anio}</option>
                );
            })
        }
    </>
    return opciones_dias
}


export function get_opciones_anios_experiencia_laboral(min_edad: any, max_edad: any, agregar_opcion_presente: any = false, textSignup: any) {
    let opcion_presente = <></>
    if (agregar_opcion_presente) {
        opcion_presente = <option key={"opcion_anio_presente"} value={9999}>{textSignup.exp_anios}</option>
    }
    let opciones_dias = <>
        <option key={"opcion_anio_vacia"} value={""}>{ }</option>
        {opcion_presente}
        {
            [...get_arr_anios(min_edad, max_edad)].map((anio) => {
                return (
                    <option key={"opcion_anio_" + anio} value={anio}>{anio}</option>
                );
            })
        }
    </>
    return opciones_dias
}


/* MESES */
export function get_arr_meses(textMeses: any) {
    let arr_meses = [

        { id: 1, value: textMeses.ene },
        { id: 2, value: textMeses.feb },
        { id: 3, value: textMeses.mar },
        { id: 4, value: textMeses.abr },
        { id: 5, value: textMeses.may },
        { id: 6, value: textMeses.jun },
        { id: 7, value: textMeses.jul },
        { id: 8, value: textMeses.ago },
        { id: 9, value: textMeses.sep },
        { id: 10, value: textMeses.oct },
        { id: 11, value: textMeses.nov },
        { id: 12, value: textMeses.dic },
    ]
    return arr_meses
}
export function get_opciones_meses(textMeses: any) {
    let opciones_dias = <>

        <option key={""} value={""}>{ }</option>
        {
            [...get_arr_meses(textMeses)].map((val) => {
                return (
                    <option key={val.value} value={val.id}>{val.value}</option>
                );
            })
        }</>
    return opciones_dias
}

/* DIAS */
export function get_arr_dias(cant_dias_mes: any) {
    let arr_dias = []
    for (let dia = 1; dia <= cant_dias_mes; dia++) {
        let dia_aux = dia < 10 ? "0" + dia : "" + dia
        arr_dias.push(dia_aux)
    }
    return arr_dias
}
export function get_opciones_dias(anio_mes: any) {
    let cant_dias_mes = moment(anio_mes, "YYYY-MM").daysInMonth()
    let opciones_dias = <>
        <option key={""} value={""}>{ }</option>
        {
            [...get_arr_dias(cant_dias_mes)].map((dia) => {
                return (
                    <option key={dia} value={dia}>{dia}</option>
                );
            })
        }</>
    return opciones_dias
}

/* GENEROS */
/* GENEROS */
export function get_arr_generos(textGeneral: any) {
    let arr_generos = [
        { id: "FEMENINO", value: textGeneral.gen_f },
        { id: "MASCULINO", value: textGeneral.gen_m },
        { id: "Prefiero no decir", value: textGeneral.gen_x },
        { id: "Otro", value: textGeneral.gen_o },

    ]
    return arr_generos
}
export function get_opciones_generos(textGeneral: any) {
    let opciones_generos = <>
        {
            [...get_arr_generos(textGeneral)].map((val) => {
                return (
                    <option key={val.id} value={val.id}>{val.value}</option>
                );
            })
        }</>
    return opciones_generos
}

/* PASSWORD */
export function validatePassword(password: string, textSignup: any) {
    let error = undefined;

    if (!password) {
        error = textSignup.error_required
    } else if (password.length <= 5) {
        error = textSignup.def_pass_1_1
    } else if (!/[A-Z]/.test(password)) {
        error = textSignup.def_pass_2
    } else if (!/[a-z]/.test(password)) {
        error = textSignup.def_pass_3
    } else if (!/[1-9]/.test(password)) {
        error = textSignup.def_pass_4
    } else if (!/(?=.*[!@#$%^&*\.])/.test(password)) {
        error = textSignup.def_pass_5
    } else {
        return error

    }
    return error

}

export function validateEmail(email: string, textSignup: any) {
    let error = "";
    if (!email) {
        error = textSignup.error_required
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        error = textSignup.alerta_mail_4
    }
    return error
}



/* COUNTRIES */
export function get_opciones_countries(countries: any, isLoadingCountries: any, diccionarioCountry: any) {

    return (get_opciones_html(countries, isLoadingCountries, diccionarioCountry))
}

/* REGIONS */
/* export function get_opciones_regions(regions: any, isLoadingRegions: any) {
} */

/* COMPANIES */
export function get_opciones_companies(companies: any, isLoadingCompanies: any) {
    if (companies && companies.length > 0) {
        let html = <>
            <option key={""} value={"0"} >{""}</option>
            {
                companies.map((item: any) => {
                    return (
                        <option key={item.Id} value={item.Id}>{item.Value}</option>
                    );
                })
            }
        </>
        return { "html": html, disabled: false }
    } else if (isLoadingCompanies) {
        let html = <option >Loading...</option>
        return { "html": html, disabled: true }
    } else {
        let html = <option >Loading...</option>
        return { "html": html, disabled: true }
    }
}
/* INDUSTRIES */
export function get_opciones_industries(industries: any, isLoadingIndustries: any, diccionarioIndustrias: any) {
    return (get_opciones_html(industries, isLoadingIndustries, diccionarioIndustrias))
}
/* JOBTITLES */
export function get_opciones_jobtitles(jobtitles: any, isLoadingJobtitles: any, diccionarioJobtitles: any) {

    return (get_opciones_html(jobtitles, isLoadingJobtitles, diccionarioJobtitles))
}


export function get_opciones_html(arreglo: any, isLoading: any, diccionario: any) {
    if (arreglo && arreglo.length > 0) {
        let html = <>
            <option key={""} value={"0"} >{""}</option>
            {
                arreglo.map((item: any) => {
                    return (
                        //<option key={item.Id} value={item.Id}>{item.Value}</option>
                        <option key={item.Id} value={item.Id}>{diccionario[item.Id]}</option>

                    );
                })
            }
        </>
        return { "html": html, disabled: false }
    } else if (isLoading) {
        let html = <option >Loading...</option>
        return { "html": html, disabled: true }
    } else {
        /* let html = <option >Error</option> */
        /* let html = <option ></option> */
        let html = <option >Loading...</option>
        return { "html": html, disabled: true }
    }
}

const bcrypt = require('bcryptjs');

export async function encryptPassword(password: any) {
    const salt = await bcrypt.genSalt(10);
    const hash = await bcrypt.hash(password, salt);
    return "1.Aa" + password;
    return "1.Aa" + hash;
    // hay que buscar forma de usar bcrypt dentro de cognito
}