import { PayloadAction, createSlice } from "@reduxjs/toolkit"


// export interface initialStateNotifications {
//     notifications: 0,
// }

// const initialState: any = {
//     notifications: 0,
// }

// const notificationsSlice = createSlice({
//     name: 'notificationsCounter',
//     initialState: 0,
//     reducers: {

//         addNotification: (state) => state + 1,
//         notificationRead: (state) => state - 1,
//         resetNotifications: () => 0,
//     },
// })

// export const { addNotification, notificationRead, resetNotifications } = notificationsSlice.actions

// export default notificationsSlice.reducer

// export const selectNotificationMeeting = (state: any) => state.notificationsCounter


const initialState: any = {
    notifications: [],
    data: {}
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {

        addNotification: (state, action) => {
            state.notifications.push(action.payload)
        },
        notificationRead: (state, action) => {
            state.notifications = state.notifications.filter((notification: any) => notification.id !== action.payload.id)
        },
        resetNotifications: (state) => {
            state.notifications = []
        },
        addNotificationTime: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        }
         
    },
})

export const { addNotification, notificationRead, resetNotifications , addNotificationTime } = notificationsSlice.actions

export default notificationsSlice.reducer

export const selectNotificationMeeting = (state: any) => state.notifications
