import TextInput from '../../components/Form/TextInput';
import { Formik, Form } from 'formik';

const EventForm = ({ handleClose, handleSubmit, isSubmitting, initialValues }: any) => {
    const validateForm = (values: any) => {
        let errors: any = {};
        if (!values.event) {
            errors.event = 'Este campo es obligatorio';
        }
        if (!values.date) {
            errors.date = 'Este campo es obligatorio';
        }
        if (!values.endDate) {
            errors.endDate = 'Este campo es obligatorio';   
        }
        if (!values.type) {
            errors.type = 'Seleccione una opción';
        }
        if (!values.description) {
            errors.description = 'Este campo es obligatorio';
        }
        if (!values.qr) {
            errors.qr = 'Este campo es obligatorio';
        }
        return errors;
    };

    const handleSubmitForm = (values: any) => {
        handleSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues || {
                event: '',
                date: '',
                endDate: '',   
                type: '',
                description: '',
                qr: '',
                visible: 1
            }}
            validate={validateForm}
            onSubmit={handleSubmitForm}
            enableReinitialize
        >
            <Form>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <TextInput name="event" label="Evento" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="datetime-local" name="date" label="Fecha de inicio" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="datetime-local" name="endDate" label="Fecha de término" required={true} />   
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput name="type" label="Tipo" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput name="description" label="Descripción" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput name="qr" label="QR" required={true} />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default EventForm;
