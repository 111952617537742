import './CardStartup.scss'

import Card from "../Card/Card"
import { Avatar } from '@mui/material';
import { getCharLimit } from '../../utils/utilsText';
import GetInterests from '../../hooks/dimensions/GetInterests/GetInterests';
import GetCountries from '../../hooks/dimensions/GetCountries/GetCountries';
import GetIndustries from '../../hooks/dimensions/GetIndustries/GetIndustries';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { text } from 'node:stream/consumers';

function CardStartup({ startup, canEdit = false, handleEditBtn, handleDeleteBtn, number = 0 }: any) {
	const textStartups = useSelector(selectCurrentDictonary).startups
	const textIndustrias = useSelector(selectCurrentDictonary).industries
	const textCountry = useSelector(selectCurrentDictonary).Country
	const { bill_ranges: textBillRange, investmentsource: textInvestment, numeroempleados: textEmpleados } = useSelector(selectCurrentDictonary).filtros_interes;


	const industries = GetIndustries()
	const countries = GetCountries()
	const intereses = GetInterests();

	let lista_intereses: any = []

	let startup_foto = (startup && startup.StartupPhoto != null && !startup.StartupPhoto.includes("static.iris")) ? startup.StartupPhoto : "/media/iconos/icono-startup.svg"

	if (intereses) {
		if (startup.Country) {
			let seleccionado_country = countries.find((el: any) => startup.Country == el.Id) || {}
			const text_country = textCountry[seleccionado_country.Id]
			lista_intereses.push(text_country)
		}
		if (startup.Industry) {
			let seleccionado_industry = industries.find((el: any) => startup.Industry == el.Id) || {}
			const text_industry = textIndustrias[seleccionado_industry.Id]
			lista_intereses.push(text_industry)
		}
		if (startup.BillingRange) {
			let seleccionado_billing_ranges = intereses.find((el: any) => el.Id == 1).Options.find((el: any) => startup.BillingRange == el.Id) || {}
			const text_billrange = textBillRange[seleccionado_billing_ranges.Id]
			lista_intereses.push(text_billrange)
		}
		if (startup.InvestmentSource) {
			let seleccionado_investment_source_levels = intereses.find((el: any) => el.Id == 10).Options.find((el: any) => startup.InvestmentSource == el.Id) || {}
			const text_investment = textInvestment[seleccionado_investment_source_levels.Id]
			lista_intereses.push(text_investment)
		}

		if (startup.EmployeesNumber) {
			let seleccionado_employees_number = intereses.find((el: any) => el.Id == 15).Options.find((el: any) => startup.EmployeesNumber == el.Id) || {}
			const text_empleados = textEmpleados[seleccionado_employees_number.Id]
			lista_intereses.push(text_empleados)
		}
		if (startup.LedByWomen) {
			let seleccionado_woman_leadership = intereses.find((el: any) => el.Id == 14).Options.find((el: any) => startup.LedByWomen == el.Id)?.Id
			if (seleccionado_woman_leadership == 46) {
				lista_intereses.push(textStartups.con_poli_mujer)
			}
			if (seleccionado_woman_leadership == 47) {
				lista_intereses.push(textStartups.sin_poli_mujer)
			}
		}
		if (startup.GenderEqualityPolicies) {
			let seleccionado_gender_equality = intereses.find((el: any) => el.Id == 8).Options.find((el: any) => startup.GenderEqualityPolicies == el.Id)?.Id
			if (seleccionado_gender_equality == 117) {
				lista_intereses.push(textStartups.con_poli_gen)
			}
			if (seleccionado_gender_equality == 118) {
				lista_intereses.push(textStartups.sin_poli_gen)
			}
		}

		if (startup.DiversityInclusionPolicies) {
			let seleccionado_diversity_inclusion = intereses.find((el: any) => el.Id == 5).Options.find((el: any) => startup.DiversityInclusionPolicies == el.Id)?.Id
			if (seleccionado_diversity_inclusion == 126) {
				lista_intereses.push(textStartups.con_poli_inc)
			}
			if (seleccionado_diversity_inclusion == 127) {
				lista_intereses.push(textStartups.sin_poli_inc)
			}
		}
	}





	let lista_intereses_html = <></>
	if (lista_intereses.length > 0) {
		lista_intereses_html = lista_intereses.map((item: any) => {
			return (
				<div className='col-auto'>

					<div className='cardStartup-tagInteres d-block mr-2 mb-2'>
						<p>{item}</p>
					</div>
				</div>

			)
		})
	}



	let redes_sociales_html: any = <></>
	let redes_sociales = []
	if (startup.WebPage) {
		redes_sociales.push(
			<a href={startup.WebPage} target='_blank' data-toggle="tooltip" data-placement="top" title="Web"><img className="card-startup-icono-boton-redes-sociales" src="/media/iconos/rrss/web.svg" alt="" /></a>
		)
	}
	if (startup.FacebookUrl) {
		redes_sociales.push(
			<a href={startup.FacebookUrl} target='_blank' data-toggle="tooltip" data-placement="top" title="Facebook"><img className="card-startup-icono-boton-redes-sociales" src="/media/iconos/rrss/facebook.svg" alt="" /></a>
		)
	}
	if (startup.InstagramUrl) {
		redes_sociales.push(
			<a href={startup.InstagramUrl} target='_blank' data-toggle="tooltip" data-placement="top" title="Instagram"><img className="card-startup-icono-boton-redes-sociales" src="/media/iconos/rrss/instagram.svg" alt="" /></a>
		)
	}
	if (startup.WebPage || startup.FacebookUrl || startup.InstagramUrl) {
		redes_sociales_html = redes_sociales.map((item: any) => {
			return (
				item
			)
		})
	} else {
		redes_sociales_html = <p>Sin redes</p>
	}


	let buttons_canEdit_html = <></>
	if (canEdit) {
		buttons_canEdit_html = <div className='d-flex flex-row justify-content-center align-items-center mt-2 w-100'>
			<button className='btn btn-block btn-rounded btn-sm btn-outline-primary m-0 mr-2 ' onClick={handleEditBtn}>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<CreateOutlinedIcon fontSize='small' />
					</div>
					<p>{textStartups.btn_2}</p>
				</div>
			</button>
			<button className='btn btn-block btn-rounded btn-sm  btn-outline-danger m-0' onClick={handleDeleteBtn}>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<DeleteForeverOutlinedIcon fontSize='small' />
					</div>
					<p>{textStartups.btn_3}</p>
				</div>
			</button>
		</div>
	}



	let desc_html = <p className='card-usuario-desc text-left '>{textStartups.label_2}</p>
	if (startup.Description) {
		desc_html = <p className='card-usuario-desc text-left '>{getCharLimit(startup.Description + "", 150)}</p>
	}

	return (
		<Card className='card-startup p-4 px-lg-5' >
			<div className='card-startup-number p-0' >
				{number + 1}
			</div>
			<div className='card-startup-top-card mb-2 d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-lg-between'>
				<div className='mr-0 mr-lg-4 mb-2 mb-lg-0'>
					<Avatar className="avatar-startup" alt={startup.Name} src={startup_foto}></Avatar>
				</div>
				<div className='w-100'>
					<div className='d-flex justify-content-lg-start justify-content-center'> { }
						<h5 className="startup-name mb-2 mb-lg-0 text-secondary"><b>{startup.Name ? startup.Name.toUpperCase() : "SIN NOMBRE"}</b></h5>
					</div>
					<div className='mt-2 d-flex justify-content-lg-start justify-content-center'> { }
						{redes_sociales_html}
					</div>
				</div>
			</div>
			<div className='mt-2'>
				<div className='row no-gutters mb-2 justify-content-center justify-content-lg-start'> { }
					{lista_intereses_html}
				</div>
				<div className=' '> { }

					<div className='mt-2 d-flex justify-content-lg-start justify-content-center'> { }
						<h6 className='text-secondary'><b>{textStartups.label_3}</b></h6>
					</div>
					<div className='mt-2 d-flex justify-content-lg-start justify-content-center w-100 text-secondary'> { }
						{desc_html}
					</div>
				</div>
			</div>
			<div className=''>
				<div className='d-flex justify-content-center align-items-center mt-2'>
					{buttons_canEdit_html}
				</div>
			</div>
		</Card>
	)
}
export default CardStartup;