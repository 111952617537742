import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MeetingsSolicitudes from './MeetingsSolicitudes/MeetingsSolicitudes';
import MeetingsHistorialReuniones from './MeetingsHistorialReuniones';
import { useNavigate, useParams } from 'react-router-dom';
import MeetingsTiempoDonado from './MeetingsTiempoDonado/MeetingsTiempoDonado';
import MeetingsProximasReuniones from './MeetingsProximasReuniones';

import "./Meetings.scss"
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Meetings = () => {

    const navigate = useNavigate();
    const params: any = useParams()

    let aux_actual_page: any = 0;
    const [value, setValue] = useState(aux_actual_page);
    const [interChange, setinterChange] = useState(false);
    if (params.subpagina != null) {
        if (params.subpagina == "meetings_requests") {
            aux_actual_page = 0
        }
        if (params.subpagina == "donate_time") {
            aux_actual_page = 1
        }
        if (params.subpagina == "next_meetings") {
            aux_actual_page = 2
        }
        if (params.subpagina == "historial") {
            aux_actual_page = 3
        }
        if (aux_actual_page != value) {
            setValue(aux_actual_page);
        }
    }


    const handleChange = (event: any, newValue: number) => {
        setinterChange(true);
        setValue(newValue);
        if (newValue == 0) {
            navigate('../meetings/meetings_requests')
        }
        if (newValue == 1) {

            navigate('../meetings/donate_time')
        }
        if (newValue == 2) {

            navigate('../meetings/next_meetings')
        }
        if (newValue == 3) {

            navigate('../meetings/historial')
        }

    };

    const textMeetings = useSelector(selectCurrentDictonary).meetings

    return (
        <div className='meetings-container padding-layout-x'>
            <div className='d-block d-lg-none'>
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className={'meetings-menu-item ' + (value == 0 ? "active" : "")} onClick={() => setValue(0)}>
                            {textMeetings.btn_Mis_solicitudes}
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className={'meetings-menu-item ' + (value == 1 ? "active" : "")} onClick={() => setValue(1)}>
                            {textMeetings.btn_Donar_tiempo}
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className={'meetings-menu-item ' + (value == 2 ? "active" : "")} onClick={() => setValue(2)}>
                            {textMeetings.btn_Proximas_reuniones}
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className={'meetings-menu-item ' + (value == 3 ? "active" : "")} onClick={() => setValue(3)}>
                            {textMeetings.btn_Historial_reuniones}
                        </div>
                    </div>
                </div>
            </div >
            <Box sx={{ width: '100%' }}>
                <Box className="d-none d-lg-block" sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                    >
                        <Tab className="meetings-menu-itemtab" label={textMeetings.btn_Mis_solicitudes} {...a11yProps(0)} />
                        <Tab className="meetings-menu-itemtab" label={textMeetings.btn_Donar_tiempo} {...a11yProps(1)} />
                        <Tab className="meetings-menu-itemtab" label={textMeetings.btn_Proximas_reuniones} {...a11yProps(2)} />
                        <Tab className="meetings-menu-itemtab" label={textMeetings.btn_Historial_reuniones} {...a11yProps(3)} />
                    </Tabs >
                </Box >
                <TabPanel value={value} index={0}>
                    <MeetingsSolicitudes></MeetingsSolicitudes>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MeetingsTiempoDonado></MeetingsTiempoDonado>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MeetingsProximasReuniones></MeetingsProximasReuniones>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <MeetingsHistorialReuniones></MeetingsHistorialReuniones>
                    
                    
                </TabPanel>
               
                
            </Box >
        </div >
    )
}

export default Meetings;

