import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, CircularProgress } from '@mui/material';
import './GenericCard.scss';

export interface ActionButtonProps<T> {
  label: string;
  color: 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'success' | 'warning';
  onClick: (data: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

interface GenericCardProps<T> {
  title?: string;
  columns: string[];
  data: T;
  actionButtons?: ActionButtonProps<T>[];
  isLoading?: boolean;
  renderers?: {
    [key: string]: (value: any) => React.ReactNode;
  };
}

const GenericCard = <T,>({
  title,
  columns,
  data,
  actionButtons = [],
  isLoading = false,
  renderers = {},
}: GenericCardProps<T>) => {

  const renderValue = (column: string) => {
    const value = (data as any)[column]; // Define 'value' aquí

    if (renderers[column]) {
      return renderers[column](value);
    }

    if (Array.isArray(value)) {
      return value.length === 1 ? (
        value[0] && typeof value[0] === 'object' ? (
          <>
            {(value[0].name && value[0].lastName)
              ? `${value[0].name} ${value[0].lastName}`
              : Object.values(value[0]).join(' ')}
          </>
        ) : (
          String(value[0])
        )
      ) : (
        <ul className="generic-card__list">
          {value.map((item, index) => (
            <li key={index}>
              {item && typeof item === 'object' ? (
                <>
                  {item.name && item.lastName
                    ? `${item.name} ${item.lastName}`
                    : Object.values(item).join(' ')}
                </>
              ) : (
                String(item)
              )}
            </li>
          ))}
        </ul>
      );
    }

    return String(value) || 'N/A';
  };

  return (
    <Card className="generic-card" elevation={3}>
      {title && <Typography variant="h6" className="generic-card__header">
        {title}
      </Typography>}
      <CardContent className="generic-card__content">
        {columns.map((column) => {
          if (column === "action") return null;

          return (
            <div key={column} className={`generic-card__text generic-card__text--${column}`}>
              <Typography variant="body2" component="span" className="generic-card__label">
                <strong>{column}:</strong>{' '}
                {renderValue(column)}
              </Typography>
            </div>
          );
        })}
      </CardContent>
      {actionButtons.length > 0 && (
        <CardActions className="generic-card__footer">
          {actionButtons.map((button, index) => (
            <Button
              key={`${button.label}-${index}`}
              size="small"
              variant="contained"
              color={button.color}
              onClick={() => button.onClick(data)}
              className="generic-card__action-button"
              disabled={button.disabled || button.isLoading || isLoading}
            >
              {button.isLoading || isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                button.label
              )}
            </Button>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

export default GenericCard;
