//import { useParams } from 'react-router-dom'


import Card from '../../../components/Card/Card'


const Appareance = () => {
    //const params = useParams()

    return (
        <>
                <Card className="w-100">
                    <div className='d-flex justify-content-start  mt-2 mb-4 px-4'>
                        <h3>Appareance</h3>
                    </div>
                    <div className='row no-gutters '>
                        <div className='col-12 col-lg-6 px-4 mb-4'>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end px-4 mb-2'>
                        <button className="btn btn-primary" type="submit">Actualizar datos</button>
                    </div>
                </Card>
        </>
    )
}

export default Appareance;