import { useLocation, Navigate } from "react-router-dom"
import MainLayout from "../../../components/Layout/MainLayout/MainLayout"
import SettingsLayout from "../../../components/Layout/SettingsLayout/SettingsLayout"
/* import { Auth } from "aws-amplify"; */
import { desencriptarToJSON } from "../../../utils/encrypt"

const RequireAuth = ({ layout = "main" }) => {
    const location = useLocation()
    const hashValue = localStorage.getItem("session");
    if (hashValue) {
        const session = JSON.parse(desencriptarToJSON(hashValue));
        if (session.isLoggedIn && session.userId) {
            if (layout == "main") {
                return <MainLayout />
            } else if (layout == "settings") {
                return <SettingsLayout />
            } else {
                return <MainLayout />
            }
        }
        else {
            return <Navigate to="/sign-in" state={{ from: location }} replace />
        }
    } else {
        return <Navigate to="/sign-in" state={{ from: location }} replace />
    }


}
export default RequireAuth