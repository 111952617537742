import React from 'react';
import { IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useCheckAccreditByAdminQuery,
    useAccreditActivityByAdminMutation,
    useDeaccreditActivityByAdminMutation,  
} from '../../redux/features/events/eventsApiSlice';

interface AccreditationButtonProps {
    email: string;
    activityId: number;
}

const AccreditationButton: React.FC<AccreditationButtonProps> = ({ email, activityId }) => {
    const dispatch = useDispatch();

    const skipQuery = !email || email.trim() === '';
    const { data, isLoading, refetch } = useCheckAccreditByAdminQuery(
        { activityId, userEmail: email },
        { skip: skipQuery, refetchOnMountOrArgChange: true }
    );

    const [accreditUser, { isLoading: isAccrediting }] = useAccreditActivityByAdminMutation();
    const [deaccreditUser, { isLoading: isDeaccrediting }] = useDeaccreditActivityByAdminMutation();

    const isAccredited = data?.isAccredited || false;

    const handleAccredit = async () => {
        if (email && !isAccredited) {
        
        let loadingAlertId = `loading_${Date.now()}`;
            let successAlertTitle = 'Usuario acreditado con éxito';
            let errorAlertTitle = 'Error al acreditar al usuario';

            let loadingAlert = {
                id: loadingAlertId,
                type: "loading",
                title: "Acreditando usuario...",
                desc: "",
                close: false,
            };
            dispatch(addAlert({ alert: loadingAlert }));

            try {
                await accreditUser({ activityId, userEmail: email }).unwrap();
                dispatch(deleteAlert({ alert_id: loadingAlertId }));
                let successAlert = {
                    id: `success_${Date.now()}`,
                    type: "success",
                    title: successAlertTitle,
                    desc: "",
                    close: true,
                    timeout: 2000
                };
                dispatch(addAlert({ alert: successAlert }));
                refetch();  
            } catch (error) {
                dispatch(deleteAlert({ alert_id: loadingAlertId }));
                let errorAlert = {
                    id: `error_${Date.now()}`,
                    type: "error",
                    title: errorAlertTitle,
                    desc: "",
                    close: true,
                    timeout: 2000
                };
                dispatch(addAlert({ alert: errorAlert }));
            }
        } else if (email && isAccredited) {
            let loadingAlertId = `loading_${Date.now()}`;
            let successAlertTitle = 'Usuario desacreditado con éxito';
            let errorAlertTitle = 'Error al desacreditar al usuario';

            let loadingAlert = {
                id: loadingAlertId,
                type: "loading",
                title: "Desacreditando usuario...",
                desc: "",
                close: false,
            };
            dispatch(addAlert({ alert: loadingAlert }));

            try {
                await deaccreditUser({ activityId, userEmail: email }).unwrap();
                dispatch(deleteAlert({ alert_id: loadingAlertId }));
                let successAlert = {
                    id: `success_${Date.now()}`,
                    type: "success",
                    title: successAlertTitle,
                    desc: "",
                    close: true,
                    timeout: 2000
                };
                dispatch(addAlert({ alert: successAlert }));
                refetch();  
            } catch (error) {
                dispatch(deleteAlert({ alert_id: loadingAlertId }));
                let errorAlert = {
                    id: `error_${Date.now()}`,
                    type: "error",
                    title: errorAlertTitle,
                    desc: "",
                    close: true,
                    timeout: 2000
                };
                dispatch(addAlert({ alert: errorAlert }));
            }
        }
    };

    return (
        <IconButton
            onClick={handleAccredit}
            sx={{
                opacity: email ? 1 : 0.5,
                pointerEvents: email ? 'auto' : 'none',
            }}
            disabled={isLoading || isAccrediting || isDeaccrediting || !email}
        >
            {isAccredited ? (
                <CancelIcon style={{ color: 'black' }} />
            ) : (
                <CheckCircleIcon style={{ color: 'black' }} />
            )}
        </IconButton>
    );
};

export default AccreditationButton;
