
import { useEffect, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
/* variables tabla */
import Tabla from '../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
import { usuario_html } from '../../utils/meetings';
import { personalData } from '../../interfaces/dimentions';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useGetUserMeetingsMutation, useGetUserSolicitudesEnviadasMutation, useGetUserSolicitudesRecibidasMutation, useGetZoomMeetingMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectUserMeetings, selectUserSolicitudesEnviadas, selectUserSolicitudesRecibidas, setUserMeetings, setUserSolicitudesEnviadas, setUserSolicitudesRecibidas } from '../../redux/features/meetings/meetingsSlice';
import { useDispatch } from 'react-redux';
import "./Meetings.scss";
import GetActualTimeZone from '../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import moment from "moment"
import { changeDateTimeZone, changeDonatedTimeZone } from '../../utils/utilsForms';
import Label from '../../components/Form/Label';
import { setDataMeetingZoom } from "../../redux/features/meetings/meetingsSlice";
import { useNavigate } from 'react-router-dom';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { text } from 'stream/consumers';
import { useLocation } from 'react-router-dom';

const MeetingsProximasReuniones = ({  }: any) => {

    const navigate = useNavigate()
    const actual_time_zone = GetActualTimeZone()
    const textMeetings = useSelector(selectCurrentDictonary).meetings

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado

    let user_id = user_personal_data.Id

    const user_solicitudes_enviadas: any = useSelector(selectUserSolicitudesEnviadas) //acceder a datos del usuario logueado
    const user_solicitudes_recibidas: any = useSelector(selectUserSolicitudesRecibidas) //acceder a datos del usuario logueado
    const user_meetings: any = useSelector(selectUserMeetings) //acceder a datos del usuario logueado

    const [getMeetings, { isLoadingGetMeetings }]: any = useGetUserMeetingsMutation()
    const [getSoliciudesRecibidas, { isLoadingGetSolicitudesRecibidas }]: any = useGetUserSolicitudesRecibidasMutation()
    const [getSoliciudesEnviadas, { isLoadingGetSolicitudesEnviadas }]: any = useGetUserSolicitudesEnviadasMutation()
    const [getZoomMeeting]: any = useGetZoomMeetingMutation()


    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_enviadas, setIsLoading_enviadas] = useState(false)
    const [isLoading_recibidas, setIsLoading_recibidas] = useState(false)
    const [isLoading_meetings, setIsLoading_meetings] = useState(false)
    const [initialCharge, setInitialCharge] = useState(false)
    const [buttonEnabled, setButtonEnabled] = useState(false);

    // código para cuando se sale de reu
    const [closeInitial, setCloseInitial] = useState(false)
    const location = useLocation();
    const [openModalEvaluar, setOpenModalEvaluar] = useState(false);
   
    const { meetingIdConfirm } = location.state || {};
    // console.log("Meetingidconfirm",meetingIdConfirm)

    const [pageSize, setPageSize] = useState<number>(10); // Añadir estado para el tamaño de página
    const [page, setPage] = useState<number>(0); // Añadir estado para la página actual
   
    if( meetingIdConfirm && !openModalEvaluar && !closeInitial ){
        // console.log("Saliendo de reu")
        setOpenModalEvaluar(true);
    }

    // useEffect(() => {
    //     if (confirm_modal_feedback) {
    //         setOpenModalEvaluar(true);
    //     }
    // }, [confirm_modal_feedback]);

    const handleEvaluar = () => {
        // console.log("evaluar");
        setOpenModalEvaluar(false);
        navigate('/meetings/historial', { state: { meetingId: meetingIdConfirm } });
    };

    const handleClose = () => {
        // console.log("Cerrando")
        setCloseInitial(true)
        setOpenModalEvaluar(false);
        
    };



    //


    useEffect(() => {
        if (!initialCharge) {
            handleReload()
            setInitialCharge(true)
        }
    }, []);

    async function handleReload() {

        setIsLoading_enviadas(true)
        setIsLoading_recibidas(true)
        setIsLoading_meetings(true)

        setIsLoading(true)
        async function handleReload_enviadas() {
            const res_enviadas: any = await getSoliciudesEnviadas({ user_id: user_id });
            const resData_enviadas = JSON.parse(res_enviadas.data.responseData)
            dispatch(setUserSolicitudesEnviadas({ user_solicitudes_enviadas: resData_enviadas }))
            setIsLoading_enviadas(false)
        }
        handleReload_enviadas()

        async function handleReload_recibidas() {
            const res_recibidas: any = await getSoliciudesRecibidas({ user_id: user_id });
            const resData_recibidas = JSON.parse(res_recibidas.data.responseData)
            dispatch(setUserSolicitudesRecibidas({ user_solicitudes_recibidas: resData_recibidas }))
            setIsLoading_recibidas(false)
        }
        handleReload_recibidas()


        async function handleReload_meetings() {
            const res_meetings: any = await getMeetings({ user_id: user_id });
            const resData_meetings = JSON.parse(res_meetings.data.responseData)
            dispatch(setUserMeetings({ user_meetings: resData_meetings }))
            setIsLoading_meetings(false)
        }
        handleReload_meetings()
    }


    let datos_enviadas: any = []
    if (user_solicitudes_enviadas) {
        for (let i = 0; i < user_solicitudes_enviadas.length; i++) {
            const element = user_solicitudes_enviadas[i];
            datos_enviadas.push({

                ConfirmDateTime: element.ConfirmDateTime,
                CreatedAd: element.CreatedAd,
                id: element.Id,
                MeetingTypeId: element.MeetingTypeId,
                MeetingTypeName: element.MeetingTypeName,
                RequestStatusId: element.RequestStatusId,

                UserRequestId: element.UserRequestId,
                UserRequestLastName: element.UserRequestLastName,
                UserRequestName: element.UserRequestName,
                UserRequestProfilePicture: element.UserRequestProfilePicture,
                UserRequestRol: element.UserRequestRol,

                UserRequestedId: element.UserRequestedId,
                UserRequestedLastName: element.UserRequestedLastName,
                UserRequestedName: element.UserRequestedName,
                UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                UserRequestedRol: element.UserRequestedRol,

                UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                RequestStatusReason: element.RequestStatusReason,
                UserRequestedEndTime: element.UserRequestedEndTime,
                UserRequestedStartTime: element.UserRequestedStartTime,
                UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,


            })
        }
    }


    let datos_recibidas: any = []
    if (user_solicitudes_recibidas) {
        for (let i = 0; i < user_solicitudes_recibidas.length; i++) {
            const element = user_solicitudes_recibidas[i];
            datos_recibidas.push({
                ConfirmDateTime: element.ConfirmDateTime,
                CreatedAd: element.CreatedAd,
                id: element.Id,
                MeetingTypeId: element.MeetingTypeId,
                MeetingTypeName: element.MeetingTypeName,
                RequestStatusId: element.RequestStatusId,

                UserRequestId: element.UserRequestId,
                UserRequestLastName: element.UserRequestLastName,
                UserRequestName: element.UserRequestName,
                UserRequestProfilePicture: element.UserRequestProfilePicture,
                UserRequestRoles: element.UserRequestRoles,

                UserRequestedId: element.UserRequestedId,
                UserRequestedLastName: element.UserRequestedLastName,
                UserRequestedName: element.UserRequestedName,
                UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                UserRequestedRoles: element.UserRequestedRoles,

                UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                RequestStatusReason: element.RequestStatusReason,
                UserRequestedEndTime: element.UserRequestedEndTime,
                UserRequestedStartTime: element.UserRequestedStartTime,
                UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,
            })
        }
    }

    let datos_meetings: any = []
    if (user_meetings) {
        for (let i = 0; i < user_meetings.length; i++) {
            const element = user_meetings[i];
            const id = element.MeetingId;
            const MeetingRequestId = element.MeetingRequestId;

            // busca id de solicitud para obtener datos de usuario y hora 
            let sol_enviada = datos_enviadas.filter((obj: any) => MeetingRequestId == obj.id);
            let sol_recibida = datos_recibidas.filter((obj: any) => MeetingRequestId == obj.id);
            let datos_solicitud = {}
            if (sol_enviada.length > 0) { // es enviada (tipo: 1)
                datos_solicitud = { ...sol_enviada[0], tipo: 1 }
            } else if (sol_recibida.length > 0) { // es recibida (tipo: 2)
                datos_solicitud = { ...sol_recibida[0], tipo: 2 }
            }
            datos_meetings.push({
                id: id,
                CreatedAt: element.CreatedAt,
                MeetingRequestId: element.MeetingRequestId,
                ZoomMeetingNumber: element.ZoomMeetingNumber,
                ZoomMeetingPassword: element.ZoomMeetingPassword,
                ZoomMeetingToken: element.ZoomMeetingToken,
                UserRequestAttendance: element.UserRequestAttendance,
                UserRequestedAttendance: element.UserRequestedAttendance,
                datos_solicitud: datos_solicitud,

            })
        }
    }

    const isEnabledTime = (reunion: any) => {
        const timezone = reunion.datos_solicitud.UserRequestedTimeZoneOffset;
        const timezoneOffset = timezone * 60 * 60 * 1000;
        const meetingTime = new Date(reunion.datos_solicitud.UserRequestedStartTime);
        const currentTime = new Date();

        const buttonEnableTime = new Date(meetingTime.getTime() - 10 * 60000 + timezoneOffset);

        if (buttonEnableTime <= currentTime) {
            return true
        } else {
            return false
        }
    }

    function opciones_html(id_reunion: number, reunion: any) {

        const user1 = {
            userId: reunion.datos_solicitud.UserRequestId,
            userImageUrl: reunion.datos_solicitud.UserRequestProfilePicture,
            userName: reunion.datos_solicitud.UserRequestName + " " + reunion.datos_solicitud.UserRequestLastName,
            userRol: reunion.datos_solicitud.UserRequestRol,
        }

        const user2 = {
            userId: reunion.datos_solicitud.UserRequestedId,
            userImageUrl: reunion.datos_solicitud.UserRequestedProfilePicture,
            userName: reunion.datos_solicitud.UserRequestedName + " " + reunion.datos_solicitud.UserRequestedLastName,
            userRol: reunion.datos_solicitud.UserRequestedRol,
        }



        return (
            <div className='d-flex justify-content-center align-items-center'>
                <button
                    className="btn btn-primary btn-block p-1"
                    disabled={!isEnabledTime(reunion)}
                    onClick={() => handleIrAReunion(reunion, user1, user2)}>
                    {textMeetings.btn_ir} {/*boton para acceder a reu*/}
                </button>
                {/* <button
                    className="btn btn-secondary btn-block p-1 mt-0 ml-1"
                    onClick={() => handleIrZoom(reunion)}>
                    <OpenInNewIcon style={{ fontSize: "25px", marginTop: '-2px' }} />
                </button> */}
            </div>

        )
    }

    function handleIrAReunion(reunion: any, user1: any, user2: any) {
        // guardar datos en redux para leerlos en zoom-meeting
        var dataMeeting = {
            userName: user_personal_data.Name + " " + user_personal_data.LastName,
            userEmail: user_personal_data.Email,
            meetingNumber: reunion.ZoomMeetingNumber, //"75540750226", // reunion.ZoomMeetingNumber
            meetingPassword: reunion.ZoomMeetingPassword, //"y6S1YM",
            meetingToken: reunion.ZoomMeetingToken,
            user1: user1,
            user2: user2,
            all: reunion
        }
        dispatch(setDataMeetingZoom({ data_meeting_zoom: dataMeeting }))
        //navigate("../zoom-meeting")
        navigate("../meeting")
    }
    async function handleIrZoom(reunion: any) {
        try {
            const zoomMeeting: any = await getZoomMeeting({ meeting_id: reunion.ZoomMeetingNumber });
            const meetingData = JSON.parse(zoomMeeting.data.responseData)
            window.open(meetingData.Url, "_blank", "rel=noopener noreferrer")
        } catch (error) {
            console.log(error)
        }
    }

    /* TABLA TIEMPOS DONADOS */

    let columnas: GridColDef[] = [
        {
            headerName: textMeetings.th_confirmacion, description: textMeetings.th_confirmacion, field: "CreatedAd", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let CreatedAt = row.CreatedAt

                let dict_createdAd = changeDateTimeZone(actual_time_zone, CreatedAt)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict_createdAd.time}

                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_2, description: textMeetings.table_1.header_2, field: "HoraReunion", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let UserRequestedStartTime = row.datos_solicitud.UserRequestedStartTime
                let UserRequestedEndTime = row.datos_solicitud.UserRequestedEndTime
                let fecha_inicio = UserRequestedStartTime?.split("T")[0]
                let hora_inicio = UserRequestedStartTime?.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime?.split("T")[1].split(":")[1]
                let hora_termino = UserRequestedEndTime?.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime?.split("T")[1].split(":")[1]

                let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict.startTime + " - " + dict.endTime}
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_3, description: textMeetings.table_1.header_3, field: "UserRequestedId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let user_id = row.datos_solicitud.UserRequestedId
                let nombre = row.datos_solicitud.UserRequestedName
                let apellido = row.datos_solicitud.UserRequestedLastName
                let url_foto = row.datos_solicitud.UserRequestedProfilePicture
                let roles = row.datos_solicitud.UserRequestedRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.th_reunion_por, description: textMeetings.th_reunion_por, field: "UserRequestId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let user_id = row.datos_solicitud.UserRequestId
                let nombre = row.datos_solicitud.UserRequestName
                let apellido = row.datos_solicitud.UserRequestLastName
                let url_foto = row.datos_solicitud.UserRequestProfilePicture
                let roles = row.datos_solicitud.UserRequestRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },

        {
            headerName: textMeetings.table_1.header_4, description: textMeetings.table_1.header_3, field: "MeetingTypeName", flex: 2, minWidth: 230,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                // let MeetingTypeName = row.datos_solicitud.MeetingTypeName
                return (
                    <div className='contenedor-tipo-solicitud'>
                        {/* <p className='tipo-solicitud'>{MeetingTypeName}</p> */}
                        <p className='tipo-solicitud'>{textMeetings.type_meetings[row.datos_solicitud.MeetingTypeId]}</p>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_8, // Título de la nueva columna
            field: "descargar", // Este campo puede ser cualquier cosa, ya que lo manejará el botón
            flex: 1,
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <button className="btn btn-primary btn-sm p-1"
                        onClick={() => handleDownload(cellValues.row)}
                    >
                        {textMeetings.table_1.button_1}
                    </button>
                );
            }
        }
        ,
        {
            headerName: textMeetings.table_1.header_7, description: textMeetings.table_1.header_6, field: "Opciones_html", sortable: false, flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let id = row.id
                return (
                    <>
                        {opciones_html(id, row)}
                    </>
                )
            }
        }
    ]

    function handleDownload(rowData: any) {


        const { datos_solicitud, ZoomMeetingNumber, ZoomMeetingPassword } = rowData;
        let { UserRequestName, UserRequestLastName, UserRequestedName, UserRequestedLastName } = datos_solicitud;

        if (textMeetings.ics_download === "ETMmeet-meeting") {

            if (datos_solicitud.MeetingTypeName === "Busco ofrecer mi producto o servicio") {
                datos_solicitud.MeetingTypeName = "I'm looking to offer my product or service"
            }
            if (datos_solicitud.MeetingTypeName === "Busco inversión o capital para mi proyecto") {
                datos_solicitud.MeetingTypeName = "I'm looking for investment or capital for my project"
            }
            if (datos_solicitud.MeetingTypeName === "Busco un socio para mi proyecto") {
                datos_solicitud.MeetingTypeName = "I'm looking for a partner for my project"
            }
            if (datos_solicitud.MeetingTypeName === "Busco generar alguna alianza comercial o estratégica") {
                datos_solicitud.MeetingTypeName = "I'm looking to create a commercial or strategic alliance"
            }
            if (datos_solicitud.MeetingTypeName === "Donación directa") {
                datos_solicitud.MeetingTypeName = "Direct donation"
            }

        }


        let start = new Date(datos_solicitud.UserRequestedStartTime + 'Z');
        let end = new Date(datos_solicitud.UserRequestedEndTime + 'Z');


        const timeOffsetMinutes = actual_time_zone * 60;


        start = new Date(start.getTime() + timeOffsetMinutes * 60000);
        end = new Date(end.getTime() + timeOffsetMinutes * 60000);


        end = new Date(end.getTime() + 10 * 60000);


        const startFormatted = formatToICSDate(start);
        const endFormatted = formatToICSDate(end);



        let email1;
        let email2;


        if (user_personal_data.Name === UserRequestName && user_personal_data.LastName === UserRequestLastName) {

            let tempName = UserRequestName;
            let tempLastName = UserRequestLastName;


            UserRequestName = UserRequestedName;
            UserRequestLastName = UserRequestedLastName;

            UserRequestedName = tempName;
            UserRequestedLastName = tempLastName

        }





        const icsData = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `UID:${ZoomMeetingNumber}@example.com`,
            `DTSTART:${startFormatted}`,
            `DTEND:${endFormatted}`,
            `SUMMARY:${textMeetings.ics_summary} ${UserRequestName} ${UserRequestLastName}`, // Título del evento
            `DESCRIPTION:${textMeetings.ics_description1} ${UserRequestName} ${UserRequestLastName}\\n${textMeetings.ics_description2} ${datos_solicitud.MeetingTypeName}\\n${textMeetings.ics_description3}: www.ETMmeet.org/meetings/next_meetings`, // Descripción del evento
            'LOCATION:ETMmeet',

            'END:VEVENT',
            'END:VCALENDAR'
        ].join('\n');


        const blob = new Blob([icsData], { type: 'text/calendar' });


        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${textMeetings.ics_download} ${UserRequestName}_${UserRequestLastName}.ics`; // Nombre del archivo a descargar
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    function formatToICSDate(date: any) {
        return date.getUTCFullYear() +
            ('0' + (date.getUTCMonth() + 1)).slice(-2) +
            ('0' + date.getUTCDate()).slice(-2) +
            'T' +
            ('0' + date.getUTCHours()).slice(-2) +
            ('0' + date.getUTCMinutes()).slice(-2) +
            ('0' + date.getUTCSeconds()).slice(-2);
    }






    let table_html = <></>
    if (isLoading_enviadas || isLoading_recibidas || isLoading_meetings) {
        table_html = <div className='table-container-loading'>
            <p>{textMeetings.loading_table}</p>
        </div>
    } else {
        table_html = <div className='table-container'>
            <Tabla 
                columnas={columnas} 
                datos={datos_meetings} 
                pageSize={pageSize}        // Añadir propiedad pageSize
                setPageSize={setPageSize}  // Añadir propiedad setPageSize
                page={page}                // Añadir propiedad page
                onPageChange={setPage}     // Añadir propiedad onPageChange
            />
        </div>
    }


    /* DETALLE DE REUNION --------------------------------------------------------------- */

    const [open_dialog_detalle, set_open_dialog_detalle] = useState(false)
    const [reunion, set_reunion] = useState<any>({})

    function handleClickDialogDetalle() {
        set_open_dialog_detalle(!open_dialog_detalle)
    }

    /* enviada = 0 => es reunion recibida */
    /* enviada = 1 => es reunion enviada */

    let detalle_reunion_html = <></>
    if (reunion.datos_solicitud) {
        /* status */
        let RequestStatusId = reunion.datos_solicitud.RequestStatusId
        let request_status = textMeetings.alerta_1
        let request_status_html_class = ""
        if (RequestStatusId == 2) {
            request_status = textMeetings.alerta_2
            request_status_html_class = "text-success"
        }
        if (RequestStatusId == 1) {
            request_status = textMeetings.alerta_3
            request_status_html_class = "text-danger"
        }
        let request_status_html = <div className={request_status_html_class}>
            {request_status}
        </div>
        /* usuarios */
        /* usuario 1 */
        let requested_user_id = reunion.datos_solicitud.UserRequestedId
        let requested_user_nombre = reunion.datos_solicitud.UserRequestedName
        let requested_user_apellido = reunion.datos_solicitud.UserRequestedLastName
        let requested_user_url_foto = reunion.datos_solicitud.UserRequestedProfilePicture
        let requested_user_roles = reunion.datos_solicitud.UserRequestedRoles
        let requested_user = <div className='d-flex justify-content-start'>
            {usuario_html(requested_user_id, requested_user_nombre, requested_user_apellido, requested_user_url_foto, requested_user_roles)}
        </div>
        /* usuario 2 */
        let request_user_id = reunion.datos_solicitud.UserRequestId
        let request_user_nombre = reunion.datos_solicitud.UserRequestName
        let request_user_apellido = reunion.datos_solicitud.UserRequestLastName
        let request_user_url_foto = reunion.datos_solicitud.UserRequestProfilePicture
        let request_user_roles = reunion.datos_solicitud.UserRequestRoles
        let request_user = <div className='d-flex justify-content-start'>
            {usuario_html(request_user_id, request_user_nombre, request_user_apellido, request_user_url_foto, request_user_roles)}
        </div>
        /* hora reunion */
        let UserRequestedStartTime = reunion.datos_solicitud.UserRequestedStartTime
        let UserRequestedEndTime = reunion.datos_solicitud.UserRequestedEndTime
        let fecha_inicio = UserRequestedStartTime.split("T")[0]
        let hora_inicio = UserRequestedStartTime.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime.split("T")[1].split(":")[1]
        let hora_termino = UserRequestedEndTime.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime.split("T")[1].split(":")[1]

        let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)

        let hora_reunion = <div className='d-flex flex justify-content-start align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div>
                    {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </div>
                <div>
                    {dict.startTime + " - " + dict.endTime}
                </div>
            </div>
        </div>

        /* hora de envio */
        let dict_createdAd = changeDateTimeZone(actual_time_zone, reunion.datos_solicitud.CreatedAd)

        let hora_envio = <div className='d-flex flex justify-content-start align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div>
                    {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </div>
                <div>
                    {dict_createdAd.time}

                </div>
            </div>
        </div>

        /* html final detalle */
        detalle_reunion_html = <>
            <div className='row'>

                <div className='col-12  mb-4'>
                    <Label label="Enviada el"></Label>
                    <p>{hora_envio}</p>
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.th_enviada_por}></Label>
                    {request_user}
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.th_recibida}></Label>
                    {requested_user}
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.th_solicitada}></Label>
                    <p>{hora_reunion}</p>
                </div>
                <div className='col-12 col-lg-6 mb-4'>
                    <Label label={textMeetings.th_estado_2}></Label>
                    <p>{request_status_html}</p>
                </div>
                <div className='col-12  mb-4'>
                    <Label label={textMeetings.th_reunion}></Label>
                    <p>{reunion.datos_solicitud.MeetingTypeName}</p>
                </div>
                <div className='col-12 mb-4'>
                    <Label label={textMeetings.th_mensaje}></Label>
                    <p>{reunion.datos_solicitud.RequestStatusReason}</p>
                </div>
            </div>
        </>
    }



    /* RETURN --------------------------------------------------------------- */

    return (
        <>
            <div className='row'>
                <div className='col-12 mb-4'>
                    <div className='d-flex justify-content-start align-items-center mb-2'>
                        <h3 className='mb-0 mr-2 font-italic'><b>{textMeetings.titulo_4}</b></h3>
                        <div className="btn-refresh mt-1" onClick={handleReload}>
                            <AutorenewIcon fontSize='small' />
                        </div>
                    </div>
                    <p>
                        {textMeetings.desc_4_1}
                    </p>
                </div>
                <div className='col-12 mb-4'>

                    <div className='table-container'>
                        {table_html}
                    </div>
                </div>
            </div>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={open_dialog_detalle}
                onClose={handleClickDialogDetalle}
            >
                <DialogTitle >
                    Detalle reunion
                </DialogTitle>
                <DialogContent>
                    {detalle_reunion_html}
                </DialogContent>
                <DialogActions>
                    <div className='d-flex justify-content-end'>
                        <button onClick={handleClickDialogDetalle} className="btn btn-danger mr-2">{textMeetings.btn_cancel}</button>
                        <button onClick={handleClickDialogDetalle} className="btn btn-primary">{textMeetings.btn_cerrar}</button>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openModalEvaluar}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
                sx={{ width: '600px', maxHeight: '320px', margin: 'auto' }} 
            >
                <DialogTitle id="alert-dialog-title">{"¿Desea evaluar la reunión?"}</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <DialogContent
                    >
                        Puede evaluar esta reunión y calificar tanto al usuario como a la plataforma para ayudarnos a mejorar la experiencia de uso. Dejénos su comentario y su evaluación 
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <button style={{ padding: '10px 20px', fontSize: '0.875rem' }}  onClick={handleClose} className="btn btn-danger" >
                        No
                    </button>
                    <button style={{ padding: '10px 20px', fontSize: '0.875rem' }} onClick={handleEvaluar} className="btn btn-primary">
                        Sí
                    </button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default MeetingsProximasReuniones;