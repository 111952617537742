
import Avatar from '@mui/material/Avatar';
import { capitalizeFirstLetter } from './utilsText';

export function usuario_html(id_usuario:any, nombre:any, apellido:any, url_foto:any, roles:any) {
    let nombre_aux = "Sin nombre"
    let apellido_aux = ""
    let url_foto_aux = (url_foto && url_foto!= null)?url_foto:"/media/iconos/icono-persona.svg"
    if(nombre){
        nombre_aux = capitalizeFirstLetter(nombre.split(" ")[0])
        apellido_aux = capitalizeFirstLetter(apellido.split(" ")[0])
    }
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <Avatar alt={nombre_aux} src={url_foto} />
            <p>{nombre_aux} {apellido_aux}</p>
            <div className='d-flex justify-content-center'>
                <div className='tag mr-2'>{}</div>

            </div>
        </div>
    )
}
export function startup_html(id_solicitud: any) {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <Avatar alt="Start" src="/media/iconos/avatar/1.jpg" />
            <p>Titulo Startup</p>
            <div className='d-flex justify-content-center'>
                <div className='tag mr-2'>IDEA</div>
            </div>
        </div>
    )
}
