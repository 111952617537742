import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import HomeResultados from '../Home/HomeResultados';
import { useGetUserContactsMutation } from '../../redux/features/user/userApiSlice';
import { personalData } from '../../interfaces/dimentions';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import UserProfileExternal from '../UserProfileExternal/UserProfileExternal';
import "../Meetings/Meetings.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contacts-tabpanel-${index}`}
      aria-labelledby={`contacts-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `contacts-tab-${index}`,
    'aria-controls': `contacts-tabpanel-${index}`,
  };
}

const Contacts = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const navigate = useNavigate();
  const textMeetings = useSelector(selectCurrentDictonary).meetings;
  const [value, setValue] = useState(0);
  const user_personal_data: personalData = useSelector(selectPersonalData);
  const user_id = user_personal_data?.Id;
  const [userContacts, setContacts] = useState<any[]>([]);
  const [isLoadingContacts, setLoadingContacts] = useState(false);
  const [visibleContacts, setVisibleContacts] = useState(5); // CANTIDAD QUE SE MUESTRAN AL COMIENZO [Mostrar 10 contactos inicialmente
  const [getUserContacts] = useGetUserContactsMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLoadContactsMain = async () => {
    if (user_id) {
      setLoadingContacts(true);
      try {
        const result = await getUserContacts(user_id).unwrap();
        const formattedContacts = result.map((contact: any) => ({
          name_job: "",
          picture: "",
          porcentaje: "",
          profile_picture: contact.profilePicture,
          sponsor: "",
          user_evaluation_stars: contact.finalRating,
          company_id: "",
          name_company: "",
          id: contact.contactUserId,
          name: contact.name,
          last_name: contact.lastName,
          description: contact.description,
        }));
        setContacts(formattedContacts);
      } catch (err) {
        console.error('Failed to load contacts', err);
      } finally {
        setLoadingContacts(false);
      }
    }
  };

  useEffect(() => {
    handleLoadContactsMain();
  }, [user_id]);

  const textContacts = useSelector(selectCurrentDictonary).contacts;

  const handleClickMostrarMas = () => {
    setVisibleContacts(prevVisibleContacts => prevVisibleContacts + 5); // CANTIDAD DE AUMENTO POR CADA MOSTRAR MÁS, [10?]
  };

  return (
    <div className='meetings-container padding-layout-x'>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab className="meetings-menu-itemtab" label={textMeetings.contactos} {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <HomeResultados
            isLoading={isLoadingContacts}
            actual_values={{ busqueda_texto: '', intereses: {}, sponsors: [], tipo_busqueda: 1 }}
            lista_resultados={userContacts.slice(0, visibleContacts)} // Mostrar solo los contactos visibles
            cant_mostrada={visibleContacts}
            maximo_cant_mostrada={userContacts.length}
            handleClickMostrarMas={handleClickMostrarMas}
            handleClickOpenDialogPublicProfile={(userId: any) => {
              setSelectedUserId(userId);
              setOpenDialog(true);
            }}
            contact_section={true}
          />
        </TabPanel>
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent className='p-0'>
          <UserProfileExternal
            user_id_param={selectedUserId}
            handleClickCloseDialogPublicProfile={() => setOpenDialog(false)}
            handleLoadContactsMain={handleLoadContactsMain}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Contacts;
