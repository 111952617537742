import "../Meetings.scss"
import Card from '../../../components/Card/Card';
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";

const MeetingsDonarTiempoPaso3 = ({ actual_values, setActualValues }: any) => {

    const textMeetings = useSelector(selectCurrentDictonary).meetings

    return (
        <div className='row no-gutters'>
            <div className='col-12 mb-4'>
                <p>{textMeetings.desc_8}</p>
            </div>
            <div className='col-12 mb-4'>
                {/* <Label label="fechas"></Label> */}
                <div className='row'>
                    {
                        actual_values.bloques_reunion_dia?.map((bloque_reunion_dia: any) => {
                            return (
                                <div className='col-12 '>
                                    <Card className="mb-2">
                                        <div className='row'>
                                            <div className='col-12'>
                                                {bloque_reunion_dia.dia_semana + " " + bloque_reunion_dia.date}
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    {bloque_reunion_dia.startTime} - {bloque_reunion_dia.endTime}
                                                </p>
                                            </div>
                                            <div className='col-12'>
                                                {bloque_reunion_dia.minutesDuration} min
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MeetingsDonarTiempoPaso3;