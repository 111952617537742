import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetUserExpertisesMutation } from '../../../redux/features/user/userApiSlice';
import { selectUserExpertises, setUserExpertises } from '../../../redux/features/user/userSlice';


export default function GetUserExpertises(user_id:any) {

    const dispatch = useDispatch()
    const value = useSelector(selectUserExpertises)
    const [get, { isLoadingGet }]: any = useGetUserExpertisesMutation()
    const [isLoading, setIsLoading] = useState(false)    
    const [initialCharge, setInitialCharge] = useState(true)    
    const fetch = async () => {
        setIsLoading(true)
        const res: any = await get({user_id:user_id});
        const resData = JSON.parse(res.data.responseData)
        dispatch(setUserExpertises({user_expertises: resData }))
        setIsLoading(false)
        setInitialCharge(false)
    }

    useEffect(() => {
        if (initialCharge) {            
            fetch()
        }
    }, [value]);

    return {value:value, isLoading: isLoading};
}
