import React, { useState, useEffect } from 'react';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import moment from 'moment';
import FeedbackDialog from './FeedbackDialog';
import EvaluateCounterpartsDialog from './FeedbackDialog/EvaluateCounterpartsDialog';
import ConfirmationDialog from './ConfirmationDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useGetUpcomingActivitiesByUserQuery,
    useSendActivityFeedbackMutation,
    useCancelUpcomingActivityByUserMutation,
    useCancelMenteeContactWheelMutation,
    useCancelMentorContactWheelMutation
} from '../../redux/features/events/eventsApiSlice';
import { Actividad } from './FeedbackQuestions/constants';


interface RoleInActivity {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    role: 'Mentor' | 'Mentee';
    statusId: number | null;
    statusName: string;
    hasFeedback: boolean;
}

interface AccreditationByBlock {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    statusId: number | null;
    statusName: string;
    hasFeedback: boolean;
    counterpart: boolean;
}

interface Activity {
    availableTimeId: number[];
    activityId: number;
    typeId: number;
    statusId: number | null;
    statusName: string;
    startDate: string;
    endDate: string;
    eventName: string;
    activityName: string;
    activityType: Actividad;
    activityDescription: string;
    roleInActivity: RoleInActivity;
    mentorAccreditationByBlock: AccreditationByBlock[];
    menteeAccreditationByBlock: AccreditationByBlock[];
}

interface UpcomingActivitiesUserProps {
    onRefetch?: (refetch: () => void) => void;
}

const UpcomingActivitiesUser: React.FC<UpcomingActivitiesUserProps> = ({ onRefetch }) => {
    const dispatch = useDispatch();
    const userPersonalData = useSelector(selectPersonalData);
    const userId = userPersonalData?.Id;
    const userEmail = userPersonalData?.Email;

    const [reload, setReload] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isMentor, setIsMentor] = useState(false);
    const [actividad, setActividad] = useState<Actividad | null>(null);
    const [contrapartes, setContrapartes] = useState<string[]>([]);
    const [selectedRole, setSelectedRole] = useState<'Mentor' | 'Mentee' | 'General' | null>(null);

    const [openEvaluateCounterpartsDialog, setOpenEvaluateCounterpartsDialog] = useState(false);
    const [currentCounterpart, setCurrentCounterpart] = useState<string>("");

    const {
        data: activitiesData,
        isLoading: isLoadingActivities,
        refetch,
        error: activitiesError,
    } = useGetUpcomingActivitiesByUserQuery(userId ?? 0, {
        skip: userId === undefined || userId === null,
    });

    const activities: Activity[] = Array.isArray(activitiesData)
        ? activitiesData
        : (activitiesData?.activities ?? []);


    console.log("activities => ", activities)

    const [sendActivityFeedback] = useSendActivityFeedbackMutation();
    const [cancelUpcomingActivity] = useCancelUpcomingActivityByUserMutation();
    const [cancelMenteeContactWheel] = useCancelMenteeContactWheelMutation();
    const [cancelMentorContactWheel] = useCancelMentorContactWheelMutation();

    const statusNames: Record<number, string> = {
        1: "Acreditado",
        2: "Cancelado",
        3: "Pendiente",
    };

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload, refetch]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (onRefetch) {
            onRefetch(refetch);
        }
    }, [onRefetch, refetch]);

    const handleEvaluation = (activityId: number, counterpartsList: { name: string; hasFeedback: boolean; }[], isMentorRole: boolean, actividadType: Actividad) => {
        setSelectedActivityId(activityId);
        setIsMentor(isMentorRole);
        setActividad(actividadType);
        setContrapartes(counterpartsList.map(cp => cp.name));

        if (actividadType === 'Rueda de Contactos') {
            setOpenEvaluateCounterpartsDialog(true);
        } else {

            if (counterpartsList.length > 0) {
                setCurrentCounterpart(counterpartsList[0].name);
                setOpenFeedbackDialog(true);
            }
        }
    };

    const handleFeedbackSubmit = async (values: any) => {

       //console.log('handleFeedbackSubmit - Datos del formulario enviados:', values);

        
    };

    const handleCancel = (id: number, role: 'Mentor' | 'Mentee' | 'General') => {
        setSelectedActivityId(id);
        setSelectedRole(role);
        setOpenConfirm(true);
    };

    const confirmCancel = async () => {
        if (selectedActivityId === null || selectedRole === null) return;

        const activity = activities.find((act) => act.activityId === selectedActivityId);

        if (!activity) {
            dispatch(deleteAlert({ alert_id: `canceling_activity_${Date.now()}` }));
            const error_alert = {
                id: `activity_not_found_${Date.now()}`,
                type: "error",
                title: "Error",
                desc: "Actividad no encontrada.",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
            return;
        }

        if (!userEmail) {
            dispatch(deleteAlert({ alert_id: `canceling_activity_${Date.now()}` }));
            const error_alert = {
                id: `user_email_missing_${Date.now()}`,
                type: "error",
                title: "Error",
                desc: "No se encontró el correo electrónico del usuario.",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
            return;
        }

        const new_alert = {
            id: `canceling_activity_${Date.now()}`,
            type: "loading",
            title: "Cancelando actividad",
            desc: "",
            close: false,
            timeout: 0,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            if (activity.typeId === 6) {
                if (selectedRole === 'Mentor') {
                    await cancelMentorContactWheel({
                        email: userEmail,
                        activityId: activity.activityId,
                    }).unwrap();
                } else if (selectedRole === 'Mentee') {
                    await cancelMenteeContactWheel({
                        email: userEmail,
                        activityId: activity.activityId,
                    }).unwrap();
                }
            } else {
                await cancelUpcomingActivity({
                    activityId: activity.activityId,
                    userId: userId ?? 0,
                }).unwrap();
            }
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            const success_alert = {
                id: `activity_canceled_${Date.now()}`,
                type: "success",
                title: "Actividad cancelada",
                desc: "La actividad ha sido cancelada exitosamente",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: success_alert }));
            setReload(true);
        } catch (error) {
            console.error('Error al cancelar la actividad:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            const error_alert = {
                id: `cancel_activity_error_${Date.now()}`,
                type: "error",
                title: "Error al cancelar",
                desc: "Hubo un error al cancelar la actividad",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
        }
    };

    const generateActionButtons = (activity: Activity): ActionButtonProps<any>[] => {
        const actionButtons: ActionButtonProps<any>[] = [];
        const { roleInActivity, typeId, mentorAccreditationByBlock, menteeAccreditationByBlock } = activity;
        const isRuedaDeContactos = typeId === 6;

        if (roleInActivity.statusId === 1) {
            if (isRuedaDeContactos) {
                const counterpartsList = roleInActivity.role === 'Mentor'
                    ? menteeAccreditationByBlock.map(cp => ({ name: cp.fullName, hasFeedback: cp.hasFeedback }))
                    : mentorAccreditationByBlock.map(cp => ({ name: cp.fullName, hasFeedback: cp.hasFeedback }));

                const hasAnyFeedbackPending = counterpartsList.some(cp => !cp.hasFeedback);

                actionButtons.push({
                    label: 'Evaluar',
                    color: 'primary',
                    onClick: () => handleEvaluation(
                        activity.activityId,
                        counterpartsList,
                        roleInActivity.role === 'Mentor',
                        activity.activityType
                    ),
                    disabled: !hasAnyFeedbackPending,
                });
            } else {
                const counterpartName = roleInActivity.role === 'Mentor'
                    ? menteeAccreditationByBlock[0]?.fullName
                    : mentorAccreditationByBlock[0]?.fullName;

                actionButtons.push({
                    label: roleInActivity.hasFeedback ? 'Evaluado' : 'Evaluar',
                    color: roleInActivity.hasFeedback ? 'success' : 'primary',
                    onClick: () => handleEvaluation(
                        activity.activityId,
                        [{
                            name: counterpartName || "la contraparte",
                            hasFeedback: activity.roleInActivity.hasFeedback
                        }],
                        roleInActivity.role === 'Mentor',
                        activity.activityType
                    ),
                    disabled: roleInActivity.hasFeedback,
                });
            }
        }




        return actionButtons;
    };

    const formattedActivities = activities
        .filter((activity) => activity.activityId !== null)
        .map((activity) => {
            const isMentorRole = activity.roleInActivity.role === 'Mentor';
            const counterpart = isMentorRole ? activity.menteeAccreditationByBlock : activity.mentorAccreditationByBlock;

            return {
                Evento: activity.eventName,
                Temática: activity.activityType,
                Actividad: activity.activityName,
                Fecha: moment(activity.startDate).format('DD/MM/YYYY'),
                "Hora de Inicio": moment(activity.startDate).format('HH:mm'),
                "Hora de Termino": moment(activity.endDate).format('HH:mm'),
                Contraparte: counterpart.map((user) => ({ id: user.userId, name: user.fullName, hasFeedback: user.hasFeedback })),
                Estado: activity.roleInActivity.statusId !== null ? (statusNames[activity.roleInActivity.statusId] || activity.roleInActivity.statusName) : activity.roleInActivity.statusName,
                Rol: activity.roleInActivity.role === 'Mentor' ? 'Experto' : 'Emprendedor',
                actionButtons: generateActionButtons(activity),
            };
        });

    const columnsToShow = [
        'Evento',
        'Actividad',
        'Temática',
        'Rol',
        'Fecha',
        'Hora de Inicio',
        'Hora de Termino',
        'Contraparte',
        'Estado',
    ];

    const selectedActivity = activities.find(act => act.activityId === selectedActivityId);

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : activitiesError ? (
        <div className='table-container'>
            <p>No hay actividades disponibles.</p>
        </div>
    ) : (
        <div className='card-container' style={{ width: '100%', paddingBottom: '80px', minHeight: '500px' }}>
            <GenericCardList
                columns={columnsToShow}
                data={formattedActivities}
                renderers={{
                    Contraparte: (users: { id?: number, name?: string, hasFeedback?: boolean }[]) => (
                        <ul>
                            {users.map((user, index) =>
                                user.id && user.name ? (
                                    <li key={index}>
                                        <a
                                            href={`/user-profile/${user.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {user.name}
                                        </a>
                                        {user.hasFeedback && <span> (Evaluado)</span>}
                                    </li>
                                ) : (
                                    <li key={index}>
                                        <span>Información de usuario no disponible</span>
                                    </li>
                                )
                            )}
                        </ul>
                    )
                }}
            />
            <FeedbackDialog
                open={openFeedbackDialog}
                onClose={() => setOpenFeedbackDialog(false)}
                onSubmit={handleFeedbackSubmit}
                counterpartName={currentCounterpart}
                isMentor={isMentor}
                actividad={actividad as Actividad}
                contrapartes={[currentCounterpart]}
                availableTimeIds={selectedActivity?.availableTimeId}
            />
            {actividad === 'Rueda de Contactos' && (
                <EvaluateCounterpartsDialog
                    open={openEvaluateCounterpartsDialog}
                    onClose={() => setOpenEvaluateCounterpartsDialog(false)}
                    counterparts={contrapartes.map(name => ({
                        name,
                        hasFeedback: activities.find(act => act.activityId === selectedActivityId)?.roleInActivity.hasFeedback ?? false
                    }))}
                    onEvaluate={(name: string) => {
                        setCurrentCounterpart(name);
                        setOpenEvaluateCounterpartsDialog(false);
                        setOpenFeedbackDialog(true);
                    }}
                />
            )}
            <ConfirmationDialog
                open={openConfirm}
                title='Confirmación'
                message='Recuerda que si cancelas la actividad podría no haber cupos disponibles en otro horario. ¿Deseas cancelar la actividad?'
                onAccept={confirmCancel}
                onCancel={() => {
                    setOpenConfirm(false);
                    setSelectedActivityId(null);
                    setSelectedRole(null);
                }}
            />
        </div>
    );
};

export default UpcomingActivitiesUser;
