
import { useEffect, useState } from 'react';

import { Formik, Form, ErrorMessage } from 'formik'
import { useSignupMutation } from '../../../redux/features/auth/authApiSlice';
import { useSelector } from 'react-redux';
import { usePostUserExpertisesMutation, usePostUserInterestsMutation, usePutPersonalDataMutation, usePutUserRolesMutation } from '../../../redux/features/user/userApiSlice';
import moment from 'moment';
import { encryptPassword } from '../../../utils/utilsForms';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import GetInterests from '../../../hooks/dimensions/GetInterests/GetInterests';
// import MultiselectAuto from '../../../components/Form/MultiselectAuto/MultiselectAuto';
import SignUpStepper from './SignUpStepper';
import { usePostUserStartupsMutation } from '../../../redux/features/startups/startupsApiSlice';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { MultiSelectInteres } from '../../../components/MultiSelectInteres/MultiSelectInteres';
import MultiselectAuto from '../../../components/Form/MultiselectAuto/MultiselectAuto';

const SignUpPageFour = ({ ChangeFormState, actual_values, actual_photo_create }: any) => {

    const textLabels = useSelector(selectCurrentDictonary).home.filtrosHome.labels
    const { industries, Country, signup: textSignup } = useSelector(selectCurrentDictonary)

    const [isLoading, setIsLoading] = useState(true);
    const intereses = GetInterests();
    useEffect(() => {
        if (intereses && intereses.length > 0) {
            setIsLoading(false);
        }
    }, [intereses]);


   
    const dispatch = useDispatch()

    const [signUp]: any = useSignupMutation()
    const [putPersonalData]: any = usePutPersonalDataMutation()
    const [postUserExpertises]: any = usePostUserExpertisesMutation()
    const [postUserStartups]: any = usePostUserStartupsMutation()
    const [postUserInterests]: any = usePostUserInterestsMutation()
    const [putRoles]: any = usePutUserRolesMutation()

    const getDiccionarioIntereses = (id: number) => {
        if (id === 9) return industries
        else if (id === 4) return Country
        else if (id === 7) return textSignup.formaidea
        else if (id === 1) return textSignup.bill_ranges_originId
        else if (id === 15) return textSignup.numeroempleados
        else if (id === 10) return textSignup.investmentsource
        else if (id === 14) return textSignup.womenleader
        else if (id === 8) return textSignup.politicagenero
        else if (id === 5) return textSignup.politicainclusion
        else return {}
    }

    let initial_values = []
    for (let i = 0; i < intereses.length; i++) {
        const element = intereses[i];
        initial_values.push({ id: element.Id, selected_options: [] })
    }
    const [validated, setValidated] = useState(false)

    const validate = (values: any) => {
        const errors: any = {}

        let interests_keys_arr = [];
        let interests_id_arr = [];
        let keys_values = Object.keys(values);
        for (let i = 0; i < keys_values.length; i++) {
            const element = keys_values[i];
            if (element.includes("interes-auto-")) {
                interests_keys_arr.push(element)
                interests_id_arr.push(element.split("-auto-")[1])
            }
        }
        if (interests_keys_arr.length < 3) {
            errors.interests = textSignup.param_4
        }

        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
        } else {
            setValidated(false)
        }
        return errors
    }

    const Volver = (e: any) => {
        e.preventDefault();
        ChangeFormState("", 3)
    }

    function generate_personal_data_form_json() {
        let fecha_nacimiento_aux: any = ""
        if (!actual_values.personal_data_anio_nacimiento || !actual_values.personal_data_mes_nacimiento || !actual_values.personal_data_dia_nacimiento) {
        } else {
            let anio = actual_values.personal_data_anio_nacimiento
            let mes = actual_values.personal_data_mes_nacimiento.length === 2 ? actual_values.personal_data_mes_nacimiento : "0" + actual_values.personal_data_mes_nacimiento
            let dia = actual_values.personal_data_dia_nacimiento.length === 2 ? actual_values.personal_data_dia_nacimiento : "0" + actual_values.personal_data_dia_nacimiento

            fecha_nacimiento_aux = moment((anio + "-" + mes + "-" + dia), 'YYYY-MM-DD')
        }

        let phone_aux = actual_values.personal_data_phone ? actual_values.personal_data_phone : "" 
        let personal_data_gender = actual_values.personal_data_gender ? actual_values.personal_data_gender : "MASCULINO" 
        let values_personal_data = {
            "Name": actual_values.personal_data_name,
            "LastName": actual_values.personal_data_parent_name,
            "Email": actual_values.personal_data_email,
            "Phone": phone_aux,
            "Genre": personal_data_gender,
            "DateOfBirth": fecha_nacimiento_aux,
            "CountryId": actual_values.personal_data_country,
            "ActualCountryId": actual_values.personal_data_country,
            //"RegionId": 0,
        }
        return values_personal_data
    }

    function generate_startup_form_data() {
        let formData = new FormData();
        formData.append('Name', actual_values.startup_Name);
        formData.append('Description', actual_values.startup_Description);
        formData.append('Stage', actual_values.startup_Stage);

        if (actual_values.startup_DocumentValue) {
            formData.append('DocumentValue', actual_values.startup_DocumentValue);
        }
        if (actual_values.startup_CreationDateYear && actual_values.startup_CreationDateMonth && actual_values.startup_CreationDateDay) {
            let start_date_aux = actual_values.startup_CreationDateYear + "-" + actual_values.startup_CreationDateMonth + "-" + actual_values.startup_CreationDateDay;
            formData.append('StartDate', start_date_aux);
        }
/* intereses */
let industryValue = actual_values.startup_IndustryId || textSignup.alerta_cargando;
if (industryValue && industryValue != 0) {
    formData.append('Industry', industryValue);
}

let investmentSourceValue = actual_values.startup_InvestmentSourceLevelId || textSignup.alerta_cargando;
if (investmentSourceValue && investmentSourceValue != 0) {
    formData.append('InvestmentSource', investmentSourceValue);
}

let employeesNumberValue = actual_values.startup_EmployeesNumberId || textSignup.alerta_cargando;
if (employeesNumberValue && employeesNumberValue != 0) {
    formData.append('EmployeesNumber', employeesNumberValue);
}

let countryValue = actual_values.startup_CountryId || textSignup.alerta_cargando;
if (countryValue && countryValue != 0) {
    formData.append('Country', countryValue);
}

let genderEqualityPoliciesValue = actual_values.startup_GenderEqualityPolicies || textSignup.alerta_cargando;
if (genderEqualityPoliciesValue && genderEqualityPoliciesValue != 0) {
    formData.append('GenderEqualityPolicies', genderEqualityPoliciesValue);
}

let diversityInclusionPoliciesValue = actual_values.startup_DiversityInclusionPolicies || textSignup.alerta_cargando;
if (diversityInclusionPoliciesValue && diversityInclusionPoliciesValue != 0) {
    formData.append('DiversityInclusionPolicies', diversityInclusionPoliciesValue);
}

let ledByWomenValue = actual_values.startup_LedByWomen || textSignup.alerta_cargando;
if (ledByWomenValue && ledByWomenValue != 0) {
    formData.append('LedByWomen', ledByWomenValue);
}

let billingRangeValue = actual_values.startup_BillingRange || textSignup.alerta_cargando;
if (billingRangeValue && billingRangeValue != 0) {
    formData.append('BillingRange', billingRangeValue);
}

        /* redes sociales */
        if (actual_values.startup_WebPage) {
            formData.append('WebPage', actual_values.startup_WebPage);
        }
        if (actual_values.startup_FacebookUrl) {
            formData.append('FacebookUrl', actual_values.startup_FacebookUrl);
        }
        if (actual_values.startup_InstagramUrl) {
            formData.append('InstagramUrl', actual_values.startup_InstagramUrl);
        }
        /* foto y video */
        if (actual_photo_create) {
            formData.append('StartupPhoto', actual_photo_create);
        }
        /* 
        if (actual_video_create) {
            formData.append('StartupVideo', actual_video_create);
        } */
        return formData
    }

    async function sign_up_requests(password_aux: any, actual_values: any, lista_id_intereses: any) {
        let item_singUp = {
            "password": password_aux,
            "emailAddress": actual_values.personal_data_email
        }
        const res_singUp: any = await signUp({ body: item_singUp });
        const responseData_singUp = JSON.parse(res_singUp.data.responseData)
        const statusCode_singUp = res_singUp.data.statusCode
        if (statusCode_singUp == 200) {

            let new_user_id = responseData_singUp.UserId;

            /* Datos personales */
            let values_personal_data = generate_personal_data_form_json()
            await putPersonalData({ id: new_user_id, body: values_personal_data });

            /* Emprendedor o ambos */
            if (actual_values.userRoles == 1 || actual_values.userRoles == 3) {
                let formData = generate_startup_form_data()

                try {
                    await postUserStartups({ body: formData, user_id: new_user_id });
                } catch (error) {
                    // Alerta error experiencia
                    let new_alert_error = { id: "error_signup_startup", type: "error", title: textSignup.error_p4_empre, desc: "", close: true }
                    dispatch(addAlert({ alert: new_alert_error }))
                }
            }

            /* Experto o ambos */
            if (actual_values.userRoles == 2 || actual_values.userRoles == 3) {
                let experiencia_laboral_form_json = {
                    "userId": new_user_id,
                    "industryId": actual_values.expertises_industry,
                    "companyId": actual_values.expertises_companyId,
                    "jobTitleId": actual_values.expertises_jobTitle,
                    "startDate": actual_values.expertises_startDate + "",
                    "endDate": actual_values.expertises_endDate + "",
                }

                try {
                    await postUserExpertises({ ...experiencia_laboral_form_json });

                } catch (error) {
                    // Alerta error experiencia
                    let new_alert_error = { id: "error_signup_experiencia", type: textSignup.error_p4_exp, desc: textSignup.param_5, close: true }
                    dispatch(addAlert({ alert: new_alert_error }))
                }

            }


            /* Intereses */
            try {
                let item = {
                    "userId": new_user_id,
                    "interests": lista_id_intereses
                }
                await postUserInterests({ body: item });

            } catch (error) {
                // Alerta error intereses
                let new_alert_error = { id: "error_signup_intereses", type: "error", title: textSignup.error_p4_inte, desc: textSignup.param_6, close: true }
                dispatch(addAlert({ alert: new_alert_error }))
            }

            /* ROLES */
            if (actual_values.userRoles == 1) {/* es emprendedor */
                let out_payload_emprendedor = { "UserId": new_user_id, "RoleId": 1, "Active": 1 }
                await putRoles({ body: out_payload_emprendedor });
            }
            if (actual_values.userRoles == 2) {/* es experto */
                let out_payload_experto = { "UserId": new_user_id, "RoleId": 6, "Active": 1 }
                await putRoles({ body: out_payload_experto });
            }
            if (actual_values.userRoles == 3) {/* es experto */
                let out_payload_emprendedor = { "UserId": new_user_id, "RoleId": 1, "Active": 1 }
                await putRoles({ body: out_payload_emprendedor });

                let out_payload_experto = { "UserId": new_user_id, "RoleId": 6, "Active": 1 }
                await putRoles({ body: out_payload_experto });
            }


            // Alerta OK
            dispatch(deleteAlert({ alert_id: "cargando_signup" }))
            let new_alert_2 = { type: "success", title: textSignup.titulo_4, desc: "OK", timeout: 2000, close: true }
            dispatch(addAlert({ alert: new_alert_2 }))
            ChangeFormState(actual_values, 5)

            // fin status 200
        } else {
            // Alerta error
            dispatch(deleteAlert({ alert_id: "cargando_signup" }))
            let new_alert_error = { id: "error_signup", type: "error", title: textSignup.titulo_5, desc: textSignup.param_7, close: true }
            dispatch(addAlert({ alert: new_alert_error }))
        }
    }
    async function handleSubmit(values: any, { setStatus, setErrors }: any) {
        let lista_id_intereses: any = []

        for (const key in values) {
            let key_aux = key;
            if (key_aux.split("-")[0] == "interes") {
                values[key].forEach((element: any) => {
                    lista_id_intereses.push(element.value)
                });
            }
        }

        let new_alert = { id: "cargando_signup", type: "loading", title: textSignup.alerta_cargando, desc: textSignup.titulo_6, close: false }
        dispatch(addAlert({ alert: new_alert }))

        /*  */
        let password_aux = ""
        encryptPassword(actual_values.personal_data_password).then((hash) => {
            password_aux = hash
            sign_up_requests(password_aux, actual_values, lista_id_intereses)

        })



    }

    let formularios: any = []
    intereses?.forEach((item: any) => {
        let formulario = <></>
        formulario = <div key={"interes-" + item.Id} className="mb-4">
            {textLabels[item.Id]}
            <div>
                <MultiSelectInteres
                    item={item}
                    placeholder={textSignup.placeholder_5 + textLabels[item.Id]}
                    diccionario={getDiccionarioIntereses(item.Id)}
                />
            </div>
        </div>
        formularios.push(formulario)
    })

    return (
        <Formik
            initialValues={actual_values}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            {({ errors, touched }) => (

                <Form className='px-lg-3 px-xl-5'>
                    <div className='mb-3 mb-lg-4 mt-3 mt-lg-4'>
                        <h3 className='text-center mb-3'>{textSignup.titulo_1}<div className='text-primary d-inline mb-0'>meet</div></h3>
                        <div className='d-flex justify-content-center align-items-start mb-4'>
                            <SignUpStepper step={actual_values.step - 1}></SignUpStepper>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <div className='d-flex flex-column align-items-center justify-content-center mb-3 mt-3'>
                                <h4 className='mb-0'>{textSignup.titulo_7}</h4>
                                <p>{textSignup.param_8}<b>3</b> {textSignup.param_8_1}</p>
                            </div>
                        </div>
                    </div>
                    {intereses && intereses.length > 0 ? (
                        formularios.map((item: any) => {
                            return (item);
                        })
                    ) : (
                        <p>Cargando...</p>
                    )}
                    <ErrorMessage name="interests" />
                    {errors?.interests}
                    <div className='row mt-4'>
                        <div className='col-12 '>
                            <div className='d-flex justify-content-between'>
                                <button /* disabled={loading || !validated} */ onClick={Volver} className='btn btn-rounded btn-outline-primary'>
                                    {textSignup.btn_3}
                                </button>
                                <button disabled={!validated} type="submit" className='btn btn-rounded btn-primary'>
                                    {textSignup.btn_4}
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )

}

export default SignUpPageFour;