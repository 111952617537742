import { NavLink, NavLinkProps } from 'react-router-dom';
import Card from '../../Card/Card';
import './SettingsLayoutItem.scss'
import { useState, forwardRef } from 'react';
import ListItem from '@mui/material/ListItem'

export interface AppMenuItemComponentProps {
  className?: string
  link?: string | null // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  children:any
  isExpandable:any
  isChildren:any
}


function SettingsLayoutItemComponent({ className, onClick, link, children, isExpandable, isChildren }: AppMenuItemComponentProps) {

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className +"  " +  (isChildren?" settings-sidebar-item-children":"")}
        children={children}
        onClick={onClick}
      />
    )
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: any, ref: any) => <NavLink {...props} ref={ref} exact="true" />)}
      to={link}
    />
  )
}
export default SettingsLayoutItemComponent;
