import './LanguageButton.scss';
import { useDispatch, useSelector } from 'react-redux'; // Importamos useDispatch y useSelector
import * as React from 'react';
import { selectCurrentIconLanguage, setIcon } from '../../../../redux/features/language/LanguageSlice';
import { setLanguage, setDictIonary } from '../../../../redux/features/language/LanguageSlice';
import { LanguageMenu } from '../../../LanguageMenu/LanguageMenu';
import { Button } from '@mui/material';

import { CircleFlag } from 'react-circle-flags'

function LanguageButton({ user_personal_data }: any) {

    const icon = useSelector(selectCurrentIconLanguage)
    const dispatch = useDispatch(); // Usamos useDispatch
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleLanguageChange = (langCode: string) => {
        // Cambiar el idioma en el estado de Redux
        dispatch(setLanguage({ language: langCode }));
        // Cambiar el diccionario en el estado de Redux
        dispatch(setDictIonary({ dictionary: langCode }));
        // Cambiar el icono de bandera en el estado de Redux
        dispatch(setIcon({ language: langCode }));
        // Guardar la preferencia de idioma en localStorage
        localStorage.setItem('language', langCode);
        setAnchorEl(null)
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <div className='language-menu-container'>
            <React.Fragment key={"right"}>
                <Button
                    id="language-menu"
                    onClick={handleClick}
                >
                    <CircleFlag countryCode={icon} height="25" />
                    <i className="fa-solid fa-caret-down dropicon"></i>
                </Button>
                <LanguageMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)} handleLanguageChange={handleLanguageChange}
                />

            </React.Fragment>
        </div>
    )
}

export default LanguageButton;