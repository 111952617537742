
import { Actividad, Rol } from './constants';

export type Question =
    | {
        type: 'rating';
        text: string;
        name: string;
        defaultValue?: number;
    }
    | {
        type: 'toggle';
        text: string;
        name: string;
        defaultValue?: boolean;
    };

export const feedbackQuestionsData: Record<Actividad, Record<Rol, Question[]>> = {
    'Mentoría': {
        'emprendedor': [
            {
                name: 'informacionUtil',
                text: "¿El experto proporcionó información y consejos útiles para tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'recomendarActividad',
                text: "¿Recomendarías esta actividad a otros emprendedores?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'satisfaccionGeneral',
                text: "¿Cómo calificarías tu satisfacción general con la actividad?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    },
    'Classic Car Pitch': {
        'emprendedor': [
            {
                name: 'informacionUtil',
                text: "¿El experto proporcionó información y consejos útiles para tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'recomendarActividad',
                text: "¿Recomendarías esta actividad a otros emprendedores?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'satisfaccionGeneral',
                text: "¿Cómo calificarías tu satisfacción general con la actividad?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    },
    'Rueda de Contactos': {
        'emprendedor': [
            {
                name: 'eleccionExperto',
                text: "¿Crees que el experto asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'eleccionEmprendedor',
                text: "¿Crees que el emprendedor asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    },
    'Electric Car Pitch': {
        'emprendedor': [
            {
                name: 'eleccionExperto',
                text: "¿Crees que el experto asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'recomendarActividad',
                text: "¿Recomendarías esta actividad a otros emprendedores/expertos?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'eleccionEmprendedor',
                text: "¿Crees que el emprendedor asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'satisfaccionGeneral',
                text: "¿Cómo calificarías tu satisfacción general con la actividad?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    },
    'Wheel Pitch': {
        'emprendedor': [
            {
                name: 'eleccionExperto',
                text: "¿Crees que el experto asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'recomendarActividad',
                text: "¿Recomendarías esta actividad a otros emprendedores/expertos?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'eleccionEmprendedor',
                text: "¿Crees que el emprendedor asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'satisfaccionGeneral',
                text: "¿Cómo calificarías tu satisfacción general con la actividad?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    },
    'Elevator Pitch': {
        'emprendedor': [
            {
                name: 'eleccionExperto',
                text: "¿Crees que el experto asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'utilidadActividad',
                text: "¿Qué tan útil fue la actividad para el desarrollo de tu proyecto?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'recomendarActividad',
                text: "¿Recomendarías esta actividad a otros emprendedores/expertos?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ],
        'experto': [
            {
                name: 'puntosClave',
                text: "¿Se presentaron los puntos clave del proyecto de manera efectiva?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'eleccionEmprendedor',
                text: "¿Crees que el emprendedor asignado fue la elección adecuada para ti?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'satisfaccionGeneral',
                text: "¿Cómo calificarías tu satisfacción general con la actividad?",
                type: "rating",
                defaultValue: 0
            },
            {
                name: 'mantenerContacto',
                text: "¿Te gustaría mantener el contacto con “xxxxxxxxxx”?",
                type: "toggle",
                defaultValue: false
            }
        ]
    }
};

export default feedbackQuestionsData;
