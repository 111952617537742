import './Favorites.scss';

function Favorites() {
  return (
    <div className="construction-page">
      <img className="construction-page-icon" src="/media/iconos/en-construccion.svg" />
      <h1>Página en construcción</h1>
      <p>¡Estamos trabajando en ella! Vuelve pronto para ver el resultado final.</p>
    </div>
  );
}

export default Favorites;