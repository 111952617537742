import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectUserDonatedTimes, setUserDonatedTimes } from '../../../redux/features/meetings/meetingsSlice';
import { useGetUserDonatedTimeMutation } from '../../../redux/features/meetings/meetingsApiSlice';


export default function GetUserDonatedTime(user_id: any) {

    const dispatch = useDispatch()
    const value = useSelector(selectUserDonatedTimes)
    const [get]: any = useGetUserDonatedTimeMutation()
    const [isLoading, setIsLoading] = useState(false)
    const [initialCharge, setInitialCharge] = useState(true)

    const fetch = async () => {
        setIsLoading(true)
        const res: any = await get({ user_id: user_id, type: 0});
        const resData = JSON.parse(res.data.responseData)
        dispatch(setUserDonatedTimes({ user_donated_times: resData }))
        setIsLoading(false)
        setInitialCharge(false)
    }

    useEffect(() => {
        if (initialCharge) {
            fetch()
        }
    }, [value]);

    return { value: value, isLoading: isLoading };
}
