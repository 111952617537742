

import CardUser from '../../components/CardUser/CardUser'
import Card from '../../components/Card/Card'
import { useState } from "react";

const Match = () => {
    const [isCheckedEmprendedores, setIsCheckedEmprendedores] = useState(false);
    const [isCheckedMentores, setIsCheckedMentores] = useState(false);
    const [isCheckedInversionistas, setIsCheckedInversionistas] = useState(false);
    const [isCheckedGerentes, setIsCheckedGerentes] = useState(false);
    const [isCheckedEmprendimientos, setIsCheckedEmprendimientos] = useState(false);

    const arrUsuarios = [
        {
            userid: 1,
            nombres: "Nombre1 Nombre2",
            apellidos: "Apellido1 Apellido2",
            apodo: "Apodo1",
            roles: ["mentor", "inversionista"],
            empresa: "empresa",
            cargo: "Gerente General",
            con_tiempo: 1,
            intereses: ["Mineria", "Otro"],
            rrss: {
                tw: "twitter",
                fb: "facebook",
                insta: "instagram",
                lk: "Linkeding"
            },
            calificacion: 2.3,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus condimentum quis massa sit amet faucibus. Nullam ultrices est in dolor condimentum luctus. Aenean interdum malesuada neque. Ut vel arcu eu ante scelerisque tristique. Nunc non sollicitudin leo. Quisque aliquet, orci nec facilisis malesuada, turpis tellus pretium velit, non feugiat tellus mauris ac erat. Fusce convallis diam eget arcu sagittis vestibulum. Suspendisse ac ipsum cursus diam efficitur pulvinar",
        },
        {
            userid: 1,
            nombres: "Nombre1 Nombre2",
            apellidos: "Apellido1 Apellido2",
            apodo: "Apodo1",
            roles: ["mentor", "inversionista"],
            empresa: "empresa",
            cargo: "Gerente General",
            con_tiempo: 1,
            intereses: ["Mineria", "Otro"],
            rrss: {
                tw: "twitter",
                fb: "facebook",
                insta: "instagram",
                lk: "Linkeding"
            },
            calificacion: 2.3,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus condimentum quis massa sit amet faucibus. Nullam ultrices est in dolor condimentum luctus. Aenean interdum malesuada neque. Ut vel arcu eu ante scelerisque tristique. Nunc non sollicitudin leo. Quisque aliquet, orci nec facilisis malesuada, turpis tellus pretium velit, non feugiat tellus mauris ac erat. Fusce convallis diam eget arcu sagittis vestibulum. Suspendisse ac ipsum cursus diam efficitur pulvinar",
        },
        {
            userid: 1,
            nombres: "Nombre1 Nombre2",
            apellidos: "Apellido1 Apellido2",
            apodo: "Apodo1",
            roles: ["mentor", "inversionista"],
            empresa: "empresa",
            cargo: "Gerente General",
            con_tiempo: 1,
            intereses: ["Mineria", "Otro"],
            rrss: {
                tw: "twitter",
                fb: "facebook",
                insta: "instagram",
                lk: "Linkeding"
            },
            calificacion: 2.3,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus condimentum quis massa sit amet faucibus. Nullam ultrices est in dolor condimentum luctus. Aenean interdum malesuada neque. Ut vel arcu eu ante scelerisque tristique. Nunc non sollicitudin leo. Quisque aliquet, orci nec facilisis malesuada, turpis tellus pretium velit, non feugiat tellus mauris ac erat. Fusce convallis diam eget arcu sagittis vestibulum. Suspendisse ac ipsum cursus diam efficitur pulvinar",
        },
        {
            userid: 1,
            nombres: "Nombre1 Nombre2",
            apellidos: "Apellido1 Apellido2",
            apodo: "Apodo1",
            roles: ["mentor", "inversionista"],
            empresa: "empresa",
            cargo: "Gerente General",
            con_tiempo: 1,
            intereses: ["Mineria", "Otro"],
            rrss: {
                tw: "twitter",
                fb: "facebook",
                insta: "instagram",
                lk: "Linkeding"
            },
            calificacion: 2.3,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus condimentum quis massa sit amet faucibus. Nullam ultrices est in dolor condimentum luctus. Aenean interdum malesuada neque. Ut vel arcu eu ante scelerisque tristique. Nunc non sollicitudin leo. Quisque aliquet, orci nec facilisis malesuada, turpis tellus pretium velit, non feugiat tellus mauris ac erat. Fusce convallis diam eget arcu sagittis vestibulum. Suspendisse ac ipsum cursus diam efficitur pulvinar",
        },
    ]


    return (
        <>
            <div className='mb-4'>
                <h2>Match personas</h2>
                <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula metus orci, nec lacinia felis luctus at. Integer vestibulum suscipit tortor luctus pellentesque. Etiam tincidunt, quam id fermentum porttitor, lorem massa eleifend est, vitae tempus massa lectus at tellus. Nam scelerisque ligula luctus lectus tincidunt condimentum.</p>
            </div>
            <Card className="mb-4 filtro-usuarios">
                <div className='row no-gutters align-items-center'>
                    <div className='col-12 col-lg-auto mr-0 mr-lg-4 mb-4 mb-lg-0'>
                        <div>
                            <i className="fa-solid fa-magnifying-glass mr-2"></i>
                            <input className="" type="text" name="" defaultValue="" />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='row'>

                            <div className='col-12 col-lg-auto '>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input id="emprendedores_check" type="checkbox" className='mr-2' name="" checked={isCheckedEmprendedores} onChange={() => setIsCheckedEmprendedores((prev) => !prev)} />
                                    <label htmlFor="emprendedores_check" className='prevent-select mb-0'>Emprendedores</label>
                                </div>
                            </div>
                            <div className='col-12 col-lg-auto '>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input id="mentores_check" type="checkbox" className='mr-2' name="" checked={isCheckedMentores} onChange={() => setIsCheckedMentores((prev) => !prev)} />
                                    <label htmlFor="mentores_check" className='prevent-select mb-0'>Mentores</label>
                                </div>
                            </div>
                            <div className='col-12 col-lg-auto '>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input id="gerentes_check" type="checkbox" className='mr-2' name="" checked={isCheckedInversionistas} onChange={() => setIsCheckedInversionistas((prev) => !prev)} />
                                    <label htmlFor="gerentes_check" className='prevent-select mb-0'>Gerentes</label>
                                </div>
                            </div>
                            <div className='col-12 col-lg-auto '>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input id="inversionistas_check" type="checkbox" className='mr-2' name="" checked={isCheckedGerentes} onChange={() => setIsCheckedGerentes((prev) => !prev)} />
                                    <label htmlFor="inversionistas_check" className='prevent-select mb-0'>Inversionistas</label>
                                </div>
                            </div>
                            <div className='col-12 col-lg-auto '>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input id="emprendimientos_check" type="checkbox" className='mr-2' name="" checked={isCheckedEmprendimientos} onChange={() => setIsCheckedEmprendimientos((prev) => !prev)}  />
                                    <label htmlFor="emprendimientos_check" className='prevent-select mb-0'>Emprendimientos</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Card>
            <div className='row lista-usuarios'>
                {
                    arrUsuarios.map((usuario) => {
                        return (
                            <div key={usuario.userid} className='col-12 col-md-6 col-lg-4 mb-4'>
                                <CardUser usuario={usuario}></CardUser>
                            </div>
                        );
                    })
                }
            </div>
        </>
    )
}

export default Match;