
import TextInput from '../Form/TextInput'
import Radio from '../Form/Radio';
/* mui */
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
/* iconos mui */
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState } from 'react';
/*  */
import { Form, Formik } from "formik";
import { addAlert, deleteAlert } from "../../redux/features/alerts/alertsSlice";
/* funciones utiles formularios */
import {
    get_opciones_anios,
    get_opciones_companies,
    get_opciones_industries,
    get_opciones_jobtitles,
    get_opciones_anios_experiencia_laboral
} from '../../utils/utilsForms';
import moment from 'moment';
import Select from '../Form/Select';
import { DialogContent } from '@mui/material';
import Label from '../Form/Label';
import { useGetCompaniesMutation, usePostAddCompanyMutation } from '../../redux/features/dimensions/dimensionsApiSlice';
import { setCompanies } from '../../redux/features/dimensions/dimensionsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';


const FormularioExperiencia = ({ handleChangeData, actual_values, companies, jobtitles, industries, registro = false }: any) => {
    const dispatch = useDispatch()
    const textMyExp = useSelector(selectCurrentDictonary).experiences
    const textSignup = useSelector(selectCurrentDictonary).signup
    const textIndustrias = useSelector(selectCurrentDictonary).industries
    const textJobtitle = useSelector(selectCurrentDictonary).job_titles


    /* opciones select */
    let opciones_companies = get_opciones_companies(companies, false)
    let opciones_companies_html = opciones_companies.html
    let opciones_companies_disabled = false

     
        // console.log('Job Titles:', jobtitles);

    let opciones_jobtitles = get_opciones_jobtitles(jobtitles, false, textJobtitle)
    let opciones_jobtitles_html = opciones_jobtitles.html
    let opciones_jobtitles_disabled = false

    let opciones_industries = get_opciones_industries(industries, false, textIndustrias)
    let opciones_industries_html = opciones_industries.html
    let opciones_industries_disabled = false

    /* dialog compañia */
    const [openDialogAgregarCompania, setOpenDialogAgregarCompania] = useState(false);
    const handleClickOpenDialogAgregarCompania = () => {
        setOpenDialogAgregarCompania(true);
    };
    const handleCloseDialogAgregarCompania = () => {
        setOpenDialogAgregarCompania(false);
    };

    let opciones_anios_startDate = get_opciones_anios(1, 40, textSignup)
    let startDate = actual_values.expertises_startDate
    let maxAgeEndDate = 40
    if (startDate) {
        let startDateInt = parseInt(startDate)
        let actualYearInt = moment().year();
        maxAgeEndDate = actualYearInt - startDateInt + 1
    }

    let opciones_anios_endDate = <></>
    opciones_anios_endDate = get_opciones_anios_experiencia_laboral(1, maxAgeEndDate, true, textSignup)


    let form_con_company_html = <></>
    if (actual_values.expertises_conCompany == "1" ? true : false) {
        form_con_company_html = <>
            <div className='col-12  mb-3'>

                <Select className="w-100" disabled={opciones_companies_disabled} name="expertises_companyId" label={textMyExp.param_3} >
                    {opciones_companies_html}
                </Select>
                <div>
                    <p>{textMyExp.param_4} <a href="#" onClick={handleClickOpenDialogAgregarCompania}>{textMyExp.param_4_link}</a></p>
                </div>
            </div>
        </>
    }

    const [actual_photo_compania, setActualPhotoCompania] = useState<any>("")
    const [keyPhotoCompania, setKeyPhotoCompania] = useState<any>("")

    function size_file_text(size: number) {
        if (size < 10000000000) {
            return (Math.round(size / 10) / 100 + " KB")
        } else if (size < 1000000000) {
            return (Math.round(size / 10000) / 100 + " MB")
        } else if (size < 100000000000) {
            return (Math.round(size / 10000000) / 100 + " GB")
        } else {
            return size
        }
    }

    function formulario_photo_compania() {
        let btn_eliminar = <></>
        if (actual_photo_compania) {
            btn_eliminar = <button onClick={() => {
                setActualPhotoCompania("");

                let randomString = Math.random().toString(36);
                setKeyPhotoCompania(randomString)
            }
            }
                className='btn btn-sm btn-danger'>
                eliminar
            </button>
        }
        return (<div>
            <div>
                <input id="startup_StartupPhoto" name="startup_StartupPhoto" key={keyPhotoCompania || ''} type="file" onChange={(event: any) => {
                    if (event.currentTarget.files[0]) {
                        setActualPhotoCompania(event.currentTarget.files[0])
                    } else {
                        setActualPhotoCompania("")
                    }
                }} />
                {btn_eliminar}
            </div>
            <div>
                <p>{actual_photo_compania?.name}</p>
                <p>{size_file_text(actual_photo_compania?.size)}</p>
                <p>{actual_photo_compania?.type}</p>
            </div>
        </div>)
    }

    function validateAgregarCompania(values: any) {
        let errors: any = {}
        if (!values.add_company_Name) {
            errors.add_company_Name = textMyExp.param_8
        }
        if (!values.add_company_Cedula) {
            errors.add_company_Cedula = textMyExp.param_8
        }

        /* if(actual_photo_compania?.size > 500){
            errors.
        } */

        return errors;
    }
    const [postAddCompany, { isLoadingPostAddCompany }]: any = usePostAddCompanyMutation()

    async function handleSubmitAgregarCompania(values: any) {


        let new_alert = { id: "create_company", type: "loading", title: "Guardando compañia...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))


        try {

            const formData = new FormData();
            formData.append('Name', values.add_company_Name);
            formData.append('Rut', values.add_company_Cedula);
            if (actual_photo_compania) {
                formData.append('CompanyPhoto', actual_photo_compania);
            }
            const res: any = await postAddCompany({ body: formData });
            const resdata = JSON.parse(res.data.responseData)
            const resCode: any = res.data.statusCode

            if (resCode == 200) {
                fetchCompanias()
                const new_id_company = resdata.Id
                handleChangeData({ ...actual_values, expertises_companyId: new_id_company })
                dispatch(deleteAlert({ alert_id: "create_company" }));
                let new_alert_2 = { id: "create_company_success", type: "success", title: "Compañia guardada correctamente", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))

            } else {
                dispatch(deleteAlert({ alert_id: "create_company" }));
                let new_alert_2 = { id: "create_company_success", type: "error", title: "Error al guardar compañia", desc: "", close: true }
                dispatch(addAlert({ alert: new_alert_2 }))
            }
            handleCloseDialogAgregarCompania()

        } catch (error) {
            console.log(error)
        }
    }

    const [getCompanias, { isLoadingGetCompanias }]: any = useGetCompaniesMutation()
    const fetchCompanias = async () => {
        const res: any = await getCompanias();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setCompanies({ companies: resData }))
    }
    const formRef_crear_compañia: any = useRef();

    return (
        <>
            <div className='row'>
                <div className='col-12 mb-3'>

                    <Select className="w-100" disabled={opciones_industries_disabled} name="expertises_industry" label={textMyExp.param_1} required={true} >
                        {opciones_industries_html}
                    </Select>
                </div>
                <div className='col-12  mb-3'>
                    <label className='label label-primary'>{textMyExp.param_2}</label>
                    <Radio label={textMyExp.param_2_o1} value="1" name="expertises_conCompany" selected></Radio>
                    <Radio label={textMyExp.param_2_o2} value="0" name="expertises_conCompany" ></Radio>
                </div>
                {form_con_company_html}
                <div className='col-12 mb-3'>

                    <Select className="w-100" disabled={opciones_jobtitles_disabled} name="expertises_jobTitle" label={textMyExp.param_5} required={true}>
                        {opciones_jobtitles_html}
                    </Select>
                </div>
                <div className='col-6 mb-3'>

                    <Select className="w-100" disabled={opciones_jobtitles_disabled} name="expertises_startDate" label={textMyExp.param_6} required={true}>
                        {opciones_anios_startDate}
                    </Select>
                </div>
                <div className='col-6 mb-3'>

                    <Select className="w-100" disabled={opciones_jobtitles_disabled} name="expertises_endDate" label={textMyExp.param_7} required={true} >
                        {opciones_anios_endDate}
                    </Select>
                </div>
            </div>


            {/* =================== MODAL (Dialog) ===================  */}
            {/* modal compañia */}
            <Dialog
                fullScreen
                open={openDialogAgregarCompania}
                onClose={handleCloseDialogAgregarCompania}
            >

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialogAgregarCompania}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {textMyExp.agregar_compania_titulo}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Formik
                        innerRef={formRef_crear_compañia}
                        initialValues={{
                            add_company_Name: "",
                            add_company_Cedula: "",
                        }}
                        validate={validateAgregarCompania}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={handleSubmitAgregarCompania}
                    >
                        <Form >
                            <div className='row'>
                                <div></div>
                                <div className='col-12 mb-4'>
                                    <TextInput name="add_company_Name" label={textMyExp.agregar_compania_param_1} required={true} />
                                </div>

                                <div className='col-12 mb-4'>
                                    <TextInput name="add_company_Cedula" label={textMyExp.agregar_compania_param_2} required={true} />
                                </div>
                                <div className='col-12 mb-4'>
                                    <Label className={"label label-primary m-0 p-0"} label={textMyExp.agregar_compania_param_3} required={false}></Label>
                                    <p className='m-0  p-0'>{textMyExp.agregar_compania_param_3_1} </p>
                                    {formulario_photo_compania()}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-danger-outline mr-2" onClick={handleCloseDialogAgregarCompania}>
                                    {textMyExp.btn_cancel}
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {textMyExp.btn_save}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </DialogContent>
                <div>
                </div>
            </Dialog>
        </>
    )
}

export default FormularioExperiencia