import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useGetUserAdminRoleMutation } from '../../redux/features/user/userApiSlice';

const EmailSender = () => {
  const [subject, setSubject] = useState('');  
  const [body, setBody] = useState('');  
  const [recipient, setRecipient] = useState('');  
  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUserAdminRole] = useGetUserAdminRoleMutation();

  useEffect(() => {
    if (userPersonalData) {
      getUserAdminRole(userPersonalData.Id)
        .unwrap()
        .then(response => {
          const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
          setIsAdmin(isAdmin);
          if (!isAdmin) navigate('/home');
        })
        .catch(error => {
          console.error("Error fetching user role:", error);
          navigate('/home');
        });
    }
  }, [userPersonalData, navigate, getUserAdminRole]);

  if (!isAdmin) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Envío correo
      </Typography>
      <TextField
        label="Título del Correo"
        variant="outlined"
        fullWidth
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Cuerpo del Correo"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={body}
        onChange={(e) => setBody(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Destinatario"
        variant="outlined"
        fullWidth
        value={recipient}
        onChange={(e) => setRecipient(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Enviar
      </Button>
    </Box>
  );
};

export default EmailSender;
