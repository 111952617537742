import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetCompaniesMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectCompanies, setCompanies } from '../../../redux/features/dimensions/dimensionsSlice';


export default function GetCompanies() {

    const dispatch = useDispatch()
    const value = useSelector(selectCompanies)
    const [get, { isLoadingGet }]: any = useGetCompaniesMutation()
    

    const fetch = async () => {
        const res: any = await get();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setCompanies({companies: resData }))
    }

    useEffect(() => {
        if (!value || value.length == 0) {
            fetch()
        }
        /*  */
    }, [value]);

    return value;
}
