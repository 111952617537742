import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../components/Form/TextInput';
import { useGetActiveEventNamesQuery, useGetActiveIndustriesQuery, useGetActiveActivityTypesQuery } from '../../redux/features/events/eventsApiSlice';

interface EventOption {
    id: number;
    event: string;
}

interface IndustryOption {
    id: number;
    name: string;
}

interface ActivityTypeOption {
    id: number;
    name: string;
}

const ActivityFormCreate = ({ handleClose, handleSubmit, isSubmitting, initialValues }: any) => {
    const [ruedaDeContactosId, setRuedaDeContactosId] = useState<number | null>(null);
    const [isRuedaDeContactos, setIsRuedaDeContactos] = useState(false);

    const { data: activityTypes = [], isLoading: isLoadingActivityTypes, refetch: refetchActivityTypes } = useGetActiveActivityTypesQuery({});

 
    useEffect(() => {
        if (activityTypes.length > 0) {
            const ruedaDeContactos = activityTypes.find((type: ActivityTypeOption) => type.name === "Rueda de Contactos");
            if (ruedaDeContactos) {
                setRuedaDeContactosId(ruedaDeContactos.id);
            }
        }
    }, [activityTypes]);

    const validationSchema = Yup.object().shape({
        activityName: Yup.string().required('Este campo es obligatorio'),
        startTime: Yup.string().required('Este campo es obligatorio'),
        endTime: Yup.string().required('Este campo es obligatorio'),
        duration: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero').min(1, 'Debe ser mayor a 0'),
        blockDuration: Yup.number()
            .integer('Debe ser un valor entero')
            .min(1, 'Debe ser mayor a 0')
            .when('activityTypeId', ([activityTypeId], schema) => {
                const isRueda = activityTypeId === ruedaDeContactosId;

                return isRueda
                    ? schema.oneOf([Yup.ref('duration')], 'Debe coincidir con la duración total').required()
                    : schema
                          .max(Yup.ref('duration'), 'La duración del bloque debe ser menor o igual a la duración total')
                          .required('Este campo es obligatorio');
            }),
        breakTime: Yup.number().integer('Debe ser un valor entero').min(0, 'Debe ser mayor o igual a 0'),
        additionalTime: Yup.number().integer('Debe ser un valor entero').min(0, 'Debe ser mayor o igual a 0'),
        description: Yup.string().required('Este campo es obligatorio'),
        eventId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        industryId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        activityTypeId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        mentorsCount: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero').min(1, 'Debe ser mayor o igual a 1')
    });

    const defaultValues = {
        activityName: '',
        startTime: '',
        endTime: '',
        duration: 0,
        blockDuration: 0,
        breakTime: 0,
        additionalTime: 0,
        description: '',
        visible: 1,
        eventId: 0,
        industryId: 0,
        activityTypeId: 0,
        mentorsCount: 1
    };

    const mergedInitialValues = { ...defaultValues, ...initialValues };

    const { data: eventNames = [], isLoading: isLoadingEventNames, refetch: refetchEventNames } = useGetActiveEventNamesQuery({});
    const { data: industries = [], isLoading: isLoadingIndustries, refetch: refetchIndustries } = useGetActiveIndustriesQuery({});

    const handleActivityTypeChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
        const value = parseInt(e.target.value);
        setIsRuedaDeContactos(value === ruedaDeContactosId);
        setFieldValue('activityTypeId', value);  
    };

    const AutoFillBlockDuration = () => {
        const { values, setFieldValue } = useFormikContext<any>();

        useEffect(() => {
            if (isRuedaDeContactos) {
                setFieldValue('blockDuration', values.duration);
            }
        }, [values.duration, isRuedaDeContactos, setFieldValue]);

        return null;
    };

    return (
        <Formik
            initialValues={mergedInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ setFieldValue, values }) => (
                <Form>
                    <AutoFillBlockDuration />
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <label htmlFor="eventId">Evento</label>
                            <Field as="select" name="eventId" className="form-control" value={values.eventId} required>
                                <option value="">Seleccione un evento</option>
                                {isLoadingEventNames ? (
                                    <option value="">Cargando eventos...</option>
                                ) : (
                                    eventNames.map((event: EventOption) => (
                                        <option key={event.id} value={event.id}>
                                            {event.event}
                                        </option>
                                    ))
                                )}
                            </Field>
                        </div>

                        <div className='col-12 mb-3'>
                            <label htmlFor="activityTypeId">Tipo de Actividad</label>
                            <Field as="select" name="activityTypeId" className="form-control" value={values.activityTypeId} required onChange={(e: any) => handleActivityTypeChange(e, setFieldValue)}>
                                <option value="">Seleccione un tipo de actividad</option>
                                {isLoadingActivityTypes ? (
                                    <option value="">Cargando tipos de actividades...</option>
                                ) : (
                                    activityTypes.map((type: ActivityTypeOption) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))
                                )}
                            </Field>
                        </div>

                        <div className='col-12 mb-3'>
                            <label htmlFor="industryId">Industria</label>
                            <Field as="select" name="industryId" className="form-control" value={values.industryId} required>
                                <option value="">Seleccione una industria</option>
                                {isLoadingIndustries ? (
                                    <option value="">Cargando industrias...</option>
                                ) : (
                                    industries.map((industry: IndustryOption) => (
                                        <option key={industry.id} value={industry.id}>
                                            {industry.name}
                                        </option>
                                    ))
                                )}
                            </Field>
                        </div>

                        <div className='col-12 mb-3'>
                            <TextInput name="activityName" label="Temática" required={true} />
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput type="datetime-local" name="startTime" label="Fecha Comienzo" required={true} />
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput type="datetime-local" name="endTime" label="Fecha Término" required={true} />
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput type="number" name="duration" label="Duración Total (minutos)" required={true} />
                        </div>
                        {!isRuedaDeContactos && (
                            <div className='col-12 mb-3'>
                                <TextInput type="number" name="blockDuration" label="Duración del Bloque (minutos)" required={true} />
                            </div>
                        )}
                        <div className='col-12 mb-3'>
                            <TextInput type="number" name="breakTime" label="Tiempo de Descanso (minutos)" />  
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput type="number" name="additionalTime" label="Tiempo Adicional (minutos)" />  
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput name="description" label="Descripción" required={true} />
                        </div>
                        <div className='col-12 mb-3'>
                            <TextInput type="number" name="mentorsCount" label="Cantidad de Mentores" required={true} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            {isSubmitting ? 'Guardando...' : 'Guardar'}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ActivityFormCreate;
