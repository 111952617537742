import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetInterestsMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectInterests, setInterests } from '../../../redux/features/dimensions/dimensionsSlice';

export default function GetInterests() {

    const interests = useSelector(selectInterests);
    const [interests_aux, setInterests_aux] = useState(interests);
    const dispatch = useDispatch();
    const [getInterestsApi, { isLoadingGetInterestsApi }]: any = useGetInterestsMutation();

    const fetchInterests = async (interests_list: any[], interest_pos: number) => {
        if (interest_pos < interests_list.length) {
            const currentInterest = interests_list[interest_pos];
            if (!currentInterest || !currentInterest.Options || currentInterest.Options.length === 0) {
                const res: any = await getInterestsApi({ parent_id: currentInterest?.Id });
                const resData = JSON.parse(res.data.responseData);
                let new_interest = { ...currentInterest, Options: resData };
                interests_list[interest_pos] = new_interest;
                fetchInterests([...interests_list], interest_pos + 1).catch(console.error);
                if (interest_pos === (interests_list.length - 1)) {
                    dispatch(setInterests({ interests: interests_list }));
                }
            } else {
                fetchInterests([...interests_list], interest_pos + 1).catch(console.error);
            }
        }
    };

    useEffect(() => {
        if (interests_aux.length > 0) {
            fetchInterests([...interests_aux], 0).catch(console.error);
        }
    }, []);

    return interests;
}
