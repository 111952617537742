import "./UserPublicData.scss";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { setUserLanguages } from '../../../redux/features/user/userSlice';
import { usePutUserLanguagesMutation } from '../../../redux/features/user/userApiSlice';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";

const UserPublicDataLanguages = ({ languages, initialUserLanguages, userId }: any) => {

    const textPublicData = useSelector(selectCurrentDictonary).public_data

    const [putUserLanguages, { isLoadingPutUserLanguages }]: any = usePutUserLanguagesMutation()

    const dispatch = useDispatch()
    const [value, setValue] = useState([])
    const [formValidated, setFormValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initialValue = initialUserLanguages?.map((item: any) => {
        return {
            label: item.LanguageName,
            title: item.LanguageName,
            value: item.LanguageId,
            key: item.LanguageId,
        };
    });
    useEffect(() => {
        setValue(initialValue)
    }, [initialUserLanguages])


    const handleChange = (event: any, newValue: any) => {
        if (newValue != initialValue) {
            setFormValidated(true)
            setValue(newValue)
        } else {
            setFormValidated(false)
        }
    }


    async function handleSubmit() {
        setIsLoading(true)
        dispatch(deleteAlert({ alert_id: "error_user_languages" }))
        dispatch(deleteAlert({ alert_id: "cargando_user_languages" }))
        let new_alert = { id: "cargando_user_languages", type: "loading", title: textPublicData.titulo_7, desc: textPublicData.titulo_11, close: false }
        dispatch(addAlert({ alert: new_alert }))

        let languages_user_format = value.map((item: any) => {
            return {
                "UserId": userId,
                "LanguageId": item.value,
                "LanguageName": item.label
            };
        });
        try {
            const res: any = await putUserLanguages({ user_id: userId, body: languages_user_format })
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                dispatch(deleteAlert({ alert_id: "cargando_user_languages" }))
                let new_alert = { type: "success", title: "Editar idiomas", desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))
                dispatch(setUserLanguages({ user_languages: languages_user_format }))

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_user_languages" }))
                let new_alert2 = { id: "error_user_languages", type: "error", title: "Error en Editar idiomas", desc: "", close: true, timeout: 50000 }
                dispatch(addAlert({ alert: new_alert2 }))
            }
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "cargando_user_languages" }))
            let new_alert2 = { id: "error_user_languages", type: "error", title: "Error en Editar idiomas", desc: "", close: true, timeout: 50000 }
            dispatch(addAlert({ alert: new_alert2 }))
        }
        setIsLoading(false)
    }


    let button_submit = <></>
    button_submit = <div className='d-flex justify-content-end mt-3'>
        <button disabled={isLoading || !formValidated} className='btn btn-primary btn-rounded' onClick={() => handleSubmit()}>{textPublicData.btn_guardar}</button>
    </div>

    let form_html = <></>
    if (languages && languages.length > 0) {
        form_html = <div className='w-100'>
            <Autocomplete
                multiple
                options={languages?.map((item: any) => {
                    return {
                        label: item.Value,
                        title: item.Value,
                        value: item.Id,
                        key: item.Id,
                    };
                })}
                className="w-100"
                getOptionLabel={(option: any) => option.title}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        label={""}
                    />
                )}
                onChange={handleChange}
                value={value}
            />
            {button_submit}
        </div>
    } else {
        form_html = <p>{textPublicData.titulo_7}</p>
    }

    return (

        <div className=''>
            <div className='d-flex justify-content-start mb-4'>
                <div>
                    <h5><b>{textPublicData.titulo_5}</b></h5>
                    <p>{textPublicData.desc_4}</p>
                </div>
            </div>
            {form_html}

        </div>
    )
}

export default UserPublicDataLanguages;