import './NavbarItem.scss'
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../../redux/features/auth/authSlice";

import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar } from '@mui/material';


function NavbarItem({ label, conFoto = false, foto = "", IconImage = <></>, linkTo = "", counter = false, labelCaret = false, handleOnClick }: any) {

    let iconoFoto = <></>
    if (conFoto) {
        iconoFoto = <Avatar src={foto} className="navbarItem-foto"></Avatar>
    } else {
        iconoFoto = <IconImage color="white" />

    }
    /* ------------------------ Icono y Contador ---------------------------------------- */

    let counter_el = <></>
    let icon_el = <></>
    if (counter) {
        counter_el = <div className='navbarItem-counter'>
            <p className='navbarItem-counter-text prevent-select'>{counter}</p>
        </div>
        icon_el = <Badge badgeContent={counter} color="primary">
            {iconoFoto}
        </Badge>
    } else {
        icon_el = iconoFoto
    }


    /* ------------------------ Tipo de boton ---------------------------------------- */

    let labelCaret_el = <></>
    if (labelCaret) {
        labelCaret_el = <i className="fa-solid fa-caret-down"></i>
    }

    if (linkTo) { /* con link */
        return (
            <NavLink to={linkTo} className='navbarItem ' > {/* activeclassname={"active"} */}
                {icon_el}
                <p className="navbarItem-label prevent-select  d-none d-lg-block">{label} {labelCaret_el}</p>
            </NavLink>

        )

    } else if (handleOnClick) { /* con click, sin link */
        return (
            <div onClick={handleOnClick} className="navbarItem prevent-select">
                {icon_el}
                <p className="navbarItem-label prevent-select  d-none d-lg-block">{label} {labelCaret_el}</p>
            </div>
        )

    } else { /* sin click, sin link */
        return (
            <div className="navbarItem prevent-select">
                {icon_el}
                <p className="navbarItem-label prevent-select d-none d-lg-block">{label} {labelCaret_el}</p>
            </div>
        )

    }

}

export default NavbarItem;