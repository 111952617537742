

import './SignUp.scss'
import './SignUpStructure.scss'
import { ChangeEvent, useState } from 'react';
import Navbar from '../../../components/Navegation/Navbar/Navbar';
import SignUpPageFour from './SignUpPageFour';
import SignUpPageThree from './SignUpPageThree';
import SignUpPageTwo from './SignUpPageTwo';
import SignUpPageOne from './SignUpPageOne';
import Card from '../../../components/Card/Card';
import SignUpPageConfirm from './SignUpPageConfirm';

const SignUp = () => {

    const [actual_photo_create, setActualPhotoCreate] = useState<any>("");
    let initialState = {
        step: 1,
        // inicio pagina 1 (usuario)
        personal_data_name: "",
        personal_data_parent_name: "",
        personal_data_email: "",
        personal_data_phone: "",
        personal_data_gender: "",
        personal_data_country: "",
        personal_data_anio_nacimiento: "",
        personal_data_mes_nacimiento: "",
        personal_data_dia_nacimiento: "",
        personal_data_password: "",
        personal_data_confirmpassword: "",
        // fin pagina 1 (usuario)
        userRoles: 0,
        userStartupState: 0,
        // fin pagina 2 (roles)
        /* Startup */
        "startup_Name": "",
        "startup_DocumentValue": "",
        "startup_Description": "",
        "startup_WebPage": "",
        "startup_FacebookUrl": "",
        "startup_InstagramUrl": "",
        "startup_Stage": 0, // intereses
        "startup_InvestmentSourceLevelId": 0,
        "startup_CountryId": 0,
        "startup_IndustryId": 0,
        "startup_EmployeesNumber": 0,
        "startup_GenderEqualityPolicies": 0,
        "startup_DiversityInclusionPolicies": 0,
        "startup_LedByWomen": 0,
        "startup_StartupPhoto": "",
        "startup_StartupVideo": "",
        "startup_CreationDateYear": "",
        "startup_CreationDateMonth": "",
        "startup_CreationDateDay": "",

        /* experiencia */

        "expertises_industry": 0,
        "expertises_companyId": 0,
        "expertises_jobTitle": 0,
        "expertises_description": "",
        "expertises_startDate": "",
        "expertises_endDate": 9999,
        "expertises_conCompany": "1"

    }


    const [state, setState] = useState(initialState);

    // Handle fields change
    const ChangeFormState = (new_state: any, new_step: any) => {
        if (new_state) {
            setState({ ...new_state, step: new_step });
        } else {
            setState({ ...state, step: new_step });
        }
    }


    let orderState: number[] = [1, 2, 3, 4, 5]
    const selectForm = () => {
        switch (state.step) {
            case orderState[0]:
                return (
                    <SignUpPageOne
                        ChangeFormState={ChangeFormState}
                        actual_values={state}
                    />

                )

            case orderState[1]:
                return (
                    <SignUpPageTwo
                        ChangeFormState={ChangeFormState}
                        actual_values={state}
                        actual_photo_create={actual_photo_create}
                        setActualPhotoCreate={setActualPhotoCreate}
                    />

                )
            case orderState[2]:
                return (
                    <SignUpPageThree
                        ChangeFormState={ChangeFormState}
                        actual_values={state}
                        actual_photo_create={actual_photo_create}
                        setActualPhotoCreate={setActualPhotoCreate}
                    />
                )
            case orderState[3]:
                return (

                    <SignUpPageFour
                        ChangeFormState={ChangeFormState}
                        actual_photo_create={actual_photo_create}
                        actual_values={state}
                    />
                )
            case orderState[4]:
                return (
                    <SignUpPageConfirm
                    />
                )
            default:
                return ""
        }
    }
    let actual_form = selectForm()
    return (

        <div className='body-container signup-layout padding-layout-x'>
            {/* <div className='signup-layout padding-layout-x'> */}
            <div>
                <Card className='signup-container w-100'>
                    <div className='pt-2 px-3 pb-3 px-xl-5 pb-xl-3'>
                        {actual_form}
                    </div>
                </Card>
            </div>
            {/* </div> */}
        </div>
    )
}

export default SignUp;

