import './Navegacion.scss'
import NavbarItem from './NavbarItem';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'; 
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'; 
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { useEffect, useState } from 'react';
import { useGetUserAdminRoleMutation } from '../../../redux/features/user/userApiSlice';

function Navegacion() {
	const userPersonalData = useSelector(selectPersonalData);
	const textNavbar = useSelector(selectCurrentDictonary).navbar1;
	const [isAdmin, setIsAdmin] = useState(false);
	const [getUserAdminRole] = useGetUserAdminRoleMutation();

	useEffect(() => {
		if (userPersonalData) {
			getUserAdminRole(userPersonalData.Id)
				.unwrap()
				.then(response => {
					const isAdmin = response.message.includes("Administrator") || response.message.includes("Super Administrator");
					setIsAdmin(isAdmin);
				})
				.catch(error => {
					console.error("Error fetching user role:", error);
				});
		}
	}, [userPersonalData, getUserAdminRole]);

	let label = textNavbar.menu1 === "Search" ? "Help" : "Ayuda";
	let label3 = textNavbar.menu1 === "Search" ? "My Contacts" : "Mis Contactos";
	let label4 = textNavbar.menu1 === "Search" ? "Administrator" : "Administrador";
	
	const handleHelpClick = () => {
		const pdfFile = textNavbar.menu1 === "Home" ? "ETMmeet - User manual.pdf" : "ETMmeet - Manual de usuario.pdf";
		window.open(`${process.env.PUBLIC_URL}/media/manualUsuario/${pdfFile}`, '_blank', 'noopener,noreferrer');
	};

	return (
		<div className='navbar-item-container'>
			<NavbarItem label={textNavbar.menu1} IconImage={SearchOutlined} linkTo='/home'></NavbarItem>
			<NavbarItem label={textNavbar.menu2} IconImage={GroupsOutlinedIcon} linkTo='/meetings'></NavbarItem>
			<NavbarItem label={label3} IconImage={ContactsOutlinedIcon} linkTo='contacts'></NavbarItem>
			<NavbarItem label={textNavbar.menuEvent} IconImage={EventOutlinedIcon} linkTo='/events'></NavbarItem>
			<NavbarItem handleOnClick={handleHelpClick} label={label} IconImage={HelpOutlineIcon} />
			{/*<NavbarItem label={"test"} IconImage={SearchOutlined} linkTo='/buscador'></NavbarItem>*/}
			{isAdmin && (
				<NavbarItem label={label4} IconImage={BuildOutlinedIcon} linkTo='Admin-Panel' />
			)}
		</div>
	)
}
export default Navegacion;
