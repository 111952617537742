import { apiSlice } from "../../api/apiSlice";

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query({
            query: () => ({
                url: '/api/events/get-all-events',
                method: 'GET',
            })
        }),
        getEventById: builder.query({
            query: (id) => ({
                url: `/api/events/get-event-by-id/${id}`,
                method: 'GET',
            })
        }),
        addEvent: builder.mutation({
            query: (newEvent) => ({
                url: '/api/events/create-event',
                method: 'POST',
                body: newEvent,
            })
        }),
        updateEvent: builder.mutation({
            query: ({ id, updatedEvent }) => ({
                url: `/api/events/update-event/${id}`,
                method: 'PUT',
                body: updatedEvent,
            })
        }),
        deleteEvent: builder.mutation({
            query: (id) => ({
                url: `/api/events/update-event-visibility/${id}`,
                method: 'PATCH',
            })
        }),

        getActiveEventNames: builder.query({
            query: () => ({
                url: '/api/events/get-event-names',
                method: 'GET',
            })
        }),


        getActivities: builder.query({
            query: () => ({
                url: '/api/events/get-all-activities',
                method: 'GET',
            })
        }),
        getActivityById: builder.query({
            query: (id) => ({
                url: `/api/events/get-activity-by-id/${id}`,
                method: 'GET',
            })
        }),
        addActivity: builder.mutation({
            query: (newActivity) => ({
                url: '/api/events/create-activity',
                method: 'POST',
                body: newActivity,
            })
        }),
        updateActivity: builder.mutation({
            query: ({ id, updatedActivity }) => ({
                url: `/api/events/update-activity/${id}`,
                method: 'PUT',
                body: updatedActivity,
            })
        }),
        deleteActivity: builder.mutation({
            query: (id) => ({
                url: `/api/events/update-activity-visibility/${id}`,
                method: 'PATCH',
            })
        }),
        getActiveIndustries: builder.query({
            query: () => ({
                url: '/api/events/get-active-industries',
                method: 'GET',
            })
        }),
        getAllActivities: builder.query({
            query: () => ({
                url: '/api/userevents/get-all-activities',
                method: 'GET',
            })
        }),

        getAllActivitiesTicket: builder.query({
            query: ({ userId }) => ({
                url: `/api/userevents/get-all-activities-and-ticket/${userId}`,
                method: 'GET',
                params: { userId },
            })
        }),

        getActivityAvailableTimesMentor: builder.query({
            query: (activityId) => ({
                url: `/api/userevents/get-activity-available-times-mentor/${activityId}`,
                method: 'GET',
            })
        }),
        assignMentorToBlock: builder.mutation({
            query: ({ mentorId, blockId }) => ({
                url: `/api/userevents/assign-mentor-to-block`,
                method: 'POST',
                params: { mentorId, blockId },
            })
        }),
        assignMenteeToBlock: builder.mutation({
            query: ({ menteeId, blockId }) => ({
                url: `/api/userevents/assign-mentee-to-block`,
                method: 'POST',
                params: { menteeId, blockId },
            })
        }),
        getUserRolesEvents: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-user-roles-events/${userId}`,
                method: 'GET',
            })
        }),

        getActivityAvailableTimesMentee: builder.query({
            query: (activityId) => `/api/userevents/get-activity-available-times-mentee/${activityId}`,
        }),

        getActivityAvailableTimesMenteeAll: builder.query({
            query: ({ activityId, user_id }) => ({
                url: `/api/userevents/get-activity-available-times-mentee-all/${activityId}/${user_id}`,
                method: 'GET',
            })
        }),

        getUpcomingActivitiesByUser: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-upcoming-activities-by-user/${userId}`,
                method: 'GET',
            })
        }),

        cancelUpcomingActivityByUser: builder.mutation({
            query: ({ activityId, userId }) => ({
                url: `/api/userevents/cancel-upcoming-activity-user/${activityId}/${userId}`,
                method: 'PATCH',
            })
        }),
        checkUserInTicketSalesEtMtuesday: builder.query({
            query: (userId) => ({
                url: `/api/userevents/check-user-in-ticket-sales-etmtuesday/${userId}`,
                method: 'GET',
            })
        }),

        confirmAssist: builder.mutation({
            query: ({ userId, qrCode }) => ({
                url: `/api/userevents/register-assist`,
                method: 'PATCH',
                params: { userId, qrCode },
            })
        }),

        getActiveActivityTypes: builder.query({
            query: () => '/api/events/get-activity-types',
        }),
        getAllAvailableTimes: builder.query({
            query: () => ({
                url: '/api/events/get-all-available-times',
                method: 'GET',
            })
        }),

        sendActivityFeedback: builder.mutation({
            query: (feedbackData) => ({
                url: '/api/userevents/send-activity-feedback',
                method: 'POST',
                body: feedbackData,
            })
        }),
        assignMentorByAdmin: builder.mutation<{ mentorName: string }, { email: string; blockId: number }>({
            query: ({ email, blockId }) => ({
                url: '/api/events/assign-mentor-by-admin',
                method: 'POST',
                params: { email, blockId },
            }),
        }),
        
        assignMenteeByAdmin: builder.mutation<{ menteeName: string }, { email: string; blockId: number }>({
            query: ({ email, blockId }) => ({
                url: '/api/events/assign-mentee-by-admin',
                method: 'POST',
                params: { email, blockId },
            }),
        }),
        
        cancelMenteeByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/cancel-mentee-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),
        cancelMentorByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/cancel-mentor-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),
        getActivityHistoryByUser: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-activity-history-by-user/${userId}`,
                method: 'GET',
            })
        }),
        getActivitiesByIdsFeedback: builder.query({
            query: ({ id1, id2 }) => ({
                url: `/api/userevents/get-activities-by-ids-feedback/${id1}/${id2}`,
                method: 'GET',
            })
        }),
        getAvailableTimesContactWheel: builder.query({
            query: () => ({
                url: '/api/events/get-available-times-contact-wheel',
                method: 'GET',
            })
        }),

        cancelMenteeContactWheelByAdmin: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/events/cancel-mentee-from-contact-wheel-activity',
                method: 'POST',
                params: { email, activityId },
            })
        }),

        assignMenteeContactWheelByAdmin: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/events/assign-mentee-to-contact-wheel-by-admin',
                method: 'POST',
                params: { email, activityId },
            })
        }),


        cancelMentorContactWheelByAdmin: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/events/cancel-mentor-from-contact-wheel-activity',
                method: 'POST',
                params: { email, activityId },
            })
        }),


        assignMentorContactWheelByAdmin: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/events/assign-mentor-to-contact-wheel-by-admin',
                method: 'POST',
                params: { email, activityId },
            })
        }),


        confirmAssistContactWheelByAdmin: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/events/confirm-assist-contact-wheel',
                method: 'POST',
                params: { email, activityId },
            })
        }),



        confirmUserRegistration: builder.mutation({
            query: ({ ticketData, userData, selectedEventsPayloadDetails }) => {
                if (!ticketData || !userData || !Array.isArray(selectedEventsPayloadDetails)) {
                    console.error("Datos inválidos: faltan algunos parámetros requeridos o no son del tipo esperado.");
                    throw new Error("Datos inválidos. No se puede enviar la solicitud.");
                }

                return {
                    url: `/api/userevents/confirm-user-registration`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ticketData,
                        userData,
                        selectedEventsPayloadDetails,
                    }),
                };
            },
        }),


        getActiveEventsByUser: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-active-events-by-user/${userId}`,
                method: 'GET',
            }),
        }),


        assignMenteeToContactWheel: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/userevents/assign-mentee-to-contact-wheel',
                method: 'POST',
                params: { email, activityId },
            })
        }),

        cancelMenteeContactWheel: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/userevents/cancel-mentee-from-contact-wheel-activity',
                method: 'POST',
                params: { email, activityId },
            })
        }),

        cancelMentorContactWheel: builder.mutation({
            query: ({ email, activityId }) => ({
                url: '/api/userevents/cancel-mentor-from-contact-wheel-activity',
                method: 'POST',
                params: { email, activityId },
            })
        }),


        accreditActivityByAdmin: builder.mutation({
            query: ({ activityId, userEmail }) => ({
                url: '/api/events/accredit-activity-by-admin',
                method: 'POST',
                params: { activityId, userEmail },
            })
        }),
        checkAccreditByAdmin: builder.query({
            query: ({ activityId, userEmail }) => ({
                url: `/api/events/check-accredit-by-admin`,
                method: 'GET',
                params: { activityId, userEmail },
            })
        }),

        deaccreditActivityByAdmin: builder.mutation({
            query: ({ activityId, userEmail }) => ({
                url: '/api/events/deaccredit-activity-by-admin',
                method: 'POST',
                params: { activityId, userEmail },
            }),
        }),

        
        registerAssist: builder.mutation({
            query: ({ userId, qrCode }) => ({
                url: `/api/userevents/register-assist`,
                method: 'PATCH',
                params: { userId, qrCode },
            })
        }),

        newSendActivityFeedback: builder.mutation({
            query: (feedbackData) => ({
                url: '/api/userevents/new-send-activity-feedback',
                method: 'POST',
                body: feedbackData,
            })
        }),

        
        
        


    })
});

export const {
    useGetEventsQuery,
    useGetEventByIdQuery,
    useAddEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useGetActiveEventNamesQuery,
    useGetActivitiesQuery,
    useGetActivityByIdQuery,
    useAddActivityMutation,
    useUpdateActivityMutation,
    useDeleteActivityMutation,
    useGetActiveIndustriesQuery,

    useGetAllActivitiesQuery,
    useGetAllActivitiesTicketQuery,
    useGetActivityAvailableTimesMentorQuery,
    useAssignMentorToBlockMutation,
    useAssignMenteeToBlockMutation,
    useGetActivityAvailableTimesMenteeQuery,
    useGetActivityAvailableTimesMenteeAllQuery,

    useGetUserRolesEventsQuery,

    useGetUpcomingActivitiesByUserQuery,

    useCancelUpcomingActivityByUserMutation,

    useCheckUserInTicketSalesEtMtuesdayQuery,
    useConfirmAssistMutation,
    useGetActiveActivityTypesQuery,
    useGetAllAvailableTimesQuery,
    useSendActivityFeedbackMutation,
    useAssignMentorByAdminMutation,
    useAssignMenteeByAdminMutation,
    useCancelMenteeByAdminMutation,
    useCancelMentorByAdminMutation,
    useGetActivityHistoryByUserQuery,
    useGetActivitiesByIdsFeedbackQuery,

    useGetAvailableTimesContactWheelQuery,
    useCancelMenteeContactWheelByAdminMutation,
    useAssignMenteeContactWheelByAdminMutation,
    useCancelMentorContactWheelByAdminMutation,
    useAssignMentorContactWheelByAdminMutation,
    useConfirmAssistContactWheelByAdminMutation,

    useConfirmUserRegistrationMutation,
    useGetActiveEventsByUserQuery,
    useAssignMenteeToContactWheelMutation,
    useCancelMenteeContactWheelMutation,
    useCancelMentorContactWheelMutation,
    useAccreditActivityByAdminMutation,
    useCheckAccreditByAdminQuery,
    useDeaccreditActivityByAdminMutation,
    useRegisterAssistMutation,
    useNewSendActivityFeedbackMutation
    
} = eventsApiSlice;
