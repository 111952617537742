import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetCountriesMutation, useGetInterestsMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectCountries, selectInterests, setCountries, setInterests } from '../../../redux/features/dimensions/dimensionsSlice';


export default function GetCountries() {

    const dispatch = useDispatch()
    const value = useSelector(selectCountries)
    const [get, { isLoadingGet }]: any = useGetCountriesMutation()
    

    const fetchCountries = async () => {
        const res: any = await get();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setCountries({countries: resData }))
    }

    useEffect(() => {
        if (!value || value.length == 0) {
            fetchCountries()
        }
        /*  */
    }, [value]);

    return value;
}
