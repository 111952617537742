
import { useField } from 'formik'
import "./Inputs.scss"
import Label from './Label'
import { TextField } from '@mui/material'

const TextAreaInput = ({ label="", classNameLabel="label-primary", showErrorText=true, required=false, ...props }:any) => {
  const [field, meta] = useField(props)
  
  return (
    <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea className="text-area" {...field} {...props} />
        {meta.touched && meta.error && showErrorText ? <div className="error text-danger">{meta.error}</div> : null}
        
    </>
  )
}

export default TextAreaInput
