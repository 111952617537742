
/* componentes formulario */
import TextInput from '../Form/TextInput'
import Select from '../Form/Select'
import Label from '../Form/Label';
import Radio from '../Form/Radio';
/* iconos mui */
import { useState } from 'react';

/* funciones utiles formularios */
import {
    get_opciones_dias,
    get_opciones_anios,
    get_opciones_meses,
    get_opciones_countries,
    get_opciones_industries,
    get_opciones_html
} from '../../utils/utilsForms';

/* libreria tiempos */
import moment from 'moment';
import TextAreaInput from '../Form/TextAreaInput';
import GetIndustries from '../../hooks/dimensions/GetIndustries/GetIndustries';
import GetCountries from '../../hooks/dimensions/GetCountries/GetCountries';
import GetInterests from '../../hooks/dimensions/GetInterests/GetInterests';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';

const FormularioEmprendimiento = ({ actual_values, handleChangeData, actual_photo_create, setActualPhotoCreate, actual_video_create, setActualVideoCreate }: any) => {

    const textStartups = useSelector(selectCurrentDictonary).startups
    const textCountry = useSelector(selectCurrentDictonary).Country
    const textMeses = useSelector(selectCurrentDictonary).general.meses
    const textIndustrias = useSelector(selectCurrentDictonary).industries
    const textBillranges = useSelector(selectCurrentDictonary).signup.bill_ranges
    const textWoman = useSelector(selectCurrentDictonary).signup.womenleader
    const textInvestment = useSelector(selectCurrentDictonary).signup.investmentsource
    const textGenero = useSelector(selectCurrentDictonary).signup.politicagenero
    const textIdea = useSelector(selectCurrentDictonary).signup.formaidea
    const textInclusion = useSelector(selectCurrentDictonary).signup.politicainclusion
    const textNumempleados = useSelector(selectCurrentDictonary).signup.numeroempleados


    const [webPageError, setWebPageError] = useState("");
    const [instagramError, setInstagramError] = useState("");
    const [facebookError, setFacebookError] = useState("");


    let anio_actual_startup = moment().year();
    let max_edad_startup = 20;
    let min_edad_startup = 1;

    const [actualAnioMes, setActualAnioMes] = useState(anio_actual_startup + "-01")

    let opciones_anios_startup = get_opciones_anios(min_edad_startup, max_edad_startup, textStartups)
    let opciones_meses_startup = get_opciones_meses(textMeses)
    let opciones_dias_startup = get_opciones_dias(actualAnioMes)


    let industries = GetIndustries()
    let opciones_industries = get_opciones_industries(industries, false, textIndustrias)
    let opciones_industries_html = opciones_industries.html
    let opciones_industries_disabled = false


    let countries = GetCountries()
    let opciones_countries = get_opciones_countries(countries, false, textCountry)
    let opciones_countries_html = opciones_countries.html


    const intereses = GetInterests();

    /* Investment Source Levels */
    let opciones_investment_source_levels = get_opciones_html(intereses.find((el: any) => el.Id == 10).Options, false, textInvestment)
    let opciones_investment_source_levels_html = opciones_investment_source_levels.html
    let opciones_investment_source_levels_disabled = false

    /* Women Leadership */
    let opciones_woman_leadership = get_opciones_html(intereses.find((el: any) => el.Id == 14).Options, false, textWoman)
    let opciones_woman_leadership_html = opciones_woman_leadership.html
    let opciones_woman_leadership_disabled = false

    /* Gender Equality Policies */
    let opciones_gender_equality = get_opciones_html(intereses.find((el: any) => el.Id == 8).Options, false, textGenero)
    let opciones_gender_equality_html = opciones_gender_equality.html

    /* Billing Ranges */
    let opciones_billing_ranges = get_opciones_html(intereses.find((el: any) => el.Id == 1).Options, false, textBillranges)
    let opciones_billing_ranges_html = opciones_billing_ranges.html

    /* Diversity Inclusion Policies */
    let opciones_diversity_inclusion = get_opciones_html(intereses.find((el: any) => el.Id == 5).Options, false, textInclusion)
    let opciones_diversity_inclusion_html = opciones_diversity_inclusion.html

    /* Employees Number */
    let opciones_employees_number = get_opciones_html(intereses.find((el: any) => el.Id == 15).Options, false, textNumempleados)
    let opciones_employees_number_html = opciones_employees_number.html
    let opciones_employees_number_disabled = false



    let formulario_emprendedores_consolidados = <></>
    if (actual_values.startup_Stage == 122) {
        formulario_emprendedores_consolidados = <>
            <div className='col-12 col-lg-6 mb-3'>
                <Label classNameLabel='label-primary label' label={textStartups.titulo_6} required={true}></Label>
                <div className='d-flex justify-content-start'>
                    <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" name="startup_CreationDateYear" label="">
                        {opciones_anios_startup}
                    </Select>
                    <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" name="startup_CreationDateMonth" label="">
                        {opciones_meses_startup}
                    </Select>
                    <Select classNameLabel="label-secondary" name="startup_CreationDateDay" label="">
                        {opciones_dias_startup}
                    </Select>
                </div>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <TextInput name="startup_DocumentValue" label={textStartups.titulo_7} required={true} />
            </div>
        </>
    }
    

    function size_file_text(size: number) {
        if (size < 10000000000) {
            return (Math.round(size / 10) / 100 + " KB")
        } else if (size < 1000000000) {
            return (Math.round(size / 10000) / 100 + " MB")
        } else if (size < 100000000000) {
            return (Math.round(size / 10000000) / 100 + " GB")
        } else {
            return size
        }
    }
    const isValidURL = (str:any) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    const handleURLChange = (e:any, setErrorFunc:any) => {
        const { value } = e.target;
        if (!isValidURL(value)) {
            setErrorFunc("URL inválido");
        } else {
            setErrorFunc("");
        }
    }
    
    const [keyPhoto, setKeyPhoto] = useState("")
    function formulario_photo() {
        let btn_eliminar = <></>
        if (actual_photo_create) {
            btn_eliminar = <button onClick={() => {
                setActualPhotoCreate("");

                let randomString = Math.random().toString(36);
                setKeyPhoto(randomString)
            }
            }
                className='btn btn-sm btn-danger'>
                eliminar
            </button>
        }
        return (<div>
            <div>
                <input id="startup_StartupPhoto" name="startup_StartupPhoto" key={keyPhoto || ''} type="file" onChange={(event: any) => {
                    if (event.currentTarget.files[0]) {
                        setActualPhotoCreate(event.currentTarget.files[0])
                    } else {
                        setActualPhotoCreate("")
                    }
                }} />
                {btn_eliminar}
            </div>
            <div>
                <p>{actual_photo_create?.name}</p>
                <p>{size_file_text(actual_photo_create?.size)}</p>
                <p>{actual_photo_create?.type}</p>
            </div>
        </div>)
    }
    return (

        <div className='row'>

            <div className='col-12 mb-3'>

                <div className='row'>
                    <div className='col-12'>
                        <Label className={"label label-primary m-0 p-0"} label={textStartups.titulo_2} required={true}></Label>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Radio boldLabel={true} label={textStartups.option_1} value="119" name="startup_Stage" desc={textStartups.label_4}></Radio>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Radio boldLabel={true} label={textStartups.option_2} value="120" name="startup_Stage" desc={textStartups.label_5}></Radio>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Radio boldLabel={true} label={textStartups.option_3} value="121" name="startup_Stage" desc={textStartups.label_7}></Radio>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Radio boldLabel={true} label={textStartups.option_4} value="122" name="startup_Stage" desc={textStartups.label_7}></Radio>
                    </div>
                </div>
            </div>
            <div className='col-12 mb-4'>
                <Label className={"label label-primary m-0 p-0"} label={textStartups.titulo_3} required={true}></Label>
                <p className='m-0  p-0'>{textStartups.label_8}</p>
                <TextInput name="startup_Name" />
            </div>
            <div className='col-12 mb-4'>
                <Label className={"label label-primary m-0 p-0"} label={textStartups.titulo_4} required={false}></Label>
                <p className='m-0  p-0'>{textStartups.label_9}</p>
                {formulario_photo()}
            </div>

            <div className='col-12 mb-4'>

                <Label className={"label label-primary m-0 p-0"} label={textStartups.titulo_5} required={true}></Label>
                <p className='m-0  p-0'>{textStartups.label_10}</p>
                <TextAreaInput className="w-100" name="startup_Description" label="" required={true} />
            </div>
            {/*  */}
            {formulario_emprendedores_consolidados}

            <div className='col-12 mb-2 mt-2'>
                {/* <hr /> */}
                <h6 className='text-muted font-italic'>{textStartups.subtitle_1}</h6>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_industries_disabled} name="startup_IndustryId" label={textStartups.features_titulos[9]} >
                    {opciones_industries_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_employees_number_disabled} name="startup_EmployeesNumberId" label={textStartups.features_titulos[15]} >
                    {opciones_employees_number_html}
                </Select>
            </div>

            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_industries_disabled} name="startup_CountryId" label={textStartups.features_titulos[4]} >
                    {opciones_countries_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_industries_disabled} name="startup_BillingRange" label={textStartups.features_titulos[1]} >
                    {opciones_billing_ranges_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_investment_source_levels_disabled} name="startup_InvestmentSourceLevelId" label={textStartups.features_titulos[10]} >
                    {opciones_investment_source_levels_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_woman_leadership_disabled} name="startup_LedByWomen" label={textStartups.features_titulos[14]} >
                    {opciones_woman_leadership_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_woman_leadership_disabled} name="startup_GenderEqualityPolicies" label={textStartups.features_titulos[8]} >
                    {opciones_gender_equality_html}
                </Select>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
                <Select className="w-100" disabled={opciones_woman_leadership_disabled} name="startup_DiversityInclusionPolicies" label={textStartups.features_titulos[5]} >
                    {opciones_diversity_inclusion_html}
                </Select>
            </div>
            <div className='col-12 mb-2 mt-2'>
                {/* <hr /> */}
            <h6 className='text-muted font-italic' >{textStartups.subtitle_2}</h6>
            </div>
            <div className='col-12 col-lg-6 mb-3'>
            <TextInput 
                name="startup_WebPage" 
                label={textStartups.label_11} 
                onChange={(e:any) => handleURLChange(e, setWebPageError)}
            />
            {webPageError && <span style={{color: 'red'}}>{webPageError}</span>}
            </div>
            <div className='col-12 col-lg-6 mb-3'>
            <TextInput 
                name="startup_InstagramUrl" 
                label={textStartups.label_12} 
                onChange={(e:any) => handleURLChange(e, setInstagramError)}
            />
            {instagramError && <span style={{color: 'red'}}>{instagramError}</span>}
            </div>
            <div className='col-12 col-lg-6 mb-3'>
            <TextInput 
                name="startup_FacebookUrl" 
                label={textStartups.label_13} 
                onChange={(e:any) => handleURLChange(e, setFacebookError)}
            />
            {facebookError && <span style={{color: 'red'}}>{facebookError}</span>}
            </div>
        </div>
    )
}

export default FormularioEmprendimiento