
import { useState } from 'react';
import { Formik, Form } from 'formik'
import GetInterests from '../../hooks/dimensions/GetInterests/GetInterests';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { MultiSelectInteres } from '../../components/MultiSelectInteres/MultiSelectInteres';


const FiltrosHome = ({ ChangeFormState, actual_values, isLoading }: any) => {


    const intereses = GetInterests();
    const { industries, Country, filtros_interes: textFiltros } = useSelector(selectCurrentDictonary)

    const getDiccionarioIntereses = (id: number) => {
        if (id === 9) return industries
        else if (id === 4) return Country
        else if (id === 7) return textFiltros.formaidea
        else if (id === 1) return textFiltros.bill_ranges_originId
        else if (id === 15) return textFiltros.numeroempleados
        else if (id === 10) return textFiltros.investmentsource
        else if (id === 14) return textFiltros.womenleader
        else if (id === 8) return textFiltros.politicagenero
        else if (id === 5) return textFiltros.politicainclusion
        else return {}
    }

    const [validated, setValidated] = useState(false)

    const validate = (values: any) => {
        const errors: any = {}
        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
        } else {
            setValidated(false)
        }


        ChangeFormState({ ...actual_values, "intereses": values })
        return errors
    }

    async function handleSubmit(values: any, { setStatus, setErrors }: any) {
    }

    let inputs_formulario_intereses: any = []
    let inputs_formulario_intereses_AUX: any = []

    let inputs_formulario_intereses_html = <></>
    if (!isLoading) {
        intereses?.map((item: any) => {
            let formulario = <></>
            let options: any = []
            let cont_encontrados = 0
            item.Options.forEach((item_option: any) => {
                let encontrado = 0
                if (actual_values["intereses"]["interes-auto-" + item.Id]) {
                    let actual_intereses_values: any = [...actual_values["intereses"]["interes-auto-" + item.Id]];
                    actual_intereses_values?.forEach((interes_seleccionado: any) => {
                        if (interes_seleccionado.value == item_option.Id) {
                            encontrado = 1
                            cont_encontrados = cont_encontrados + 1
                        }
                    });
                }
                if (encontrado == 0) {
                    options.push({
                        // label: item_option.Value,
                        // title: item_option.Value,
                        label: getDiccionarioIntereses(item.Id)[item_option.OrigId ? item_option.OrigId : item_option.Id],
                        title: getDiccionarioIntereses(item.Id)[item_option.OrigId ? item_option.OrigId : item_option.Id],
                        value: item_option.Id,
                        key: item_option.Id,
                        parent_id: item.Id
                    });
                }
            });
            formulario = <div key={"interes-" + item.Id} className="mb-4 select-interes-container-container">
                <p className='mb-2 font-italic'>
                    {textFiltros.labels[item.Id]}
                </p>
                <div className='select-interes-container'>
                    <MultiSelectInteres
                        item={item}
                        placeholder={textFiltros.placeholder + textFiltros.labels[item.Id]}
                        diccionario={getDiccionarioIntereses(item.Id)}
                    />
                    {/* <MultiselectAuto
                        className="select-interes"
                        key={"interes-auto-" + item.Id}
                        name={"interes-auto-" + item.Id}
                        placeholder={"Selecciona tus intereses de " + item.Value}
                        options={options}
                    >
                    </MultiselectAuto> */}
                </div>
            </div>
            inputs_formulario_intereses.push(formulario)
            inputs_formulario_intereses_AUX.push(cont_encontrados)
        })
        inputs_formulario_intereses_html = <div className="mt-4">
            <Formik
                initialValues={actual_values["intereses"]}
                validate={validate}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                <Form className='filtros-industrias'>
                    {inputs_formulario_intereses.map((item: any) => {
                        return (item)
                    })}
                </Form>
            </Formik>
        </div>

    } else {
        inputs_formulario_intereses_html = <div>

        </div>
    }
    return (
        inputs_formulario_intereses_html
    )

}

export default FiltrosHome;