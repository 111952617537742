import React, { useEffect, useState } from 'react';

import moment from "moment"
import "../Meetings.scss"
import Label from '../../../components/Form/Label';
import Select from '../../../components/Form/Select';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';

const MeetingsDonarTiempoPaso2 = ({ actual_values, setActualValues }: any) => {
    const user_personal_data: any = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    const user_id = user_personal_data.Id
    const textMeetings = useSelector(selectCurrentDictonary).meetings
    const textGeneral = useSelector(selectCurrentDictonary).general
    /* Valores iniciales */
    let inicialValues = {
        startTime: actual_values.startDate,
        endTime: actual_values.endTime,
        timeZone: actual_values.timeZone // desde -12 a 12 
    }

    /* Validacion por parte del componente padre, se utiliza validate para actualizar los valores actuales del formulario */
    function validate(values: any) {
        if (actual_values.startTime != values.startTime) {
            values.endTime = ""
        }
        let duration = 0
        let bloques_reunion = []
        let bloques_reunion_dia = []
        if (values.startTime && values.endTime) {
            duration = get_duration(values.startTime, values.endTime)
            bloques_reunion = generar_bloques_reunion(values.startTime, values.endTime, duration)

            bloques_reunion_dia = generate_bloques_reunion_dia(actual_values.days, duration, values.timeZone, bloques_reunion)
        }
        setActualValues({ ...actual_values, ...values, duration: duration, bloques_reunion: bloques_reunion, bloques_reunion_dia: bloques_reunion_dia })
        let errors: any = {}
        return errors;
    }

    /* funcion submit no hace nada, el formulario es controlado por el padre */
    function handleSubmit(values: any) {
    }

    /* obtiene las opciones de tiempos */
    function opciones_hora(es_hora_termino: any) {
        let maxHour = 24;
        let minHour = 0;
        let arr = [];
        arr.push(<option value={""}>{""}</option>);
    
        let min_inicio = 0;
        let hora_inicio = 0;
        if (es_hora_termino === 1) {
            if (!actual_values.startTime) {
                return [];
            }
            hora_inicio = parseInt(actual_values.startTime.split(":")[0]);
            min_inicio = parseInt(actual_values.startTime.split(":")[1]);
            minHour = hora_inicio;
    
            // Si la hora de inicio es entre 20:30 y 23:00, la hora máxima de finalización es 23:30
            if (hora_inicio >= 20 && hora_inicio <= 23) {
                maxHour = 23;
            } else {
                maxHour = hora_inicio + 4;
            }
        } else {
            // Asegurarse de que la hora de inicio máxima sea 23:00
            maxHour = 23;
        }
    
        for (let hour = minHour; hour <= maxHour; hour++) {
            let hour_aux: any = hour;
            if (hour < 10) {
                hour_aux = "0" + hour;
            }
            if (!(es_hora_termino && hora_inicio === hour && min_inicio >= 0)) {
                arr.push(<option value={hour_aux + ":00"}>{hour_aux + ":00"}</option>);
            }
            if (!(es_hora_termino && hora_inicio === hour && min_inicio >= 30)) {
                arr.push(<option value={hour_aux + ":30"}>{hour_aux + ":30"}</option>);
            }
        }
    
        // Si la hora de inicio es entre 20:30 y 23:00, agregar la opción de 23:30 al final
        if (es_hora_termino === 1 && hora_inicio >= 20 && hora_inicio <= 23 && min_inicio > 0) {
            arr.push(<option value={"23:30"}>{"23:30"}</option>);
        }
    
        return arr;
    }
    
    


    /* obtiene la duracion a partir del tiempo de inicio y termino */
    function get_duration(startTime: any, endTime: any) {
        if (!endTime || !startTime || endTime == "" || startTime == "") {
            return ""
        }
        let duration: any = 0
        let restaHoras = parseInt(endTime.split(":")[0]) - parseInt(startTime.split(":")[0])
        let restaMinutos = parseInt(endTime.split(":")[1]) - parseInt(startTime.split(":")[1])
        if (restaMinutos < 0) {
            restaHoras = restaHoras - 1
            restaMinutos = restaMinutos + 60
        }
        duration = restaHoras * 60 + restaMinutos

        return duration
    }

    function generar_bloques_reunion(startTime: any, endTime: any, duration: any) {
        if (startTime && endTime && duration) {
            let arr: any = []
            let prev_time = startTime
            let actual_time = startTime
            let cant_reu = duration / 30
            for (let i = 0; i < cant_reu; i++) {
                let actual_hour: any = parseInt(prev_time.split(":")[0])
                let actual_min: any = parseInt(prev_time.split(":")[1])
                let actual_min_menos_descanso: any = actual_min + 20
                let actual_hour_menos_descanso = actual_hour
                actual_min = actual_min + 30
                if (actual_min >= 60) {
                    actual_hour = actual_hour + 1
                    actual_min = actual_min - 60
                }
                if (actual_min_menos_descanso >= 60) {
                    actual_hour_menos_descanso = actual_hour_menos_descanso + 1
                    actual_min_menos_descanso = actual_min_menos_descanso - 60
                }
                if (actual_hour < 10) {
                    actual_hour = "0" + actual_hour
                }
                if (actual_hour_menos_descanso < 10) {
                    actual_hour_menos_descanso = "0" + actual_hour_menos_descanso
                }
                if (actual_min_menos_descanso < 10) {
                    actual_min_menos_descanso = "0" + actual_min_menos_descanso
                }
                if (actual_min < 10) {
                    actual_min = "0" + actual_min
                }
                actual_time = actual_hour + ":" + actual_min
                let actual_time_menos_descanso = actual_hour_menos_descanso + ":" + actual_min_menos_descanso
                arr.push([prev_time, actual_time_menos_descanso])
                prev_time = actual_time
            }

            return arr
        } else {
            return []
        }
    }

    function generate_bloques_reunion_dia(days: any, duration: any, timeZone: any, bloques_reunion: any) {
        let arr: any = [];
        days?.map((dia: any) => {
            // Usamos moment para manejar la fecha
            let d = moment(dia, 'YYYY-MM-DD');
            let year = d.year();
            let month: any = d.month() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            let dayDate: any = d.date();
            if (dayDate < 10) {
                dayDate = "0" + dayDate;
            }
            let day: any = d.day();
            let day_aux = textGeneral.days[day];
            var date = dayDate + "-" + month + "-" + year;
    
            bloques_reunion?.map((bloque_reunion: any) => {
                let item = {
                    userId: user_id,
                    dia_semana: day_aux,
                    date: date,
                    startTime: bloque_reunion[0],
                    endTime: bloque_reunion[1],
                    minutesDuration: 20,
                    timeZoneOffset: timeZone,
                }
                arr.push(item);
            });
        });
        return arr;
    }
    

    let bloques_reunion_html = <></>
    if (actual_values.bloques_reunion) {
        bloques_reunion_html = <>
            {actual_values.bloques_reunion?.map((item: any) => {
                return (<div className='col-12'>
                    <h6 className='d-inline mr-2'>{item[0]} - {item[1]}</h6>
                </div>)
            })}
        </>
    }

    return (

        <Formik
            initialValues={inicialValues}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            <Form className='px-lg-3 px-xl-5'>
                <div className='row no-gutters mt-4 mb-2'>
                    <div className='col-12 col-lg-6 mb-4 pr-lg-2 pr-0'>
                        <Label classNameLabel='label-primary label' label={textMeetings.label_1} required={true}></Label>
                        <div className='d-flex justify-content-start'>
                            <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" name="startTime" label="">
                                {opciones_hora(0)}
                            </Select>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4 pl-lg-2 pl-0'>
                        <Label classNameLabel='label-primary label' label={textMeetings.label_2} required={true}></Label>
                        <div className='d-flex justify-content-start'>
                            <Select classNameLabel="label-secondary" className="mr-2 mr-lg-3" name="endTime" label="">
                                {opciones_hora(1)}
                            </Select>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4 pl-lg-2 pl-0'>
                        <Label classNameLabel='label-primary label mr-2' label={textMeetings.label_3} ></Label>
                        <p className='font-italic d-inline text-muted'>{textMeetings.desc_6}</p>
                        <div className='d-flex justify-content-start'>
                            <div>
                                <h6><b className='mr-2'>{actual_values.duration / 30}</b>{textMeetings.desc_7}</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4 pl-lg-2 pl-0'>
                        <Label classNameLabel='label-primary label ' label={textMeetings.label_4} ></Label>
                        <div className='row'>
                            {bloques_reunion_html}
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

export default MeetingsDonarTiempoPaso2;