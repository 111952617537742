import { useField } from 'formik'
import Label from './Label'

const Select = ({ label, classNameLabel="label-primary", required=false, ...props }:any) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <Label classNameLabel={classNameLabel} label={label} required={required}></Label>
      <select {...field} {...props} />
      {meta.touched && meta.error 
        ? <div className="error text-danger">{meta.error}</div> : null}
    </div>
  )
}

export default Select
