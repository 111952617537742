import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    position: [],
}

const browserdataSlice = createSlice({
    name: 'browserdata',
    initialState: initialState,
    reducers: {
        setPosition: (state, action) => {
            const { position } = action.payload
            state.position = position
        },
    },
})

export const { setPosition,  } = browserdataSlice.actions

export default browserdataSlice.reducer

export const selectPosition = (state:any) => state.browserdata.position