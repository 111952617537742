/* react */
import React, { useEffect, useState } from 'react'
/* funciones utiles formularios */
import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import Select from '../../../components/Form/Select'
import {
    get_opciones_dias,
    get_opciones_anios,
    get_opciones_meses,
    get_opciones_generos,
    get_opciones_countries,
    validatePhoneNumber,
} from '../../../utils/utilsForms';
/* interfaces */
import { personalData } from '../../../interfaces/dimentions';
/* redux */
import { useSelector, useDispatch } from 'react-redux';
import { selectCountries } from "../../../redux/features/dimensions/dimensionsSlice";
import { selectPersonalData, setPersonalData } from "../../../redux/features/user/userSlice";
import { useGetCountriesMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { setCountries } from '../../../redux/features/dimensions/dimensionsSlice'
import { usePutPersonalDataMutation } from '../../../redux/features/user/userApiSlice';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
/* otros */
import moment from "moment"
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { text } from 'stream/consumers';
import { textFieldClasses } from '@mui/material';



/* INICIO COMPONENTE ========================================================================================================= */
const UserPersonalData = () => {
    //const params = useParams()

    const user_personal_data = useSelector(selectPersonalData)
    const textPersonalData = useSelector(selectCurrentDictonary).personal_data
    const textCountry= useSelector(selectCurrentDictonary).Country
    const textSignup= useSelector(selectCurrentDictonary).signup
    const textGeneral= useSelector(selectCurrentDictonary).general.generos
    const textMeses= useSelector(selectCurrentDictonary).general.meses

    const dispatch = useDispatch()
    let initialState: personalData = { ...user_personal_data, MesNacimiento: parseInt(user_personal_data.MesNacimiento) }

    let anio_actual = moment().year();
    let max_edad = 99;
    let min_anio = anio_actual - max_edad;

    const [actualAnioMes, setActualAnioMes] = useState(min_anio + "-01")
    const [diasMes, setDiasMes] = useState(moment(actualAnioMes, "YYYY-MM").daysInMonth())

    const [actual_state, setActualSate] = useState(initialState)
    const [showSaveChanges, setShowSaveChanges] = useState(false)
    const [validated, setValidated] = useState(false)
    const [getCountries, { isLoadingCountries }]: any = useGetCountriesMutation()

    const [putPersonalData, { isLoadingPutPersonalData }]: any = usePutPersonalDataMutation()
    const countries = useSelector(selectCountries)

    // ================================================================================================================================================================ 
    // ==================================================================== VALIDACION ================================================================================
    // ================================================================================================================================================================ 

    const validate = (values: any) => {
        setValidated(false)
        const errors: any = {}

        /* Validacion Anio-mes */
        if (values.AnioNacimiento && values.MesNacimiento) {
            let anioMes = values.AnioNacimiento + "-" + values.MesNacimiento
            if (anioMes !== actualAnioMes) {
                setActualAnioMes(anioMes)
                setDiasMes(moment(anioMes, "YYYY-MM").daysInMonth())
            }
        }

        if (!values.Name) {
            errors.Name = textSignup.error_required;
        } else if (values.Name.length < 3) {
            errors.Name = textSignup.def_name_1;
        } else if (!/^[\p{L}\s]+$/u.test(values.Name)) {
            errors.Name = textSignup.titulo_11;
        }

        if (!values.LastName) {
            errors.LastName = textSignup.error_required
        } else if (values.LastName.length < 3) {
            errors.LastName = textSignup.def_name_2
        }

        
        if (values.Phone && validatePhoneNumber(values.Phone)) {
            errors.Phone = textSignup.error_phone
        }

        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
        } else {
            setValidated(false)
        }

        if (actual_state != values) {
            setShowSaveChanges(true)
            setActualSate(values)
        }

        return errors
    }


    async function handleSubmit(values: any, { setStatus, setErrors }: any) {
        dispatch(deleteAlert({ alert_id: "error_personal_data" }))
        dispatch(deleteAlert({ alert_id: "cargando_personal_data" }))
        let new_alert = { id: "cargando_personal_data", type: "loading", title: textSignup.alerta_cargando, desc: textSignup.alerta_personal_1, close: false }
        dispatch(addAlert({ alert: new_alert }))
        try {
            let fecha_nacimiento: any = ""
            let fecha_nacimiento_aux: any = ""
            if (!values.AnioNacimiento || !values.MesNacimiento || !values.DiaNacimiento) {
                fecha_nacimiento = null
            } else {
                let anio = values.AnioNacimiento
                let mes = values.MesNacimiento.length === 2 ? values.MesNacimiento : "0" + values.MesNacimiento
                let dia = values.DiaNacimiento.length === 2 ? values.DiaNacimiento : "0" + values.DiaNacimiento
                fecha_nacimiento = anio + "-" + mes + "-" + dia + "T00:00:00.000Z"

                fecha_nacimiento_aux = moment((anio + "-" + mes + "-" + dia), 'YYYY-MM-DD')
            }

            //let actualregion = values.RegionId ? values.RegionId : null;

            let phone_aux = values.Phone ? values.Phone : "" 
            let personal_data_gender = values.Genre ? values.Genre : "MASCULINO" 

            let values_personal_data = {
                "Name": values.Name,
                "LastName": values.LastName,
                "Email": values.Email,
                "Phone": phone_aux,
                "Genre": personal_data_gender,
                "DateOfBirth": fecha_nacimiento_aux,
                "CountryId": values.CountryId,
                "ActualCountryId": values.CountryId
            }
            const resPutPersonalData = await putPersonalData({ id: user_personal_data.Id, body: values_personal_data });
            const resPutPersonalData_status = resPutPersonalData.data.statusCode;
            const resPutPersonalData_data = JSON.parse(resPutPersonalData.data.responseData);
            if (resPutPersonalData_status === 200) {
                dispatch(deleteAlert({ alert_id: "cargando_personal_data" }))
                dispatch(addAlert({ alert: { type: "success", title: textSignup.alerta_personal_1, desc: "OK", timeout: 4000, close: true } }))

                dispatch(setPersonalData({ personal_data: { ...resPutPersonalData_data, Id: user_personal_data.Id } }))
            } else {
                dispatch(deleteAlert({ alert_id: "cargando_personal_data" }))
                dispatch(addAlert({ alert: { type: "error", title: textSignup.alerta_persona_2, desc: "", close: true } }))
            }
            setValidated(false)
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "cargando_personal_data" }))
            dispatch(addAlert({ alert: { type: "error", title: textSignup.alerta_persona_2, desc: "", close: true } }))
        }
    }



    useEffect(() => {
        if (!countries || countries.length == 0) {
            // Peticion inicial de paises
            const fetchCountries = async () => {
                const res: any = await getCountries();
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setCountries({ countries: resdata }))
            }
            fetchCountries().catch(console.error);
        }

    }, [])



    // ================================================================================================================================================================ 
    // ==================================================================== OPCIONES SELECTS ==========================================================================
    // ================================================================================================================================================================ 
    let opciones_generos = get_opciones_generos(textGeneral)
    let opciones_anios = get_opciones_anios(16, 100,textSignup)
    let opciones_meses = get_opciones_meses(textMeses)
    let opciones_dias = get_opciones_dias(actualAnioMes)

    let opciones_countries = get_opciones_countries(countries, isLoadingCountries, textCountry)
    let opciones_countries_html = opciones_countries.html
    let opciones_countries_disabled = opciones_countries.disabled

    // ================================================================================================================================================================ 
    // ==================================================================== FORMULARIO ================================================================================
    // ================================================================================================================================================================ 
    let formulario = <Formik
        initialValues={initialState}
        validate={validate}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
        render={({ handleSubmit, handleChange, handleBlur, values, errors, status }) => (
            <Form>

                <div className='row '>
                    <div className='col-12 col-lg-6 mb-3'>
                        <TextInput name="Name" placeholder={textSignup.placeholder_2} label={textPersonalData.titulo_2} />
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <TextInput name="LastName" placeholder={textSignup.placeholder_3} label={textPersonalData.titulo_3} />
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <TextInput disabled name="Email" placeholder={textSignup.placeholder_1} label={textPersonalData.titulo_4} />
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <TextInput name="Phone" placeholder="+12312345678" label={textPersonalData.titulo_5} />
                    </div>

                    <div className='col-12 col-lg-6 mb-3'>
                        <label htmlFor="" className='label label-primary'>{textPersonalData.titulo_6}</label>
                        <div className='d-flex justify-content-start'>
                            <Select className="mr-2" classNameLabel="label-secondary" name="AnioNacimiento" label="" >
                                {opciones_anios}
                            </Select>
                            <Select className="mr-2" classNameLabel="label-secondary" name="MesNacimiento" label="" >
                                {opciones_meses}
                            </Select>
                            <Select className="mr-2" classNameLabel="label-secondary" name="DiaNacimiento" label="" >
                                {opciones_dias}
                            </Select>

                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Select className="w-100" name="Genre" label={textPersonalData.titulo_7} >
                            {opciones_generos}
                        </Select>
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <Select className="w-100" disabled={opciones_countries_disabled} name="CountryId" label={textPersonalData.titulo_8} >
                            {opciones_countries_html}
                        </Select>
                    </div>
                    <div className={'col-12 mt-4 ' + ((validated && showSaveChanges) ? "" : "d-none")}>
                        <div className='d-flex justify-content-end'>
                            <button type="submit" className='btn btn-primary btn-rounded'>
                                {/* Guardar */}
                                {isLoadingPutPersonalData ? textSignup.alerta_cargando : textSignup.btn_5}
                            </button>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <div className='d-flex justify-content-end'>
                            <p>{status}</p>
                        </div>
                    </div>
                </div>

            </Form>
        )}
    >
    </Formik>

    // ================================================================================================================================================================ 
    // ==================================================================== RETURN ====================================================================================
    // ================================================================================================================================================================ 
    return (
        <div className='px-lg-4'>

            <h3 className='font-italic'><b>{textPersonalData.titulo_1}</b></h3>
            <p className='parrafo1'>{textPersonalData.desc_1}</p>
            <div className='pb-3'>
                <hr />
            </div>
            {formulario}
        </div>
    )
}

export default UserPersonalData;


