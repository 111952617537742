import { createSlice } from "@reduxjs/toolkit"

import moment from 'moment';


interface startup {
    documentValue: string
    documentTypeId: number
    title: string
    businessName: string
    description: string
    shortDescription: string
    formalizationDegreeId: number
    investmentSourceLevelId: number
    countryId: number
    regionId: number
    cityId: number
    comunaId: number
    industryId: number
    odsOnuId: number
    starValueId: number
    employeesNumber: number
    genderEqualityPolicies: number // falta
    diversityInclusionPolicies: number // falta
    webPage: string
    facebookUrl: string
    instagramUrl: string
    activitiesInit: string // datetime "2023-03-21T06:10:22.445Z"
    publicInvestment: string
    privateInvestment: string
    ledByWomen: number
    startupPhoto: string
    startupVideo: string
}


const initialState = {
    startups_list: [],
}

const startupsSlice = createSlice({
    name: 'startups',
    initialState: initialState,
    reducers: {
        setStartupsList: (state, action) => {
            const { startups_list } = action.payload
            state.startups_list = startups_list.reverse()
        },
    },
})

export const {
    setStartupsList,
} = startupsSlice.actions

export default startupsSlice.reducer


//recordar que se debe agregar el reducer en el store.ts

export const selectStartupsList = (state: any) => state.startups.startups_list
