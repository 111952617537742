import React from 'react';
import { IconButton, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import './ContactToggle.scss';
interface ContactToggleProps {
    maintainContact: boolean;
    onToggle: (value: boolean) => void;
    counterpartName?: string;
}


const ContactToggle: React.FC<ContactToggleProps> = ({ maintainContact, onToggle, counterpartName }) => {
    return (
        <div className="contact-toggle">
            <Typography variant="subtitle1">
                ¿Te gustaría mantener el contacto con {counterpartName || "la contraparte"}?
            </Typography>
            <div className="toggle-buttons">
                <IconButton
                    onClick={() => onToggle(true)}
                    color={maintainContact ? 'primary' : 'default'}
                    aria-label="Mantener contacto"
                    aria-pressed={maintainContact}
                    className={`MuiIconButton-root ${maintainContact ? 'primary' : 'default'}`}
                >
                    <ThumbUpIcon />
                </IconButton>
                <IconButton
                    onClick={() => onToggle(false)}
                    color={!maintainContact ? 'primary' : 'default'}
                    aria-label="No mantener contacto"
                    aria-pressed={!maintainContact}
                    className={`MuiIconButton-root ${!maintainContact ? 'primary' : 'default'}`}
                >
                    <ThumbDownIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default ContactToggle;
