import React, { useState, useEffect, useRef } from 'react';
import jsQR from 'jsqr';
import './QrReaderComponent.scss';

const QrReaderComponent: React.FC = () => {
    const [qrData, setQrData] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const animationRef = useRef<number | null>(null);
    const lastScannedRef = useRef<string | null>(null); // Ref para el último QR escaneado

    useEffect(() => {
        startVideo();

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            if (videoRef.current && videoRef.current.srcObject) {
                (videoRef.current.srcObject as MediaStream).getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const startVideo = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
            requestAnimationFrame(scanQRCode);
        } catch (error) {
            console.error("Error accediendo a la cámara: ", error);
        }
    };

    const scanQRCode = () => {
        if (canvasRef.current && videoRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code && code.data !== qrData && code.data !== lastScannedRef.current) {
                    setQrData(code.data);
                    lastScannedRef.current = code.data; // Guardamos el QR en el ref
                    if (code.data.startsWith("http")) {
                        openLink(code.data);
                    }
                }
            }
        }
        animationRef.current = requestAnimationFrame(scanQRCode);
    };

    const openLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleReset = () => {
        setQrData(null);
        lastScannedRef.current = null; // Reiniciamos el ref del último QR escaneado
        startVideo(); // Reiniciar el escáner
    };

    return (
        <div className="qr-reader-container">
            <div className="qr-scanner-wrapper">
                <video ref={videoRef} className="qr-video" style={{ display: 'none' }}></video>
                <canvas ref={canvasRef} width={300} height={300}></canvas>
            </div>
            <div className="qr-result">
                <p>📷 Escaneo en progreso...</p>
                {qrData ? (
                    qrData.startsWith("http") ? (
                        <>
                            <p>Enlace detectado:</p>
                            <button onClick={() => openLink(qrData)} className="qr-link-button">Abrir enlace</button>
                        </>
                    ) : (
                        <p>Código QR detectado: {qrData}</p>
                    )
                ) : (
                    <p>Apunte la cámara a un código QR.</p>
                )}
            </div>
            {qrData && (
                <button onClick={handleReset} className="qr-reset-button">Escanear otro QR</button>
            )}
            <div className="qr-warning">
                <p>🔔 Habilite ventanas emergentes en su navegador para abrir enlaces automáticamente.</p>
            </div>
        </div>
    );
};

export default QrReaderComponent;
