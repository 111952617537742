import './SettingsLayout.scss'
import { Outlet } from "react-router-dom"
import { useState } from 'react'
import { useRef } from 'react'

import BottomNav from '../../Navegation/BottomNav/BottomNav'
import SettingsLayoutSidebar from './SettingsLayoutSidebar'


function SettingsLayout() {

	const [sidebaropen, setSidebaropen] = useState(true)

	const ref_settings_body: any = useRef();


	const toggleSidebar = () => {
		setSidebaropen(!sidebaropen)
	}


	return (
		<>

			<BottomNav isOpen={sidebaropen}></BottomNav>

			<div className={'body-container'} ref={ref_settings_body}>
				<div className='settings-body-container-inner'>
					<div className='body-settings padding-layout-x pl-lg-4'>
						<Outlet />
					</div>
				</div>
			</div>
			<div className='settings-sidebar'>
				<SettingsLayoutSidebar></SettingsLayoutSidebar>
			</div>
		</>
	)
}
export default SettingsLayout;
