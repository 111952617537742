
import React, { useState } from 'react'
//import "../../Landing/Landing.scss"
import "./Roles.scss";
import Card from "../../../components/Card/Card";
// API UTILIZADAS EN ESTA VISTA
import { useSelector, useDispatch } from 'react-redux';
import { useGetUserRolesMutation, usePutUserRolesMutation } from "../../../redux/features/user/userApiSlice";
import { useEffect } from "react";
import { selectRoles, setRoles } from "../../../redux/features/user/userSlice";
import { selectPersonalData } from "../../../redux/features/user/userSlice";
import { personalData } from "../../../interfaces/dimentions";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Switch } from '@mui/material';

const Roles = () => {

    const textMyRoles = useSelector(selectCurrentDictonary).my_roles


    const dispatch = useDispatch()
    const [getRoles, { isLoadingGetRoles }]: any = useGetUserRolesMutation()
    const roles_list = useSelector(selectRoles)
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    const [putRoles, { isLoadingPutRoles }]: any = usePutUserRolesMutation()
    const user_id = user_personal_data ? user_personal_data.Id : 0
    const [isLoading, setIsLoading] = useState(false);
    const [mobileversion, setMobileVersion] = useState(false);

    useEffect(() => {
        if (!roles_list || roles_list.length == 0) {
            // Peticion inicial de paises
            const fetchRoles = async () => {
                setIsLoading(true)
                const res: any = await getRoles({ user_id: user_personal_data.Id });
                const resdata = JSON.parse(res.data.responseData)
                dispatch(setRoles({ roles: resdata }))
                setIsLoading(false)
            }
            fetchRoles().catch(console.error);
        }
    }, [])
 

    function ToggleRole(UserId: any, RolId: any, ActiveRole: any) {
        //dispara la API del PUT/POST
        let out_payload = {
            "UserId": UserId,
            "RoleId": RolId,
            "Active": ((ActiveRole == 1) ? 0 : 1)
        }

        const attemptSaving = async () => {
            const res: any = await putRoles({ body: out_payload });
            const resdata = JSON.parse(res.data.responseData)
            dispatch(setRoles({ roles: resdata }))
        }
        attemptSaving().catch(console.error);
    }

    function CardRol(UserId: any, RolId: any, ImageUrl: any, ActiveRole: any, Name: any, Description: any, Description2: any) {
        let url = ImageUrl
        let activo_html = <p className="text-danger"><i className="fas fa-xmark mr-1"></i> {textMyRoles.subtitle_1}</p>
        let botones_html = <button onClick={() => ToggleRole(UserId, RolId, ActiveRole)} className="btn btn-rounded btn-block btn-success">
            <div className='d-flex justify-content-center align-items-center'>
                <CheckOutlinedIcon className='mr-2' />
                <p>{textMyRoles.btn_activar}</p>
            </div>
        </button>
        if (ActiveRole) {
            activo_html = <p className="text-success"><i className="fas fa-check mr-1"></i> {textMyRoles.subtitle_2}</p>
            botones_html = <button onClick={() => ToggleRole(UserId, RolId, ActiveRole)} className="btn btn-rounded btn-block btn-outline-danger">
                <div className='d-flex justify-content-center align-items-center'>
                    <CloseOutlinedIcon className='mr-2' />
                    <p>{textMyRoles.btn_desactivar}</p>
                </div>
            </button>
        }
        return <div className={"col-12 col-md-6 col-lg-12 col-xl-6 mb-4"}>
            <Card className={"h-100 d-flex flex-column justify-content-between align-items-center card-rol " + (ActiveRole ? "rol-activo" : "")}>
                <img alt='' src={url} className="img-rol" />
                <h4 className="mb-1 text-primary">{Name}</h4>
                <div className="mb-2 mb-md-0 mb-lg-2 mb-xl-0" >
                    {activo_html}
                </div>
                <div className="mb-3 mb-md-0 mb-lg-4 mb-xl-0 card-rol-desc" >
                    <p className="text-center font-italic mb-2">{Description}</p>
                    <p className="text-center ">{Description2}</p>
                </div>
                <div className="" >
                    {botones_html}
                </div>
            </Card>
        </div>
    }
    function TarjetaRolEmprendedor(UserId: any, RolId: any, ImageUrl: any, ActiveRole: any, Name: any, Description: any){
        
        return <div className={"col-12 col-md-6 col-lg-12 col-xl-6"}>
        <div className="card-contenedor">
            <div className="card-new card-emprendedor2">
                <div className="card-content">
                    <h2 className="card-title">{Name}</h2>
                    <p className="card-text">{Description}</p>
                    {!mobileversion && ( // Renderizar solo si no es versionmobil
                    <div className="col-12" >
                        <Switch
                            checked={ActiveRole}
                            onChange={() => ToggleRole(UserId, RolId, ActiveRole)}
                            name="filtroSwitch"
                            sx={{
                                '& .MuiSwitch-switchBase': {
                                  color: 'lightgray', 
                                  '&.Mui-checked': {
                                    color: '#36C557',
                                  },
                                  '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#36C557',
                                  },
                                },
                              }}
                        />
                        <label>
                            {ActiveRole? "Rol activo" : "Rol inactivo"}
                        </label>
                    </div>
                    )}
                </div>
            </div>
        </div>
        </div>
    }
    function TarjetaRolExperto(UserId: any, RolId: any, ImageUrl: any, ActiveRole: any, Name: any, Description: any){
        
        return <div className={"col-12 col-md-6 col-lg-12 col-xl-6 mb-4"}>
        <div className="card-contenedor">
            <div className="card-new card-experto">
                <div className="card-content">
                    <h2 className="card-title">{Name}</h2>
                    <p className="card-text">{Description}</p>
                    {!mobileversion && ( // Renderizar solo si no es versionmobil
                    <div className="col-12" >
                        <Switch
                            checked={ActiveRole}
                            onChange={() => ToggleRole(UserId, RolId, ActiveRole)}
                            name="filtroSwitch"
                            color="primary"
                            sx={{
                                '& .MuiSwitch-switchBase': {
                                  color: 'lightgray', 
                                  '&.Mui-checked': {
                                    color: '#36C557',
                                  },
                                  '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#36C557',
                                  },
                                },
                              }}
                        />
                        <label>
                            {ActiveRole? "Rol activo" : "Rol inactivo"}
                        </label>
                    </div>
                    )}
                </div>
            </div>
        </div>
        </div>
    }
    
  

    let emprendedor_activo = 0
    let experto_activo = 0
    roles_list?.forEach((element: any) => {
        if (element.RolId == 1 && element.Active == 1) {
            emprendedor_activo = 1
        }
        if (element.RolId == 6 && element.Active == 1) {
            experto_activo = 1

        }
    });


    let html_roles = <></>
    if (isLoading) {
        html_roles = <div className="mt-4 d-flex justify-content-center align-items-center">
            <CircularProgress className="settings-loading-icon mr-2" size="1.5rem" />
            <p>{textMyRoles.titulo_4}</p>
        </div>
    } else {
        html_roles = <div className="row gutters">
            
            {
                TarjetaRolEmprendedor(user_id,
                    1,
                    "../../Landing/emprendedor.jpg",
                    emprendedor_activo,
                    textMyRoles.titulo_2,
                    textMyRoles.desc_2,
                    )                
            }
            {
                TarjetaRolExperto(user_id,
                    6,
                    "../../Landing/emprendedor.jpg",
                    experto_activo,
                    textMyRoles.titulo_3,
                    textMyRoles.desc_3,
                    )                
            }
        </div>
    }
    let html_tarjeta= <div className="row gutters">
            {
                TarjetaRolEmprendedor(user_id,
                    1,
                    "../../Landing/emprendedor.jpg",
                    emprendedor_activo,
                    textMyRoles.titulo_2,
                    textMyRoles.desc_2,
                    )                
            }
            {
                TarjetaRolExperto(user_id,
                    6,
                    "../../Landing/emprendedor.jpg",
                    experto_activo,
                    textMyRoles.titulo_3,
                    textMyRoles.desc_3,
                    )                
            }
            
            
    </div>

    return (
        <div className="px-lg-4">
            <h3 className='font-italic'><b>{textMyRoles.titulo_1}</b></h3>
            <p className='parrafo1'>{textMyRoles.desc_1}</p>
            <div className='pb-3'>
                <hr />
            </div>
            {html_roles}
        </div>
    )
}
export default Roles;