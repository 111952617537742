import './UserButton.scss'
import { useState } from 'react'
import NavbarItem from '../NavbarItem';
import SettingsLayoutSidebar from '../../../Layout/SettingsLayout/SettingsLayoutSidebar';
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import List from '@mui/material/List';

import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../../redux/features/language/LanguageSlice';

function UserButton({ user_public_data, user_personal_data }: any) {

	let foto_perfil = "/media/iconos/icono-persona.svg"
	if (user_public_data && user_public_data.ProfilePicture) {
		foto_perfil = user_public_data.ProfilePicture
	}

	const textNavbar = useSelector(selectCurrentDictonary).navbar1


	const [state, setState] = useState(false);
	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event && event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setState(open);
	};

	const list = <Box className="ancho" role="presentation" onKeyDown={toggleDrawer(false)} >
		<List onClick={toggleDrawer(false)}>
			<SettingsLayoutSidebar handleClickOption={toggleDrawer(false)}></SettingsLayoutSidebar>
		</List>
	</Box>


	return (
		<div>
			<React.Fragment key={"right"}>
				{/* <Button onClick={toggleDrawer(true)}>{"right"}</Button> */}
				<NavbarItem conFoto={true} foto={foto_perfil} handleOnClick={toggleDrawer(true)} label={textNavbar.menu6} labelCaret={true} image=""></NavbarItem>
				<SwipeableDrawer
					anchor={"right"}
					open={state}
					onClose={toggleDrawer(false)}
					onOpen={toggleDrawer(true)}
				>
					{list}
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	)
}

export default UserButton;

