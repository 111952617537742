import { Link, useNavigate } from 'react-router-dom';
import './TarjetaExperto.scss'
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { Dialog, DialogContent, DialogTitle, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useGetUserDonatedTimeMutation } from '../../redux/features/meetings/meetingsApiSlice';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Button, Rating} from '@mui/material';
import { DialogTiempoReunion } from '../../pages/UserProfileExternal/DialogTiempoReunion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { capitalizeFirstLetter, getCharLimit } from '../../utils/utilsText';

function TarjetaExperto({ 
	user_evaluation_stars, 
	CompanySponsor, 
	handleClickOpenDialogPublicProfile, 
	Name, 
	LastName, 
	Description, 
	ProfilePicture, 
	CompanyName, 
	CompanyJob, 
	CompanyPicture, 
	UserId, 
	CompanyPosition, 
	PercentageSim, 
	Industria,
	Comentarios,
	Industria_id,
	contact_section }: any){

	const navigate = useNavigate()
	const textHome = useSelector(selectCurrentDictonary).home
	const textIndustries = useSelector(selectCurrentDictonary).industries
	const [isSaved, setIsSaved] = useState(false)
	let image_url = (ProfilePicture) ? ProfilePicture : "/media/iconos/icono-persona.svg"
	let company_img = (CompanyPicture) ? CompanyPicture : "/media/iconos/icono-empresa.svg"
	let nombre_corto = Name.split(" ")[0] + " " + LastName.split(" ")[0]
	// nuevo
	const [isLoadingButton, setIsLoadingButton] = useState(true)
	const [valueDonatedTime, setValueDonatedTime] = useState<any>(false)
	const [initialChargeLoadingButton, setInitialChargeLoadingButton] = useState(true)
	const [get]: any = useGetUserDonatedTimeMutation()
	const [opiniones_open, set_opiniones_open] = useState(false)
	const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

	const fetchDonatedTime = async () => {
		setIsLoadingButton(true)
		const res: any = await get({ user_id: UserId, type:  0});
		const resData = JSON.parse(res.data.responseData)
		setValueDonatedTime(resData)
		setIsLoadingButton(false)
		setInitialChargeLoadingButton(false)
	}

	useEffect(() => {
			
		if (initialChargeLoadingButton) {
			// console.log("FETCHING EFFECT")
			fetchDonatedTime()
		}
	}, [valueDonatedTime]); 
	// fin nuevo

	function handleOnClickUserCard() {
		handleClickOpenDialogPublicProfile()
		//navigate("../user-profile/"+UserId)
	}

	let company_sponsor_html = <></>
	if (CompanySponsor == 1) {
		company_sponsor_html = <p className='name-company-sponsor-usuario text-center'>sponsor</p>
	}
	let comentarios=[]
	if(Comentarios){
		comentarios = JSON.parse(Comentarios);
	}
	const handleOpiniones = () => {
		set_opiniones_open(true);
	  };
	
	const handleClose = () => {
		set_opiniones_open(false);
	  };
	

	let dialog_opiniones_html=<Dialog
			  fullWidth
			  maxWidth="xs"
			  open={opiniones_open}
			  onClose={handleClose}
		  >
			  <DialogTitle >
			  {textHome.dialog_opiniones}
			  </DialogTitle>
			  <DialogContent>
				<ul>
				{comentarios.map((comentario: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined)=>(
					<li>{comentario}</li>
				))}
				</ul>
	  			
			  </DialogContent>
		  </Dialog>
		
	let opiniones_html = <> <div className='ver-opiniones'>{textPublicProfile.sin_opiniones}</div></>;
	
	if(comentarios.length===1){
		opiniones_html= <> <div className='ver-opiniones'  onClick={handleOpiniones}>{textHome.unopinion}</div></>;
	}
	else {
		if(comentarios.length>1){
			opiniones_html= <> <div className='ver-opiniones'  onClick={handleOpiniones}>{comentarios.length} {textHome.opiniones}</div></>;
		}
	}
	
	let desc_html = textPublicProfile.sin_descripcion
	if (Description) {
		desc_html = getCharLimit(Description + "", 100)
	}
	let industria = textHome.sin_industria
	if(Industria){
		industria = getCharLimit(textIndustries[Industria_id] + "", 25)
	}
    let evaluacion_html = <></>
	if (user_evaluation_stars) {
		evaluacion_html = <div className='d-flex justify-content-center align-items-center mb-3 mb-xl-0 '>
			<Rating name="read-only" size="medium" value={user_evaluation_stars} readOnly />
		</div>
	} else {
		evaluacion_html = <div className='d-flex justify-content-center align-items-center mb-3 mb-xl-0 '>
		<Rating name="read-only" size="medium" value={0} readOnly />
	</div>

	}
	
	
    const [sol_reu_open, set_sol_reu_open] = useState(false)
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open)
    }
	let btn_solicitar_reunion_html = <></>

	if (!isLoadingButton) {
		 
		if (valueDonatedTime && valueDonatedTime.length > 0) {
            btn_solicitar_reunion_html = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn-agenda' onClick={handleClickDialog}>{textHome.agenda} <ArrowForwardIcon></ArrowForwardIcon></button>
			</>
        } else {
            btn_solicitar_reunion_html  = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn-agenda' onClick={handleClickDialog}>{textHome.agenda}<ArrowForwardIcon></ArrowForwardIcon></button>
			</>
        }
	
	} else {
		btn_solicitar_reunion_html = <>{textHome.cargando}</>
	}
	const handleClickMostrarPerfil = () => {
		handleClickOpenDialogPublicProfile();
	  };
	
	return (
		<div className="tarjeta-container">
			<div className="col-12 tarjeta-experto">				
				<div className='tarjeta-content'>
					<div className="tarjeta-imagen mt-2">
						<div className="img-container">
							<img src={image_url} alt="imagen" className="imagen-user"
							onClick={handleClickMostrarPerfil}>
							</img>  
						</div>						
					<Tooltip arrow title={textHome.match}>
						<p className='porcentaje'>{Math.floor(PercentageSim)}%</p>
					</Tooltip>
					
					</div>
					<div className="etiqueta-container m-1">
						<p className='etiqueta-texto'>{industria}</p>
					</div>
					<strong className='tarjeta-title m-1' onClick={handleClickMostrarPerfil}>
						{getCharLimit(capitalizeFirstLetter(nombre_corto) + "", 60)}
					</strong>
					<p className={"tarjeta-descripcion"} 
					style={{ border: '', minHeight: '80px', width:'100%'}}>
						{desc_html} 
						
					</p>				
					{evaluacion_html}
					{opiniones_html}
					{dialog_opiniones_html}
				</div>
				<div className='tarjeta-btn'>
					{btn_solicitar_reunion_html}
				</div>
			</div>
		</div>
		
	)
}
export default TarjetaExperto