
import { useField } from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import "./MultiselectAuto.scss"
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";


const MultiselectAuto = ({ options, placeholder = "", label = "label", ...props }: any) => {
    const [field, meta, helpers] = useField({ ...props })
    const TextLoading = useSelector(selectCurrentDictonary).general.loading
    let placeholder_aux = placeholder;
    if (options.length == 0) {
        placeholder_aux = { TextLoading } + "..."
    }
    const { value } = meta;
    if (value && value.length > 0) {
        placeholder_aux = ""
    }

    const { setValue } = helpers;

    const handleChange = (event: any, new_value: any, reason: any, details: any) => {
        setValue(new_value)
    }

    return (

        <div>
            <Autocomplete
                multiple
                options={options}
                className="w-100"
                getOptionLabel={(option: any) => option.title}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        label={""}
                        placeholder={placeholder_aux}
                    />
                )}
                popupIcon={<ArrowCircleDownOutlinedIcon color="secondary" />}

                clearIcon={<HighlightOffOutlinedIcon color="secondary" />}
                forcePopupIcon={false}
                onChange={handleChange}
                value={value || []}
            />
            {meta.touched && meta.error ?
                <div>{meta.error}</div> : null}
        </div>
    )

}


export default MultiselectAuto;