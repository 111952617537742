
import { Formik, Form } from 'formik'
import TextInput from '../../../components/Form/TextInput'
import Select from '../../../components/Form/Select'
import Label from '../../../components/Form/Label';
import Radio from '../../../components/Form/Radio';
import { useEffect, useState } from 'react';
import { useSignupMutation } from '../../../redux/features/auth/authApiSlice';

import { useSelector } from 'react-redux';
import { usePutPersonalDataMutation } from '../../../redux/features/user/userApiSlice';

import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';

import {
    get_arr_meses,
    get_arr_anios,
    get_arr_dias,
    get_opciones_dias,
    get_opciones_anios,
    get_opciones_meses,
    get_opciones_generos,
    get_opciones_countries,
    get_opciones_companies,
    get_opciones_industries,
    get_opciones_jobtitles,
    validatePassword,
    validateEmail
} from '../../../utils/utilsForms';

import { selectInterests } from '../../../redux/features/dimensions/dimensionsSlice';
import SignUpStepper from './SignUpStepper';
import FormularioExperiencia from '../../../components/formularios/FormularioExperiencia';
import FormularioEmprendimiento from '../../../components/formularios/FormularioEmprendimiento';
import GetCompanies from '../../../hooks/dimensions/GetCompanies/GetCompanies';
import GetJobTitles from '../../../hooks/dimensions/GetJobTitles/GetJobTitles';
import GetIndustries from '../../../hooks/dimensions/GetIndustries/GetIndustries';



const SignUpPageTwo = (
    {
        ChangeFormState, 
        actual_values,
        actual_photo_create,
        setActualPhotoCreate
    }: any) => {

    let companies = GetCompanies()
    let jobtitles = GetJobTitles()
    let industries = GetIndustries()

    const textSignup= useSelector(selectCurrentDictonary).signup

    const interests = useSelector(selectInterests)
    const [signUp, { isLoadingSignUp }]: any = useSignupMutation()
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    const [errorsDict, setErrorsDict] = useState<any>({})

    const [userRoles, setUserRoles] = useState(actual_values.userRoles) // 0: no seleccionado, 1: emprendedor, 2: experto, 3: ambos

    const [actual_values_create, setActualValuesCreate] = useState<any>({ ...actual_values });


    const validate = (values: any) => {
        const errors: any = {}
        ChangeFormState({ ...actual_values, ...values, userRoles: actual_values.userRoles }, 2)

        if (!userRoles) {
            errors.userRoles = textSignup.error_p3_rol
        }



        if (!errors || Object.keys(errors).length === 0) {
            setValidated(true)
            setErrorsDict({})
        } else {
            setErrorsDict(errors)
            setValidated(false)
        }
        return errors
    }

    const Volver = (e: any) => {
        e.preventDefault();
        ChangeFormState("", 1)
    }

    async function handleSubmit(values: any, { setStatus, setErrors }: any) {
        ChangeFormState({ ...actual_values, userRoles: userRoles }, 3)
    }


    function handleSetUserRoles(rol: number) {
        setUserRoles(rol)
        setValidated(true)
        ChangeFormState({ ...actual_values, userRoles: rol }, 2)
    }


    useEffect(() => {


    }, [actual_values])

    // ================================================================================================================================================================ 
    // ==================================================================== OPCIONES SELECTS ==========================================================================
    // ================================================================================================================================================================ 


    function rolbutton(rol_id:any, rol:any, desc1:any, desc2:any, rol_activo:any){
        let desc1_html = desc1? <p className='text-center mb-2'><b>{desc1}</b></p> : <p></p>
        let desc2_html = desc2? <p className='text-center mb-2'><b>{desc2}</b></p> : <p></p>
        let html_div = <div className={'cursor-pointer prevent-select btn-rol-signup d-flex flex-column h-100 bg-secondary text-white p-3 rounded mr-0 mr-lg-2 mr-xl-2 ' + (rol_activo == rol_id ? "rol-activo" : "")} onClick={() => handleSetUserRoles(rol_id)} >
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h4 className='text-center mb-1'><b>{rol}</b></h4>
                {desc1_html}
                {desc2_html}
            </div>
        </div>
        return html_div
    }
    

    return (
        <Formik
            initialValues={actual_values}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            <Form className='px-lg-3 px-xl-5'>
                <div className='mb-3 mb-lg-4 mt-3 mt-lg-4'>
                    <h3 className='text-center mb-3'>{textSignup.titulo_1}<div className='text-primary d-inline mb-0'>meet</div></h3>
                    <div className='d-flex justify-content-center align-items-start mb-4'>

                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <SignUpStepper step={actual_values.step - 1}></SignUpStepper>
                        </div>
                    </div>
                </div>
                <input type="text" className="d-none" value={userRoles} />
                <div className='row justify-content-center align-items-start no-gutters'>
                            <div className='col-12 col-lg-6 mb-2 px-0 px-lg-1 px-xl-2 h-100'>
                            {rolbutton(
                                1,
                                textSignup.titulo_10, //Emprendedor
                                    textSignup.msj_1,
                                    textSignup.msj_2,
                                userRoles
                            )}
                            </div>

                            <div className='col-12 col-lg-6 mb-2 px-0 px-lg-1 px-xl-2  h-100'>
                                {rolbutton(
                                    2,
                                    textSignup.titulo_10_1, //Experto
                                    textSignup.msj_3,
                                    textSignup.msj_4,
                                    userRoles
                                )}
                            </div>
                            <div className='col-12  mb-2 px-0 px-lg-1 px-xl-2  h-100'>
                                {rolbutton(
                                    3,
                                    textSignup.titulo_10_2, //Ambos
                                    "",
                                    "",
                                    userRoles
                                )}
                            </div>
                        </div>

                <div className='row mt-4'>
                    <div className='col-12 '>
                        <div className='d-flex justify-content-between'>
                            <button /* disabled={loading || !validated} */ onClick={Volver} className='btn btn-rounded btn-outline-primary'>
                                {textSignup.btn_3}
                            </button>
                            <button disabled={loading || !validated} type="submit" className='btn btn-rounded btn-primary'>
                                {textSignup.btn_1}
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )

}

export default SignUpPageTwo;