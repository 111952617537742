import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useGetJobTitlesMutation } from '../../../redux/features/dimensions/dimensionsApiSlice';
import { selectJobTitles, setJobTitles } from '../../../redux/features/dimensions/dimensionsSlice';


export default function GetJobTitles() {

    const dispatch = useDispatch()
    const value = useSelector(selectJobTitles)
    const [get, { isLoadingGet }]: any = useGetJobTitlesMutation()
    

    const fetch = async () => {
        const res: any = await get();
        const resData = JSON.parse(res.data.responseData)
        dispatch(setJobTitles({job_titles: resData }))
    }

    useEffect(() => {
        if (!value || value.length == 0) {
            fetch()
        }
        /*  */
    }, [value]);

    return value;
}
