import './MsjButton.scss'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NavbarItem from '../NavbarItem';
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';

import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import NotificationItem from '../../../Notifications/NotificationItem';
import { useGetUserNotificationsMutation } from '../../../../redux/features/user/userApiSlice';
import { useSelector } from 'react-redux';
import { selectPersonalData, selectUserNotifications, setUserNotifications } from '../../../../redux/features/user/userSlice';
import { selectCurrentDictonary } from '../../../../redux/features/language/LanguageSlice';

function MsjButton({ user_personal_data }: any) {
	 
	const dispatch = useDispatch()
	const textNavbar = useSelector(selectCurrentDictonary).navbar1
	const textNotification = useSelector(selectCurrentDictonary).notifications

	const user_personal_data2: any = useSelector(selectPersonalData) //acceder a datos del usuario logueado
	let user_id = user_personal_data2.Id

	const notificaciones: any = useSelector(selectUserNotifications) //acceder a datos del usuario logueado antes era const

	const [getUserNotifications]: any = useGetUserNotificationsMutation()

	const [notifications, setNotifications] = useState([]);
	const [counter, setCounter] = useState(0);

	useEffect(() => {
		handleReload()
		const interval = setInterval(() => {
			handleReload()
        }, 1000*60*5); //por milisegundos

        return () => {
            clearInterval(interval);
        };
	}, [user_personal_data])

	async function handleReload() {
		const res: any = await getUserNotifications({ user_id: user_id });
		const resData = JSON.parse(res.data.responseData)
	 	
	 
		const visibleNotifications = resData.filter((notification: any) => notification.Visible !== 0);
	 
		dispatch(setUserNotifications({ user_notifications: visibleNotifications.reverse() }))
		setNotifications(visibleNotifications);
		
	 
		const unreadNotifications = visibleNotifications.filter((notification: any) => !notification.ReadedByUser)
		setCounter(unreadNotifications.length);
	}
	
	const [state, setState] = useState(false);

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event && event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setState(open);
		setCounter(0);
	};

	let notificaciones_html = <div className='notificaciones-container  d-flex flex-column align-items-center justify-content-center'>
		<div className='d-flex flex-column align-items-center justify-content-center'>
			<DraftsOutlinedIcon />
			<p>No tienes notificaciones</p>
		</div>
	</div>
	 
	if (true && notificaciones && notificaciones.length > 0) {
		notificaciones_html = <>
			<div className='notificaciones-container d-flex flex-column align-items-center justify-content-start'>
				{notificaciones.map((notificacion: any) => {
					  if (notificacion.Visible == 0) {
						return null; // No renderizará este elemento
					  }

					let Id = notificacion.Id;
					let NotificationTypeId = notificacion.NotificationTypeId;
					let ReadedByUser = notificacion.ReadedByUser;
					let UserRequested = notificacion.UserRequestedName + " " + notificacion.UserRequestedLastName;
					let ProfilePictureUserRequested = notificacion.UserRequestedProfilePicture;
					let NotificationTime = notificacion.CreatedAt;
					let timeZoneOffset = notificacion.TimeZoneOffset;
					let AppMessageName = "";
					let classNameAvatar = "";
					let NameUserRequest = "";
					let redirect = "";
					let profilePicture = "";
					let nextMeetingTime = "";
					let Vinculateduserid = notificacion.UserRequestedid;
					let redirectProfile = "../user-profile/"+ Vinculateduserid;

				 
					if (NotificationTypeId == 1) {
						AppMessageName = textNotification.param_1;
						classNameAvatar = "bg-success";
						NameUserRequest = UserRequested;
						redirect = "../meetings/next_meetings";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 2) {
						NameUserRequest = UserRequested;
						AppMessageName = textNotification.param_2;
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
						redirect = "../meetings/meetings_requests";
					} else if (NotificationTypeId == 3) { 
						AppMessageName = textNotification.param_3;
						nextMeetingTime = textNotification.param_4;
						classNameAvatar = "bg-warning";
						redirect = "../meetings/next_meetings";
					} else if (NotificationTypeId == 4) {
						AppMessageName = textNotification.param_3;
						nextMeetingTime = textNotification.param_5;
						classNameAvatar = "bg-warning";
						redirect = "../meetings/next_meetings";
					} else if (NotificationTypeId == 8) { 
						AppMessageName = textNotification.param_6;
						classNameAvatar = "bg-danger";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 9) {
						AppMessageName = textNotification.param_7;
						classNameAvatar = "bg-danger";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 10) {
						AppMessageName = textNotification.param_8;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 11) { 
						AppMessageName = textNotification.not_ha_donado_tiempo;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 12) {
						AppMessageName = textNotification.not_ha_donado_tiempo_directo;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 13) {
						AppMessageName = textNotification.not_ha_aceptado_tiempo_directo;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/next_meetings";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 14) {
						AppMessageName = textNotification.not_ha_rechazado_tiempo_directo;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 15) {
						AppMessageName = textNotification.not_reunion_qr;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/next_meetings";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 16) {
						AppMessageName = textNotification.not_tiempo_qr;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../meetings/meetings_requests";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					} else if (NotificationTypeId == 17) {
						AppMessageName = textNotification.not_añadir_contacto;
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = redirectProfile;
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					}
					else if (NotificationTypeId == 18) {
						AppMessageName = "Ha agendado mentoría";
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../events";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					}
					else if (NotificationTypeId == 19) {
						AppMessageName = "Ha cancelado la mentoría";
						classNameAvatar = "bg-warning";
						NameUserRequest = UserRequested;
						redirect = "../events";
						profilePicture = (ProfilePictureUserRequested) ? ProfilePictureUserRequested : "/media/iconos/icono-persona.svg";
					}

					return (
						<React.Fragment key={notificacion.Id}>
							<NotificationItem
								id={Id}
								redirect={redirect}
								profilePicture={profilePicture}
								classNameAvatar={classNameAvatar}
								NameUserRequest={NameUserRequest}
								AppMessageName={AppMessageName}
								NotificationTime={NotificationTime}
								nextMeetingTime={nextMeetingTime}
								ReadedByUser={ReadedByUser}
								notifications={notifications}
								setNotification={setNotifications}
								timeZoneOffset={timeZoneOffset}
								handleClick={toggleDrawer(false)}
								reload={handleReload}
								redirectProfile={redirectProfile}
							/>
						</React.Fragment>
					)
				})}
			</div>
		</>
	}

	return (
		<div>
			<React.Fragment key={"right"}>
				<NavbarItem IconImage={NotificationsOutlined} handleOnClick={toggleDrawer(true)} label={""} counter={counter ? counter : 0} iconClass="fa fa-envelope" labelCaret={true} image="" />
				<SwipeableDrawer
					anchor={"right"}
					open={state}
					onClose={toggleDrawer(false)}
					onOpen={toggleDrawer(true)}
				>
					<Box className="ancho" role="presentation" onKeyDown={toggleDrawer(false)}>
						<div className='titulo-notificaciones d-flex justify-content-center align-items-center'>
							<h4 className='mb-0'>{textNavbar.menu5}</h4>
						</div>
						{notificaciones_html}
					</Box>
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	)
}

export default MsjButton;
