import Zoom from './Zoom'
import { useSelector } from 'react-redux';
import TestCards from '../../../components/Zoom/TestCards';
import { selectDataMeetingZoom } from "../../../redux/features/meetings/meetingsSlice";
import { selectMeetingStatus } from "../../../redux/features/meetings/meetingsSlice";
import CardZoomProfile from '../../../components/Zoom/CardZoomProfile';
import VideoCall2 from '../../../components/TwillioComponents/VideoCall2';
import { personalData } from '../../../interfaces/dimentions';
import { selectPersonalData } from '../../../redux/features/user/userSlice';

const Meeting = () => {

	const data_meeting_zoom: any = useSelector(selectDataMeetingZoom)
	const meeting_status: any = useSelector(selectMeetingStatus)
	// console.log("Meeting data_meeting_zoom.all", data_meeting_zoom.all)
	let datos_solicitud = data_meeting_zoom.all.datos_solicitud;
	// console.log("Meeting datos_solicitud", datos_solicitud)

	/* VARIABLES REUNION */
	let UserRequestedEndTime = datos_solicitud.UserRequestedEndTime;
	let UserRequestedStartTime = datos_solicitud.UserRequestedStartTime;

	let UserRequestedProfilePicture = datos_solicitud.UserRequestedProfilePicture;
	let UserRequestProfilePicture = datos_solicitud.UserRequestProfilePicture;
	
	let UserRequestName = datos_solicitud.UserRequestName;
	let UserRequestLastName = datos_solicitud.UserRequestLastName;

	let UserRequestedName = datos_solicitud.UserRequestedName;
	let UserRequestedLastName = datos_solicitud.UserRequestedLastName;

	let UserRequestId = datos_solicitud.UserRequestId;
	let UserRequestedId = datos_solicitud.UserRequestedId;
	/* VARIABLES REUNION */
	
	// console.log("Meeting data_meeting_zoom", data_meeting_zoom)
	// console.log("Meeting meeting_status", meeting_status)

	const meetingNumber = data_meeting_zoom.meetingNumber;
	const meetingPassword = data_meeting_zoom.meetingPassword;
	const meetingToken = data_meeting_zoom.meetingToken;
	const userName = data_meeting_zoom.userName;
	const userEmail = data_meeting_zoom.userEmail;

	const user1 = data_meeting_zoom.user1;
	const user2 = data_meeting_zoom.user2;
	let roomName = meetingNumber + "-" + user1 + "-" + user2

	
    const user_personal_data: personalData = useSelector(selectPersonalData) 
    let user_id = user_personal_data.Id
	let roomName2 = "MT"+meetingNumber
	// console.log("Meeting user_id", user_id)
	// console.log("Meeting roomName2", roomName2)
	// console.log("Meeting roomName2", roomName2)
	// console.log("roomName2 && user_id", roomName2 && user_id)

	if(roomName2.length > 0 && user_id > 0 ){
		return (
			<VideoCall2 
				userId={user_id} 
				roomName={roomName2}
				UserRequestedEndTime={UserRequestedEndTime}
				UserRequestedStartTime={UserRequestedStartTime}
				UserRequestedProfilePicture={UserRequestedProfilePicture}
				UserRequestProfilePicture={UserRequestProfilePicture}
				UserRequestName={UserRequestName}
				UserRequestLastName={UserRequestLastName}
				UserRequestedName={UserRequestedName}
				UserRequestedLastName={UserRequestedLastName}
				UserRequestId={UserRequestId}
				UserRequestedId={UserRequestedId}
				data_meeting= {data_meeting_zoom.all}
			
			></VideoCall2>
		)
	}else{
		return <></>
	}
}

export default Meeting

/* {!meeting_status.meetingJoin ? (
				<div className='sidebar-meeting-preview-container'>
					{ <Navbar onZoomMeeting={true} handleClickVerUsuariosMeet={()=>{}}></Navbar>}
					<div className='sidebar-meeting-preview'>
						<TestCards user1={user1} user2={user2} showButton={false}></TestCards>
					</div>
				</div>
			) : (
				<div className='sidebar-meeting-join'>
					{ <CardZoomProfile user1={user1} user2={user2} showButton={false}></CardZoomProfile>}
				</div>
			)}
			<Zoom userName={userName} userEmail={userEmail} meetingNumber={meetingNumber} meetingPassword={meetingPassword} meetingToken={meetingToken}></Zoom> */